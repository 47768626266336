import { AfterViewInit, Component, Inject, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, defaultIfEmpty, isEmpty, map, tap, distinctUntilChanged, of, BehaviorSubject } from 'rxjs';
import { ViewJsonComponent } from '../../../dialogs/view-json/view-json.component';
import { cipInjectionTokens } from 'src/environments/InjectionTokens/cipInjectionTokens';
import { CIPQuestion, CIPQuestionRecord } from 'src/app/shared/datasource/interfaces/CIP/cip-questions.interface';
import { DictionaryTableType } from 'src/app/shared/datasource/interfaces/dictionary-table-type.interface';

@Component({
  selector: 'jafar-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent {
  constructor( @Inject(cipInjectionTokens.questionsToken) public Questions: Observable<CIPQuestionRecord>){}
  dialog = inject(MatDialog)

  noQuestionRecords : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  noDisplayQuestionRecords : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)

  ngAfterViewInit(){
  this.Questions.subscribe(
    items => {
      console.log("Questions passed in :", items)
      if (items.displayQuestions){
        const EmptyDisplayQuestions = items.displayQuestions.length == 0 ? true : false
        console.log("EmptyDisplayQuestions", EmptyDisplayQuestions)
        this.noDisplayQuestionRecords.next(EmptyDisplayQuestions)
      }
      else(
        this.noDisplayQuestionRecords.next(true)
      )
      if (items.questions) {
        const EmptyQuestions = items.questions.length == 0 ? true : false
        console.log("EmptyQuestions", EmptyQuestions)
        this.noQuestionRecords.next(EmptyQuestions)
      }
      else(
        this.noQuestionRecords.next(true)
      )
    }
  )
  console.log("noQuestionRecords", this.noQuestionRecords)
  console.log("noDisplayQuestionRecords", this.noDisplayQuestionRecords)
}

  getDictionary(questions : CIPQuestion ){
    let visiblePropeties : string[] =[]
    const Datasource = Object.keys(questions).map(
      (propertyKey : string ) => {
        const indexKey = propertyKey as keyof typeof questions
        const keyValuePair : DictionaryTableType = {
          name: propertyKey,
          value: questions[indexKey]
        }
        visiblePropeties.push(propertyKey)
        return keyValuePair
      }
    )
    return Datasource
  }

  onViewJson (){
    this.dialog.open(ViewJsonComponent, {
      data: this.Questions,
      disableClose: true,
      hasBackdrop: true,
      backdropClass: 'dialog-background'
    } )
  }
}
