import { Component, ElementRef, Input, OnChanges, ViewChild, inject } from '@angular/core';
import { BehaviorSubject} from 'rxjs';
import { ValidationService } from '../../../datasource/services/validation/validation.service';
import { ValidationErrorRecord, ValidationTypes } from 'src/app/shared/datasource/services/validation/validation-pattern.interface';

@Component({
  selector: 'jafar-form-errors',
  templateUrl: './form-errors.component.html',
  styleUrls: ['./form-errors.component.scss']
})
export class FormErrorsComponent implements OnChanges {
  @Input()errors!: any
  @Input()touched!: boolean

  @ViewChild("errorMessage", {read: ElementRef}) ErrorElement! : any

  validationService = inject(ValidationService)
  errorMessages$ = new BehaviorSubject<string[]>([])

  ngOnChanges(): void {
    console.log(this.ErrorElement)
    if (this.touched && this.errors ) {
      this.getErrorMessage()
    }
    else {
      this.errorMessages$.next([])
    }
  }

  getErrorMessage(){
    let messages : string[] = []
    Object.keys(this.errors).forEach(
      (error) => {
        const validationErrorRecord  : ValidationErrorRecord = {
          validationType : error as ValidationTypes,
          validationResult  : this.errors[error]
        }
        messages.push(this.validationService.getErrorMessage(validationErrorRecord) )
      }
    )
    this.errorMessages$.next(messages)
  }

}