import { Component, OnInit, inject } from '@angular/core';
import { CIPEnvRouteDefault } from 'src/app/shared/datasource/interfaces/CIP/cip-envRouteDefault.interface';
import { CipEnvRouteDefaultStore } from 'src/app/shared/datasource/stores/cip/cip-envroutedefault-store.service';
import { TableColumns, TableSettings } from 'src/app/shared/ui/reusable-table/interface/reusable-table.interface';
import { SidePanelStoreService } from 'src/app/shared/ui/templates/pages/page-with-sidepanel/side-panel-store.service';
import { ComponentPortal } from '@angular/cdk/portal';
import { EnvRouteSidepanelComponent } from '../../side-panel-pages/env-route-sidepanel/env-route-sidepanel.component';
import { EnvRouteDefaultSidepanelComponent } from '../../side-panel-pages/env-route-default-sidepanel/env-route-default-sidepanel.component';
import { CreateEnvRouteDefaultSidepanelComponent } from '../../side-panel-pages/create-env-route-default-sidepanel/create-env-route-default-sidepanel.component';
import envRouteDefaultHeaders from 'src/assets/config/tableHeaders/envroutedefault-headers.json'
import { UserStore } from 'src/app/core/datasource/services/user/user-store.service';
@Component({
  selector: 'jafar-env-route-default',
  templateUrl: './env-route-default.component.html',
  styleUrls: ['./env-route-default.component.scss']
})
export class EnvRouteDefaultComponent implements OnInit {
  envRouteDefaultStore  = inject(CipEnvRouteDefaultStore)
  tableSideMenuStore    = inject(SidePanelStoreService)
  userStore             = inject(UserStore)

  hasNoDefaultRoute : boolean = false
  tableHeaders : TableColumns[] = envRouteDefaultHeaders as TableColumns[]
  tableSettings! : TableSettings

  
  ngOnInit(){
    this.envRouteDefaultStore.customerDefaultEnvRoutes$.subscribe(
      route => route.length > 0 ? this.hasNoDefaultRoute = false : this.hasNoDefaultRoute = true
    )
   this.tableSettings = {
     pagination: false,
     paginationOptions: {
       pageSize: 0,
       pageSizeOptions: []
     },
     sorting: {
       sortedColumn: 'active',
       sortedOrder: 'desc'
     },
     search: false,
     reorderColumns: true,
     hidePageSize: true
   }
  }

  onAddEnvRouteDefault(){
    const sideMenuComp  = new ComponentPortal(CreateEnvRouteDefaultSidepanelComponent)
    this.tableSideMenuStore.setSideMenuComponent(sideMenuComp)
    this.tableSideMenuStore.setIsOpen(true)
  }

  onRowSelection(rowSelection: CIPEnvRouteDefault){
    this.envRouteDefaultStore.updateSelectedEnvRouteDefault(rowSelection)
    const sideMenuComp  = new ComponentPortal(EnvRouteDefaultSidepanelComponent)
    this.tableSideMenuStore.setSideMenuComponent(sideMenuComp)
    this.tableSideMenuStore.setIsOpen(true)
  }

}
