<mat-card class="card-framework">
    <mat-card-header class="card-header">
      <div mat-card-avatar class="company-tile"></div>

      <mat-card-title>
        <ng-content select="[title]"></ng-content>
      </mat-card-title>

      <mat-card-subtitle>
        <ng-content select="[subtitle]"></ng-content>
      </mat-card-subtitle>

    </mat-card-header>

    <mat-divider class="divider"></mat-divider>

    <mat-card-content class="card-content">
      <ng-container [ngTemplateOutlet]="contentTemplateRef" [ngTemplateOutletContext]="{}"></ng-container>
    </mat-card-content>

    <mat-card-actions class="card-footer" align="end">
      <ng-container [ngTemplateOutlet]="actionTemplateRef" [ngTemplateOutletContext]="{}"></ng-container>
    </mat-card-actions>
    
  </mat-card>