import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";
import { GenericApiService } from "../../generic-api/generic-api.service";
import { QueryParams } from "../../../../interfaces/generic-api.interface";
import { CIPSettings } from "src/environments/environment";
import { CIPRequestTask, CIPRequestTaskRecord } from "src/app/shared/datasource/interfaces/CIP/cip-requestTasks.interface";
import { CIPRecord } from "src/app/shared/datasource/interfaces/CIP/cip-general.interface";
import { CIPRequestRecord } from "src/app/shared/datasource/interfaces/CIP/cip-requests.interface";

@Injectable({
  providedIn: 'root'
})
export class CIPRequestTasksService  extends GenericApiService{

  httpClient = inject(HttpClient)

  getDefaultHeaders() : HttpHeaders{
    const headers = new HttpHeaders()
    .set('Accept', '*/*')
    .set('Content-Type', 'application/json')

    return headers
  }

  getRequestTasks (search? : string) : Observable<CIPRequestTask[]> {
    const headers = this.getDefaultHeaders()
    const url     = `${CIPSettings.baseUrl}/${CIPSettings.endPoints["requestTasks"]}`
    let query: QueryParams = {
      code    : `${CIPSettings.code}`
    }
    if (search) {
      query = {...query,  filter  : search  }
    }
    return this.get<CIPRequestTask[]>(url, headers, query)
  }

  getRequestTaskDetails(CIPId : string) : Observable<CIPRequestTaskRecord> {
    const headers = this.getDefaultHeaders()
    const url     = `${CIPSettings.baseUrl}/${CIPSettings.endPoints["requestTaskDetails"]}`
    const query: QueryParams = {
      code    : `${CIPSettings.code}`,
      cipId   : CIPId
    }
    return this.get<CIPRequestTaskRecord>(url, headers, query)
  }

}
