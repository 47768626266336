<mat-card class="support-group-card">
    <mat-card-header class="support-group-card-header">
        <div class="support-group-text">Support Groups</div>
        <span class="support-group-title-space"></span>
        <mat-icon matTooltip="View Json Payload" class="support-group-title-icons" (click)="onViewJson()">feed</mat-icon>
    </mat-card-header>

    <mat-card-content class="support-group-content">
        <div  *ngIf="!(NoSupportGroupRecords | async); else displayNoItems">
            <mat-accordion class="support-group-accordian">
                <mat-expansion-panel *ngFor="let item of (SupportGroups |async)" class="support-group-accordian">
                    <mat-expansion-panel-header class="support-group-accordian-header" >
                        <mat-panel-title class="support-group-accordion-title">
                            {{item.type}} -
                        </mat-panel-title >
                        <mat-panel-description >
                            {{item.assignee}}
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="support-group-accordian-content">
                        <jafar-dictionary-table [dataSource]="getDictionary(item)"></jafar-dictionary-table>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>

    </mat-card-content>
</mat-card>

<ng-template #displayNoItems>
    <div class="no-support-groups"> There are NO Support Groups For this Record</div>
</ng-template>
