import { SecretExpirationColor, SecretExpirationStatus, SecretExpirationToolTip } from '../datasource/interfaces/azure-app.interface';
import { ApprovalPayload } from '../datasource/interfaces/approval-interface';
import { UserProfile } from '../datasource/interfaces/user-profile';
import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class HelperFunctionsService {

  constructor(
    private datePipe: DatePipe
  ) { }


  public getTimeDifference(endDateTime : string , outputFormat : TimeFormats ){
    const startDate      = new Date()
    const endDate        = new Date(endDateTime)
    const expiryStateMs  = endDate.getTime() - startDate.getTime()
    const expiryState    = Math.floor(Math.abs(expiryStateMs / outputFormat))
    return expiryState
  }

  public getExpiryStatus(expiryDays : number){
      let status
      switch (true){
          case expiryDays <= 0 : {
              status = {
              status            : 3,
              expirationStatus  : SecretExpirationStatus.Critical,
              expirationToolTip : SecretExpirationToolTip.Critical,
              expirationColor   : SecretExpirationColor.Critical
              }
              break
          }

          case expiryDays <= 30 : {
              status = {
              status            : 2,
              expirationStatus  : SecretExpirationStatus.Warning,
              expirationToolTip : SecretExpirationToolTip.Warning,
              expirationColor   : SecretExpirationColor.Warning
              }
              break
          }

          case expiryDays > 30 : {
              status = {
              status            : 1,
              expirationStatus  : SecretExpirationStatus.Healthy,
              expirationToolTip : SecretExpirationToolTip.Healthy,
              expirationColor   : SecretExpirationColor.Healthy
              }
              break
          }
          default : {
              status = {
              status            : 0,
              expirationStatus  : SecretExpirationStatus.None,
              expirationToolTip : SecretExpirationToolTip.None,
              expirationColor   : SecretExpirationColor.None
              }
              break
          }
      }

      return status
  }

  public updateDropDownList<T>(currentObjects: T[], allObjects: T[]): T[] {
    const stringArray = JSON.stringify(currentObjects)
    console.log("StringArray:", stringArray)
    const returnObject =  allObjects.filter((object: T) => {
      const stringObject = JSON.stringify(object)
      console.log("CurrentString:", stringObject)
      return (stringArray.includes(stringObject)) ? null: object
    })
    console.log(returnObject)
    return returnObject
  }

  public getIcon<T>(value: T, index: string): any {
    type inputType = keyof typeof value;
    let returnObject!: any
    const indexProperty = index as inputType;
    const boolObject = value[indexProperty]
    switch(boolObject){
      case true: {
        const tooltip = index == "isLineManager" ? "True" : "Approved"
        returnObject = {icon: "check_circle",color: "Healthy", tooltip: tooltip}
        break;
      }

      case false: {
        const tooltip = index == "isLineManager" ? "False" : "Denied"
        returnObject = {icon: "cancel", color: "Critical", tooltip: tooltip}
        break;
      }

      default: {
        const tooltip = index == "isLineManager" ? "N/A" : "Action Required"
        returnObject = {icon: "info", color: "None", tooltip: tooltip}
        break;
      }
    }
    return returnObject;
  }

  public createApprovalPayload(requestName: string, description: string, payload: any, userProfile: UserProfile): ApprovalPayload{
    // Construct Approval Payload body with input parameters
    const ApprovalBody: ApprovalPayload = {
      requestName      : requestName,
      RequestFirstName : userProfile.givenName,
      RequestMail      : userProfile.mail,
      RequestLastName  : userProfile.surname,
      Description      : description,
      payload          : payload
    }

    return ApprovalBody
  }

  public capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  public convertToOdataDate(dateObject: any){
    const endDateMidnight: Date = new Date((dateObject.endDate)?.setHours(23,59,59,999))
    console.log(endDateMidnight)
    const formattedDate: any = {
      startDate: this.datePipe.transform(dateObject.startDate, 'yyyy-MM-ddTHH:mm:ss.SSSSSSS') + 'Z',
      endDate: this.datePipe.transform(endDateMidnight, 'yyyy-MM-ddTHH:mm:ss.SSSSSSS') + 'Z'
    }

    return formattedDate
  }
}

export enum TimeFormats {
    Seconds = (1000),
    Minutes = (60 * 1000),
    Hours   = (60 * 60 * 1000),
    Days    = (24 * 60 * 60 * 1000),
    Months  = (7 * 24 * 60 * 60 * 1000),
    Years   = (12 * 24 * 60 * 60 * 1000),
}

export interface DateFilterRange {
  startDate : Date | null
  endDate   : Date | null
}
