import { Component, EventEmitter, Inject, Output, ViewChild, inject, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ChipAutoComplete } from 'src/app/shared/datasource/interfaces/chip-autocomplete.interface';
import { AadCustomGroupResponse, AADMember, EditAadGroupPayload, AADMemberType, AzureCustomGroup } from 'src/app/shared/datasource/interfaces/aad-group.interface';
import { ChipAutocompleteComponent } from 'src/app/shared/ui/chip-autocomplete/chip-autocomplete.component';
import { ArrayDifference, AppArrayDifference } from '../../../datastore/edit-group.interface';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackBarStyle } from 'src/app/shared/ui/snackbar/snackbar.interface';
import { DynamicFormComponent } from 'src/app/shared/ui/dynamic-form/dynamic-form.component';
import editGroupJson from 'src/assets/config/dynamic-forms/group-form.json';
import { DynamicFormSchema } from 'src/app/shared/ui/dynamic-form/dynamic-form.interface';
import { UserService } from 'src/app/core/datasource/services/user/user.service';
import { AadAppregService } from 'src/app/shared/datasource/services/http-requests/azure-functions/application-registrations/aad-appreg.service';
import { AzureApp } from 'src/app/shared/datasource/interfaces/azure-app.interface';
import { AadGroupsService } from 'src/app/shared/datasource/services/http-requests/azure-functions/groups/aad-groups.service';
import { DialogOutput } from 'src/app/shared/datasource/interfaces/dialog.interface';

@Component({
  selector: 'jafar-edit-azure-group-popup',
  templateUrl: './edit-azure-group-popup.component.html',
  styleUrls: ['./edit-azure-group-popup.component.scss']
})
export class EditAzureGroupPopupComponent {
  @ViewChild("editGroupForm")  editGroupForm! : DynamicFormComponent
  @ViewChild("usersDropdown")  usersDropdown! : ChipAutocompleteComponent
  @ViewChild("appsDropdown")   appsDropdown!  : ChipAutocompleteComponent
  @ViewChild("ownersDropdown") ownersDropdown!: ChipAutocompleteComponent

  //import Services
  userProfile = inject(UserService)

  activeState: boolean = false;
  groupNameStepLabel!: string | null;
  editGroupData: DynamicFormSchema[] = editGroupJson as any[]
  // editGroupData: DynamicFormSchema[] = editGroupJson as DynamicFormSchema[]

  // Dropdown List Data Set - Static
  allGroups           : AadCustomGroupResponse[] = [];
  allUsers            : AADMember[] = [];
  allAppRegistrations : AzureApp[] = [];

  // form to store the group description properties
  groupDescriptionForm! : FormGroup

  // These are the original (default) members from the selected Group
  azureDefaultPayload     : any
  azureCurrentPayload     : any
  defaultUsers            : AADMember[] = []
  defaultAppRegistrations : AzureApp[] = []
  defaultOwners           : AADMember[] = []

  // This stores all the changes the user is making on the form
  newGroupInformation! : AzureCustomGroup
  payload! : EditAadGroupPayload

  // Validation - Default status of the Next Step Buttons
  groupSelectedButton      : boolean = false
  membersSelectedButton    : boolean = true
  ownerSelectedButton      : boolean = false
  appSelectedButton        : boolean = false

  // Chip Configs
  groupChipConfig: ChipAutoComplete = {
    listLabel      : 'List of Groups',
    displayName    : 'groupName',
    listPlaceHolder: 'Select Group...'
  };

  userChipConfig: ChipAutoComplete = {
    listLabel      : 'List of Users',
    displayName    : 'displayName',
    listPlaceHolder: 'Add User...'
  };

  appChipConfig: ChipAutoComplete = {
    listLabel      : 'List of AppRegistrations',
    displayName    : 'displayName',
    listPlaceHolder: 'Add App Registration... '
  }

  ownerChipConfig: ChipAutoComplete = {
    listLabel      : 'List of Owners',
    displayName    : 'displayName',
    listPlaceHolder: 'Add Owner...'
  };

  constructor(
    private azureFunction   : AadGroupsService,
    public aadAppRegService : AadAppregService,
    public dialogRef        : MatDialogRef<EditAzureGroupPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AzureCustomGroup
  ){}

  ngOnInit(){
    this.setDefaultValues()
    console.log(this.defaultUsers)
  }

  ngAfterViewInit(){
    this.getCIPUsers()

    console.log("IN AFTERVIEW INIT")
    this.getApplicationList()
    this.getCustomGroups()
    this.setFormDefaults()
  }

  // Method that gets list of groups that the currently logged in user Owns
  getCustomGroups(){
    this.azureFunction.getCustomGroupsByUserId(this.userProfile.activeUser.id).subscribe(
      (groupResponse) => {
        this.allGroups = this.createCopyOfArray(groupResponse)
        console.log(this.allGroups)
      },
    )
  }

  // Method to get a list of all the CIP users
  getCIPUsers(){
    console.log("Getting CIP Users")
    this.azureFunction.getGroupByGroupName('Role_CIP_User').subscribe((response: any) => {
    response.map(
      (group: any) => {
        var allMembers: AADMember[] = []
        group.members.map(
          (member: AADMember) =>{
            const updatedMember: AADMember = {
              id         : member.id,
              displayName: member.displayName,
              type       : AADMemberType.user
            }
            allMembers.push(updatedMember)
          }
        )
        console.log(allMembers)
        this.allUsers = allMembers
      })
    })
  }

  // Method to get list of all the App Registrations user has access too
  getApplicationList(){
    this.aadAppRegService.getApplicationList(this.userProfile.activeUser.id).subscribe(
      apps => {
        this.allAppRegistrations = apps
        this.azureDefaultPayload = apps.map((response: any) => {
          console.log(response)
          return {
            id: response.id,
            appId: response.appId,
            displayName: response.displayName,
            description: response.description,
            friendlyName: response.friendlyName,
            entAppId: response.entAppId
          }
        })
        console.log(apps)
        console.log(this.allAppRegistrations)
      }
    )
  }

  // Method to populate the form with the details from the selected group
  setDefaultValues(){
    console.log(this.data)
    // To ensure seperation we convert to string then back
    this.defaultUsers            = [...this.data.members]
    this.defaultAppRegistrations = [...this.data.apps]
    this.azureCurrentPayload     = this.data.apps.map(
      (response: any) => {
        return {
          id: response.id,
          appId: response.appId,
          displayName: response.displayName,
          description: response.description,
          friendlyName: response.friendlyName,
          entAppId: response.entAppId
        }
      }
    )
    this.defaultOwners           = [...this.data.owners]
    console.log(this.defaultUsers)
    // Set Default form values from dataset
    this.updateGroupDescription()
  }

  setFormDefaults(){
    // This has to be initialized on afterinit since the child component isnt initialized yet
    this.editGroupForm.dynamicForm.controls["groupName"].setValue(this.data.groupName)
    this.editGroupForm.dynamicForm.controls["description"].setValue(this.data.description)
    this.editGroupForm.dynamicForm.controls["contactMail"].setValue(this.data.contactMail)
    this.editGroupForm.dynamicForm.controls["itsmSupportGroup"].setValue(this.data.itsmSupportGroup)
  }

  // Method that updates newGroupInformation with latest selected Group Description
  updateGroupDescription(){
    if(this.editGroupForm){
      this.newGroupInformation = {
        ...this.newGroupInformation,
        groupName        : this.editGroupForm.dynamicForm.controls['groupName'].value ?? this.data.groupName,
        description      : this.editGroupForm.dynamicForm.controls['description'].value ?? this.data.description,
        contactMail      : this.editGroupForm.dynamicForm.controls['contactMail'].value ?? this.data.contactMail,
        itsmSupportGroup : this.editGroupForm.dynamicForm.controls['itsmSupportGroup'].value ?? this.data.itsmSupportGroup,
      }
    }
  }

  // Method that updates newGroupInformation with latest selected Users
  updatedGroupsUserList(currentUserList: AADMember[]) {
    this.newGroupInformation = {
      ...this.newGroupInformation,
      members : this.createCopyOfArray(currentUserList)
    }

    this.hasValidMembers()
  }

  // Method that updates newGroupInformation with latest selected
  updateGroupOwners(currentOwnerList: AADMember[]) {
    this.newGroupInformation = {
      ...this.newGroupInformation,
      owners : this.createCopyOfArray(currentOwnerList)
    }
    if (currentOwnerList && currentOwnerList.length > 0){
      this.ownerSelectedButton = true
    }
    else {
      this.ownerSelectedButton = false
    }
  }

 // Method that updates newGroupInformation with latest selected App Registrations
  updateGroupsAppRegistrations(currentAppList: AADMember[]) {
    this.newGroupInformation = {
      ...this.newGroupInformation,
      apps : this.createCopyOfArray(currentAppList)
    }
    this.hasValidApps()
  }


  // Method Called from the HTML to go to the next step
  goToNextStep(stepper : MatStepper, stepNumber : number){
      switch (stepNumber){

      case 1 : {
        console.log ("Step 1 - Update Group Details")
        this.updateGroupDescription()
        break;
      }

      case 2 : {
        console.log ("Step 2 - Update Group Membership")
        // If User is owner then let user edit group owners
        break;
      }

      case 3 : {
        console.log ("Step 3 - Update Owner Membership")
        break
      }

      case 4 : {
        console.log ("Step 4 - Update App Registrations")
        this.payload = this.constructGroupPayload()
        break;
      }

      case 5: {
        this.updateGroup(this.payload)
        this.snackbarMessage()
      }
    }

    stepper.selected!.completed = true;
    stepper.selected!.editable = false;
    stepper.next()
  }

  // Method Called from HTML to rest the form back to step 1 with default values
  reset(stepper?: MatStepper){
    this.groupSelectedButton = false
    this.groupNameStepLabel = ""
    if (stepper){
      stepper.reset()
    }
    this.setDefaultValues()
  }

  // Method that calls the azure function to edit the group
  updateGroup(payload:EditAadGroupPayload){
    console.log(payload)
    this.azureFunction.updateGroup(payload)
  }

  // Method that constructs the payload needed by azure function to edit group
  constructGroupPayload(){
    // Users
    const newUserIds      : string[] = this.newGroupInformation.members.map((members) => {return members.id})
    const originalUserIds : string[] = this.data.members ? this.data.members.map((members) => {return members.id}) : [];
    const userDifference : ArrayDifference = this.getArrayDifferences(newUserIds, originalUserIds)

    // Apps
    const appDifference : AppArrayDifference = this.getAppDifferences(this.newGroupInformation.apps, this.data.apps)

    // Owners
    let ownerDifference : ArrayDifference;
    if (this.data.isOwner){
      const newOwnerIds     : string[] = this.newGroupInformation.owners.map((member) => {return member.id})
      const originalOwnerIds: string[] = this.data.owners.map((member) => {return member.id})
      ownerDifference = this.getArrayDifferences(newOwnerIds, originalOwnerIds)
      console.log(ownerDifference)
    }
    else{
      ownerDifference = {
        added   : [],
        removed : []
      }
    }


    console.log(this.newGroupInformation)
    let groupPayload: EditAadGroupPayload = {
      reqFirstName     : this.userProfile.activeUser.givenName,
      reqLastName      : this.userProfile.activeUser.surname,
      reqMailAddress   : this.userProfile.activeUser.mail,
      reqUserId        : this.userProfile.activeUser.id,
      id               : this.data.id,
      groupName        : this.newGroupInformation.groupName ?? this.data.groupName,
      description      : this.newGroupInformation.description ?? this.data.description,
      contactMail      : this.newGroupInformation.contactMail ?? this.data.contactMail,
      ITSMSupportGroup : this.newGroupInformation.itsmSupportGroup ?? this.data.itsmSupportGroup,
      membersAdded     : userDifference.added,
      membersRemoved   : userDifference.removed,
      appsAdded        : appDifference.added.map((app) => {return app.entAppId}),
      appsRemoved      : appDifference.removed,
      ownersAdded      : ownerDifference.added,
      ownersRemoved    : ownerDifference.removed
    }
    let stringPayload = JSON.stringify(groupPayload)
    console.log(stringPayload)
    return groupPayload
  }

  // Method to get the differences between 2 arrays
  getArrayDifferences(newArray: string[], oldArray: string[]): ArrayDifference{

    const addition = newArray.filter((item) => {
      return !oldArray?.includes(item)
    })

    const subtraction = oldArray?.filter((item) => {
      return !newArray.includes(item)
    })

    let result: ArrayDifference = {
      added   : addition,
      removed : subtraction
    }
    return result
  }

  getAppDifferences(newApps: AzureApp[], oldApps: AzureApp[]): AppArrayDifference{

    // const newApps : AzureApp[] = newArray.map((app) => {return app})
    // const oldApps : AzureApp[] = oldArray.map((app) => {return app})

    const appIdAddition = newApps.filter(
      (NewApp) => {
        const NewApplicationId      = JSON.stringify(NewApp.id)
        const ExistingAppList = JSON.stringify(oldApps)
        console.log("Is New Ap Id : ", NewApplicationId)
        console.log("In : ", ExistingAppList)
        return !(ExistingAppList.includes(NewApplicationId))
    })
    console.log("Apps To Add : ", appIdAddition)

    // console.log("newAppAssignment" , )
    // const newAppAssignmentId : string[] = newApps.map((App) => {return App.entAppAssignmentId})
    // const oldAppAssignmentId : string[] = oldApps.map((App) => {return App.entAppAssignmentId})
    // console.log("new App Assignment Id", newAppAssignmentId )
    // console.log("old App Assignment Id", oldAppAssignmentId )

    const appIdSubtraction = oldApps
    // Check Which Apps Have been removed from original list
    .filter(
      (OldApp) => {
        const OldApplicationId = JSON.stringify(OldApp.id)
        const NewAppList       = JSON.stringify(newApps)
        console.log("Is Old App : ", OldApplicationId)
        console.log("In : ", NewAppList)
        return !(NewAppList.includes(OldApplicationId))
      }
    )
    // Take the AssignementId of each App
    .map(
      (App) => {
        return App.entAppAssignmentId
      }
    )
    console.log("Apps To Remove : ", appIdSubtraction)

    let result: AppArrayDifference = {
      added   : appIdAddition,
      removed : appIdSubtraction
    }
    return result
  }

  snackbarMessage(){
    const ReturnedData : DialogOutput = {
      data : {
        status:"Success"
      },
      snackbarConfig: {
        buttonName: "OK",
        message: `Group ${this.newGroupInformation.groupName} has been successfully Edited.`,
        style : SnackBarStyle.default,
        settings: {
          horizontalPosition: 'center',
          verticalPosition:'top',
          panelClass : ['edit-snack-bar']
        }
      }
    }
    this.dialogRef.close(ReturnedData)
  }

  onCancel(){
    this.dialogRef.close()
  }

  // Method to stop Mutable nature of Arrays
  createCopyOfArray(arrayToCopy:any){
    const strArray = JSON.stringify(arrayToCopy)
    const jsonArray = JSON.parse(strArray)
    return jsonArray
  }

  hasValidMembers() {
    if (this.newGroupInformation.members.length !== 0){
      this.membersSelectedButton = true
    }
    else {
      this.membersSelectedButton = false
    }
  }

  hasValidApps(){
    if (this.newGroupInformation.apps.length !== 0){
      this.appSelectedButton = true
    }
    else {
      this.appSelectedButton = false
    }
  }

  onClose(): void {
    this.dialogRef.close(false)
  }
}
