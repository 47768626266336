import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";
import { CIPSettings } from "src/environments/environment";
import { GenericApiService } from "../../generic-api/generic-api.service";
import { QueryParams } from "../../../../interfaces/generic-api.interface";
import { CIPIncident, CIPIncidentRecord } from "src/app/shared/datasource/interfaces/CIP/cip-incidents.interface";
import { CIPRecord } from "src/app/shared/datasource/interfaces/CIP/cip-general.interface";

@Injectable({
  providedIn: 'root'
})
export class CIPIncidentsService extends GenericApiService{

  private httpClient = inject(HttpClient)

  private getDefaultHeaders() : HttpHeaders{
    const headers = new HttpHeaders()
    .set('Accept', '*/*')
    .set('Content-Type', 'application/json')

    return headers
  }

  getIncidents (search? : string) : Observable<CIPIncident[]> {
    const headers = this.getDefaultHeaders()
    const url     = `${CIPSettings.baseUrl}/${CIPSettings.endPoints["incidents"]}`
    let query: QueryParams = {
      code    : `${CIPSettings.code}`
    }
    if (search) {
      query = {...query,  filter  : search  }
    }
    console.log(search)
    return this.get<CIPIncident[]>(url, headers, query)
  }


  getIncidentDetails(CIPId : string) : Observable<CIPIncidentRecord> {
    const headers = this.getDefaultHeaders()
    const url     = `${CIPSettings.baseUrl}/${CIPSettings.endPoints["incidentDetails"]}`
    const query: QueryParams = {
      code    : `${CIPSettings.code}`,
      cipId   : CIPId
    }
    // this.get<HttpResponse<CIPIncidentRecord>>(url, headers, query).subscribe(result => console.log(result))
    return this.get<CIPIncidentRecord>(url, headers, query)
  }

}
