import { AfterViewInit, Component, ChangeDetectionStrategy, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { BehaviorSubject, filter, map, Observable, startWith } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ChipAutoComplete } from '../../datasource/interfaces/chip-autocomplete.interface';
import { HelperFunctionsService } from '../../functions/helperfunctions';

@Component({
  selector: 'jafar-chip-autocomplete',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './chip-autocomplete.component.html',
  styleUrls: ['./chip-autocomplete.component.scss'],
})

export class ChipAutocompleteComponent implements OnInit,OnChanges{

  @Input()inputData!: any[]
  @Input()inputConfig!: ChipAutoComplete
  @Input()defaultData?: any[]
  @Output()newItemEvent = new EventEmitter<any[]>();

  helperFunctions = inject(HelperFunctionsService)
  separatorKeysCodes: number[] = [ENTER, COMMA];
  inputBox = new FormControl<string | any>('');
  updatedList: any[] = []
  dropdownList = new BehaviorSubject<any[]>([])
  dropdownList$ = this.dropdownList.asObservable()
  selectedItems: any[] = [];
  displayProperty!: string

  constructor() {  }

  ngOnInit(){
    console.log(this.defaultData)
    console.log(this.inputData)
    this.displayProperty = this.inputConfig.displayName
    this.updatedList = this.defaultData ? this.helperFunctions.updateDropDownList(this.defaultData, this.inputData) : this.inputData
    console.log(this.updatedList)
    this.dropdownList.next(this.updatedList)
    this.dropdownList$ = this.dropdownList.asObservable()
  }

  ngOnChanges(changes: SimpleChanges){
    console.log(changes)
    console.log("NgOnChanges:",this.inputData)
    this.defaultData ? this.selectedItems = this.defaultData : ""
    this.updatedList = this.defaultData ? this.helperFunctions.updateDropDownList(this.defaultData, this.inputData) : this.inputData
    console.log("NgOnChanges UpdatedList:", this.updatedList)
    this.dropdownList.next(this.updatedList)
    this.newItemEvent.emit(this.selectedItems)
    this.inputBox.setValue(null)
  }

  add(event: MatChipInputEvent): void {
    // Clear the input value
    event.chipInput!.clear();
    console.log("Do i ever go into add function")
    this.inputBox.setValue(null);
  }

  remove(item: any): void {
    const index = this.selectedItems.indexOf(item);
    console.log(item)
    if (index >= 0) {
      this.selectedItems.splice(index, 1);
      console.log(this.selectedItems)
      this.dropdownList.next(this.helperFunctions.updateDropDownList(this.selectedItems, this.updatedList))
      this.dropdownList$ = this.dropdownList.asObservable()
      this.newItemEvent.emit(this.selectedItems)
    }
  }

  selected(item: MatAutocompleteSelectedEvent): void {
    // Convert Items to string so we can compare them
    const newItem      = JSON.stringify(item.option.value).toLowerCase()
    const currentItems = JSON.stringify(this.selectedItems).toLowerCase()

    // Only add data that isnt already in the list
    if (!currentItems.includes(newItem)){
      this.selectedItems.push(item.option.value);
      this.newItemEvent.emit(this.selectedItems)
    }

    this.inputBox.setValue(null);
    this.inputBox.markAsPristine()
    this.inputBox.markAsUntouched()
    this.inputBox.reset()
    this.dropdownList.next(this.helperFunctions.updateDropDownList(this.selectedItems, this.inputData))
    this.dropdownList$ = this.dropdownList.asObservable()
  }

  inputFilter(){
    this.dropdownList$ = this.inputBox.valueChanges.pipe(
      map((inputText: any) => {
        console.log(inputText)
        if (inputText){
          console.log(typeof inputText)
          let filter = inputText
          filter = typeof inputText === 'string' ? inputText : inputText[this.displayProperty]
          return filter ? this._filter(filter as string) : this.updatedList.slice()
        }
        else {return this.updatedList}
      }),
    );
  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    const displayProperty = this.inputConfig.displayName
    return this.updatedList.filter(data => data[displayProperty].toLowerCase().includes(filterValue));
  }

  displayFn(displayData: any): string {
    return displayData && displayData[this.displayProperty] ? displayData[this.displayProperty]: null;
  }

  public reset(defaultData?: any[]){
    console.log("DropDown being Reset")
    console.log(this.selectedItems)
    if (defaultData && defaultData.length > 0 ) {
      this.selectedItems = defaultData
      console.log(this.selectedItems)
    }
    else {
      this.selectedItems = []
      console.log(this.selectedItems)
    }
    this.newItemEvent.emit(this.selectedItems)
  }

  clearInput(input: HTMLInputElement){
    input.value = ""
  }
}
