import { Component, Inject, OnInit, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DynamicFormSchema } from '../../../../../shared/ui/dynamic-form/dynamic-form.interface';
import companyJsonForm  from 'src/assets/config/dynamic-forms/company-form.json'
import { CreateCompanyPayload, CreateCompanyResponse, CreateCustomerPayload } from 'src/app/shared/datasource/interfaces/cip.interface';
import { SnackBarStyle } from 'src/app/shared/ui/snackbar/snackbar.interface';
import { SpinnerService } from '../../../../../shared/datasource/services/spinner/spinner.service';
import { DynamicFormComponent } from 'src/app/shared/ui/dynamic-form/dynamic-form.component';
import { ValidationService } from 'src/app/shared/datasource/services/validation/validation.service';
import { CipService } from 'src/app/shared/datasource/services/http-requests/azure-functions/cip/cip.service';
import { UserService } from 'src/app/core/datasource/services/user/user.service';
import { UserProfile } from 'src/app/shared/datasource/interfaces/user-profile';
import { ApprovalResponse } from 'src/app/shared/datasource/interfaces/approval-interface';
import { DialogOutput } from 'src/app/shared/datasource/interfaces/dialog.interface';
import { HelperFunctionsService } from 'src/app/shared/functions/helperfunctions';

@Component({
  selector: 'jafar-create-company-popup',
  templateUrl: './create-company-popup.component.html',
  styleUrls: ['./create-company-popup.component.scss']
})
export class CreateCompanyPopupComponent implements OnInit {
  formData : DynamicFormSchema[] = companyJsonForm as DynamicFormSchema[]
  validateCompanyName: string[] = []
  @ViewChild("companyForm") companyForm! : DynamicFormComponent

  public myForm = this.formbuilder.group({
    firstName: "",
    customInput : ""
  })
  userProfile = inject (UserService)

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private jeannieService    : CipService,
    private formbuilder       : FormBuilder,
    public spinnerService     : SpinnerService,
    private validationService : ValidationService,
    public dialogRef          : MatDialogRef<CreateCompanyPopupComponent>,
    private helperFunctions   : HelperFunctionsService
  ){}

  ngOnInit(){
    this.getValidationSet()
  }

  createCompanyPayload(form : FormGroup) : CreateCompanyPayload{
    console.log(form)
    const companyPayload : CreateCompanyPayload = {
      Name        : form.value.companyName,
      DisplayName : form.value.companyDisplayName
    }
    return companyPayload
  }

  getValidationSet(){
    this.jeannieService.getCIPCompanies().subscribe(
      (results)=> {
        results.forEach((company) => {
          this.validateCompanyName.push(company.name.toLowerCase())
        })
      }
    )
  }

  createCompanyRequest(companyPayload: CreateCompanyPayload){
    const userProfile: UserProfile = this.userProfile.activeUser
    const companyDescription = `Company Creation Request: ${this.companyForm.dynamicForm.controls["companyName"].value}`

    // Create App Payload
    const companyRequestPayload = this.helperFunctions.createApprovalPayload("CreateCompany", companyDescription, companyPayload, userProfile)
    console.log(JSON.stringify(companyRequestPayload))

    this.jeannieService.createCIPCompany(companyRequestPayload).subscribe((response: any) => {
      console.log("response:", response)
      this.createSucceedMessage(response)
    })
  }

  createSucceedMessage(response : ApprovalResponse){
    const ReturnedData : DialogOutput = {
      data : {
        status:"Request Submission Success",
        responseData: response
      },
      snackbarConfig: {
        buttonName: "OK",
        message:
          `Company Request has been submitted succesfully.
                  Your Request number is : ${response.id}`,
        style : SnackBarStyle.default,
        settings: {
          horizontalPosition: 'center',
          verticalPosition:'top'
        }
      }
    }
    this.dialogRef.close(ReturnedData)
  }

  createFailureMessage(message: string){
    const ReturnedData : DialogOutput = {
      data : {
        status:"Request Submission Failed"
      },
      snackbarConfig: {
        buttonName: "Close",
        message: message,
        style : SnackBarStyle.default,
        settings: {
          horizontalPosition: 'center',
          verticalPosition:'top'
        }
      }
    }
    this.dialogRef.close(ReturnedData)
  }

  onSubmit(){
    let nameValidation: boolean = this.validationService.validate(this.companyForm.dynamicForm.value.companyName, this.validateCompanyName)
    if(!nameValidation){
      const payload = this.createCompanyPayload(this.companyForm.dynamicForm)
      this.createCompanyRequest(payload)
    }else{
      let failureMessage = `Company Create Submission Failed. Company with name "${this.companyForm.dynamicForm.value.companyName}" already exists`
      this.createFailureMessage(failureMessage)
    }
  }

  onCancel(): void {
    this.dialogRef.close(false)
  }


}
