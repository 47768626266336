<div class="dialog-framework">
  <div class="title-framework">
    <span class="title-spacing"></span>
    <title mat-dialog-title class="center-title">
      Create Application Secret
    </title>
    <span class="title-spacing"></span>
    <mat-icon class="close-icon-position" matTooltip="Close" (click)="onCancel()">close</mat-icon>
  </div>
    <mat-divider></mat-divider>
    <br>
    <div class="center-text">
      You are about to create a secret for application:<br>
      <h3><b>{{data.appName}}</b></h3>
    </div>

    <mat-stepper #stepper
      orientation="vertical"
      [linear]="true"
      animationDuration="800"
      class="stepper"
    >

    <mat-step completed="false" editable="true" label="Secret Name" >
      <div class="secret-name-group">
        <div class="center-text" >
          Please enter a name for the application secret
          <br>
        </div>

        <div>
          <mat-form-field appearance="outline" class="text-box" color="accent" >
            <mat-label>Application Secret</mat-label>
            <input matInput [(ngModel)]="secretName">
            <button *ngIf="secretName" matSuffix mat-icon-button (click)="secretName=''">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>

      </div>

      <div class="button-group">
        <jafar-action-button [disabled]="step1NextButtonDisabled" (btnClick)="onNext(stepper)">Next</jafar-action-button>
        <jafar-dismiss-button (btnClick)="onCancel()">Cancel</jafar-dismiss-button>
      </div>


    </mat-step>

      <mat-step completed="false" editable="true" label="Expiration" >
        <div class="expiration-group">
          <div class="center-text" >
            Please select secret expiry time.<br>
          </div>

          <div>
            <mat-form-field appearance="outline" class="dropdown" color="accent" >
              <mat-label>Expiry Months</mat-label>
              <mat-select (valueChange)="onExpirySelection($event) " >
                <mat-option value=3>3 Months</mat-option>
                <mat-option value=6 >6 Months</mat-option>
                <mat-option value=12>12 Months</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

        </div>

        <div class="button-group">
          <jafar-action-button [disabled]="step2NextButtonDisabled" (click)="onNext(stepper)">Next</jafar-action-button>
          <jafar-dismiss-button (click)="onCancel()">Cancel</jafar-dismiss-button>
        </div>


      </mat-step>

      <mat-step completed="false" editable="true" label="Confirmation">
        <br>
        <div>
          <h3>You are about to create the following secret:</h3>
        </div>
        <div>
          Application:<br>
          <b>{{ data.appName }}</b><br>
          <br>
          Secret Name:<br>
          <b>{{ secretName }}</b><br>
          <br>
          Expiry:<br>
          <b> {{ expiryMonths}} Months</b><br>
          <br>
        </div>
        <div>
          <div> Are you sure you want to create the above Secret?</div>
          <br>
          <div class="button-group">
            <jafar-action-button (click)="onSubmit()">Submit</jafar-action-button>
            <jafar-dismiss-button (click)="onCancel()">Cancel</jafar-dismiss-button>
          </div>
          <br>
        </div>
      </mat-step>

    </mat-stepper>

 </div>



