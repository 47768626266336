<mat-card class="card-appearance">
  <mat-card-header class="mat-header">
    <h1><ng-content></ng-content></h1>
    <span>
      <div>
        <ng-container [ngTemplateOutlet]="toolbarTemplateRef" [ngTemplateOutletContext]="{}"></ng-container>
      </div>
    </span>
  </mat-card-header>
  <mat-divider class="divider"></mat-divider>
  <mat-card-content>
    <ng-container    *ngIf="(spinnerService.visibility | async); then spinner; else content"></ng-container>
  </mat-card-content>
</mat-card>

<ng-template #content>
  <div>
    <jafar-reusable-table [tableData]="table.data | async" [tableColumns]="table.columns" [tableSettings]="table.settings" (selectedRecord)="onSelect($event)"></jafar-reusable-table>
  </div>
</ng-template>

<ng-template #spinner>
  <div class="spinner-container">
      <mat-spinner value="Loading Table Content..." color="accent" class="spinner"></mat-spinner>
      <br>
      <br>
      <h1 class="spinner-title">Loading Table Content...</h1>
  </div>
</ng-template>
