import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { ControlValueAccessorDirective } from '../../form-cva/control-value-accessor.directive';
import { TextAreaControlOptions } from '../../dynamic-form.interface';

@Component({
  selector: 'jafar-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TextAreaComponent,
      multi : true
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic'
      }
    }
  ]
})
export class TextAreaComponent<T> extends ControlValueAccessorDirective<T> implements AfterViewInit {
  @Input()textAreaOptions!: TextAreaControlOptions | undefined
  @Input()customErrorMessages! : any
  placeholder! : string
  label! : string
  rows!: string
  cols!: string

  ngAfterViewInit(): void {
    this.label = this.textAreaOptions?.label ?? ""
    this.placeholder = this.textAreaOptions?.placeholder ?? ""
    this.rows = this.textAreaOptions?.rows ?? ""
    this.cols = this.textAreaOptions?.cols ?? ""
    console.log(this.label, this.placeholder, this.rows, this.cols)
  }
}
