<mat-form-field
  class="form-style"
  appearance="outline"
  color="accent"
>
  <mat-label>{{label}}</mat-label>

  <input matInput
    type="list"
    color="accent"
    (keyup)="onKeyPress($event)"
    (focus)="onFocus()"
    [placeholder]="placeholder"
    [matAutocomplete]="auto"
    [value]="textBoxValue"
    [formControl]="control"
  />

  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="getDisplayProperty"
    (optionSelected)="onSelection($event)"
  >
      <mat-option *ngFor="let item of dropDownList | async" [value]="item">
        {{item[displayProperty]}}
      </mat-option>

      
  </mat-autocomplete>
</mat-form-field>
<jafar-form-errors
  [errors]="control.errors"
  [touched]="control.touched"
></jafar-form-errors>
