<mat-card class="side-menu-card" [ngClass]="'custom-route-colour'">
    <mat-card-title class="side-menu-card-title">
      <div class="side-menu-back-button-container">
        <mat-icon matTooltip="Back To Route Tables" class="side-menu-back-button material-symbols-outlined" (click)="onGoBack()">arrow_back_ios</mat-icon>
      </div>
      <div class="side-menu-card-title-text">{{routeTitle}}</div>
      <ng-container *ngIf="showActionIcons; then actionIcons; else editIcons"></ng-container>
    </mat-card-title>

    <mat-card-content class="side-menu-card-content">
      <ng-container [ngSwitch]="showPage">
        <ng-container *ngSwitchCase="'viewRouteForm'"   [ngTemplateOutlet]="viewRouteForm"></ng-container>
        <ng-container *ngSwitchCase="'editRouteForm'"   [ngTemplateOutlet]="editRouteForm"></ng-container>
        <ng-container *ngSwitchCase="'deleteRouteForm'" [ngTemplateOutlet]="deleteRouteForm"></ng-container>
        <ng-container *ngSwitchCase="'showJson'"        [ngTemplateOutlet]="showJson"></ng-container>
      </ng-container>
    </mat-card-content>
  </mat-card>

  <ng-template #actionIcons>
    <div class="side-menu-action-icons">
      <mat-icon matTooltip="View Json" class="side-menu-title-icons material-symbols-outlined" (click)="onViewJsonIcon()">feed</mat-icon>

      <ng-container *ngIf="userStore.routingAdmin$ | async">
        <mat-icon matTooltip="Edit Route" class="side-menu-title-icons material-symbols-outlined" (click)="onEditRouteIcon()">stylus</mat-icon>
        <mat-icon matTooltip="Delete Route" class="side-menu-title-icons material-symbols-outlined" (click)="onDeleteRouteIcon()">delete</mat-icon>
        </ng-container>
    </div>
  </ng-template>

  <ng-template #editIcons>
    <div class="side-menu-action-icons">
      <mat-icon matTooltip="Cancel" class="side-menu-title-icons" (click)="onCancel()">close</mat-icon>
    </div>
  </ng-template>

  <ng-template #viewRouteForm>
    <div class="delete-form-alignment">
      <div class="delete-form-title-styling">ID</div>
      <div class="delete-form-body-styling">{{envRouteStore.selectedRoute.id}}</div><br>
      <div class="delete-form-title-styling">Created At</div>
      <div class="delete-form-body-styling">{{envRouteStore.selectedRoute.createdAt  | date:"full"}}</div><br>
      <div class="delete-form-title-styling">Customer</div>
      <div class="delete-form-body-styling">{{envRouteStore.selectedRoute.customer}}</div><br>
      <div class="delete-form-title-styling">Question</div>
      <div class="delete-form-body-styling">{{envRouteStore.selectedRoute.question}}</div><br>
      <div class="delete-form-title-styling">Answer</div>
      <div class="delete-form-body-styling">{{envRouteStore.selectedRoute.answer}}</div><br>
      <div class="delete-form-title-styling">Route Name</div>
      <div class="delete-form-body-styling">{{envRouteStore.selectedRoute.routeName}}</div><br>
      <div class="delete-form-title-styling">Config</div>
      <div class="delete-form-body-styling">{{envRouteStore.selectedRoute.config}}</div><br>
      <div class="delete-form-title-styling">Active</div>
      <div style="text-transform: capitalize" class="delete-form-body-styling">{{envRouteStore.selectedRoute.active}}</div><br>
    </div>
  </ng-template>

  <ng-template #editRouteForm>
    <div (mousemove)="editFormLoad()">
      <jafar-dynamic-form class ="form-item" [form]="envRouteForm" #formComponents></jafar-dynamic-form>
      <div *ngIf="!showActionIcons" class="side-menu-button-group">
        <!-- <jafar-dismiss-button  class="side-menu-button-reset" (btnClick)="onReset()">Reset</jafar-dismiss-button>
        <span class="side-menu-button-spacing"></span> -->
        <jafar-action-button class="side-menu-button-submit" [disabled]="!formComponents.dynamicForm.valid || formComponents.dynamicForm.touched == false" (btnClick)="onUpdate()">Save</jafar-action-button>
      </div>
    </div>
  </ng-template>

  <ng-template #deleteRouteForm>
    <div class="delete-form-alignment">
      <h3>You are about to delete the following route:</h3>
      <ng-container [ngTemplateOutlet]="viewRouteForm"></ng-container>
      <jafar-action-button class="delete-route-button" (btnClick)="onDelete()">Delete Route</jafar-action-button>
    </div>
  </ng-template>

  <ng-template #showJson>
    <pre class="json-text"> {{envRouteStore.selectedRoute | json}} </pre>
  </ng-template>
