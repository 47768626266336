<mat-card appearance="outlined" class="landing-page-card">
  <mat-card-title class="headline-h1">
    Welcome to the User Experience Portal
  </mat-card-title>
  <mat-card-content>
  <mat-divider></mat-divider>
  <div class="body-spacing">
    <h4>Below are a few helpful links to help get you started:</h4>
  </div>
  <div *ngIf="(wikiService.wikiObservable$ | async); let wikiPages">
    <mat-grid-list cols="8" rowHeight="100px" gutterSize="10px">
      <mat-grid-tile *ngFor="let wikiPage of wikiPages"
      [colspan]="1"
      [rowspan]="1"
      class="grid-style">
        <a
          href="{{wikiPage.remoteUrl}}"
          target="_blank"
          class="text-inside-grid"
        >
          {{wikiPage.name}}
        </a>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
  </mat-card-content>
</mat-card>
