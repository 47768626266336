import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment, azureFunctions } from 'src/environments/environment';
import { QueryParams } from '../../../../interfaces/generic-api.interface';
import { GenericApiService } from '../../generic-api/generic-api.service';
import { AppSecret } from '../../../../interfaces/azure-app-secret.interface';
import { SecretExpirationStatus, SecretExpirationToolTip, SecretExpirationColor } from '../../../../interfaces/azure-app.interface';
import { createAppSecretRequest, deleteAppSecretRequest } from '../../../../interfaces/app-secret.interface';

@Injectable({
  providedIn: 'root'
})
export class AppSecretService extends GenericApiService{

  private readonly graphApiUrl: string = environment.generic.graphApiUrl
  resourceUrl: string = "";

  constructor(protected httpClient: HttpClient) {
    super(httpClient)
  }

  getHeaders(): HttpHeaders{
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json; charset=utf-8');
    httpHeaders = httpHeaders.set('odata.metadata', 'minimal');
    return httpHeaders
  }

  private defaultHeaders(): HttpHeaders{
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json; charset=utf-8');
    return httpHeaders
  }

  getApplicationSecret(appObjectId: string):Observable<AppSecret[]>{
    let apiUrl = `${azureFunctions.portalFunctions.baseUrl}/AppSecret`
    let myheaders = this.defaultHeaders();
    const query: QueryParams = {
      code    : `${azureFunctions.portalFunctions.authCode}`,
      appId   : `${appObjectId}`
    }
    return this.get<AppSecret[]>(apiUrl, myheaders, query).pipe(
      map (
        (appSecrets: AppSecret[]) => {
          console.log(appSecrets)
          let allSecrets: AppSecret[] = []
          appSecrets.map(
            (appsecret: AppSecret) => {
              const expiryDays   = getTimeDifference(appsecret.endDateTime, TimeFormats.Days)
              const expiryStatus = getExpiryStatus(expiryDays)
              appsecret.startDateTime     = (new Date(appsecret.startDateTime)).toUTCString(),
              appsecret.endDateTime       = (new Date(appsecret.endDateTime)).toUTCString(),
              appsecret.expiryDays        = expiryDays,
              appsecret.expirationStatus  = expiryStatus.expirationStatus,
              appsecret.expirationToolTip = expiryStatus.expirationToolTip,
              appsecret.expirationColor   = expiryStatus.expirationColor,
              allSecrets.push(appsecret)
            }
          )
          return allSecrets
        }
      )
    )
  } // Rework of Method finished - now resides in Azure Functions

  addApplicationSecret( payload : createAppSecretRequest): Observable<AppSecret>{
    let apiUrl = `${azureFunctions.portalFunctions.baseUrl}/AppSecret`
    let expiryDate = this.getISOExpirtyDateFromMonths(payload.expiryMonths);
    let myheaders = this.defaultHeaders();
    const query: QueryParams = {
      code    : `${azureFunctions.portalFunctions.authCode}`
    }
    // Construct Body Payload
    let body: any = {
      appObjectId : payload.appObjectId,
      value: {
        passwordCredential:{
          displayName : payload.displayName,
          endDateTime: expiryDate
        }
      }
    }
    // Stringify Object
    const postBody = JSON.stringify(body)
    // Post Object to Endpoint
    return this.post<AppSecret>(apiUrl, postBody, myheaders, query)
  }

  deleteApplicationSecret(payload : deleteAppSecretRequest){
    let apiUrl = `${azureFunctions.portalFunctions.baseUrl}/AppSecret`
    let myheaders = this.defaultHeaders();
    const query: QueryParams = {
      code  : `${azureFunctions.portalFunctions.authCode}`,
      appId : payload.appObjectId,
      keyId : payload.keyId,
    }
    // Post Object to Endpoint
    return this.delete<AppSecret>(apiUrl, myheaders, query)
  }

  // Support API Methods to be moved out
  getISOExpirtyDateFromMonths(expiryMonths :number) : string{
    const expiryDate = new Date();
    expiryDate.setMonth(expiryDate.getMonth() + parseInt(expiryMonths.toString()));
    console.log(expiryDate.toISOString())
    return expiryDate.toISOString()
  }

}

export enum TimeFormats {
  Seconds = (1000),
  Minutes = (60 * 1000),
  Hours   = (60 * 60 * 1000),
  Days    = (24 * 60 * 60 * 1000),
  Months  = (7 * 24 * 60 * 60 * 1000),
  Years   = (12 * 24 * 60 * 60 * 1000),
}

export function getTimeDifference(endDateTime : string , outputFormat : TimeFormats ){
  const startDate      = new Date()
  const endDate        = new Date(endDateTime)
  const expiryStateMs  = endDate.getTime() - startDate.getTime()
  const expiryState    = Math.floor(Math.abs(expiryStateMs / outputFormat))
  return expiryState
}

export function getExpiryStatus(expiryDays : number){
  let status
  switch (true){
    case expiryDays <= 0 : {
      status = {
        status            : 3,
        expirationStatus  : SecretExpirationStatus.Critical,
        expirationToolTip : SecretExpirationToolTip.Critical,
        expirationColor   : SecretExpirationColor.Critical
      }
      break
    }

    case expiryDays <= 30 : {
      status = {
        status            : 2,
        expirationStatus  : SecretExpirationStatus.Warning,
        expirationToolTip : SecretExpirationToolTip.Warning,
        expirationColor   : SecretExpirationColor.Warning
      }
      break
    }

    case expiryDays > 30 : {
      status = {
        status            : 1,
        expirationStatus  : SecretExpirationStatus.Healthy,
        expirationToolTip : SecretExpirationToolTip.Healthy,
        expirationColor   : SecretExpirationColor.Healthy
      }
      break
    }
    default : {
      status = {
        status            : 0,
        expirationStatus  : SecretExpirationStatus.None,
        expirationToolTip : SecretExpirationToolTip.None,
        expirationColor   : SecretExpirationColor.None
      }
      break
    }
  }

  return status
}
