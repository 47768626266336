import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MarkdownDialogComponent } from 'src/app/shared/ui/markdown-dialog/markdown-dialog.component';
import { MarkdownDisplayService } from 'src/app/shared/datasource/services/markdown-display/markdown-display.service';

@Component({
  selector: 'jafar-help-icon',
  templateUrl: './help-icon.component.html',
  styleUrls: ['./help-icon.component.scss']
})
export class HelpIconComponent implements OnInit {

  constructor(
    public markdownService: MarkdownDisplayService,
    public dialog: MatDialog

  ) { }

  ngOnInit(): void {
  }

  openWiki(){
    let url = 'https://dev.azure.com/CCGDCore/Portal/_wiki/wikis/Portal.wiki/45/Setting-Up-Angular'   
    window.open(url, '_blank');
  }
  
  openDialog(path:string, title: string){ 
    
    this.markdownService.getMDContent(path)
    this.dialog.open(MarkdownDialogComponent, {
      data: {
        Title: title
      }
    });
  }

}
