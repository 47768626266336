export function compareDates(currentValue: Date, filterStartDate: Date){
  const validDate: boolean = currentValue > filterStartDate ? true : false
  return validDate
}

export function convertStringToDate(input: string){
  const dateValue: Date = new Date(input)
  return dateValue
}

export function getFilterStartDate(days: number){
  var date = new Date();
  console.log(date.toLocaleDateString(), date.toLocaleTimeString())
  var last = new Date(date.getTime() - (Number(days) * 24 * 60 * 60 * 1000));
  return last
}
