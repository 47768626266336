<ng-container
	*ngIf="(spinnerService.visibility | async) && formSubmitted; then spinner; else content">
</ng-container>

<ng-template #content>
	<div class="dialog-framework">
		<h1 class="title"> Create Customer</h1>
		<mat-divider></mat-divider>
		<jafar-dynamic-form class ="form-item" [form]="formData" #customerForm></jafar-dynamic-form>
    <div class="redirect-link">
      <span class="icon-position">
        <mat-icon class="icon-spacing">info</mat-icon>
        <span>Company not in List? Create one <a routerLink="/managecip/companies" (click)="onCancel()">HERE</a></span>
      </span>
    </div>
		<div class="button-group">
      <jafar-action-button [disabled]="!customerForm.dynamicForm.valid" (btnClick)="onSubmit()">Submit</jafar-action-button>
      <jafar-dismiss-button (btnClick)="onCancel()">Cancel</jafar-dismiss-button>
		</div>
	</div>
</ng-template>

<ng-template #spinner>
	<div class="spinner-container">
		<mat-spinner value="Creating Company..." color="accent" class="spinner"></mat-spinner>
		<br>
		<br>
		<h1 class="title">Creating Customer ...</h1>
	</div>

</ng-template>




