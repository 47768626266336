import { Injectable } from '@angular/core';
import { JeannieCompany, JeannieCustomer} from '../../../../interfaces/cip.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { azureFunctions, environment } from 'src/environments/environment';
import { Observable, map, shareReplay } from 'rxjs';
import { QueryParams } from '../../../../interfaces/generic-api.interface';
import { GenericApiService } from '../../generic-api/generic-api.service';
import { ApprovalPayload, ApprovalResponse } from 'src/app/shared/datasource/interfaces/approval-interface';

@Injectable({
  providedIn: 'root'
})
export class CipService extends GenericApiService {
  constructor(private httpClient: HttpClient) {
    super(httpClient)
  }

  //Auth Token Variables
  cipTokenUrl: string = environment.generic.jeannieAuthUrl

  getHeaders(): HttpHeaders{
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json; charset=utf-8');
    httpHeaders.set('Accept', '*/*')
    return httpHeaders
  }

  getCipHeaders(): HttpHeaders{
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set("Accept", '*/*');
    return httpHeaders
  }

  getCIPCustomers(): Observable<JeannieCustomer[]>{
    let allCustomersUrl: string = `${azureFunctions.portalFunctions.baseUrl}/CIPCustomer`
    let headers = this.getCipHeaders()
    const query: QueryParams = {
      code    : `${azureFunctions.portalFunctions.authCode}`
    }
    return this.get<JeannieCustomer[]>(allCustomersUrl, headers, query).pipe(
      map(
        (customerPayload : JeannieCustomer[]) => {
          customerPayload.map(
            (customer) => {
              if (customer.active){
                customer.customerIconColour = "Healthy"
                customer.customerStatus  = "check_circle"
                customer.customerToolTip = "Active"
              }
              else {
                customer.customerIconColour = "Critical"
                customer.customerStatus  = "cancel"
                customer.customerToolTip = "Inactive"
              }
              return customer
            }
          )
          return customerPayload
        }
      )
    )

  }

  getCIPCompanies(): Observable<JeannieCompany[]>{
    let allCustomersUrl: string = `${azureFunctions.portalFunctions.baseUrl}/CIPCompanies`
    let headers = this.getCipHeaders()
    const query: QueryParams = {
      code    : `${azureFunctions.portalFunctions.authCode}`
    }

    return this.get<JeannieCompany[]>(allCustomersUrl, headers, query).pipe(
      map(
        (companyPayload : JeannieCompany[]) => {
          companyPayload.map(
            (company) => {
              if (company.active){
                company.companyIconColour = "Healthy"
                company.companyStatus     = "check_circle"
                company.companyToolTip    = "Active"
              }
              else {
                company.companyIconColour = "Critical"
                company.companyStatus     = "cancel"
                company.companyToolTip    = "Inactive"
              }
              return company
            }
          )
          return companyPayload
        }
      ),
      shareReplay({bufferSize :1, refCount: true})
      ) as Observable<JeannieCompany[]>
  }

  createCIPCompany(companyBody: ApprovalPayload): Observable<ApprovalResponse>{
    let createCompanyUrl: string = `${azureFunctions.approvalFunctions.baseUrl}/NewApprovalProcess`
    let headers = this.getCipHeaders()
    const query: QueryParams = {
      code    : `${azureFunctions.approvalFunctions.authCode}`
    }

    const jsonBody = JSON.stringify(companyBody);

    return this.post<ApprovalResponse>(createCompanyUrl, jsonBody, headers, query)
  }

  createCIPCustomer(customerBody: ApprovalPayload): Observable<ApprovalResponse>{
    let createCustomerUrl: string = `${azureFunctions.approvalFunctions.baseUrl}/NewApprovalProcess`
    let headers = this.getCipHeaders()
    const query: QueryParams = {
      code    : `${azureFunctions.approvalFunctions.authCode}`
    }
    const jsonBody = JSON.stringify(customerBody);
    return this.post<ApprovalResponse>(createCustomerUrl, jsonBody, headers, query)
  }

}
