import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'jafar-view-json',
  templateUrl: './view-json.component.html',
  styleUrls: ['./view-json.component.scss']
})
export class ViewJsonComponent {
  jsonPayload! : any
  constructor(
    public dialogRef: MatDialogRef<ViewJsonComponent>,
    @Inject(MAT_DIALOG_DATA) public jsonData: Observable<any>,
  ) {}


}
