import { Component, Inject, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, BehaviorSubject, map, tap } from 'rxjs';
import { ViewJsonComponent } from '../../../dialogs/view-json/view-json.component';
import { CipRowFilterComponent } from '../../../dialogs/cip-row-filter/cip-row-filter.component';
import { cipInjectionTokens } from 'src/environments/InjectionTokens/cipInjectionTokens';
import { DictionaryTableType } from 'src/app/shared/datasource/interfaces/dictionary-table-type.interface';

@Component({
  selector: 'jafar-cip-information',
  templateUrl: './cip-information.component.html',
  styleUrls: ['./cip-information.component.scss'],
  // imports: [MatExpansionModule, CommonModule, MatIconModule, MatCardModule, CoreModule ],
  // standalone: true

})
export class CipInformationComponent {
  // @Input('SupportGroups') SupportGroups!: Observable<CIPSupportGroup[]>
  constructor( @Inject(cipInjectionTokens.cipInformationToken) public inputData: Observable<any>){
    // console.log(inputData)
  }

  dialog = inject(MatDialog)
  cipTypePropertyList: DictionaryTableType[] = []
  visibleCipTypeProperties: string[] = []

  ngOnInit(){
    this.inputData.subscribe((result: any) => {
      // console.log(result)
      this.getProperties(result)})
  }

  getProperties(CIPObject : any ){
    // console.log(CIPObject)
    const Datasource = Object.keys(CIPObject).map(
      (propertyKey :any ) => {
        const keyValuePair : DictionaryTableType = {
          name: propertyKey,
          value: CIPObject[propertyKey]
        }
        if( CIPObject[propertyKey] !== null && CIPObject[propertyKey] !== ''){
            this.visibleCipTypeProperties.push(propertyKey)
            // console.log(this.visibleCipTypeProperties)
        }
        return keyValuePair
      }
    )
    this.cipTypePropertyList = Datasource
    // console.log("Property List", this.cipTypePropertyList)
    // console.log("Visible Properties", this.visibleCipTypeProperties)

  }

  onViewJson (){
    this.dialog.open(ViewJsonComponent, {
      data: this.inputData,
      disableClose: true,
      hasBackdrop: true,
      backdropClass: 'dialog-background'
    } )
    // JSON.stringify(information)
  }

  onChooseRows(){
    const dialogRef = this.dialog.open(CipRowFilterComponent, {
      data: {
        allProperties      : this.cipTypePropertyList,
        selectedProperties : this.visibleCipTypeProperties,
        title: "Available Rows",
        description: "Please select the Rows you would like to display"
      }
    });

    dialogRef.afterClosed().subscribe((result:any) => {
      // console.log('The dialog was closed');
      // console.log(result)
      this.visibleCipTypeProperties = [...result]
    });
  }
}
