<mat-chip-listbox
    multiple
    (change)="toggleSelection($event)"
>
    <mat-chip-option
        *ngFor="let option of (chipOptions)"
        [disabled]="option.disabled"
        [value]="option.id"
        [selected]="option.selected"

    >
        {{option.displayname}}
    </mat-chip-option>
</mat-chip-listbox>

<!-- <jafar-form-errors
    [errors]="control.errors"
    [touched]="control.touched"
></jafar-form-errors> -->


