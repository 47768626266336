import { Injectable, inject } from "@angular/core";
import { BehaviorSubject, filter, isEmpty, map, of, switchMap, tap } from 'rxjs';
import { AADGroup, AadCustomGroupResponse, AadGroupMembersResponse } from "src/app/shared/datasource/interfaces/aad-group.interface";
import { UserProfile } from "src/app/shared/datasource/interfaces/user-profile";
import { AadGroupsService } from "src/app/shared/datasource/services/http-requests/azure-functions/groups/aad-groups.service";
import { UserProfileService } from "src/app/shared/datasource/services/http-requests/ms-graph/user/user-profile.service";

@Injectable({
    providedIn: 'root'
})

export class UserStore {
  private aadGroupService = inject(AadGroupsService)
  private userService = inject(UserProfileService)

  private readonly _opsDashboardUser$ : BehaviorSubject<boolean>      = new BehaviorSubject<boolean>(false);
  private readonly _routingAdmin$ : BehaviorSubject<boolean>         = new BehaviorSubject<boolean>(false);
  private readonly _userProfile$     : BehaviorSubject<UserProfile>   = new BehaviorSubject<UserProfile>(_EmptyUserProfile);

  fetchUserProfile(){
    this.userService.getUser().subscribe(
      response => {
        this._userProfile$.next(response)
        console.log("user profile", this._userProfile$.getValue())
      }
    )
  }

  fetchOpsDashboardUser(userId : string) {
    console.log(userId)
    this.aadGroupService.getGroupByName('Role_Portal_OpsDashboard_Prod')
    .pipe(
      map ( (groupResponse : AADGroup) => {return groupResponse.members}),
      switchMap ( (groupResponse : AadGroupMembersResponse[]) => {return groupResponse}),
      filter ( (member : AadGroupMembersResponse) => (member.id === userId)),
      isEmpty()
    )
    .subscribe(result => this._opsDashboardUser$.next(!result))
  }

  fetchRoutingAdmins(userId: string){
    this.aadGroupService.getGroupByName('Role_Portal_Routing_Prod')
    .pipe(
      map ( (groupResponse : AADGroup) => {return groupResponse.members}),
      switchMap ( (groupResponse : AadGroupMembersResponse[]) => {return groupResponse}),
      filter ( (member : AadGroupMembersResponse) => (member.id === userId)),
      isEmpty()
    )
    .subscribe(result => this._routingAdmin$.next(!result))
  }

  get opsDashboardUser$ () {
    return this._opsDashboardUser$.asObservable()
  }

  get opsDashboardUser () {
    return this._opsDashboardUser$.getValue()
  }

  get routingAdmin$ () {
    return this._routingAdmin$.asObservable()
  }

  get routingAdmin () {
    return this._routingAdmin$.getValue()
  }

  get userProfile$ (){
    return this._userProfile$.asObservable()
  }
}

const _EmptyUserProfile = {
  displayName: "",
  givenName  : "",
  surname    : "",
  mail       : "",
  id         : "",
}
