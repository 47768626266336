<ng-container *ngIf="tableSettings.search" >
  <mat-form-field class="filter" appearance="outline" color="accent">
    <mat-label>Filter</mat-label>
    <input  matInput   (keyup)="filterTable($event)" placeholder="filter" #input>
  </mat-form-field>
</ng-container>
<mat-table matSort
  [dataSource]="dataSource"
  cdkDropList
  cdkDropListOrientation="horizontal"
  (cdkDropListDropped)="onDrop($event)"
  [matSortActive]="tableSettings.sorting.sortedColumn"
  [matSortDirection]="tableSettings.sorting.sortedOrder"
>

  <ng-container *ngFor="let column of tableColumns" [matColumnDef]="column.id" >
    <ng-container
      *ngIf="column.sortable; then sortableColumn; else notSortable">
    </ng-container>

    <ng-template #sortableColumn>
      <mat-header-cell *matHeaderCellDef cdkDrag mat-sort-header [ngClass]="column.headerPosition">
        {{column.name}}
      </mat-header-cell>
    </ng-template>

    <ng-template #notSortable>
      <mat-header-cell *matHeaderCellDef cdkDrag [ngClass]="column.headerPosition">
        {{column.name}}
      </mat-header-cell>
    </ng-template>

    <ng-container
    *ngIf="column.format == 'icon'; then routeIcon; else routeText">
    </ng-container>
    <ng-template #routeText>
      <mat-cell *matCellDef="let element; let row"
        [ngClass]="column.contentPosition"
      >
        {{element[column.id] | tableFormatting : column.format }}
    </mat-cell>
    </ng-template>
    <ng-template #routeIcon>
      <mat-cell *matCellDef="let element; let row"
      [ngClass]="column.contentPosition">
      <div [ngClass]="element[column.id] | tableStyling: column.style">
        <mat-icon matTooltip="{{element[column.id]}}" class="icon-style">
          {{element[column.id] | tableFormatting : column.format}}
        </mat-icon>
      </div>
    </mat-cell>
    </ng-template>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumnsIds"></mat-header-row>
  <mat-row
    *matRowDef="let row; columns: displayedColumnsIds;"
    matRipple
    (click)="onRowSelection(row)"
  ></mat-row>

</mat-table>

<mat-paginator *ngIf="tableSettings.pagination && tableSettings.paginationOptions"
  [pageSize]="tableSettings.paginationOptions.pageSize"
  [pageSizeOptions]="tableSettings.paginationOptions.pageSizeOptions"
  showFirstLastButtons="true"
  [hidePageSize]="tableSettings.hidePageSize"
></mat-paginator>
