import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { QueryParams } from '../../../../interfaces/generic-api.interface';
import { Observable, map } from 'rxjs';
import { AppSecret } from '../../../../interfaces/azure-app-secret.interface';
import { AzureApp, SecretExpirationColor, SecretExpirationStatus, SecretExpirationToolTip } from '../../../../interfaces/azure-app.interface';
import { azureFunctions, environment } from 'src/environments/environment';
import { GenericApiService } from '../../generic-api/generic-api.service';
import { HelperFunctionsService, TimeFormats} from '../../../../../functions/helperfunctions';
import { ApprovalPayload, ApprovalResponse } from 'src/app/shared/datasource/interfaces/approval-interface';

@Injectable({
  providedIn: 'root'
})
export class AadAppregService extends GenericApiService {

  constructor(private httpClient: HttpClient) {
    super(httpClient)
  }

  helperFunctions = inject(HelperFunctionsService)

  // Cutover To New Function Done
  getApplicationList(userId:string): Observable<AzureApp[]> {
    const uri: string = `${azureFunctions.portalFunctions.baseUrl}/UserApps`
    const defaultHttpHeaders = this.defaultHeaders()
    const query: QueryParams = {
      code    : `${azureFunctions.portalFunctions.authCode}`,
      userId  : `${userId}`
    }
    // Revisit this function. Currently, if there are no secrets, the app doesnt get returned. Maybe something else to be used instead of Pipe?
    return this.get<AzureApp[]>(uri, defaultHttpHeaders, query).pipe(
      map (
        (appRegPayload: AzureApp[]) => {
          let allApps: AzureApp[] = []
          let filteredAppReg = appRegPayload.filter(
            (app) => {return app.appName.endsWith(`-${environment.appRegistrationPrefix}`) }
          )
          filteredAppReg.map(
            (app) => {
              if(app.passwordCredentials.length === 0){
                app.expirationStatus  = SecretExpirationStatus.None
                app.expirationToolTip = SecretExpirationToolTip.None
                app.expirationColor   = SecretExpirationColor.None
                allApps.push(app)
              }
              else{
                let currentExpiryStatus = 0
                app.passwordCredentials.forEach(
                  (secret: AppSecret) => {
                    const expiryDays   = this.helperFunctions.getTimeDifference(secret.endDateTime, TimeFormats.Days)
                    const expiryStatus = this.helperFunctions.getExpiryStatus(expiryDays)

                    if (expiryStatus.status > currentExpiryStatus) {
                      currentExpiryStatus   =  expiryStatus.status
                      app.expirationStatus  = expiryStatus.expirationStatus
                      app.expirationToolTip = expiryStatus.expirationToolTip
                      app.expirationColor   = expiryStatus.expirationColor
                    }
                  }
                )
                allApps.push(app)
              }
            }
          )
          return allApps
        }
      )
    )
  }

  createApplication(createAppPayload: ApprovalPayload): Observable<any>{
    let createAppUrl: string = `${azureFunctions.approvalFunctions.baseUrl}/NewApprovalProcess`
    let headers = this.defaultHeaders()
    const query: QueryParams = {
      code    : `${azureFunctions.approvalFunctions.authCode}`
    }
    const jsonPayload = JSON.stringify(createAppPayload);
    console.log(jsonPayload)
    return this.post<ApprovalResponse>(createAppUrl, jsonPayload, headers, query)
  }

  deleteApplication(AppObjectId: string){
    const uri: string = `${azureFunctions.portalFunctions.baseUrl}/AADAppReg`
    console.log(uri);
    const defaultHttpHeaders = this.defaultHeaders()
    const query: QueryParams = {
      code    : `${azureFunctions.portalFunctions.authCode}`,
      objectId: AppObjectId,
    }

    return (this.delete(uri, defaultHttpHeaders, query)).subscribe(result => console.log(result))
  }

  private defaultHeaders(): HttpHeaders{
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set("Accept", '*/*');
    httpHeaders.set("Content-Type", "application/json")
    return httpHeaders
  }
}
