import { ChangeDetectorRef, Component, OnChanges, OnInit, ViewChild, inject } from '@angular/core';
import { UserStore } from 'src/app/core/datasource/services/user/user-store.service';
import { CIPEnvRoute, CIPEnvRouteUpdateRequest } from 'src/app/shared/datasource/interfaces/CIP/cip-envRoute.interface';
import { CipEnvRouteStore } from 'src/app/shared/datasource/stores/cip/cip-envroute-store.service';
import { DynamicFormComponent } from 'src/app/shared/ui/dynamic-form/dynamic-form.component';
import { DynamicFormSchema } from 'src/app/shared/ui/dynamic-form/dynamic-form.interface';
import { SidePanelStoreService } from 'src/app/shared/ui/templates/pages/page-with-sidepanel/side-panel-store.service';
import envRouteJson from 'src/assets/config/dynamic-forms/envroute-form.json'

@Component({
  selector: 'jafar-env-route-sidepanel',
  templateUrl: './env-route-sidepanel.component.html',
  styleUrls: ['./env-route-sidepanel.component.scss']
})
export class EnvRouteSidepanelComponent implements OnInit {
  @ViewChild("formComponents") formComponents! : DynamicFormComponent

  // Inject Services \ Stores
  sidePanelStore  = inject(SidePanelStoreService)
  userStore       = inject(UserStore)
  envRouteStore   = inject(CipEnvRouteStore)
  changeDetectorRef = inject(ChangeDetectorRef)

  // Objects that contain the form Configuration
  envRouteForm  : DynamicFormSchema[] = envRouteJson as DynamicFormSchema[]

  // Page Routing \ Layout Options
  routeTitle      : string        = "Route Details"
  showPage        : TablePageType = "viewRouteForm"
  showActionIcons : boolean       = true
  editPageFirstLoad : boolean = false

  ngOnInit(){
    this.setDefaults()
  }

  setDefaults(){
    this.envRouteForm.forEach(
      formComponent => {
        const envProperty = formComponent.controlName as keyof CIPEnvRoute
        formComponent.defaultValue = this.envRouteStore.selectedRoute[envProperty]
      }
    )
  }

  editFormLoad(){
    if (!this.editPageFirstLoad){
      console.log("Triggering Change Detection")
      this.changeDetectorRef.detectChanges()
      this.changeDetectorRef.markForCheck()
      this.editPageFirstLoad = true
    }
  }

  onGoBack(){
    this.sidePanelStore.setIsOpen(false)
  }

  onCancel(){
    if (this.formComponents) {
      this.setDefaults()
    }
    this.routeTitle = "Route Details"
    this.showActionIcons = true
    this.showPage = "viewRouteForm"
  }

  onViewJsonIcon(){
    this.showPage = "showJson"
    this.routeTitle = "View Route as Json"
    this.showActionIcons = false
  }

  onEditRouteIcon(){
    this.showPage        = "editRouteForm"
    this.routeTitle      = "Edit Route"
    this.showActionIcons = false
  }

  onDeleteRouteIcon(){
    this.showPage        = "deleteRouteForm"
    this.showActionIcons = false
    this.routeTitle      = "Delete Route"
  }

  onUpdate(){
    const routeId = this.envRouteStore.selectedRoute.id
    const updateRoute: CIPEnvRouteUpdateRequest ={
      routeName : this.formComponents.dynamicForm.get('routeName')?.value.routeId,
      config    : this.formComponents.dynamicForm.get('config')?.value,
      active    : this.formComponents.dynamicForm.get('active')?.value.length > 0 ? true : false,
      updateAssociatedItems: false
    }
    this.envRouteStore.editEnvRoute(routeId, updateRoute)
    this.onGoBack()
  }

  onDelete(){
    const routeId = this.envRouteStore.selectedRoute.id
    this.envRouteStore.deleteEnvRoute(routeId)
    this.onGoBack()
  }

}

// DEPRECATED - As we no longer toggle the edit we can contol the visibility through the json config
  // enableControls(){
  //   this.envRouteToggleFields.forEach((formName: string) => {
  //     this.routeFormData.dynamicForm.get(formName)?.enable()
  //   })
  //   this.showActionIcons = false
  // }

  // disableControls(){
  //   this.envRouteToggleFields.forEach((formName: string) => {
  //     this.routeFormData.dynamicForm.get(formName)?.disable()
  //   })
  //   this.showActionIcons = true
  // }






export type TablePageType = "viewRouteForm" | "editRouteForm"| "deleteRouteForm" | "showJson"| "routeAsFormData"
