<mat-table [dataSource]="dataSource" matSort
  [dataSource]="dataSource" 
  cdkDropList
  cdkDropListOrientation="horizontal"
  (cdkDropListDropped)="drop($event)"
>

  <ng-container
    [matColumnDef]="column.columnHeaderIndex"
    *ngFor="let column of tableHeaders"
  >
  <span>
    <mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag>
      <div class="icon-alignment" *ngIf="column.icon == true else default">
        {{column.columnHeaderName}}
      </div>
      <ng-template #default>
        {{column.columnHeaderName}}
      </ng-template>
      
    </mat-header-cell>

    <mat-cell *matCellDef="let element; let row"
      [ngClass] = "{'selected-row' : row == selectedRecord}"
    >
    <div class="icon-alignment" *ngIf="column.icon == true && column.iconToolTip && column.iconColor else value">
      <mat-icon matTooltip="{{element[column.iconToolTip]}}" class="{{element[column.iconColor]}}">{{element[column.columnHeaderIndex]}}</mat-icon>
    </div>
    <ng-template #value>
      {{element[column.columnHeaderIndex]}}
    </ng-template>
    </mat-cell>
  </span>
  </ng-container>

  <mat-header-row

    *matHeaderRowDef="columnHeaderIndex"
  ></mat-header-row>

  <mat-row matRipple *matRowDef="let row; columns: columnHeaderIndex;"
    (click)="onRowSelection(row)"
  ></mat-row>

</mat-table>

<mat-paginator
  [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions"
></mat-paginator>

