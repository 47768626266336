import { Component, TemplateRef, ViewChild, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { idType, CIPRecord } from 'src/app/shared/datasource/interfaces/CIP/cip-general.interface';
import { CipSearchComponent } from 'src/app/shared/ui/dialogs/cip-search/cip-search.component';
import requestHeadersJson  from '../../../../assets/config/tableHeaders/request-headers.json'
import { CipRequestStore } from 'src/app/shared/datasource/stores/cip/cip-request-store.service';
import { FormGroup, FormControl, Validators, AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { CIPRequest } from 'src/app/shared/datasource/interfaces/CIP/cip-requests.interface';
import { BehaviorSubject, Observable, catchError, map, of } from 'rxjs';
import { CipSearchData } from 'src/app/shared/datasource/interfaces/CIP/cip-page-layout.interface';
import { ReusableTable, TableColumns } from 'src/app/shared/ui/reusable-table/interface/reusable-table.interface';
import { PaginationDefaults } from 'src/environments/generic-variables';
import { CipCustomerStore } from 'src/app/shared/datasource/stores/cip/cip-customer-store.service';
import { Customer } from '../../manage-azure-app-reg/datastore/customer.interface';
import { CipRowFilterComponent } from 'src/app/shared/ui/dialogs/cip-row-filter/cip-row-filter.component';
import { DateRangeComponent } from 'src/app/shared/ui/date-range-picker/date-range/date-range.component';
import { DateFilterRange, HelperFunctionsService } from 'src/app/shared/functions/helperfunctions';

@Component({
  selector: 'jafar-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss']
})
export class RequestsComponent {
  @ViewChild('datePicker') datePicker!: DateRangeComponent

  // All Injected Services / Stores
  requestStore  = inject(CipRequestStore)
  dialog = inject(MatDialog)
  router = inject(Router)
  customerStore = inject(CipCustomerStore)
  helperFunctions = inject(HelperFunctionsService)

  // Local Variables and Obsedrvables
  allRequestTableHeaders: TableColumns[] = requestHeadersJson as TableColumns[]
  displayedRequestTableHeaders!: TableColumns[]
  requestTable$ = new BehaviorSubject<ReusableTable>({
    data     : this.requestStore.requests$,
    columns  : this.displayedRequestTableHeaders,
    settings : {
      pagination: true,
      paginationOptions: PaginationDefaults,
      sorting: {
        sortedColumn: 'createdAt',
        sortedOrder: 'desc'
      },
      search: true,
      reorderColumns: true,
      hidePageSize: false
    }
  })

  customerSelected!: boolean
  selectedDateRange!: DateFilterRange | null
  customerDropdown = {
    listLabel       : "Customer",
    displayName     : "displayName"
  }

  // All public variables
  public requestFilters: idType[] = ['id', 'internalRequestId', 'customer']
  public searchData!: CipSearchData
  public SearchFG = new FormGroup (
    {
      searchId         : new FormControl('', { validators: [Validators.required], updateOn: 'blur'}),
    }
  )

  @ViewChild("search") searchTemplate!: TemplateRef<any>

  ngOnInit(){
    // Check to see if a customer is in the store, if it is, filter the data on that customer.
    this.customerStore.customer.displayName != "" ?
    this.requestStore.fetchCustomerRequests(this.customerStore.customer) :
    this.requestStore.fetchRequests()

    this.customerStore.fetchCustomers()
    this.displayedRequestTableHeaders = [...this.allRequestTableHeaders]
    .filter((columns: TableColumns) => {return columns.show})
    this.requestTable$.next({
      ...this.requestTable$.getValue(),
      columns: this.displayedRequestTableHeaders
    })
    this.requestStore.resetRequestInfo()
  }

  ngAfterViewInit() {
    this.searchData = {
      cipType: "Request",
      template: this.searchTemplate,
      id: "",
      idType: "id",
    }
    this.SearchFG.controls['searchId'].addAsyncValidators(this.requestDetailValidator())
    this.SearchFG.controls['searchId'].updateValueAndValidity()
  }

  goToRequest(cipId:string){
    console.log("pageID", cipId)
    this.router.navigate(["opsdashboard/request",cipId])
  }

  selectFilter(event: idType){
    this.SearchFG.controls['searchId'].clearAsyncValidators()
    this.SearchFG.controls['searchId'].updateValueAndValidity()
    this.SearchFG.reset()
    this.customerStore.clearSelectedCustomer()
    this.searchData.idType = event
  }

  getRequestInfo(){
    console.log(this.searchData.idType)

    // Clear Async Validators
    switch(this.searchData.idType)
    {
      case "id":
        // Add Async Validators back
        this.SearchFG.controls['searchId'].addAsyncValidators(this.requestDetailValidator())
        // Initialize Validators
        this.SearchFG.controls['searchId'].updateValueAndValidity()
        if(this.SearchFG.controls.searchId.value){
          (this.requestStore.fetchRequestInfo(this.SearchFG.controls.searchId.value as string))
        }
        console.log(this.SearchFG)
        break;

      case "internalRequestId":
        this.SearchFG.controls['searchId'].addAsyncValidators(this.requestValidator())
        this.SearchFG.controls['searchId'].updateValueAndValidity()
        const searchString = `internalRequestId eq '${this.SearchFG.controls['searchId'].value}'`
        console.log(searchString)
        this.requestStore.fetchRequests(searchString)
        console.log(this.SearchFG)
        break;

      default:
        console.log("ERROR: Invalid Search Type")
        break
    }
  }

  getCustomerRequests(customer: Customer){
    const dateFilter = this.selectedDateRange ? this.selectedDateRange : null
    if(customer && dateFilter !== null){
      console.log("CUSTOMER + DATE FILTER")
      this.customerStore.updateSelectedCustomer(customer)
      this.requestStore.fetchCustomerRequests(customer, this.selectedDateRange)
    }else if(customer && dateFilter == null){
      console.log("CUSTOMER ONLY FILTER")
      this.customerStore.updateSelectedCustomer(customer)
      this.requestStore.fetchCustomerRequests(customer)
    }else{
      console.log("NO FILTER")
      this.customerStore.clearSelectedCustomer()
      this.requestStore.fetchRequests()
    }
  }

  getRequestsByDateRange(dateRange: DateFilterRange){
    const customer = this.customerStore.customer.displayName != "" ? this.customerStore.customer : null
    if(customer !== null && dateRange){
      console.log("CUSTOMER + DATE FILTER")
      this.requestStore.fetchCustomerRequests(customer, this.selectedDateRange)
    }else{
      console.log("DATE ONLY FILTER")
      const dateFilter = `createdAt ge ${dateRange.startDate} and createdAt le ${dateRange.endDate}`
      this.requestStore.fetchRequests(dateFilter)
    }
  }

  private requestValidator(): AsyncValidatorFn{
    return (control: AbstractControl): Observable<ValidationErrors | null> =>
    this.requestStore.validateRequests(`internalRequestId eq '${control.value}'`).pipe(
      map((response: CIPRequest[]) =>
        {
          console.log(response.length)
          return response.length != 0 ? null : {validateRequests: true}
        }
      )
    )
  }

  private requestDetailValidator(): AsyncValidatorFn{
    return (control: AbstractControl): Observable<ValidationErrors | null> =>
    this.requestStore.validateRequestInfo(control.value).pipe(
      map((response: CIPRecord) =>
        {
          console.log(response)
          return response ? null: {validateRequestInfo: true}
        }
      ),
      catchError(() => {
        return of({validateRequestInfo: true});
      })
    )
  }

  onCancel(){
    this.requestStore.fetchRequests()
    this.dialog.closeAll()
    this.SearchFG.reset()
    this.searchData = {
      cipType: "Request",
      template: this.searchTemplate,
      id: "",
      idType: "id",
    }
  }

  onClearFilter(){
    // Clear all values selected in date picker
    this.selectedDateRange = null
    this.datePicker.dateRange.controls['startDate'].setValue(null)
    this.datePicker.dateRange.controls['endDate'].setValue(null)
    // Clear any selected customers, and refetch all incidents without filters
    this.customerStore.clearSelectedCustomer()
    this.requestStore.fetchRequests()
  }

  onChooseColumns(){
    console.log(this.allRequestTableHeaders)
    console.log(this.displayedRequestTableHeaders)
    const selectedColumns: TableColumns[] = [...this.allRequestTableHeaders]
    .filter((column: TableColumns) => {
      console.log(column.show)
      return column.show
    })
    console.log(selectedColumns)
    const dialogRef = this.dialog.open(CipRowFilterComponent, {
      data: {
        allProperties      : this.allRequestTableHeaders,
        selectedProperties : selectedColumns.map((column: TableColumns) => column.name),
        title: "Available Columns",
        description: "Please select the Columns you would like to display"
      }
    });

    dialogRef.afterClosed().subscribe((result:any) => {
      console.log(result)
      const updatedColumns = [...this.allRequestTableHeaders].map(
        (column: TableColumns) => {
          result.includes(column.name) ? column = {...column, show: true} : column = {...column, show: false}
          return column
        })

      this.allRequestTableHeaders = updatedColumns
      this.requestTable$.next({
        ...this.requestTable$.getValue(),
        columns: updatedColumns.filter((column: TableColumns) => {return column.show})
      })
    });
  }

  onDateFilter(event: any){
    console.log(event)
    const dateObject: DateFilterRange = {
      startDate: event.controls['startDate'].value,
      endDate: event.controls['endDate'].value
    }
    const formattedDate = this.helperFunctions.convertToOdataDate(dateObject)
    this.selectedDateRange = formattedDate
    console.log(formattedDate)
    this.getRequestsByDateRange(formattedDate)
  }

  onRefresh(){
    const customer = this.customerStore.customer.displayName != "" ? this.customerStore.customer : null
    const dateFilter = this.selectedDateRange ? this.selectedDateRange : null
    if(customer !== null && dateFilter !== null){
      console.log("CUSTOMER + DATE FILTER")
      this.requestStore.fetchCustomerRequests(customer, this.selectedDateRange)
    }else if(customer && dateFilter == null){
      console.log("CUSTOMER ONLY FILTER")
      this.requestStore.fetchCustomerRequests(customer)
    }else if(customer == null && dateFilter){
      console.log("DATE ONLY FILTER")
      const searchString = `CreatedAt ge ${dateFilter.startDate} and CreatedAt le ${dateFilter.endDate}`
      this.requestStore.fetchRequests(searchString)
    }else{
      console.log("NO FILTER")
      this.requestStore.fetchRequests()
    }
  }

  onSearch(){
    this.customerStore.clearSelectedCustomer()
    this.dialog.open(
      CipSearchComponent, {
        data:this.searchData,
        disableClose: true
      });
  }

  onSelect(event: any){
    console.log(event)
    const request = event as CIPRequest
    this.goToRequest(request.id)
  }

  onSubmit(){
    const searchType = this.searchData.idType as string
    if(searchType == "id"){
      this.SearchFG.controls['searchId'].value ? this.goToRequest(this.SearchFG.controls['searchId'].value): null
    }

    if(searchType == "internalRequestId"){
      this.requestStore.requests$.subscribe(
        (response: any) => {
        if(response.length == 1 && this.SearchFG.controls['searchId'].value){
          this.goToRequest(response[0].id)
        }
      })
    }
    this.dialog.closeAll()
    this.SearchFG.reset()
  }
}
