import { Component, OnInit, ViewChild, inject,  } from '@angular/core';
import { Router } from '@angular/router';
import { AccessRequestService } from '../../datasource/services/access-request.service';
import { ThemeService } from '../../datasource/services/theme/theme.service';
import accessRequestJson from 'src/assets/config/dynamic-forms/access-request-form.json'
import { DynamicFormSchema } from 'src/app/shared/ui/dynamic-form/dynamic-form.interface';
import { DynamicFormComponent } from 'src/app/shared/ui/dynamic-form/dynamic-form.component';
import { UserService } from '../../datasource/services/user/user.service';
import { AccessRequestPayload } from '../../datasource/interfaces/access-request';
import { UserProfile } from 'src/app/shared/datasource/interfaces/user-profile';
import { HelperFunctionsService } from 'src/app/shared/functions/helperfunctions';

@Component({
  selector: 'jafar-access-request',
  templateUrl: './access-request.component.html',
  styleUrls: ['./access-request.component.scss'],
})

export class AccessRequestComponent implements OnInit {
  @ViewChild("accessRequestForm") accessRequestForm! : DynamicFormComponent
  accessRequestData : DynamicFormSchema[] = accessRequestJson as DynamicFormSchema[]
  userProfile = inject (UserService)
  helperFunctions = inject(HelperFunctionsService)

  constructor(
    private router: Router,
    private accessRequest : AccessRequestService,
    public theme : ThemeService
  ){}

  ngOnInit(): void {
  }

 onSubmit(){
    console.log(this.accessRequestForm)
    this.newUserRequest()
    this.accessRequest.setFeedbackMessage("Your request has been submitted. You should receive an email shortly with confirmation.")
    this.router.navigate(["/login"])
  }

  newUserRequest(){
    const payloadDescription: string = `${this.accessRequestForm.dynamicForm.value.justification}`
    const user: UserProfile = {
      displayName: `${this.accessRequestForm.dynamicForm.value.lastName}, ${this.accessRequestForm.dynamicForm.value.firstName}`,
      givenName: `${this.accessRequestForm.dynamicForm.value.firstName}`,
      surname: `${this.accessRequestForm.dynamicForm.value.lastName}`,
      mail: `${this.accessRequestForm.dynamicForm.value.email}`,
      id: "10"
    }

    console.log(user)
    const accessRequestPayload : AccessRequestPayload = {
      givenName: `${this.accessRequestForm.dynamicForm.value.firstName}`,
      surname: `${this.accessRequestForm.dynamicForm.value.lastName}`,
      email: `${this.accessRequestForm.dynamicForm.value.email}`
    }

    const accessRequestApproval = this.helperFunctions.createApprovalPayload("CreateUser", payloadDescription, accessRequestPayload, user)

    this.accessRequest.createAccessRequest(accessRequestApproval).subscribe(
      (response: any) => {
        console.log(response)
      }
    )
  }

  resetForm(){
    this.accessRequestForm.dynamicForm.reset()
  }

  resetInput(inputField: string){
    this.accessRequestForm.dynamicForm.get(inputField)?.setValue(null)
  }

}
