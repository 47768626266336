import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';

@Component({
  selector: 'jafar-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss']
})
export class DateRangeComponent {
  @Output() newDateEvent = new EventEmitter<any>();
  @ViewChild('dateRangePicker') dateRangePicker!: MatDatepicker<Date>;

  public dateRange = new FormGroup({ startDate: new FormControl<Date | null>(null), endDate: new FormControl<Date | null>(null)})

  openDatePicker() {
    this.dateRangePicker.opened ? this.dateRangePicker.close() : this.dateRangePicker.open()
  }

  emitSelectedDate(){
    console.log(this.dateRange)
    this.newDateEvent.emit(this.dateRange)
  }

}
