import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";
import { GenericApiService } from "../../generic-api/generic-api.service";
import { QueryParams } from "../../../../interfaces/generic-api.interface";
import { CIPSettings } from "src/environments/environment";
import { CIPRecord } from "src/app/shared/datasource/interfaces/CIP/cip-general.interface";
import { CIPChange, CIPChangeRecord } from "src/app/shared/datasource/interfaces/CIP/cip-changes.interface";

@Injectable({
  providedIn: 'root'
})
export class CIPChangesService  extends GenericApiService{

  httpClient = inject(HttpClient)

  getDefaultHeaders() : HttpHeaders{
    const headers = new HttpHeaders()
    .set('Accept', '*/*')
    .set('Content-Type', 'application/json')

    return headers
  }

  getChanges(search? : string) : Observable<CIPChange[]> {
    const headers = this.getDefaultHeaders()
    const url     = `${CIPSettings.baseUrl}/${CIPSettings.endPoints["changes"]}`
    let query: QueryParams = {
      code    : `${CIPSettings.code}`
    }
    if (search) {
      query = {...query,  filter  : search  }
    }
    return this.get<CIPChange[]>(url, headers, query)
  }

  getChangeDetails(CIPId : string) : Observable<HttpResponse<CIPChangeRecord>> {
    const headers = this.getDefaultHeaders()
    const url     = `${CIPSettings.baseUrl}/${CIPSettings.endPoints["changeDetails"]}`
    const query: QueryParams = {
      code    : `${CIPSettings.code}`,
      cipId   : CIPId
    }
    return this.get<HttpResponse<CIPChangeRecord>>(url, headers, query)
  }

}
