import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'jafar-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})

export class SnackbarComponent {
  constructor(
    public sb : MatSnackBar,
    public sbRef: MatSnackBarRef<SnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {}

  actiononButton(){
    this.sbRef.afterDismissed()
  }

  done(){
    console.log("Closing Snackbar")
    this.sbRef.dismiss()
  }

  copyToClipboard(){
    console.log("copying secret to clipboard")
    console.log(this.data)
    navigator.clipboard.writeText(this.data.message);
  }
}



// MOVED TO SERVICE

  // openSnackBar(snackConfig: SnackBarConfig) {
  //   // this._snackBar.open(snackConfig.message, snackConfig.snackBarButton, {
  //   //   horizontalPosition: snackConfig.horizontalPosition,
  //   //   verticalPosition: snackConfig.verticalPosition,
  //   //   panelClass:[snackConfig.style],
  //   //   announcementMessage:"my announcement"
  //   // });
  // }

  // openComponent(){
  //   this.sb.openFromComponent(SnackbarComponent, {
  //     data: 'Hi from Child Component',
  //     duration: 12000,
  //     panelClass: ["snack-notification"],
  //     horizontalPosition: "center",
  //     verticalPosition: "top",
  //   });



  // openTemplate(snackConfig: SnackBarConfig){
  //   console.log(snackConfig)
  //   this.message = snackConfig.message
  //   console.log(this.message)
  //   // this._snackBar.openFromTemplate("./snackbar.component.html", {
  //   //   data : snackConfig.message,
  //   //   duration: 12000,
  //   //   panelClass: [snackConfig.style],
  //   //   horizontalPosition: snackConfig.horizontalPosition,
  //   //   verticalPosition: snackConfig.verticalPosition,
  //   // });
  // }

