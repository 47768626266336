import { AppSecret } from "./azure-app-secret.interface"

export interface AzureAppPayload {
    "@odata.context": string
    value: AzureApp[]
}

export interface AzureApp {
    id                   : string
    appId                : string
    appName              : string
    displayName          : string
    entAppId             : string
    entAppRoleId         : string
    entAppAssignmentId   : string
    notes                : string
    description          : string
    friendlyName         : string
    requestFirstName     : string
    requestLastName      : string
    requestMail          : string
    passwordCredentials  : AppSecret[]
    isOwner              : boolean
    expirationStatus     : string
    expirationToolTip    : string
    expirationColor      : string
}

export interface AppNotes {
    description         : string
    friendlyName        : string
    requestFirstName    : string
    requestLastName     : string
    requestMail         : string
}

export enum SecretExpirationStatus {
    Healthy    = "check_circle",
    Warning    = "warning",
    Critical   = "cancel",
    None       = "info"
}

export enum SecretExpirationToolTip {
    Healthy    = "Secret is Valid",
    Warning    = "Secret Expiring in 30 Days",
    Critical   = "Secret has Expired",
    None       = "No Secret Found"
}

export enum SecretExpirationColor {
    Healthy    = "Healthy",
    Warning    = "Warning",
    Critical   = "Critical",
    None       = "None"
}
