<ng-container
	*ngIf="spinnerService.visibility | async; then spinner; else content">
</ng-container>

<ng-template #content>
  <div class="dialog-framework">
    <div class="title-framework">
      <span class="title-spacing"></span>
      <title mat-dialog-title class="center-title">
          Create Application
      </title>
      <span class="title-spacing"></span>
      <mat-icon class="close-icon-position" matTooltip="Close" (click)="onCancel()">close</mat-icon>
    </div>
    <div class="dialog-content" #create >
      <mat-divider></mat-divider>
      <div class="create-framework">

        <mat-stepper orientation="vertical" #stepper [linear]="true"  animationDuration="800"
          class="stepper-framework">

          <!-- Step 1 -->
          <mat-step completed="false" editable="true">
            <ng-template matStepLabel >
              <div class="stepper-label">Details</div>

            </ng-template>
            <ng-template matStepContent>
              <jafar-dynamic-form class ="form-item" [form]="appCreateFormData" #appCreateForm></jafar-dynamic-form>
                <div class="redirect-link">
                  <span class="icon-position">
                    <mat-icon class="icon-spacing">info</mat-icon>
                    <span>Customer not in List? Create one <a routerLink="/managecip/customers" (click)="onCancel()">HERE</a></span>
                  </span>
                </div>
              <span class="button-group">
                <jafar-action-button [disabled]="!appCreateForm.dynamicForm.valid" (btnClick)="onNext(stepper)">Next</jafar-action-button>
                <jafar-dismiss-button (btnClick)="onCancel()">Cancel</jafar-dismiss-button>
              </span>
            </ng-template>

          </mat-step>

          <!-- Step 2 -->
          <mat-step completed="false" editable="true">
            <ng-template matStepLabel >
              <div class="stepper-label">Permissions</div>

            </ng-template>
            <ng-template matStepContent>

              <mat-radio-group
                required="true"
                (change)="onRadioButtonSelected(roleGroupSelected)"
                [(ngModel)]="roleGroupSelected"
              >
                <mat-radio-button *ngFor="let role of roleGroupList" [value]="role">
                  {{role}}
                </mat-radio-button>
              </mat-radio-group>

              <div>Your role is: {{roleGroupSelected}}</div>


              <jafar-dynamic-form class ="form-item" [form]="appPermFormData" #appRegPermissionsForm ></jafar-dynamic-form>

            <!-- <jafar-dynamic-form class ="form-item" [form]="formData" #appRegPermissionsForm></jafar-dynamic-form> -->
              <!-- <jafar-chiplist  [chipOptions]="chipOptions" ></jafar-chiplist> -->
            <!-- {{appRegPermissionsForm.dynamicForm.value | json}} -->
              <span class="button-group">
                <jafar-action-button (btnClick)="onNext(stepper)">Next</jafar-action-button>
                <jafar-dismiss-button (btnClick)="onCancel()">Cancel</jafar-dismiss-button>
                <!-- <button mat-raised-button class="next-button" color="primary"
                  (click)="onNext(stepper)"> Next </button>

                <button mat-stroked-button class="reset-button" (click)="onCancel()"> Cancel</button> -->
              </span>
            </ng-template>
          </mat-step>

          <!-- Step 3 -->
          <mat-step>
            <ng-template matStepLabel>
              <div class="stepper-label">Confirmation</div>

            </ng-template>
            <ng-template matStepContent>
              <br>
              <div>
                <h3>You are about to submit a Request <br> to create an Application:</h3>
              </div>
              <div>
                Application Name:<br>
                <b>{{appCreateForm.dynamicForm.controls["appName"].value}}</b><br><br>
                Description:<br>
                <b>{{appCreateForm.dynamicForm.controls["description"].value}}</b><br><br>
                Customer Name:<br>
                <b>{{(appCreateForm.dynamicForm.controls["customer"].value).displayName}}</b><br><br>
                Owner Email:<br>
                <b>{{appCreateForm.dynamicForm.controls["email"].value}}</b><br><br>
                Application Access:<br>
                <div *ngFor="let role of appPermForm.dynamicForm.controls['appRoles'].value">
                  <b>{{role}}</b><br>
                </div><br>
                Environment:<br>
                <b>{{currentEnvironment}}</b><br>
              </div>
              <span>
                <br>
                <div> Are you sure you want to create the above Application?</div>
                <br>
                <jafar-action-button [disabled]="!appCreateForm.dynamicForm.valid" (btnClick)="onSubmit()">Confirm</jafar-action-button>
                <jafar-dismiss-button (btnClick)="onCancel()">Cancel</jafar-dismiss-button>
              </span>
            </ng-template>
          </mat-step>
        </mat-stepper>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #spinner>
	<div class="spinner-container">
		<mat-spinner value="Submitting Request..." color="accent" class="spinner"></mat-spinner>
		<br>
		<br>
		<h1 class="title">Submitting Request ...</h1>
	</div>
</ng-template>
