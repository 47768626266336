  <mat-stepper #stepper labelPosition="bottom" headerPosition="bottom" class="stepper">
    <mat-step #step1 label="Step 1 - New">
      <!-- <ng-template matStepLabel>{{step1Label | async}}</ng-template> -->
    </mat-step>

    <mat-step #step2 label="Step 2 - Queued">
      <!-- <ng-template matStepLabel>{{step2Label | async}}</ng-template> -->
    </mat-step>

    <mat-step #step3 label="Step 3 - In Progress">
    <!-- <ng-template matStepLabel>{{step3Label | async}}</ng-template> -->
    </mat-step>

    <mat-step #step4 label="Step 4 - Completed \ Cancelled">
    <!-- <ng-template matStepLabel>{{step4Label | async}}</ng-template> -->
    </mat-step>

  <mat-step #step5 label="Step 5 - Confirmed">
  <!-- <ng-template matStepLabel>{{step5Label | async}}</ng-template> -->
  </mat-step>

</mat-stepper>