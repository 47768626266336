import { Component, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { PaginatedTable } from 'src/app/shared/datasource/interfaces/paginated-table.interface';
import { SnackbarService } from 'src/app/shared/datasource/services/snackbar/snackbar.service';
import { CreateAzureGroupPopupComponent } from '../ui/create-group/create-azure-group-popup/create-azure-group-popup.component';
import { DeleteAzureGroupPopupComponent } from '../ui/delete-group/delete-azure-group-popup/delete-azure-group-popup.component';
import { EditAzureGroupPopupComponent } from '../ui/edit-group/edit-azure-group-popup/edit-azure-group-popup.component';
import { AzureCustomGroup } from 'src/app/shared/datasource/interfaces/aad-group.interface';
import azureGroupJson from 'src/assets/config/tableHeaders/manage-groups-headers.json'
import { SpinnerService } from 'src/app/shared/datasource/services/spinner/spinner.service';
import { UserService } from 'src/app/core/datasource/services/user/user.service';
import { AadGroupsService } from 'src/app/shared/datasource/services/http-requests/azure-functions/groups/aad-groups.service';
import { DialogInput, DialogOutput } from 'src/app/shared/datasource/interfaces/dialog.interface';
import { DialogService } from 'src/app/shared/datasource/services/dialogs/dialog.service';
import { TableColumnHeader } from 'src/app/shared/datasource/interfaces/expandable-table-interface';


@Component({
  selector: 'jafar-manage-azure-groups',
  templateUrl: './manage-azure-groups.component.html',
  styleUrls: ['./manage-azure-groups.component.scss']
})

export class ManageAzureGroupsComponent {
  public tableData!               : PaginatedTable
  public disableEditGroupButton   : boolean = true
  public disableDeleteGroupButton : boolean = true
  private selectedGroup           : AzureCustomGroup | null = null
  public userProfile  = inject(UserService)
  private groupHeaders: TableColumnHeader[] = azureGroupJson as TableColumnHeader[]

  constructor(
    public customGroupService : AadGroupsService,
    public dialog: DialogService,
    public matSnackBar : MatSnackBar,
    public snackBar: SnackbarService,
    public spinnerService: SpinnerService
  ){}

  ngOnInit(): void {
    this.tableData = {
      tableDataSource : new MatTableDataSource,
      columnHeaders: [],
      showFilter: true,
    }
    this.userProfile.activeUser$.subscribe(
      (user)=> { this.getGroupList(user.id) }
    )
    // this.getGroupList()
    this.tableData.columnHeaders = this.groupHeaders
  }

  onSelection(selectedGroup : any){
    this.selectedGroup = selectedGroup
    this.disableEditGroupButton = false
    this.disableDeleteGroupButton = !selectedGroup.isOwner
  }

  getGroupList(id : string){
    this.customGroupService
      .getCustomGroupsByUserId(id)
        .subscribe(groups => {
          console.log(groups)
          this.tableData.tableDataSource.data = groups
        }
    )
  }

  openCreateDialog(): void {
    const createDialog : DialogInput = {
      component : CreateAzureGroupPopupComponent,
      data: {ownerId : this.userProfile.activeUser}
    }
    this.dialog.openDialog(createDialog)

    // Will update the application list on close
    this.dialogClosed()
  }

  openDeleteDialog(): void {
    const deleteDialog : DialogInput = {
      component :  DeleteAzureGroupPopupComponent,
      data: this.selectedGroup
    }
    this.dialog.openDialog(deleteDialog)

    // Will update the application list on close
    this.dialogClosed()
  }

  openEditDialog(){
    const editDialog : DialogInput = {
      component :  EditAzureGroupPopupComponent,
      data: this.selectedGroup
    }
    this.dialog.openDialog(editDialog)

    // Will update the application list on close
    this.dialogClosed()
  }

  dialogClosed(){
    this.dialog.onClose().subscribe(
      (result : DialogOutput) => {
        console.log(result)
        if (result){
          if (result.snackbarConfig !== undefined){
            this.snackBar.openSnackBar(result.snackbarConfig)
          }

          console.log("Updating the List")
          setTimeout(
            () => { this.getGroupList(this.userProfile.activeUser.id) }, 2000
          );
          this.disableEditGroupButton   = true
          this.disableDeleteGroupButton = true
          this.selectedGroup = null
        }
      }
    )
  }

}
