import { Injectable, Injector, inject } from "@angular/core";
import { BehaviorSubject, Observable, map } from "rxjs";
import { cipInjectionTokens } from "src/environments/InjectionTokens/cipInjectionTokens";
import { CIPProcessState, CIPItemState } from "../../interfaces/CIP/cip-states.interface";
import { CIPChangesService } from "../../services/http-requests/azure-functions/cip/cip-changes.service";
import { HttpResponse } from "@angular/common/http";
import { CIPRecord } from "../../interfaces/CIP/cip-general.interface";
import { CIPHeader } from "../../interfaces/CIP/cip-page-layout.interface";
import { CIPSupportGroup } from "../../interfaces/CIP/cip-supportgroups.interface";
import { CIPChange, CIPChangeRecord } from "../../interfaces/CIP/cip-changes.interface";


@Injectable({
    providedIn: 'root'
})
export class CipChangeStore {
  // Inject the service the store is linked too
  private changeService = inject(CIPChangesService)

  // Decalare the Behaviour Subjects to Store the Data we need
  private readonly _changes$      : BehaviorSubject<CIPChange[]>        = new BehaviorSubject<CIPChange[]>([]);

  private readonly _emptyCIPRecord : CIPChangeRecord= {
    changeInfo: null,
    workLogs: [],
    supportGroup: []
  }
  private readonly _change$      : BehaviorSubject<CIPChangeRecord>   = new BehaviorSubject<CIPChangeRecord>(this._emptyCIPRecord);

  // Methods to populate the Stores

  fetchChanges(search? : string){
    return this.changeService.getChanges(search)
  }

  fetchChangeInfo(cipId : string): Observable<HttpResponse<CIPChangeRecord>>{
    return this.changeService.getChangeDetails(cipId)
  }

  updateAllChanges(changeList: CIPChange[]){
    this._changes$.next(changeList)
  }

  updateChangeInfo(changeInfo: CIPChangeRecord){
    this._change$.next(changeInfo)
  }

  // Observable Streams of the contents of the stroe
  // CHANGES
  get changes$(): Observable<CIPChange[]> {
      return this._changes$.asObservable();
  }

  get changeInfo$(): Observable<CIPChangeRecord> {
      return this._change$.asObservable();
  }
  get changeHeaderInjector() : Injector {
      const changeHeader : Observable<CIPHeader | null> = this._change$.pipe(
          map((change :CIPChangeRecord)=> {
            if (change.changeInfo) {
              const changeInfo : CIPChange = change.changeInfo
              const  header : CIPHeader = {
                  CIPType         : "Change",
                  CIPInternalId   : changeInfo.internalChangeId,
                  Customer        : changeInfo.msgHeaderCompany,
                  CreatedOn       : changeInfo.createdAt,
                  ProcessState    : (changeInfo.processStateName).toLowerCase() as CIPProcessState,
                  ItemState       : (changeInfo.changeStateName).toLowerCase() as CIPItemState
              }
              return header
            }
            else{
                return null
            }
          })
      )
      return Injector.create({providers: [{ provide: cipInjectionTokens.cipInformationToken, useValue: changeHeader}]})

  }

  get changeInjector() : Injector {
      const changeInfo = this._change$.pipe(map((result :any)=>( result.changeInfo as CIPSupportGroup )) )
      return Injector.create({providers: [{ provide: cipInjectionTokens.cipInformationToken, useValue: changeInfo}]})
  }

  get changeSupportGroupInjector() : Injector {
      const supportGroups = this._change$.pipe(map((result)=>( result.supportGroup ?? [])) )
      return Injector.create({providers: [{ provide: cipInjectionTokens.supportGroupsToken, useValue: supportGroups}]})
  }

  get changeWorkLogsInjector() : Injector {
      const worklogs = this._change$.pipe(map((result)=>( result.workLogs ?? [])) )
      return Injector.create({providers: [{ provide: cipInjectionTokens.workLogsToken, useValue: worklogs}]})
  }
}

