import { ChangeDetectionStrategy,  Component, Input, OnChanges, SimpleChanges, forwardRef } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CheckboxOptions } from '../../dynamic-form.interface';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessorDirective } from '../../form-cva/control-value-accessor.directive';

@Component({
  selector: 'jafar-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    }
]
})
export class CheckboxComponent<T> extends ControlValueAccessorDirective<T> implements OnChanges {
  @Input() checkboxOptions! : CheckboxOptions | undefined

  selectedItems: string[] = []
  checkboxValue : string = "false"
  checkboxChecked : boolean = false

  ngOnChanges(changes : SimpleChanges){
    console.log("Checkbox Options", this.checkboxOptions)
    console.log("checkbox component : ngOnChanges : passed in changes - " , changes)

    if (changes['checkboxOptions'].isFirstChange() ){
      this.updatSelectedItems(true)
    }
    else{
      this.updatSelectedItems(false)
    }

  }

  updatSelectedItems(skipUpdate: boolean){
    this.checkboxValue = this.checkboxOptions?.value ?? "false"
    this.checkboxChecked = this.checkboxOptions?.checked ?? false

      console.log("checkbox Component : updateSelectedItems : this.selectedItems -" , this.selectedItems)
      if (!skipUpdate){
        this.onChange(this.selectedItems)
      }
  }

  onCheckClick(checkbox : MatCheckboxChange ){
    this.addRemoveRole(checkbox.checked, checkbox.source.value)
    console.log("checkbox Component : onCheckClick : this.selectedItems -" ,this.selectedItems)
    this.onChange(this.selectedItems)

  }

  addRemoveRole(checkState:boolean, value : string){
    // Add Item To Array
    if (checkState){
      // Only Add Items not already in list
      if (!this.selectedItems.includes(value)){this.selectedItems.push(value)}
    }
    // Delete Item from array
    else {
      this.selectedItems = this.selectedItems.filter(r => r != value)
    }


  }

}
