import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackBarConfig, SnackBarStyle } from '../../../ui/snackbar/snackbar.interface';
import { SnackbarComponent } from '../../../ui/snackbar/snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  defaultSettings : MatSnackBarConfig = {
    horizontalPosition : 'center',
    verticalPosition: 'top',
    panelClass: ['edit-snack-bar']
  }

  constructor(
    private snackBar: MatSnackBar
  ) { }


  openSnackBar(config: SnackBarConfig){
    let sbConfig = this.defaultSettings
    sbConfig = {
      ...config.settings,
      panelClass : ['edit-snack-bar']
    }
    this.snackBar.open(config.message,config.buttonName, sbConfig )
  }

  openCustomSnackBar(config:SnackBarConfig, action: string){
    let sbConfig = new MatSnackBarConfig
    sbConfig = {
      ...config.settings,
      panelClass : ['edit-snack-bar'],
      data: {
        message: config.message,
        action : action
      }
    }
    this.snackBar.openFromComponent(SnackbarComponent , sbConfig )


  }

}
