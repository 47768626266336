import { ChangeDetectorRef, Directive, Inject, Injector, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl, FormControlDirective, FormControlName, FormGroupDirective, NG_VALUE_ACCESSOR, NgControl} from '@angular/forms';
import { Subject, distinctUntilChanged, startWith, takeUntil, tap } from 'rxjs';

@Directive({
  selector: '[jafarControlValueAccessor]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ControlValueAccessorDirective,
      multi : true
    }
  ]
})
export class ControlValueAccessorDirective<T>  implements ControlValueAccessor, OnInit{
  control! : FormControl
  public onChange!: (m: any) => {}  // Added for checkbox component
  private _onTouch! : () => T
  private _destroy$ = new Subject<void>()

  constructor(@Inject(Injector) private injector: Injector) { }

  ngOnInit(){
    // console.log("CVA: OnInit Called")
    this.setFormControl()
  }

  setFormControl(){
    // Get the form Control Name that has been passed in
    try{
      const formControl = this.injector.get(NgControl);
      // console.log("CVA: SetFormControl Method : formControl:" , formControl)

      switch (formControl.constructor) {
        case FormControlName:
          this.control = this.injector
            .get(FormGroupDirective)
            .getControl(formControl as FormControlName);
          break;

        default:
          this.control = (formControl as FormControlDirective).form as FormControl;
          break;
      }
      // console.log("CVA: SetFormControl Method(after Switch statement) : this.control", this.control)
    }
    catch (err) {
      this.control = new FormControl();
    }
  }

  writeValue(value: T): void {
    // if we have a form control update value else create a new form control
    // console.log("CVA : writeValue Method : Passed in value ", value )
    // this.control ? this.control.setValue(value) :  new FormControl(value)
  }

  registerOnChange(fn: any): void {
    // console.log("CVA : registerOnChanges method called : fn -" , fn)
    // We only mark as changed if value is different
    // By marking form as untouched when user clicks into box will clear errors until user click out
    this.control?.valueChanges
    .pipe(
      takeUntil(this._destroy$),
      startWith(this.control.value),
      distinctUntilChanged(),
      tap((val) => fn(val))
    )
    .subscribe(
      () => this.control?.markAsUntouched()
    );

    this.onChange = fn   // Added for checkbox component
  }

  registerOnTouched(fn: ()=> T): void {
    // console.log("CVA : registerOnTouched method called : passed in fn - ", fn)
    this._onTouch = fn;
  }

  updateTouched (){
    // console.log("CVA : updateTouched method called")
    this._onTouch()
  }

}
