import { AzureApp } from "./azure-app.interface"

// Azure Function Payloads (Requests)
export interface CreateAadGroupPayload{
    groupName        : string | null
    description      : string | null
    reqFirstName     : string | null
    reqLastName      : string | null
    reqMailAddress   : string | null
    reqUserId        : string | null
    contactMail      : string | null
    itsmSupportGroup : string | null
    appRegistrations : string[] | null
    owners           : string[] | null
    members          : string[] | null

}

export interface EditAadGroupPayload{
    id               : string | null
    groupName        : string | null
    description      : string | null
    reqFirstName     : string | null
    reqLastName      : string | null
    reqMailAddress   : string | null
    reqUserId        : string | null
    contactMail      : string | null
    ITSMSupportGroup : string | null
    membersAdded     : string[] | null
    membersRemoved   : string[] | null
    appsAdded        : string[] | null
    appsRemoved      : string[] | null
    ownersAdded      : string[] | null
    ownersRemoved    : string[] | null
}

// Azure Function Responses
export interface AadGroupMembersResponse {
    "@odata.type"     : string
    id                : string
    displayName       : string
    givenName?        : string
    mail              : string
    surname?          : string
    userPrincipalName : string
}

export interface AadCustomGroupResponse {
    id               : string
    displayName      : string
    groupName        : string
    description      : string
    requestFirstName : string
    requestLastName  : string
    requestMail      : string
    contactMail      : string
    ITSMSupportGroup : string
    isOwner          : boolean
    owners           : AADMember[]
    members          : AADMember[]  // to be removed
    apps             : AzureApp[]  // will become AADGroup
}

export interface AzureCustomGroup {
    id               : string
    displayName      : string
    groupName        : string
    description      : string
    requestFirstName : string
    requestLastName  : string
    requestMail      : string
    contactMail      : string
    itsmSupportGroup : string
    isOwner          : boolean
    ownerStatusIcon? : string 
    ownerIconColour? : string
    ownerToolTip?    : string
    owners           : AADMember[]
    members          : AADMember[]  // to be removed
    apps             : AzureApp[]
}


export interface AppRegistration {
    appId       : string,
    appRoleId?  : string,
    name        : string,
    assignmentId: string,
}

// export interface AADGroup {
//     ContactMail       : string,
//     Description       : string,
//     LinkedAppRegAppID : string,
//     LinkedAppRegName  : string,
//     LinkedAppRegObjID : string,
//     RequestFirstName  : string,
//     RequestLastName   : string,
//     RequestMail       : string
// }


export interface AADMember {
    id                 : string
    displayName        : string          // actual groupName or User Name
    friendlyName?      : string          // user defined Name or Linked AppName
    type               : AADMemberType
    LinkedAppRegObjID? : string
    LinkedAppRegName?  : string
    LinkedAppRegID?    : string
}

export enum AADMemberType{
    group = '#microsoft.graph.group',
    user  = '#microsoft.graph.user'
}


export type AADGroup = {
    classification: any
    createdDateTime: string
    creationOptions:  any
    deletedDateTime: any
    description: string
    displayName: string
    expirationDateTime: any
    groupTypes: Array<string>
    id: string
    isAssignableToRole: any
    mail: string
    mailEnabled: boolean
    mailNickname: string
    members: AadGroupMembersResponse[]
    membershipRule: any
    membershipRuleProcessingState: any
    onPremisesDomainName: any
    onPremisesLastSyncDateTime: any
    onPremisesNetBiosName: any
    onPremisesProvisioningErrors: []
    onPremisesSamAccountName: any
    onPremisesSecurityIdentifier: any
    onPremisesSyncEnabled: any
    preferredDataLocation: any
    preferredLanguage: any
    proxyAddresses: Array<string>
    renewedDateTime: string
    resourceBehaviorOptions: Array<any>
    resourceProvisioningOptions: Array<string>
    securityEnabled: boolean
    securityIdentifier: string
    serviceProvisioningErrors: []
    theme: any
    visibility: string
  }
