<div class="spv-header-framework" *ngIf="showHeader | async; else noheader">
    <div class="spv-header-title-info">
        <div class="spv-header-heading">
            <h1>{{(cipHeader | async)?.CIPType}} - {{(cipHeader | async)?.CIPInternalId }}  </h1>
        </div>
        <div class="spv-header-subheading">
            <i>Customer : </i> {{(cipHeader | async)?.Customer}}   <br>
            <i>Created on : </i>{{(cipHeader | async)?.CreatedOn | date : "dd MMMM yyyy"}}  
        </div>
        <span class="spv-header-expander"></span>
    </div>
    <div class="spv-header-stepper-status">
        <jafar-cip-task-status [itemState]="(cipHeader | async)?.ItemState"  [processState]="(cipHeader | async)?.ProcessState"  ></jafar-cip-task-status> 
    </div>
</div>

<ng-template #noheader>
    <div class="header-noheader">
        <div class="header-loading-text"> Loading CIP Status ....  </div>
        <div class="progress-container">
            <span class="progress-padding"></span>
            <mat-progress-bar mode="indeterminate" class="header-progress" value="some data here"></mat-progress-bar>
            <span class="progress-padding"></span>

        </div>
    
    </div>
 
</ng-template>