import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject} from '@angular/core';
import { CipSearchData } from 'src/app/shared/datasource/interfaces/CIP/cip-page-layout.interface';

@Component({
  selector: 'jafar-cip-search',
  templateUrl: './cip-search.component.html',
  styleUrls: ['./cip-search.component.scss']
})
export class CipSearchComponent {

  constructor(
    public dialogRef: MatDialogRef<CipSearchComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CipSearchData,
  ) {console.log(data)}

}
