import { Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MarkdownDisplayService } from '../../datasource/services/markdown-display/markdown-display.service';

@Component({
  selector: 'app-markdown-dialog',
  templateUrl: './markdown-dialog.component.html',
  styleUrls: ['./markdown-dialog.component.scss']
})
export class MarkdownDialogComponent {
  
  constructor(
    public dialogRef: MatDialogRef<MarkdownDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public mdservice: MarkdownDisplayService
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
