import { Component, Inject, OnInit, ViewChild, inject } from '@angular/core';
import { MenuCloseReason } from '@angular/material/menu';
import { Customer } from 'src/app/pages/manage-azure-app-reg/datastore/customer.interface';
import { ChipAutoComplete } from 'src/app/shared/datasource/interfaces/chip-autocomplete.interface';
import { CipCustomerStore } from 'src/app/shared/datasource/stores/cip/cip-customer-store.service';
import { CipRoutingComponent } from '../cip-routing.component';
import { CipEnvRouteStore } from 'src/app/shared/datasource/stores/cip/cip-envroute-store.service';
import { CipEnvRouteDefaultStore } from 'src/app/shared/datasource/stores/cip/cip-envroutedefault-store.service';
import { TableSideMenuStore } from 'src/app/shared/ui/templates/pages/split-panel/store/table-sidemenu-store.service';
import { SidePanelStoreService } from 'src/app/shared/ui/templates/pages/page-with-sidepanel/side-panel-store.service';
@Component({
  selector: 'jafar-routing-title',
  templateUrl: './routing-title.component.html',
  styleUrls: ['./routing-title.component.scss']
})
export class RoutingTitleComponent implements OnInit{
  customerStore = inject(CipCustomerStore)
  envRouteStore = inject(CipEnvRouteStore)
  defaultEnvRouteStore = inject(CipEnvRouteDefaultStore)
  sidePanelStore = inject(SidePanelStoreService)

  selectedCustomerSettings = {
    listLabel       : "Customer",
    displayName     : "displayName"
  }

  notSelectedCustomerSettings = {
    listLabel       : "Select Customer To View Routing Info",
    displayName     : "displayName"
  }

  customerDropdownOptions! : ChipAutoComplete
  ngOnInit(): void {
    this.customerDropdownOptions = {...this.selectedCustomerSettings}

    this.customerStore.customers$.subscribe(
      result => console.log(result)
    )
  }

  onDropdownClick(){
    console.log("Dropdown has been clicked")
    console.log("customer :", this.customerStore.customer)
    if(this.customerStore.customer.name){
      this.customerDropdownOptions = {...this.selectedCustomerSettings}
    }
    else{
      this.customerDropdownOptions = {...this.notSelectedCustomerSettings}

    }

  }

  onCustomerSelection(Customer: Customer){
    this.sidePanelStore.setIsOpen(false)

    if (!Customer){
      console.log("No Customer ")
      this.customerStore.clearSelectedCustomer()
      this.customerDropdownOptions = {...this.notSelectedCustomerSettings}
    }
    else{
      console.log("Customer ")
      this.customerDropdownOptions = {...this.selectedCustomerSettings}
      this.customerStore.updateSelectedCustomer(Customer)
      this.envRouteStore.fetchCustomersEnvRoutes(Customer.sapName)
      this.defaultEnvRouteStore.fetchCustomersDefaultEnvRoutes(Customer.sapName)
    }
  }
}
