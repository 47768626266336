import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'jafar-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss']
})
export class ActionButtonComponent {
  @Input() disabled: boolean = false
  @Output() btnClick = new EventEmitter()


  onClick(){
    this.btnClick.emit()
  }
}
