import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { UserProfile } from 'src/app/shared/datasource/interfaces/user-profile';
import { environment } from 'src/environments/environment';
import { GenericApiService } from '../../generic-api/generic-api.service';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService  extends GenericApiService{
  userProfile!: UserProfile
  private httpClient = inject(HttpClient)
  
  private getDefaultHeaders() : HttpHeaders{
    const headers = new HttpHeaders()
    .set('Accept', '*/*')
    .set('Content-Type', 'application/json')

    return headers
  }


  getUserProfile(){
    let profile: UserProfile
    this.httpClient.get(
      `${environment.generic.graphApiUrl}/me`
    ).subscribe(
      (response: any) => {
        const profile: UserProfile = {
          displayName: response.displayName,
          givenName  : response.givenName,
          surname    : response.surname,
          mail       : response.mail,
          id         : response.id
        }
        console.log(JSON.stringify(response))
        this.userProfile = profile
      }
    )
  }

  getUser(){
    const headers = this.getDefaultHeaders()
    const url = `${environment.generic.graphApiUrl}/me`
    return this.get<UserProfile>(url, headers )
  }
}

