<div class="widget-framework">
    <jafar-page-widget class="widget"
        [contents]="contentTemplate"
        [actions]="actionTemplate"
    >
        <div title>CIP</div>
        <div subtitle> Manage CIP Elements </div>
    </jafar-page-widget>
</div>

<ng-template #contentTemplate >
    This is where you can manage CIP Elements<br>
</ng-template>

<ng-template #actionTemplate>
    <button mat-flat-button  matTooltip="Manage CIP"  color="primary" (click)="buttonClicked()" class="manage-azure-button">
        Go To Manage CIP
        <mat-icon>shortcut</mat-icon>

    </button>

</ng-template>
