<div class="dialog-framework2">
  <h1 mat-dialog-title>{{data.title}}</h1>
  <div mat-dialog-content class="dialog-content">
      <p>{{data.description}}</p>
      <br>
      <div class="check-section">
          <section class="example-section" *ngFor="let rowProperty of data.allProperties">
              <mat-checkbox
                  [checked]="isChecked(rowProperty.name)"
                  class="example-margin"
                  (change)="onOptionChange($event)"
                  [id]="rowProperty.name"
              >
                  {{rowProperty.name}}
              </mat-checkbox>
          </section>
      </div>
  </div>
  <div mat-dialog-actions>
      <button mat-button [mat-dialog-close]="data.selectedProperties"> Cancel </button>
      <button mat-button [mat-dialog-close]="propertiesToShow" cdkFocusInitial>Ok</button>
  </div>
</div>
