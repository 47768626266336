import { AfterViewInit, Component, Inject, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, BehaviorSubject, tap, map, isEmpty, defaultIfEmpty } from 'rxjs';
import { ViewJsonComponent } from '../../../dialogs/view-json/view-json.component';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from 'src/app/shared/shared.module';
import { CoreModule } from 'src/app/core/core.module';
import { cipInjectionTokens } from 'src/environments/InjectionTokens/cipInjectionTokens';
import { CIPWorkLog } from 'src/app/shared/datasource/interfaces/CIP/cip-worklogs.Interface';
import { DictionaryTableType } from 'src/app/shared/datasource/interfaces/dictionary-table-type.interface';

@Component({
  selector: 'jafar-work-logs',
  templateUrl: './work-logs.component.html',
  styleUrls: ['./work-logs.component.scss'],
  // imports: [MatExpansionModule, CommonModule, MatIconModule, MatCardModule, CoreModule],
  // standalone: true
})
export class WorkLogsComponent implements AfterViewInit{
  // @Input('WorkLogs') SupportGroups!: Observable<CIPSupportGroup[]>
  constructor( @Inject(cipInjectionTokens.workLogsToken) public WorkLogs: Observable<CIPWorkLog[]>){}
  dialog = inject(MatDialog)

  NoWorklogRecords : Observable<boolean> = new Observable<true>

  ngAfterViewInit(){
    this.NoWorklogRecords= this.WorkLogs.pipe(
      map(items => items.length == 0 ? true : false),
      defaultIfEmpty(true)
    );
    // this.NoWorklogRecords.subscribe(x => console.log(x));

  }
  

  getDictionary(workLogs : CIPWorkLog ){
    let visiblePropeties : string[] =[]
    const Datasource = Object.keys(workLogs).map(
      (propertyKey : string ) => {
        const indexKey = propertyKey as keyof typeof workLogs
        const keyValuePair : DictionaryTableType = {
          name: propertyKey,
          value: workLogs[indexKey]
        }
          visiblePropeties.push(propertyKey)
          return keyValuePair
      }

    )
    return Datasource
  }

  onViewJson (){
    this.dialog.open(ViewJsonComponent, {
      data: this.WorkLogs,
      disableClose: true,
      hasBackdrop: true,
      backdropClass: 'dialog-background'
    } )
  }
  //   this.WorkLogs.subscribe(
  //     (workLogs: CIPWorkLog[]) => {
  //       this.dialog.open(ViewJsonComponent, {data: JSON.stringify(workLogs)} )
  //     }
  //   )
  // }
}
