import { InjectionToken } from "@angular/core";
import { CIPEnvRoute } from "src/app/shared/datasource/interfaces/CIP/cip-envRoute.interface";
import { CIPEnvRouteDefault } from "src/app/shared/datasource/interfaces/CIP/cip-envRouteDefault.interface";
import { CIPHeader } from "src/app/shared/datasource/interfaces/CIP/cip-page-layout.interface";
import { CIPQuestion } from "src/app/shared/datasource/interfaces/CIP/cip-questions.interface";
import { CIPSupportGroup } from "src/app/shared/datasource/interfaces/CIP/cip-supportgroups.interface";
import { CIPWorkLog } from "src/app/shared/datasource/interfaces/CIP/cip-worklogs.Interface";

export const cipInjectionTokens = {
  cipHeader             : new InjectionToken<CIPHeader>(""),
  cipInformationToken   : new InjectionToken<any>(""),
  supportGroupsToken    : new InjectionToken<CIPSupportGroup[]>(""),
  workLogsToken         : new InjectionToken<CIPWorkLog[]>(""),
  questionsToken        : new InjectionToken<CIPQuestion[]>(""),
  envRoutesToken        : new InjectionToken<CIPEnvRoute[]>(""),
  envDefaultRouteToken  : new InjectionToken<CIPEnvRouteDefault[]>(""),
  selectedRouteToken    : new InjectionToken<CIPEnvRoute | CIPEnvRouteDefault>(""),
}
