<mat-card class="side-menu-card" [ngClass]="'custom-route-colour'">
    <mat-card-title class="side-menu-card-title">
        <div class="side-menu-back-button-container">
            <mat-icon matTooltip="Back To Route Tables" class="side-menu-back-button material-symbols-outlined" (click)="onGoBack()">arrow_back_ios</mat-icon>
        </div>
        <div class="side-menu-card-title-text">{{routeTitle}}</div>
        <div class="side-menu-action-icons">
            <mat-icon matTooltip="Reset Form" class="side-menu-title-icons" (click)="onCancel()">close</mat-icon>
        </div>
    </mat-card-title>
    <mat-card-content class="side-menu-card-content">
        <div class="side-menu-description">
            This will create a <b>custom route</b> for Customer:
        </div>
        <div class="create-form-title-styling">{{customerStore.customer.displayName}}</div>
        <jafar-dynamic-form class ="form-item" [form]="envRouteForm" #formComponents (selectedOption)="onSelection($event)"></jafar-dynamic-form>
        <div class="side-menu-button-group">
            <jafar-action-button class="side-menu-button-submit" [disabled]="!formComponents.dynamicForm.valid || formComponents.dynamicForm.touched == false" (btnClick)="onCreate()">Create Custom Route</jafar-action-button>
        </div>
    </mat-card-content>
</mat-card>

