import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { deleteAppSecretRequest } from 'src/app/shared/datasource/interfaces/app-secret.interface';
import { AppSecretService } from 'src/app/shared/datasource/services/http-requests/azure-functions/application-registrations/app-secret.service';
import { SnackBarStyle } from 'src/app/shared/ui/snackbar/snackbar.interface';
import { DialogOutput } from 'src/app/shared/datasource/interfaces/dialog.interface';

@Component({
  selector: 'jafar-delete-azure-secret-popup',
  templateUrl: './delete-azure-secret-popup.component.html',
  styleUrls: ['./delete-azure-secret-popup.component.scss']
})
export class DeleteAzureSecretPopup {
  typedAppName : string = ""
  submitButtonDisabled : boolean = true

  constructor(
    private appSecretService : AppSecretService,
    public dialogRef: MatDialogRef<DeleteAzureSecretPopup>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { console.log(data)}

  validateName(event: Event){
    console.log(event)
    const userInput = (event.target as HTMLInputElement).value;
    console.log(userInput)
    console.log(this.data)
    if( userInput === this.data.appSecret.displayName){
      this.submitButtonDisabled = false
    }
    // need to include the else otherwise nothing sets the button back to disabled if it no longer matches
    else {
      this.submitButtonDisabled = true
    }
  }

  deleteAppSecret(){
    const deleteRequestPayload: deleteAppSecretRequest = {
      appObjectId : this.data.appId,
      keyId : this.data.appSecret.keyId
    }
    console.log(deleteRequestPayload)
    this.appSecretService.deleteApplicationSecret(deleteRequestPayload).subscribe(
      result => {
        console.log(result)
      }
    )

  }

  onCancel(): void {
    this.dialogRef.close("Cancelled")
  }

  onSubmit(){
    const ReturnedData : DialogOutput = {
      data : {
        status:"Success"
      },
      snackbarConfig: {
        buttonName: "OK",
        message: "Secret has been successfully deleted",
        style : SnackBarStyle.default,
        settings: {
          horizontalPosition: 'center',
          verticalPosition:'top'
        }
      }
    }
    this.dialogRef.close(ReturnedData)
    this.deleteAppSecret()
  }
}
