import { ComponentPortal } from '@angular/cdk/portal';
import { Component } from '@angular/core';
import { CipRoutingComponent } from './cip-routing/cip-routing.component';

@Component({
  selector: 'jafar-routing-page',
  template: `
    <jafar-page-with-sidepanel [content]="cipRoutingComponent">
      <jafar-routing-title></jafar-routing-title>
    </jafar-page-with-sidepanel>
  `
})

export class RoutingPageComponent {
  cipRoutingComponent! : ComponentPortal<CipRoutingComponent>;

  ngOnInit(){
    this.cipRoutingComponent = new ComponentPortal(CipRoutingComponent)
  }

}
