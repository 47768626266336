<ng-container  *ngIf="(customersStore.customer$| async)?.name; then showRouting ; else showNoRoutes"></ng-container>

<ng-template #showRouting>
    <div class="routing-page-framework">
        <jafar-env-route-default></jafar-env-route-default>
        <br>
        <jafar-env-route></jafar-env-route>
    </div>
</ng-template>

<ng-template #showNoRoutes>
    <div class="routing-page-select-customer">
        Please Select a Customer
    </div>
</ng-template>