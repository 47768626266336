import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { createAppSecretRequest } from 'src/app/shared/datasource/interfaces/app-secret.interface';
import { AppSecretService } from 'src/app/shared/datasource/services/http-requests/azure-functions/application-registrations/app-secret.service';
import { SnackBarConfig, SnackBarStyle } from 'src/app/shared/ui/snackbar/snackbar.interface';
import { DialogOutput } from 'src/app/shared/datasource/interfaces/dialog.interface';

@Component({
  selector: 'jafar-create-azure-secret-popup',
  templateUrl: './create-azure-secret-popup.component.html',
  styleUrls: ['./create-azure-secret-popup.component.scss']
})
export class CreateAzureSecretPopup {
  secretName: string = ""
  step1NextButtonDisabled : boolean = false
  step2NextButtonDisabled : boolean = true
  expiryMonths! : number
  secretText! : string
  constructor(
    private AppSecretService : AppSecretService,
    public dialogRef: MatDialogRef<CreateAzureSecretPopup>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { console.log(data)}


  onExpirySelection(selection:number){
    console.log(selection)
    this.step2NextButtonDisabled = false
    this.expiryMonths = selection
  }

  createAppSecret(){
    console.log(this.data)
    const addSecretPayload : createAppSecretRequest = {
      appObjectId: this.data.appId,
      expiryMonths : this.expiryMonths,
      displayName: this.secretName
    }
    this.AppSecretService.addApplicationSecret(addSecretPayload).subscribe(
      result => {
        const resultData : DialogOutput = this.getReturnData(result.secretText)
        this.closeDialog(resultData)
      }
    )
  }

  getReturnData(secretText:string) : DialogOutput{
    const ReturnedData : DialogOutput = {
      data : {
        status:`Success - ${secretText} `
      },
      snackbarConfig: {
        buttonName: "OK",
        message: secretText,
        style : SnackBarStyle.default,
        settings: {
          horizontalPosition: 'center',
          verticalPosition:'top'
        }
      }

    }
    return ReturnedData
  }

  onNext(stepper : MatStepper){
    stepper.selected!.completed = true
    stepper.selected!.editable = false
    stepper.next()
  }

  onSubmit(){
    console.log("Submit")
    console.log()
    this.createAppSecret()

  }

  closeDialog(returnedData :DialogOutput){
    this.dialogRef.close(
      returnedData
    )
  }

  onCancel(): void {
    console.log("Cancel")
    this.dialogRef.close(false)
  }
}
