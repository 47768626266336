<div class="framework">
  <mat-card class="main-card">
    <mat-card-content>
    <div>
        <mat-icon matTooltip="Back to Login" routerLink="/login" class="item-alignment">arrow_back</mat-icon>
        <h1 class="headline"> Request Access</h1>
    </div>
    <mat-divider class="divider"></mat-divider>
    <mat-card-header>
        <h4>
            Please fill in the form below to request access for an Account
        </h4>
    </mat-card-header>
    <div class="form-item">
      <jafar-dynamic-form [form]="accessRequestData" #accessRequestForm></jafar-dynamic-form>
    </div>
    </mat-card-content>
    <mat-card-actions class="card-actions">
      <div>
        <jafar-action-button [disabled]="!accessRequestForm.dynamicForm.valid" (btnClick)="onSubmit()">Submit</jafar-action-button>
        <jafar-dismiss-button (btnClick)="resetForm()">Reset</jafar-dismiss-button>
      </div>
      </mat-card-actions>
  </mat-card>
</div>
