import { Injectable, Injector, inject } from "@angular/core";
import { BehaviorSubject, Observable, concatMap, filter, from, map, tap, toArray } from "rxjs";
import { CIPEnvRouteDefaultService } from "../../services/http-requests/azure-functions/cip/cip-envroutedefault.service";
import { CIPEnvRouteDefault, CIPEnvRouteDefaultCreateRequest, CIPEnvRouteDefaultUpdateRequest } from "../../interfaces/CIP/cip-envRouteDefault.interface";
import { cipInjectionTokens } from "src/environments/InjectionTokens/cipInjectionTokens";
import { CIPEnvRoute } from "../../interfaces/CIP/cip-envRoute.interface";

@Injectable({
    providedIn: 'root'
})

export class CipEnvRouteDefaultStore {
  // Inject the service the store is linked too
  private _envRouteDefaultService = inject(CIPEnvRouteDefaultService)

  // Decalare the Behaviour Subjects to Store the Data we need
  private readonly _envRoutesDefault$ : BehaviorSubject<CIPEnvRouteDefault[]>   = new BehaviorSubject<CIPEnvRouteDefault[]>([]);
  private readonly _customerDefaultEnvRoute$ : BehaviorSubject<CIPEnvRouteDefault[]>   = new BehaviorSubject<CIPEnvRouteDefault[]>([]);

  private readonly _selectedEnvRouteDefault$ : BehaviorSubject<CIPEnvRouteDefault>   = new BehaviorSubject<CIPEnvRouteDefault>(EMPTYENVROUTEDEFAULT);

  // Methods to populate the Stores
  fetchEnvRouteDefaults(){
    this._envRouteDefaultService.getDefaultEnvRoutes()
    .subscribe(response => this._envRoutesDefault$.next(response))
  }

  fetchCustomersDefaultEnvRoutes(CustomerSapName : string){
    console.log("Getting Customers Default Env Routes for: CustomerSapName ")
    this._envRoutesDefault$.pipe(
      concatMap(
        (routes : CIPEnvRouteDefault[]) => (
          from(routes).pipe(
            filter((route : CIPEnvRouteDefault) => (route.customer.toLowerCase() === CustomerSapName.toLowerCase())),
            toArray(),
            tap(customerRoutes => {
              console.log(customerRoutes)
              this._customerDefaultEnvRoute$.next(customerRoutes)
            })
          )
        )
      )
    ).subscribe()
  }

  // Exposed Properties of the Store
  // Observable Property
  get envDefaultRoutes$(): Observable<CIPEnvRouteDefault[]> {
    return this._envRoutesDefault$.asObservable();
  }

  get customerDefaultEnvRoutes$() : Observable<CIPEnvRouteDefault[]> {
    return this._customerDefaultEnvRoute$.asObservable()
  }
  get selectedRouteDefault$(): Observable<CIPEnvRouteDefault> {
    return this._selectedEnvRouteDefault$.asObservable();
  }

  get selectedRouteDefault(): CIPEnvRouteDefault {
    return this._selectedEnvRouteDefault$.getValue();
  }
  // Current State
  get envDefaultRoutes() : CIPEnvRouteDefault[] {
    return this._envRoutesDefault$.getValue()
  }

  // Injector
  get envRouteDefaultInjector() : Injector {
    return Injector.create(
      {providers: [
        { provide: cipInjectionTokens.envDefaultRouteToken,
           useValue: this.envDefaultRoutes$
        }
      ]}
    )
  }

  get selectedEnvRouteDefaultInjector() : Injector {
    return Injector.create(
      {
        providers: [
          {
            provide: cipInjectionTokens.selectedRouteToken,
            useValue: this.selectedRouteDefault$
          }
        ]
      }
    )
  }

  // Exposed Methods to Amend the store
  newEnvRouteDefault(newEnvRouteDefault : CIPEnvRouteDefaultCreateRequest) {
    this._envRouteDefaultService.createDefaultEnvRoute(newEnvRouteDefault)
    .subscribe(response => {
      console.log(response)
      this.fetchEnvRouteDefaults()
    })
  }

  editEnvRouteDefault( routeId : string , updatedRouteDefault : CIPEnvRouteDefaultUpdateRequest){
    this._envRouteDefaultService.updateDefaultEnvRoute(routeId, updatedRouteDefault)
    .subscribe(response => {
      console.log(response)
      this.fetchEnvRouteDefaults()
    })
  }

  deleteEnvRouteDefault(routeId : string){
    this._envRouteDefaultService.deleteDefaultEnvRoute(routeId)
    .subscribe(response => {
      console.log(response)
      this.fetchEnvRouteDefaults()
    })
  }

  updateSelectedEnvRouteDefault(envRouteDefault : CIPEnvRouteDefault){
    this._selectedEnvRouteDefault$.next(envRouteDefault)
  }

  clearSelectedEnvRouteDefault(){
    this._selectedEnvRouteDefault$.next(EMPTYENVROUTEDEFAULT)
  }

}

const EMPTYENVROUTEDEFAULT : CIPEnvRouteDefault = {
  id: "",
  customer: "",
  requestType: "",
  routeName: "",
  config: "",
  active: false,
  createdAt: new Date
}