<div>
    <span class="header-group">
        <mat-icon class="header-icon"> notifications </mat-icon>
        <h3> Secret {{data.action}} </h3>
    </span>
    <div>
        Your Secret has been {{data.action}} with the following Token:<br>
        <br>
        <div class="token"><b>{{ data.message }}</b></div>
        <br>
        When you close this message, you will no longer be able to access the token.<br>
        Please make sure you make a note of the token before clicking Done.
    </div>
</div>
<br>
<div class="button-group">
    <button mat-stroked-button color="primary" (click)="copyToClipboard()" class="copy-to-clipboard">
        <mat-icon>content_copy</mat-icon>
        Copy Token To Clipboard
    </button>
    <jafar-action-button (btnClick)="done()">Done</jafar-action-button>
</div>

