import { Component, Inject, OnInit, ViewChild, inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import workItemJson from 'src/assets/config/dynamic-forms/raise-workitem-form.json'
import { DynamicFormComponent } from '../dynamic-form/dynamic-form.component';
import { DynamicFormSchema } from '../dynamic-form/dynamic-form.interface';
import { UserProfileService } from '../../datasource/services/http-requests/ms-graph/user/user-profile.service';
import { WorkItemDialog } from '../../datasource/services/http-requests/azure-devops/workitem/workitem.interface';

@Component({
  selector: 'jafar-raise-workitem-dialog',
  templateUrl: './raise-workitem-dialog.component.html',
  styleUrls: ['./raise-workitem-dialog.component.scss']
})
export class RaiseWorkitemDialogComponent {
  @ViewChild("workItemForm") workItemForm! : DynamicFormComponent
  workItemData: DynamicFormSchema[] = workItemJson as DynamicFormSchema[]
  UserService = inject(UserProfileService)

  constructor(
    public dialogRef: MatDialogRef<RaiseWorkitemDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: WorkItemDialog,
  ) {}

  ngOnInit(){
    this.UserService.getUserProfile()
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit(){
    const userDetails = this.UserService.userProfile
    console.log("User Profile : ", userDetails)
    const FriendlyName = `${userDetails.givenName} ${userDetails.surname}`
    this.data.output.Title = this.workItemForm.dynamicForm.value.title ?? ""
    this.data.output.Description = this.workItemForm.dynamicForm.value.description ?? ""
    this.data.output.Mail = userDetails.mail
    this.data.output.User = FriendlyName
  }

}
