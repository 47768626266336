import { Injectable, inject } from '@angular/core';
import { GenericApiService } from '../../generic-api/generic-api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { ApprovalResponse } from 'src/app/shared/datasource/interfaces/approval-interface';
import { azureFunctions } from 'src/environments/environment';
import { QueryParams } from '../../../../interfaces/generic-api.interface';
import { convertStringToDate } from 'src/app/pages/user-requests/datastore/user-request-helpers';
import { HelperFunctionsService } from 'src/app/shared/functions/helperfunctions';

@Injectable({
  providedIn: 'root'
})
export class UserRequestService extends GenericApiService {

  constructor(private httpClient: HttpClient) {
    super(httpClient)
  }
  helperFunctions = inject(HelperFunctionsService)

  getUserRequests(userEmail: string): Observable<ApprovalResponse[]>{
    let createCustomerUrl: string = `${azureFunctions.approvalFunctions.baseUrl}/UserRequests`
    let headers = this.getHeaders()
    const query: QueryParams = {
      code    : `${azureFunctions.approvalFunctions.authCode}`,
      user    : `${userEmail}`
    }
    return this.get<ApprovalResponse[]>(createCustomerUrl, headers, query).pipe(
      map(
        (requests: ApprovalResponse[]) => {
          requests.map(
            (approval: ApprovalResponse) => {
              approval.id = approval.id.toString().padStart(6, '0')
              let approvalIconDetails  = this.helperFunctions.getIcon(approval, "approved")
              approval.approvalStatus  = approvalIconDetails.icon
              approval.approvalColor   = approvalIconDetails.color
              approval.approvalTooltip = approvalIconDetails.tooltip

              const createdAtDate = convertStringToDate(approval.createdAt)
              const createdAt = `${createdAtDate.toLocaleDateString()} ${createdAtDate.toLocaleTimeString()}`
              approval.createdAt = createdAt
              return approval
            }
          )
          return requests
        }
      )
    )
  }

  getHeaders(): HttpHeaders{
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set("Accept", '*/*');
    return httpHeaders
  }
}
