import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'jafar-azure-application-widget',
  templateUrl: './azure-application-widget.component.html',
  styleUrls: ['./azure-application-widget.component.scss']
})
export class AzureApplicationWidgetComponent {
  router = inject(Router)
  viewClicked(){
    this.router.navigateByUrl("manageazure/appreg")
  }
  createClicked(){
    console.log("Azure Apps Create Action")
  }

  
}
