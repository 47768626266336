import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { ApprovalPayload, ApprovalResponse } from 'src/app/shared/datasource/interfaces/approval-interface';
import { QueryParams } from 'src/app/shared/datasource/interfaces/generic-api.interface';
import { GenericApiService } from 'src/app/shared/datasource/services/http-requests/generic-api/generic-api.service';
import { azureFunctions } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccessRequestService extends GenericApiService{

  constructor(private httpClient: HttpClient) {
    super(httpClient)
   }

  feedbackMessage$ = new BehaviorSubject<string>("")


  setFeedbackMessage (Message: string){
    this.feedbackMessage$.next(Message)
  }

  getFeedbackMessage(){
    return this.feedbackMessage$.getValue()
  }

  createAccessRequest(userRequestPayload: ApprovalPayload){
    let accessRequestUrl: string = `${azureFunctions.approvalFunctions.baseUrl}/NewApprovalProcess`
    let headers = this.getHeaders()
    const query: QueryParams = {
      code    : `${azureFunctions.approvalFunctions.authCode}`
    }
    const jsonBody = JSON.stringify(userRequestPayload);
    console.log(jsonBody)
    return this.post<ApprovalResponse>(accessRequestUrl, jsonBody, headers, query)
  }

  getHeaders(): HttpHeaders{
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set("Accept", '*/*');
    return httpHeaders
  }
}




