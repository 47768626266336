import { DevOpsProjects, DevOpsEndpoints, DevOpsApiVersion } from "src/app/shared/datasource/enums/devops.enum"
import { WorkItemQueryID } from "src/app/shared/datasource/enums/work-item-query.enum"
import { WorkItemType } from "src/app/shared/datasource/enums/work-item-type.enum"

export interface WorkItemResponse {
  id: number
  rev: number
  fields: any
  _links: any
  url: string
}

export interface ApprovalWorkItem {
  payload   : object,
  title     : string,
  area      : string,
  iteration : string,
  tag       : string
}

export type workItemType = "portalFeeback" | "portalBug"

export interface DevOpsConfig {
  token             : string
  baseUrl           : string
  workItemEndpoint  : string
  queryEndpoint     : string
  apiVersion        : string
  portalFeeback     : WorkItemConfig
  portalBug         : WorkItemConfig
}

export interface WorkItemConfig {
  projectName : string
  queryId     : string
  area        : string
  iteration   : string
  tag         : string
  type        : string
}

export interface WorkItemDefaultResponse {
  asOf            : Date
  columns         : any[]
  queryResultType : string
  queryType       : string
  workItems       : WorkItemDefault[]
}

export interface WorkItemDefault {
  id : number
  url : string
}

export interface WorkItem {
  Title         : string
  State         : string
  Id            : number
  ReportedBy    : string
  WorkItemType  : string
  Description   : string
  StateIcon     : string
  ToolTip       : string
}


export type WorkItemDetailsDefaultResponse = {
    id: number
    rev: number
    fields: {
      "System.AreaPath": string
      "System.TeamProject": string
      "System.IterationPath": string
      "System.WorkItemType": string
      "System.State": string
      "System.Reason": string
      "System.CreatedDate": string
      "System.CreatedBy": {
        displayName: string
        url: string
        _links: {
          avatar: {
            href: string
          }
        }
        id: string
        uniqueName: string
        imageUrl: string
        descriptor: string
      }
      "System.ChangedDate": string
      "System.ChangedBy": {
        displayName: string
        url: string
        _links: {
          avatar: {
            href: string
          }
        }
        id: string
        uniqueName: string
        imageUrl: string
        descriptor: string
      }
      "System.CommentCount": number
      "System.Title": string
      "Microsoft.VSTS.Common.StateChangeDate": string
      "Microsoft.VSTS.Common.Priority": number
      "Custom.ReportedBy": string
      "Custom.ReportedBy_mailcontact": string
      "System.Description": string
      "System.Tags": string
    }
    _links: {
      self: {
        href: string
      }
      workItemUpdates: {
        href: string
      }
      workItemRevisions: {
        href: string
      }
      workItemComments: {
        href: string
      }
      html: {
        href: string
      }
      workItemType: {
        href: string
      }
      fields: {
        href: string
      }
    }
    url: string
}

export class WorkItemStore {
  bugRecords! : WorkItem[]
  taskRecords!: WorkItem[]
}

export interface WorkItemRequest {
  token   : string
  url     : WorkItemUrl
  payload?: WorkItemPayload
}

export interface WorkItemUrl {
  baseUrl     : string
  projectName : DevOpsProjects
  endpoint    : DevOpsEndpoints
  Type?       : WorkItemType
  queryID?    : WorkItemQueryID
  apiversion  : DevOpsApiVersion
  workItemId? : number
}

export interface WorkItemPayload {
  Title                  : string
  Description            : string
  AreaPath?              : string
  IterationPath          : string
  Tags                   : string
  ReportedBy             : string
  ReportedBy_mailcontact : string
  Type?                  : WorkItemType
}

export interface WorkItemDetails {
  Title       : string
  State       : string
  Id          : number
  ReportedBy  : string
  WorkItemType: string
  Description : string
  StateIcon   : string
  ToolTip     : string
}


// Currently Using the below
export interface WorkItemDialog {
  input  : WorkItemDialogInput,
  output : WorkItemDialogOutput
}

export interface WorkItemDialogInput {
  MainTitle          : string,
  TitleMessage       : string,
  DescriptionMessage : string
}

export interface WorkItemDialogOutput {
  Title       : string
  Description : string
  User        : string
  Mail        : string
}
