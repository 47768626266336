// S: RESPONSIBILITY - To display a list of menu Items

import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input,OnInit, } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from '../../datasource/interfaces/menu-item.interface';
import { LoginService } from '../../datasource/services/login.service';
import { SideMenuService } from '../../datasource/services/side-menu.service';

@Component({
  selector: 'jafar-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush , 
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({transform: 'rotate(0deg)'})),
      state('expanded', style({transform: 'rotate(180deg)'})),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]

})

export class SideMenuComponent implements OnInit{
  panelOpenState: boolean = false
  expanded!: boolean;

  // @Input() sideMenu! : SideMenu;  
  @Input() menuItem!: MenuItem;
  @Input() depth: number = 0;
  // @Input() childMenuItem! : MenuItemChild

  ngOnInit(): void {
  }

  constructor(
    public sideMenuService: SideMenuService,
    public router: Router
  ){
    if (this.depth === undefined) {
      this.depth = 0;
    }
  }

  onItemSelected(item: MenuItem) {
    if (!item.children || !item.children.length) {
      this.router.navigate([item.path]);
      console.log(item.path)
      // this.navService.closeNav();
    }
    if (item.children && item.children.length) {
      console.log(item.path)
      item.path !== null ? this.router.navigate([item.path]) : null
      this.expanded = !this.expanded;
    }
  }

}
