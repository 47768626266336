<button matTooltip="Filter by Date" mat-button class="date-picker-button" (click)="openDatePicker()" >
  <mat-icon class="date-picker-icon">today</mat-icon>
  Filter by Date Range
</button>
<mat-form-field class="date-picker">
  <mat-label>Enter a date range</mat-label>
  <mat-date-range-input [formGroup]="dateRange" [rangePicker]="dateRangePicker">
    <input matStartDate formControlName="startDate" placeholder="Start date">
    <input matEndDate formControlName="endDate" placeholder="End date" (dateChange)="emitSelectedDate()">
  </mat-date-range-input>
  <mat-datepicker-toggle matIconSuffix [for]="dateRangePicker"></mat-datepicker-toggle>
  <mat-date-range-picker #dateRangePicker></mat-date-range-picker>
</mat-form-field>
