import { DynamicFormSchema } from './../../../../../shared/ui/dynamic-form/dynamic-form.interface';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackBarStyle } from 'src/app/shared/ui/snackbar/snackbar.interface';
import { Component, Inject, OnInit, ViewChild, inject } from '@angular/core';
import { JeannieCustomer } from 'src/app/shared/datasource/interfaces/cip.interface';
import { environment } from 'src/environments/environment';
import { MatStepper } from '@angular/material/stepper';
import { CreatePopupInput } from './create-azure-app-popup.interface';
import { CreateAadApplicationPayload } from 'src/app/shared/datasource/interfaces/aad-appreg.interface';
import { ValidationService } from 'src/app/shared/datasource/services/validation/validation.service';
import { ChipListOptions } from 'src/app/shared/ui/dynamic-form/dynamic-form.interface';
import appPermissionsJson  from 'src/assets/config/dynamic-forms/appreg-permissions-form.json'
import { DynamicFormComponent } from 'src/app/shared/ui/dynamic-form/dynamic-form.component';
import createCustomerJson from 'src/assets/config/dynamic-forms/create-app-form.json'
import { FormControl, FormGroup } from '@angular/forms';
import { UserService } from 'src/app/core/datasource/services/user/user.service';
import { AadAppregService } from 'src/app/shared/datasource/services/http-requests/azure-functions/application-registrations/aad-appreg.service';
import { CipService } from 'src/app/shared/datasource/services/http-requests/azure-functions/cip/cip.service';
import { UserProfile } from 'src/app/shared/datasource/interfaces/user-profile';
import { ApprovalResponse } from 'src/app/shared/datasource/interfaces/approval-interface';
import { SpinnerService } from 'src/app/shared/datasource/services/spinner/spinner.service';
import { DialogOutput } from 'src/app/shared/datasource/interfaces/dialog.interface';
import { DialogService } from 'src/app/shared/datasource/services/dialogs/dialog.service';
import { HelperFunctionsService } from 'src/app/shared/functions/helperfunctions';

@Component({
  selector: 'jafar-create-azureapp-popup-with-roles',
  templateUrl: './create-azure-app-popup-with-roles.component.html',
  styleUrls: ['./create-azure-app-popup-with-roles.component.scss']
})
export class CreateAzureAppPopupWithRoles implements OnInit {
  // Initialize Child Views
  @ViewChild("appCreateForm")       appCreateForm! : DynamicFormComponent
  @ViewChild("appRegPermissionsForm") appPermForm! : DynamicFormComponent

  // Import Services
  userProfile = inject (UserService)
  helperFunctions = inject(HelperFunctionsService)


  // Get the Form from the Json File
  appPermFormData   : DynamicFormSchema[] = appPermissionsJson as any //DynamicFormSchema[]
  appCreateFormData : DynamicFormSchema[] = createCustomerJson as any //DynamicFormSchema[]
  public disableSelectFullPermissions : boolean = true

  // Radio Buttons
  roleGroupSelected : string   = "Lite";
  roleGroupList     : string[] = ['Lite', 'Full', 'Custom'];

  // Chip Options
  selectedRoles : ChipListOptions[] = []
  defaultRoles  : ChipListOptions[] = []

  // Result
  roleArray: string[] = []


  // Miscellaneous Variables
  permissionGroup: string[] = []
  validateAppRegName: string[] = []
  chipOptions : ChipListOptions[] = []
  currentEnvironment: string = "";

  form: FormGroup = new FormGroup({
    roles: new FormControl()
  });


  // Construct all required components/services
  constructor(
    public dialog: DialogService,
    public dialogRef: MatDialogRef<CreateAzureAppPopupWithRoles>,
    public spinnerService : SpinnerService,
    private jeannieService: CipService,
    private aadAppRegService: AadAppregService,
    private validationService: ValidationService,
    @Inject(MAT_DIALOG_DATA) public data: CreatePopupInput
  ) { }

  // Get initial Data with defined methods
  ngOnInit(): void {
    this.getAllCustomers()
    this.getValidationSet()
    this.currentEnvironment = environment.jeannieEnvironment
    this.defaultRoles = this.appPermFormData[0]?.chiplistOptions ?? []
    this.selectedRoles = [...this.defaultRoles]
  }

  getAllCustomers(){
    this.jeannieService.getCIPCustomers().subscribe((response: JeannieCustomer[]) => {
      this.appCreateFormData.filter(
        (element: DynamicFormSchema) => {
          if (element.controlName === "customer" && element.dropdownOptions?.dropdownData !== undefined){
            element.dropdownOptions.dropdownData = response //as Observable<JeannieCompanyResponse>
          }
        }
      )
    })
  }

  getValidationSet(){
    this.aadAppRegService.getApplicationList(this.userProfile.activeUser.id).subscribe(
      (results) => {
        results.forEach((appReg) => {
          this.validateAppRegName.push(appReg.friendlyName.toLowerCase())
        })
      }
    )
  }

  getCreateApplicationRequestPayload() : CreateAadApplicationPayload {
    const appPayload : CreateAadApplicationPayload = {
      CustomerID         : (this.appCreateForm.dynamicForm.controls["customer"].value).customerId,
      JeannieEnvironment : environment.jeannieEnvironment,
      FriendlyName       : this.appCreateForm.dynamicForm.controls["appName"].value,
      Description        : this.appCreateForm.dynamicForm.controls["description"].value,
      RequestFirstName   : this.userProfile.activeUser.givenName,
      RequestLastName    : this.userProfile.activeUser.surname,
      RequestMail        : this.appCreateForm.dynamicForm.controls["email"].value,
      Owner              : this.data.ownerId,
      ProvidedRoles      : this.roleArray
    }
    return appPayload
  }

  getRolesFromSelection(){
    this.roleArray = (this.selectedRoles
      .filter(role => role.selected == true))
      .map(role => role.id)
  }

  createApplication (appPayload: CreateAadApplicationPayload){
    // Instantiate variables to be used in creating the payload
    const userProfile: UserProfile = this.userProfile.activeUser
    const appDescription = `Application Creation Request: ${this.appCreateForm.dynamicForm.controls["appName"].value}`

    // Create App Payload
    const createAppPayload = this.helperFunctions.createApprovalPayload("CreateAppReg", appDescription, appPayload, userProfile)
    console.log(JSON.stringify(createAppPayload))

    // Submit Creation Request
    this.aadAppRegService.createApplication(createAppPayload).subscribe(
      (response: any) => {
        console.log("response:", response)
        this.createSucceedMessage(response)
      }
    )
  }

  createSucceedMessage(response: ApprovalResponse){
    const ReturnedData : DialogOutput = {
      data : {
        status:"Success",
        responseData: response
      },
      snackbarConfig: {
        buttonName: "OK",
        message: `
          Your Application Request has been successfully submitted.
          Your Request Number is: ${response.id}.
          You will shortly receive a confirmation email.
        `,
        style : SnackBarStyle.default,
        settings: {
          horizontalPosition: 'center',
          verticalPosition:'top'
        }
      }
    }
    this.dialogRef.close(ReturnedData)
  }

  createFailureMessage(message: string){
    const ReturnedData : DialogOutput = {
      data : {
        status:"Request Submission Failed"
      },
      snackbarConfig: {
        buttonName: "Close",
        message: message,
        style : SnackBarStyle.default,
        settings: {
          horizontalPosition: 'center',
          verticalPosition:'top'
        }
      }
    }
    this.dialogRef.close(ReturnedData)
  }

  setChipState(selectedState: boolean, disabledState: boolean){
    let allSelected : ChipListOptions[] = []
    this.defaultRoles.forEach(
      (role :ChipListOptions) => {
        if (role.id  !== "ApiMailSend"){
          role = {...role,
            selected : selectedState,
            disabled: disabledState
          }
        }
        allSelected.push(role)
      }
    )
    this.selectedRoles = allSelected
  }

  onRadioButtonSelected(selectedRole : string){
    switch (selectedRole) {
      case "Lite" : {
        console.log("None Selected")
        this.setChipState(false, true)
        break
      }
      case "Full" : {
        console.log("Full Selected")
        this.setChipState(true, true)
        console.log("RB Button Pressed - Roles are now:" , this.selectedRoles)
        break
      }
      case "Custom" : {
        console.log("Custom Selected")
        this.setChipState(true, false)
        break
      }
      default : {
        console.log("Not a valid selection")
        break
      }
    }
    let firstItemInArray = this.appPermFormData[0]
    firstItemInArray.chiplistOptions = this.selectedRoles
    this.appPermFormData = [firstItemInArray]

  }

  onCancel(): void {
    this.dialogRef.close(false)
  }

  onNext(stepper: MatStepper){
    console.log(stepper)
    console.log(stepper.selectedIndex)
    if (stepper.selectedIndex === 0){
      console.log(stepper.selectedIndex)
      let nameValidation: boolean = this.validationService.validate(this.appCreateForm.dynamicForm.controls["appName"].value, this.validateAppRegName)
      if(nameValidation){
        let failureMessage = `App Request Submission Failed. Application with name "${this.appCreateForm.dynamicForm.controls["appName"].value}" already exists`
        this.createFailureMessage(failureMessage)
      }
      this.onRadioButtonSelected(this.roleGroupSelected)
      console.log("Going to Second Step")
      console.log(stepper.selectedIndex)
    }

    if (stepper.selectedIndex === 1 ){
      console.log(stepper.selectedIndex)
      this.roleArray = this.appPermForm.dynamicForm.controls["appRoles"].value
      console.log("OnNext Method : this.roleArray - ", this.roleArray)
      console.log("Going to Third Step")
      console.log(stepper.selectedIndex)
    }

    stepper.selected!.completed = true;
    stepper.selected!.editable = false;
    stepper.next()
  }

  onSubmit(){
    const appPayload = this.getCreateApplicationRequestPayload()
    console.log(JSON.stringify(appPayload))
    this.createApplication(appPayload)
  }
}
