<mat-card class="card-appearance">

    <mat-card-header class="header">
      <span class="title">
        <h1>Manage Application Secrets</h1>
        <span class="title-span"></span>

        <button mat-button color="accent" class="title-back-button" routerLink="/manageazure/appreg">
          <mat-icon > keyboard_return </mat-icon>
          Back To Applications
        </button>
      </span>

      <span class="button-group">
          <button mat-button class="action-button2" (click)="openCreateDialog()"> Create Secret</button>
          <mat-divider class="button-divider" [vertical]="true"></mat-divider>
          <button mat-button [disabled]="disableRefreshButton" class="action-button2" (click)="openRefreshDialog()"> Refresh Secret</button>
          <mat-divider class="button-divider" [vertical]="true"></mat-divider>
          <button mat-button [disabled]="disableDeleteButton" class="action-button2" (click)="openDeleteDialog()"> Delete  Secret</button>
      </span>

    </mat-card-header>

    <mat-card-content>

      <mat-divider class="divider"></mat-divider>
      <span>
        <h3>Application: <b>{{applicationName}}</b>
          <mat-icon matTooltip="Refresh Secrets" (click)="getApplicationSecret()" class="item-alignment">sync</mat-icon>
        </h3>

      </span>
      <jafar-paginated-table
        [tableInput]="tableData | async"
        (selectedRecord)="onSelection($event)"
      ></jafar-paginated-table>

    </mat-card-content>
</mat-card>
