import { Component, Inject, ViewChild, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CreateCustomerPayload, CreateCustomerResponse } from 'src/app/shared/datasource/interfaces/cip.interface';
import { SpinnerService } from 'src/app/shared/datasource/services/spinner/spinner.service';
import { DynamicFormSchema } from 'src/app/shared/ui/dynamic-form/dynamic-form.interface';
import { SnackBarStyle } from 'src/app/shared/ui/snackbar/snackbar.interface';
import customerJsonForm  from 'src/assets/config/dynamic-forms/customer-form.json'
import { environment }        from 'src/environments/environment';
import { DynamicFormComponent } from 'src/app/shared/ui/dynamic-form/dynamic-form.component';
import { ApprovalWorkItem, WorkItemResponse } from 'src/app/shared/datasource/services/http-requests/azure-devops/workitem/workitem.interface';
import { ValidationService } from 'src/app/shared/datasource/services/validation/validation.service';
import { WorkitemService } from 'src/app/shared/datasource/services/http-requests/azure-devops/workitem/workitem.service';
import { CipService } from 'src/app/shared/datasource/services/http-requests/azure-functions/cip/cip.service';
import { ApprovalResponse } from 'src/app/shared/datasource/interfaces/approval-interface';
import { UserProfile } from 'src/app/shared/datasource/interfaces/user-profile';
import { UserService } from 'src/app/core/datasource/services/user/user.service';
import { DialogOutput } from 'src/app/shared/datasource/interfaces/dialog.interface';
import { HelperFunctionsService } from 'src/app/shared/functions/helperfunctions';

@Component({
  selector: 'jafar-create-customer-popup',
  templateUrl: './create-customer-popup.component.html',
  styleUrls: ['./create-customer-popup.component.scss']
})
export class CreateCustomerPopupComponent {

  @ViewChild("customerForm") customerForm! : DynamicFormComponent
  validateCustomerName : string[] = []
  formData : DynamicFormSchema[] = customerJsonForm as any[]
  // formData : DynamicFormSchema[] = customerJsonForm as DynamicFormSchema[]

  formSubmitted : boolean = false
  userProfile = inject (UserService)
  helperFunctions = inject(HelperFunctionsService)
  constructor(
    private jeannieService: CipService,
    private workItemService : WorkitemService,
    public spinnerService : SpinnerService,
    public dialogRef: MatDialogRef<CreateCustomerPopupComponent>,
    private validationService: ValidationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ){}

  ngOnInit(){
    this.getCompanies()
    this.getValidationSet()
  }

  getCompanies(){
    this.jeannieService.getCIPCompanies().subscribe(
      result => {
        console.log(result)
        this.formData.filter(
          (element : DynamicFormSchema) => {
            if (element.controlName === "company" && element.dropdownOptions?.dropdownData !== undefined){
              element.dropdownOptions.dropdownData = result //as Observable<JeannieCompanyResponse>
            }
          }
        )
      }
    )
  }

  getValidationSet(){
    this.jeannieService.getCIPCustomers().subscribe(
      (results)=> {
        results.forEach((customer) => {
          this.validateCustomerName.push(customer.name.toLowerCase())
        })
      }
    )
  }

  createCustomerPayload(form: FormGroup) : CreateCustomerPayload{
    let stringSapName: string = form.value.SapName
    const customerPayload : CreateCustomerPayload = {
      CompanyId   : form.value.company.companyId,
      Name        : form.value.customerName,
      DisplayName : form.value.customerDisplayName,
      SapId       : form.value.SapId,
      SapName     : stringSapName.toUpperCase(),
    }
    return customerPayload
  }

  createSucceedMessage(response : ApprovalResponse){
    const ReturnedData : DialogOutput = {
      data : {
        status:"Request Submission Success",
        responseData: response
      },
      snackbarConfig: {
        buttonName: "OK",
        message:
          `Your Customer Request has been submitted succesfully.
                    Your Request number is : ${response.id}`,
        style : SnackBarStyle.default,
        settings: {
          horizontalPosition: 'center',
          verticalPosition:'top'
        }
      }
    }
    this.dialogRef.close(ReturnedData)
  }

  createFailureMessage(message: string){
    const ReturnedData : DialogOutput = {
      data : {
        status:"Request Submission Failed"
      },
      snackbarConfig: {
        buttonName: "Close",
        message: message,
        style : SnackBarStyle.default,
        settings: {
          horizontalPosition: 'center',
          verticalPosition:'top'
        }
      }
    }
    this.dialogRef.close(ReturnedData)
  }

  createCustomerRequest(customerPayload: CreateCustomerPayload){
    const userProfile: UserProfile = this.userProfile.activeUser
    const customerDescription = `Customer Creation Request: ${this.customerForm.dynamicForm.controls["customerName"].value}`

    // Create App Payload
    const customerRequestPayload = this.helperFunctions.createApprovalPayload("CreateCustomer", customerDescription, customerPayload, userProfile)
    console.log(JSON.stringify(customerRequestPayload))

    this.jeannieService.createCIPCustomer(customerRequestPayload).subscribe((response: ApprovalResponse) => {
      console.log(response.id)
      this.createSucceedMessage(response)
    })
  }

  // createCustomerWorkItem(payload: CreateCustomerPayload){
  //   const workItemPayload : ApprovalWorkItem = {
  //     payload: payload,
  //     title: `New Customer - ${payload.DisplayName}`,
  //     area: 'uxcustomer',
  //     iteration: 'Customer_Requests',
  //     tag: 'customer'
  //   }
  //   console.log (workItemPayload)
  //   this.workItemService.createApprovalWorkItem(workItemPayload).subscribe((response: WorkItemResponse) => {
  //     console.log(response)
  //     this.createSucceedMessage(response)
  //   })
  // }

  onSubmit(){
    let nameValidation: boolean = this.validationService.validate(this.customerForm.dynamicForm.value.customerName, this.validateCustomerName)
    if(!nameValidation){
      this.formSubmitted = true
      const customerPayload = this.createCustomerPayload(this.customerForm.dynamicForm)
      this.createCustomerRequest(customerPayload)
    }else{
      let failureMessage = `Customer Create Submission Failed. Customer with name "${this.customerForm.dynamicForm.value.customerName}" already exists`
      this.createFailureMessage(failureMessage)
    }
  }

  onCancel(): void {
    this.dialogRef.close(false)
  }

}
