<span class="framework">
    <mat-card appearance="outlined" class="login-page-card">
        <mat-card-title class="content">
            <img class="card-icon"/>
        </mat-card-title>
        <mat-card-content>

            <span class="content">
                <br>
                        {{welcomeMessage}}
            </span>

            <p></p>
            <ng-container *ngIf="accessRequest.getFeedbackMessage()  then displayError; else displayNothing"></ng-container>

            <ng-template  #displayError>
                <p class="Notification">
                    {{ accessRequest.feedbackMessage$ | async }}
                </p>
            </ng-template>

            <ng-template #displayNothing>
                <p class="Notification"></p>
            </ng-template>

            <mat-card-actions class="button-content">
                <!-- <span class="big-span"></span> -->
                <jafar-action-button (btnClick)="homePageLogin()">Login</jafar-action-button>
            </mat-card-actions>
        </mat-card-content>
        <mat-card-footer>
          <p class="create-account-footer">
            Don't have an account? Click <a routerLink="/access" class="register-account"> HERE </a> to register.
          </p>
        </mat-card-footer>
    </mat-card>
</span>
