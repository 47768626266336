import { Component, TemplateRef, ViewChild, inject, AfterViewInit, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { idType, CIPRecord } from 'src/app/shared/datasource/interfaces/CIP/cip-general.interface';
import { CipSearchComponent } from 'src/app/shared/ui/dialogs/cip-search/cip-search.component';
import requestTaskHeadersJson  from '../../../../assets/config/tableHeaders/request-task-headers.json'
import { CipRequestTaskStore } from 'src/app/shared/datasource/stores/cip/cip-requestTask-store.service';
import { FormGroup, FormControl, Validators, AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { CIPRequestTask } from 'src/app/shared/datasource/interfaces/CIP/cip-requestTasks.interface';
import { Observable, catchError, map, of, BehaviorSubject } from 'rxjs';
import { CipSearchData } from 'src/app/shared/datasource/interfaces/CIP/cip-page-layout.interface';
import { TableColumns, ReusableTable } from 'src/app/shared/ui/reusable-table/interface/reusable-table.interface';
import { PaginationDefaults } from 'src/environments/generic-variables';
import { CipCustomerStore } from 'src/app/shared/datasource/stores/cip/cip-customer-store.service';
import { Customer } from '../../manage-azure-app-reg/datastore/customer.interface';
import { CipRowFilterComponent } from 'src/app/shared/ui/dialogs/cip-row-filter/cip-row-filter.component';
import { HelperFunctionsService, DateFilterRange } from 'src/app/shared/functions/helperfunctions';
import { DateRangeComponent } from 'src/app/shared/ui/date-range-picker/date-range/date-range.component';

@Component({
  selector: 'jafar-request-tasks',
  templateUrl: './request-tasks.component.html',
  styleUrls: ['./request-tasks.component.scss']
})
export class RequestTasksComponent implements OnInit, AfterViewInit {
  @ViewChild('datePicker') datePicker!: DateRangeComponent

  // All Injected Services / Stores
  requestTaskStore  = inject(CipRequestTaskStore)
  dialog = inject(MatDialog)
  router = inject(Router)
  customerStore = inject(CipCustomerStore)
  helperFunctions = inject(HelperFunctionsService)

  // Local Variables and Obsedrvables
  allRequestTaskTableHeaders: TableColumns[] = requestTaskHeadersJson as TableColumns[]
  displayedRequestTaskTableHeaders!: TableColumns[]
  requestTaskTable$ = new BehaviorSubject<ReusableTable>({
    data     : this.requestTaskStore.requestTasks$,
    columns  : this.displayedRequestTaskTableHeaders,
    settings : {
      pagination: true,
      paginationOptions: PaginationDefaults,
      sorting: {
        sortedColumn: 'createdAt',
        sortedOrder: 'desc'
      },
      search: true,
      reorderColumns: true,
      hidePageSize: false
    }
  })

  customerSelected!: boolean
  selectedDateRange!: DateFilterRange | null
  customerDropdown = {
    listLabel       : "Customer",
    displayName     : "displayName"
  }

  // All public variables
  public requestTaskFilters: idType[] = ['id', 'internalRequestTaskId','grandParentId', 'customer']
  public searchData!: CipSearchData
  public SearchFG = new FormGroup (
    {
      searchId         : new FormControl('', { validators: [Validators.required], updateOn: 'blur'}),
    }
  )

  @ViewChild("search") searchTemplate!: TemplateRef<any>

  ngOnInit(){
    // Check to see if a customer is in the store, if it is, filter the data on that customer.
    this.customerStore.customer.displayName != "" ?
    this.requestTaskStore.fetchCustomerRequestTasks(this.customerStore.customer) :
    this.requestTaskStore.fetchRequestTasks()

    this.customerStore.fetchCustomers()
    this.displayedRequestTaskTableHeaders = [...this.allRequestTaskTableHeaders]
    .filter((columns: TableColumns) => {return columns.show})
    this.requestTaskTable$.next({
      ...this.requestTaskTable$.getValue(),
      columns: this.displayedRequestTaskTableHeaders
    })
    this.requestTaskStore.resetRequestTaskInfo()
  }

  ngAfterViewInit() {
    this.searchData = {
      cipType: "RequestTask",
      template: this.searchTemplate,
      id: "",
      idType: "id",
    }
    this.SearchFG.controls['searchId'].addAsyncValidators(this.requestTaskDetailValidator())
    this.SearchFG.controls['searchId'].updateValueAndValidity()
  }

  goToRequestTask(cipId:string){
    console.log("pageID", cipId)
    this.router.navigate(["opsdashboard/requesttask",cipId])
  }

  selectFilter(event: idType){
    this.SearchFG.controls['searchId'].clearAsyncValidators()
    this.SearchFG.controls['searchId'].updateValueAndValidity()
    this.SearchFG.reset()
    this.customerStore.clearSelectedCustomer()
    this.searchData.idType = event
  }

  getRequestTaskInfo(){
    console.log(this.searchData.idType)

    // Clear Async Validators
    switch(this.searchData.idType)
    {
      case "id":
        // Add Async Validators back
        this.SearchFG.controls['searchId'].addAsyncValidators(this.requestTaskDetailValidator())
        // Initialize Validators
        this.SearchFG.controls['searchId'].updateValueAndValidity()
        if(this.SearchFG.controls.searchId.value){
          (this.requestTaskStore.fetchRequestTaskInfo(this.SearchFG.controls.searchId.value as string))
        }
        console.log(this.SearchFG)
        break;

      case "internalRequestTaskId":
        this.SearchFG.controls['searchId'].addAsyncValidators(this.requestTaskValidator())
        this.SearchFG.controls['searchId'].updateValueAndValidity()
        const searchString = `internalRequestTaskId eq '${this.SearchFG.controls['searchId'].value}'`
        console.log(searchString)
        this.requestTaskStore.fetchRequestTasks(searchString)
        console.log(this.SearchFG)
        break;

      case "grandParentId":
        this.SearchFG.controls['searchId'].addAsyncValidators(this.requestIdValidator())
        this.SearchFG.controls['searchId'].updateValueAndValidity()
        const searchRequestString = `grandParentId eq '${this.SearchFG.controls['searchId'].value}'`
        console.log(searchRequestString)
        this.requestTaskStore.fetchRequestTasks(searchRequestString)
        console.log(this.SearchFG)
        break;

      default:
        console.log("ERROR: Invalid Search Type")
        break
    }
  }

  getCustomerRequestTasks(customer: Customer){
    const dateFilter = this.selectedDateRange ? this.selectedDateRange : null
    if(customer && dateFilter !== null){
      console.log("CUSTOMER + DATE FILTER")
      this.customerStore.updateSelectedCustomer(customer)
      this.requestTaskStore.fetchCustomerRequestTasks(customer, this.selectedDateRange)
    }else if(customer && dateFilter == null){
      console.log("CUSTOMER ONLY FILTER")
      this.customerStore.updateSelectedCustomer(customer)
      this.requestTaskStore.fetchCustomerRequestTasks(customer)
    }else{
      console.log("NO FILTER")
      this.customerStore.clearSelectedCustomer()
      this.requestTaskStore.fetchRequestTasks()
    }
  }

  getRequestTasksByDateRange(dateRange: DateFilterRange){
    const customer = this.customerStore.customer.displayName != "" ? this.customerStore.customer : null
    if(customer !== null && dateRange){
      console.log("CUSTOMER + DATE FILTER")
      this.requestTaskStore.fetchCustomerRequestTasks(customer, this.selectedDateRange)
    }else{
      console.log("DATE ONLY FILTER")
      const dateFilter = `createdAt ge ${dateRange.startDate} and createdAt le ${dateRange.endDate}`
      this.requestTaskStore.fetchRequestTasks(dateFilter)
    }
  }

  private requestIdValidator(): AsyncValidatorFn{
    return (control: AbstractControl): Observable<ValidationErrors | null> =>
    this.requestTaskStore.validateRequestTasks(`grandParentId eq '${control.value}'`).pipe(
      map((response: CIPRequestTask[]) =>
        {
          console.log(response.length)
          return response.length != 0 ? null : {validateRequestTasks: true}
        }
      )
    )
  }

  private requestTaskValidator(): AsyncValidatorFn{
    return (control: AbstractControl): Observable<ValidationErrors | null> =>
    this.requestTaskStore.validateRequestTasks(`internalRequestTaskId eq '${control.value}'`).pipe(
      map((response: CIPRequestTask[]) =>
        {
          console.log(response.length)
          return response.length != 0 ? null : {validateRequestTasks: true}
        }
      )
    )
  }

  private requestTaskDetailValidator(): AsyncValidatorFn{
    return (control: AbstractControl): Observable<ValidationErrors | null> =>
    this.requestTaskStore.validateRequestTaskInfo(control.value).pipe(
      map((response: CIPRecord) =>
        {
          console.log(response)
          return response ? null: {validateRequestTaskInfo: true}
        }
      ),
      catchError(() => {
        return of({validateRequestTaskInfo: true});
      })
    )
  }

  onCancel(){
    this.requestTaskStore.fetchRequestTasks()
    this.dialog.closeAll()
    this.SearchFG.reset()
    this.searchData = {
      cipType: "RequestTask",
      template: this.searchTemplate,
      id: "",
      idType: "id",
    }
  }

  onChooseColumns(){
    console.log(this.allRequestTaskTableHeaders)
    console.log(this.displayedRequestTaskTableHeaders)
    const selectedColumns: TableColumns[] = [...this.allRequestTaskTableHeaders]
    .filter((column: TableColumns) => {
      console.log(column.show)
      return column.show
    })
    console.log(selectedColumns)
    const dialogRef = this.dialog.open(CipRowFilterComponent, {
      data: {
        allProperties      : this.allRequestTaskTableHeaders,
        selectedProperties : selectedColumns.map((column: TableColumns) => column.name),
        title: "Available Columns",
        description: "Please select the Columns you would like to display"
      }
    });

    dialogRef.afterClosed().subscribe((result:any) => {
      console.log(result)
      const updatedColumns = [...this.allRequestTaskTableHeaders].map(
        (column: TableColumns) => {
          result.includes(column.name) ? column = {...column, show: true} : column = {...column, show: false}
          return column
        })

      this.allRequestTaskTableHeaders = updatedColumns
      this.requestTaskTable$.next({
        ...this.requestTaskTable$.getValue(),
        columns: updatedColumns.filter((column: TableColumns) => {return column.show})
      })
    });
  }

  onClearFilter(){
    // Clear all values selected in date picker
    this.selectedDateRange = null
    this.datePicker.dateRange.controls['startDate'].setValue(null)
    this.datePicker.dateRange.controls['endDate'].setValue(null)
    // Clear any selected customers, and refetch all incidents without filters
    this.customerStore.clearSelectedCustomer()
    this.requestTaskStore.fetchRequestTasks()
  }

  onDateFilter(event: any){
    console.log(event)
    const dateObject: DateFilterRange = {
      startDate: event.controls['startDate'].value,
      endDate: event.controls['endDate'].value
    }
    const formattedDate = this.helperFunctions.convertToOdataDate(dateObject)
    this.selectedDateRange = formattedDate
    console.log(formattedDate)
    this.getRequestTasksByDateRange(formattedDate)
  }

  onRefresh(){
    const customer = this.customerStore.customer.displayName != "" ? this.customerStore.customer : null
    const dateFilter = this.selectedDateRange ? this.selectedDateRange : null
    if(customer !== null && dateFilter !== null){
      console.log("CUSTOMER + DATE FILTER")
      this.requestTaskStore.fetchCustomerRequestTasks(customer, this.selectedDateRange)
    }else if(customer && dateFilter == null){
      console.log("CUSTOMER ONLY FILTER")
      this.requestTaskStore.fetchCustomerRequestTasks(customer)
    }else if(customer == null && dateFilter){
      console.log("DATE ONLY FILTER")
      const searchString = `CreatedAt ge ${dateFilter.startDate} and CreatedAt le ${dateFilter.endDate}`
      this.requestTaskStore.fetchRequestTasks(searchString)
    }else{
      console.log("NO FILTER")
      this.requestTaskStore.fetchRequestTasks()
    }
  }

  onSearch(){
    this.customerStore.clearSelectedCustomer()
    this.dialog.open(
      CipSearchComponent, {
        data:this.searchData,
        disableClose: true
      });
  }

  onSelect(event: any){
    console.log(event)
    const requestTask = event as CIPRequestTask
    this.goToRequestTask(requestTask.id)
  }

  onSubmit(){
    const searchType = this.searchData.idType as string
    if(searchType == "id"){
      this.SearchFG.controls['searchId'].value ? this.goToRequestTask(this.SearchFG.controls['searchId'].value): null
    }

    if(searchType == "internalRequestTaskId"){
      this.requestTaskStore.requestTasks$.subscribe(
        (response: any) => {
        if(response.length == 1 && this.SearchFG.controls['searchId'].value){
          this.goToRequestTask(response[0].id)
        }
      })
    }

    if(searchType == "grandParentId"){
      this.requestTaskStore.requestTasks$.subscribe(
        (response: any) => {
        if(response.length == 1 && this.SearchFG.controls['searchId'].value){
          this.goToRequestTask(response[0].id)
        }
      })
    }

    this.dialog.closeAll()
    this.SearchFG.reset()
  }
}
