import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { DropdownPanel } from './custom-dropdown-interface.ts';
import { DropDownType } from '../../reusable-filter/reusable-filter.interface.js';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'jafar-custom-dropdown',
  templateUrl: './custom-dropdown.component.html',
  styleUrls: ['./custom-dropdown.component.scss']
})
export class CustomDropdownComponent implements DropdownPanel, OnChanges, OnInit{
  @Input() dropDownType!: DropDownType
  @ViewChild(TemplateRef) templateRef!: TemplateRef<any>;
  @Output() closed = new EventEmitter<void>();

  _multiSelect$ = new BehaviorSubject<boolean>(false)
  multiSelect$: Observable<boolean> = this._multiSelect$.asObservable()
  constructor() {}

  ngOnInit(){
    console.log(this._multiSelect$.getValue())
    this.multiSelect$.subscribe((value) => {console.log(value)})
  }

  ngOnChanges(changes: SimpleChanges){
    console.log(changes['dropDownType'].currentValue)
    changes["dropDownType"].currentValue == 'multiple' ? this._multiSelect$.next(true) : this._multiSelect$.next(false)
  }

  onSelect(){
    if(this.dropDownType == 'single'){
      this.closed.emit()
    }
  }
}
