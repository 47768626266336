import { CIPStageInfo } from "src/app/shared/datasource/interfaces/CIP/cip-states.interface"

export const CIPStages : any = {
    "none" : {
      "in creation"  : {
        stepIndex     : 2,
        stepNumber    : 0,
        label         : "In Creation",
      }
    },

    "new" : {
      "new" : {
        stepIndex     : 0,
        stepNumber    : 1,
        label         : "New",
      }
    },

    "in creation" : {
      "in creation"  : {
        stepIndex     : 2,
        stepNumber    : 0,
        label         : "In Creation ",
      }
    },

    "assigned" : {
      "confirmed" : {
        stepIndex     : 1,
        stepNumber    : 2,
        label         : "Queued",
      }
    },

    "in progress" : {
      "in progress" : {
        stepIndex     : 2,
        stepNumber    : 3,
        label         : "In Progress",
      },
      "completed" : {
        stepIndex     : 3,
        stepNumber    : 4,
        label         : "Completed",
      },
      "completed failed" : {
        stepIndex     : 3,
        stepNumber    : 4,
        label         : "Completed",
      },
      "completed success" : {
        stepIndex     : 3,
        stepNumber    : 4,
        label         : "Completed",
      },
      "cancelled" : {
        stepIndex     : 3,
        stepNumber    : 4,
        label         : "Cancelled",
      }
    },

    "resolved": {
      "completed" : {
        stepIndex     : 4,
        stepNumber    : 5,
        label         : "Confirmed",
      },
      "completed failed" : {
        stepIndex     : 4,
        stepNumber    : 5,
        label         : "Confirmed",
      },
      "completed success" : {
        stepIndex     : 4,
        stepNumber    : 5,
        label         : "Confirmed",
      },
      "cancelled" : {
        stepIndex     : 4,
        stepNumber    : 5,
        label         : "Confirmed",
      }
    }
  }

  export const ErrorStep : CIPStageInfo = {
    stepIndex     : 2,
    stepNumber    : 999,
    label         : "State Mismatch"
  }