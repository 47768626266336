<mat-card class="card-framework">
    <mat-card-header class="card-header">
        <ng-content></ng-content>
    </mat-card-header>

    <mat-divider class="divider" ></mat-divider>

    <mat-card-content class="card-content">
        <ng-container *ngIf="showContent; then displayContent; else displayNoItems"></ng-container>
    </mat-card-content>

</mat-card>

<ng-template #displayContent>
    <mat-drawer-container hasBackdrop="true" class="sub-sidepanel-container" >
        <mat-drawer mode="over" [opened]="sidePanelStore.isOpen$ | async" position="end" disableClose="true" >
            <ng-template [cdkPortalOutlet]="sidePanelStore.sideMenuComponent"></ng-template>
        </mat-drawer>
        <mat-drawer-content>
            <ng-template [cdkPortalOutlet]="content"></ng-template>
        </mat-drawer-content>
    </mat-drawer-container>
</ng-template>


<ng-template #displayNoItems>
    <div class="no-Item"> There are NO Items to Display for this incident</div>
</ng-template>
