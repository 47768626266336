import { Component, Input, OnInit, ViewChild, OnChanges, AfterViewInit } from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { MatSort } from '@angular/material/sort';
import { TableColumnHeader } from '../../datasource/interfaces/expandable-table-interface';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from 'src/environments/environment';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'jafar-expandable-table',
  templateUrl: './expandable-table.component.html',
  styleUrls: ['./expandable-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})

export class ExpandableTableComponent implements OnInit, AfterViewInit, OnChanges {

  // Inputs for table
  @Input() tableData! : any
  @Input() tableHeaders! : any

  // Viewchild objects for sort and table rendering
  @ViewChild(MatSort, {static: true}) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  // table data source
  dataSource : MatTableDataSource<any> = new MatTableDataSource(this.tableData)

  // Variables used to display data
  columnHeaderIndex : string[] = []
  columnsToDisplay: string[] = [];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedColumn!: any
  expandedIconDetail: any
  expandedData!: any | null;

  // Paginator Details
  pageSize: number = environment.generic.defaultPageSize
  pageSizeOptions: number[] = environment.generic.pageSizeOptions

  ngOnInit(): void{
    this.columnHeaderIndex          = this.getColumnHeaderIndexes()
    this.expandedColumn             = this.getExpandedValue()
    this.expandedIconDetail         = this.getExpandedIconDetail()
    this.columnsToDisplayWithExpand = [...this.columnHeaderIndex, 'expand'];
  }

  ngOnChanges(){
    this.dataSource = new MatTableDataSource(this.tableData)
    this.dataSource.sort = this.sort
    this.dataSource.paginator = this.paginator;
    console.log("Table Data : ", this.dataSource)
  }

  ngAfterViewInit(){
    // Handle Pagination
    this.dataSource = new MatTableDataSource(this.tableData)
    this.dataSource.sort = this.sort
    this.dataSource.paginator = this.paginator;
}

  getColumnHeaderIndexes() : string[] {
    let columnHeaders: TableColumnHeader[] = []
    columnHeaders = this.tableHeaders.filter(
      (header : any) => { return header.expanded !== true ? header.columnHeaderIndex : null }
    )
    return columnHeaders.map(
      filteredHeaders => {
        return filteredHeaders.columnHeaderIndex
      }
    )
  }

  getExpandedValue(){
    return (this.tableHeaders.filter(
      (header : any)  => { return header.expanded === true ? header.columnHeaderIndex : null }
    ))[0]
  }

  getExpandedIconDetail(){
    return (this.tableHeaders.filter(
      (header : any)  => { return header.icon === true ? header.columnHeaderIndex : null }
    ))[0]
  }

}
