import { Injectable, InjectionToken, Injector, inject } from "@angular/core";
import { BehaviorSubject, Observable, concatMap, filter, from, map, switchMap, tap, toArray } from 'rxjs';
import { CIPEnvRouteService } from "../../services/http-requests/azure-functions/cip/cip-envroute.service";
import { CIPEnvRoute, CIPEnvRouteCreateRequest, CIPEnvRouteUpdateRequest } from '../../interfaces/CIP/cip-envRoute.interface';
// import { cipInjectionTokens } from "src/environments/InjectionTokens/cipInjectionTokens";
import { CIPEnvRouteDefault } from "../../interfaces/CIP/cip-envRouteDefault.interface";
import { CIPHeader } from "../../interfaces/CIP/cip-page-layout.interface";
import { CIPQuestion } from "../../interfaces/CIP/cip-questions.interface";
import { CIPSupportGroup } from "../../interfaces/CIP/cip-supportgroups.interface";
import { CIPWorkLog } from "../../interfaces/CIP/cip-worklogs.Interface";
import { cipInjectionTokens } from "src/environments/InjectionTokens/cipInjectionTokens";

@Injectable({
    providedIn: 'root'
})

export class CipEnvRouteStore {
  // Inject the service the store is linked too
  private _envRouteService = inject(CIPEnvRouteService)

  // Decalare the Behaviour Subjects to Store the Data we need
  private readonly _envRoutes$ : BehaviorSubject<CIPEnvRoute[]>   = new BehaviorSubject<CIPEnvRoute[]>([]);
  private readonly _customerEnvRoutes$ : BehaviorSubject<CIPEnvRoute[]>   = new BehaviorSubject<CIPEnvRoute[]>([]);

  private readonly _selectedEnvRoute$ : BehaviorSubject<CIPEnvRoute>   = new BehaviorSubject<CIPEnvRoute>(EMPTYENVROUTE);
  // Methods to populate the Stores
  fetchEnvRoutes(){
    this._envRouteService.getEnvRoutes()
    .subscribe(response => this._envRoutes$.next(response))
  }

  fetchCustomersEnvRoutes(CustomerSapName : string){
    console.log("Getting Customers Env Routes for: CustomerSapName ")
    this._envRoutes$.pipe(
      concatMap(
        (routes : CIPEnvRoute[]) => (
          from(routes).pipe(        // without this from the toArray wasnt working
            filter((route : CIPEnvRoute) => (route.customer.toLowerCase() === CustomerSapName.toLowerCase())),
            toArray(),
            map(customerRoutes => this._customerEnvRoutes$.next(customerRoutes))
          )
        )
      )
    ).subscribe()
  }

  // Exposed Properties of the Store
  // Observable Property
  get envRoutes$(): Observable<CIPEnvRoute[]> {
    return this._envRoutes$.asObservable();
  }

  get customerEnvRoutes$() : Observable<CIPEnvRoute[]> {
    return this._customerEnvRoutes$.asObservable()
  }

  get selectedRoute$(): Observable<CIPEnvRoute> {
    return this._selectedEnvRoute$.asObservable();
  }
  get selectedRoute(): CIPEnvRoute {
    return this._selectedEnvRoute$.getValue()
  }

  // Current State
  get envRoutes() : CIPEnvRoute[] {
    return this._envRoutes$.getValue()
  }

  // Injector
  get envRouteInjector() : Injector {
    return Injector.create(
      {
        providers: [
          {
            provide: cipInjectionTokens.envRoutesToken,
            useValue: this.envRoutes$
          }
        ]
      }
    )
  }


  get selectedEnvRouteInjector() : Injector {
    return Injector.create(
      {
        providers: [
          {
            provide: cipInjectionTokens.selectedRouteToken,
            useValue: this.selectedRoute$
          }
        ]
      }
    )
  }
  // Exposed Methods to Amend the store
  newEnvRoute(newEnvRoute : CIPEnvRouteCreateRequest) {
    this._envRouteService.createEnvRoute(newEnvRoute)
    .subscribe(response => {
      console.log(response)
      this.fetchEnvRoutes()
    })
  }

  editEnvRoute( routeId : string , updatedroute : CIPEnvRouteUpdateRequest){
    this._envRouteService.updateEnvRoute(routeId, updatedroute)
    .subscribe(response => {
      console.log(response)
      this.fetchEnvRoutes()
    })
  }

  deleteEnvRoute(routeId : string){
    this._envRouteService.deleteEnvRoute(routeId)
    .subscribe(response => {
      console.log(response)
      this.fetchEnvRoutes()
    })
  }

  updateSelectedEnvRoute(envRoute : CIPEnvRoute){
    this._selectedEnvRoute$.next(envRoute)
  }

  clearSelectedEnvRoute(){
    this._selectedEnvRoute$.next(EMPTYENVROUTE)
  }
}


const EMPTYENVROUTE : CIPEnvRoute = {
  id: "",
  customer: "",
  question: "",
  answer: "",
  routeName: "",
  config: "",
  active: false,
  createdAt: new Date
}