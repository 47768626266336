<mat-card class="welcome-page-card">
  <h1 class="headline-h1">Manage CIP</h1>
  <mat-divider></mat-divider>
  <mat-card-content class="mat-content">
    <jafar-company-widget></jafar-company-widget>
    <jafar-customer-widget></jafar-customer-widget>
  </mat-card-content>
</mat-card>

<!-- <div class="page-framework">
  <div class="page-title"><h1>Manage CIP</h1></div>
  <mat-divider></mat-divider>
  <div class="mat-content">
    <jafar-company-widget></jafar-company-widget>
    <jafar-customer-widget></jafar-customer-widget>
  </div>
  <mat-grid-list
    [cols]="mybreakpoint"
    rowHeight="10rem"
    gutterSize="15px"
    class="grid-list-framework"
    (window:resize)="handleSize($event)"
  >

    <mat-grid-tile class="grid-tile">
      <mat-card class="card-framework">
        <mat-card-header>
          <div mat-card-avatar class="company-tile"></div>
          <mat-card-title>
            Company
          </mat-card-title>
          <mat-card-subtitle>Manage CIP Companies</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <mat-divider></mat-divider>
          <div class="text-margin">This is where you can manage companies</div>
        </mat-card-content>
        <mat-card-footer>
          <mat-card-actions align="end">
            <button mat-icon-button matTooltip="View Companies" (click)="goToCompanies()">
              <mat-icon>visibility</mat-icon>
            </button>

            <button mat-icon-button matTooltip="Create Company" (click)="OpenCreateCompanyDialog()">
              <mat-icon>add_circle</mat-icon>
            </button>
          </mat-card-actions>
        </mat-card-footer>
      </mat-card>
    </mat-grid-tile>

    <mat-grid-tile class="grid-style">
      <mat-card class="card-framework">
        <mat-card-header>
          <div mat-card-avatar class="customer-tile"></div>
          <mat-card-title class="">
            Customer
          </mat-card-title>
          <mat-card-subtitle>Manage CIP Customers</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <mat-divider></mat-divider>
          <div class="text-margin">This is where you can manage customers</div>
        </mat-card-content>
        <mat-card-footer>
          <mat-card-actions align="end">
            <button mat-icon-button matTooltip="View Customers" (click)="goToCustomers()">
              <mat-icon>visibility</mat-icon>
            </button>

            <button mat-icon-button matTooltip="Create Customers" (click)="OpenCreateCustomerDialog()">
              <mat-icon>add_circle</mat-icon>
            </button>
          </mat-card-actions>
        </mat-card-footer>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>
</div> -->
