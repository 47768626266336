import { Injectable, Injector, inject } from "@angular/core";
import { BehaviorSubject, Observable, map } from "rxjs";
import { cipInjectionTokens } from "src/environments/InjectionTokens/cipInjectionTokens";
import { CIPProcessState, CIPItemState } from "../../interfaces/CIP/cip-states.interface";
import { CIPIncidentsService } from "../../services/http-requests/azure-functions/cip/cip-incidents.service";
import { CIPIncident, CIPIncidentRecord } from '../../interfaces/CIP/cip-incidents.interface';
import { CIPHeader } from "../../interfaces/CIP/cip-page-layout.interface";
import { Customer } from "src/app/pages/manage-azure-app-reg/datastore/customer.interface";
import { DateFilterRange } from "src/app/shared/functions/helperfunctions";

@Injectable({
    providedIn: 'root'
})
export class CipIncidentStore {
  // Inject the service the store is linked too
  private incidentService = inject(CIPIncidentsService)

  // Decalare the Behaviour Subjects to Store the Data we need
  private readonly _allIncidents$ : BehaviorSubject<CIPIncident[]>      = new BehaviorSubject<CIPIncident[]>([]);

  private readonly _emptyCIPRecord : CIPIncidentRecord= {
      incidentInfo: null,
      workLogs: [],
      supportGroup: []
  }
  private readonly _incidentInfo$    : BehaviorSubject<CIPIncidentRecord>   = new BehaviorSubject<CIPIncidentRecord>(this._emptyCIPRecord);
  // Methods to populate the Stores

  fetchAllIncidents(search? : string){
    this.incidentService.getIncidents(search).subscribe(response =>
      {
        console.log("RESPONSE LOGGED:", response)
        this._allIncidents$.next(response)
      }
    )
  }

  fetchCustomerIncidents(customer: Customer, dateObject?: any){
    // Initialize customer search string
    const customerSearchString = `customerId eq '${customer.customerId}'`

    // Construct final search string depending on inputs
    const searchString = dateObject ?
    customerSearchString + `and CreatedAt ge ${dateObject.startDate} and CreatedAt le ${dateObject.endDate}`:
    customerSearchString

    // Fetch incidents based off search string
    this.incidentService.getIncidents(searchString).subscribe(response =>
      {
        console.log(response)
        this._allIncidents$.next(response)
      }
    )
  }

  fetchIncidentInfo(cipId : string){
    this.incidentService.getIncidentDetails(cipId).subscribe(response => {
      this._incidentInfo$.next(response)
    })
  }

  validateIncidents(search? : string){
    return this.incidentService.getIncidents(search)
  }

  validateIncidentInfo(cipId : string):Observable<CIPIncidentRecord>{
    return this.incidentService.getIncidentDetails(cipId)
  }

  resetIncidentInfo(){
    this._incidentInfo$.next(this._emptyCIPRecord)
  }

  // INCIDENTS
  get incidents$() : Observable<CIPIncident[]> {
      return this._allIncidents$.asObservable();
  }

  get incidentInfo$() : Observable<CIPIncidentRecord> {
    console.log("incident info from store:", this._incidentInfo$.subscribe(test => console.log(test)))
    return this._incidentInfo$.asObservable();
  }

  get incidentHeaderInjector() : Injector {
    const incHeader : Observable<CIPHeader | null> = this._incidentInfo$.pipe(
      map((incident : CIPIncidentRecord)=> {
        if (incident.incidentInfo){
          const  header : CIPHeader = {
            CIPType         : "Incident",
            CIPInternalId   : incident.incidentInfo.internalIncidentId,
            Customer        : incident.incidentInfo.msgHeaderCompany,
            CreatedOn       : incident.incidentInfo.createdAt,
            ProcessState    : (incident.incidentInfo.processStateName)?.toLowerCase() as CIPProcessState,
            ItemState       : (incident.incidentInfo.incidentStateName)?.toLowerCase() as CIPItemState
          }
          return header
        }
        else{
          return null
        }
      })
    )
    return Injector.create({providers: [{ provide: cipInjectionTokens.cipInformationToken, useValue: incHeader}]})
  }

  get incidentInjector() : Injector {
      const incidentInfo = this._incidentInfo$.pipe(
        map((result : CIPIncidentRecord)=>{
            console.log("Incident Injector data", result)
            return result.incidentInfo as CIPIncident
        })
      )
      return Injector.create({providers: [{ provide: cipInjectionTokens.cipInformationToken, useValue: incidentInfo}]})
  }

  get incidentSupportGroupInjector() : Injector {
      const supportGroups = this._incidentInfo$.pipe(map((result)=>( result.supportGroup ?? [])) )
      return Injector.create({providers: [{ provide: cipInjectionTokens.supportGroupsToken, useValue: supportGroups}]})
  }

  get incidentWorkLogsInjector() : Injector {
      const worklogs = this._incidentInfo$.pipe(map((result)=>( result.workLogs ?? [])) )
      return Injector.create({providers: [{ provide: cipInjectionTokens.workLogsToken, useValue: worklogs}]})
  }

}

