import { ComponentPortal } from "@angular/cdk/portal";
import { Component, inject } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CipIncidentStore } from "src/app/shared/datasource/stores/cip/cip-incident-store.service";
import { CipInformationComponent } from "src/app/shared/ui/templates/cip/cip-information/cip-information.component";
import { CipSpvHeaderComponent } from "src/app/shared/ui/templates/cip/cip-spv-header/cip-spv-header.component";
import { SupportGroupsComponent } from "src/app/shared/ui/templates/cip/support-groups/support-groups.component";
import { WorkLogsComponent } from "src/app/shared/ui/templates/cip/work-logs/work-logs.component";

@Component({
  selector: 'jafar-incident-spv',
  templateUrl: './incident-spv.component.html',
  styleUrls: ['./incident-spv.component.scss']
})
export class IncidentSpvComponent {
  HeaderComponent!     : ComponentPortal<CipSpvHeaderComponent>;
  MainGridComponent!   : ComponentPortal<CipInformationComponent>;
  TopGridComponent!    : ComponentPortal<WorkLogsComponent>;
  BottomGridComponent! : ComponentPortal<SupportGroupsComponent>;

  incidentStore = inject(CipIncidentStore)
  route = inject(ActivatedRoute)

  ngOnInit() {
    this.route.params.subscribe(
      (result : any) => {
          console.log("route result", result)
         this.incidentStore.fetchIncidentInfo(result.id)
      }
    )
    
    const incHeaderInjector     = this.incidentStore.incidentHeaderInjector
    const incidentInjector      = this.incidentStore.incidentInjector
    const supportGroupsInjector = this.incidentStore.incidentSupportGroupInjector
    const workLogsInjector      = this.incidentStore.incidentWorkLogsInjector

    this.HeaderComponent       = new ComponentPortal(CipSpvHeaderComponent    , null, incHeaderInjector);
    this.MainGridComponent     = new ComponentPortal(CipInformationComponent  , null, incidentInjector);
    this.TopGridComponent      = new ComponentPortal(WorkLogsComponent        , null, workLogsInjector);
    this.BottomGridComponent   = new ComponentPortal(SupportGroupsComponent   , null, supportGroupsInjector);

  }
}
