import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { WikiSubpage, WikiPage, WikiResponse, WikiResponseValue } from 'src/app/shared/datasource/interfaces/wiki.interface';

@Injectable({
  providedIn: 'root'
})
export class WikiService {
  wikiObservable$ = new BehaviorSubject<WikiSubpage[]>([])
  wikiPages!: WikiPage
  subPages!: WikiSubpage[]
  constructor(
    private http: HttpClient,
  ){ }

  getRootWiki(): Observable<WikiResponse>{
    const headers = new HttpHeaders()
      .set('Authorization', 'Basic dXNlcjp0Z3k3YzNkemxwNmttam56NHViZ2p6YmQ3Yng1enBwc2UzeG94dnpvM3RyNWdwYWJwMnFh')
      .set('Content-Type', 'application/json')
      .set('Accept', '*/*');
    const urlQuery = 'https://dev.azure.com/CCGDCore/Portal/_apis/wiki/wikis?api-version=7.0'

    return this.http.get<WikiResponse>(urlQuery, {headers})
  }

  getWikiSubPages(wikiBaseUrl: string): Observable<WikiResponseValue>{
    const headers = new HttpHeaders()
      .set('Authorization', 'Basic dXNlcjp0Z3k3YzNkemxwNmttam56NHViZ2p6YmQ3Yng1enBwc2UzeG94dnpvM3RyNWdwYWJwMnFh')
      .set('Content-Type', 'application/json')
      .set('Accept', '*/*');
    const urlQuery = `${wikiBaseUrl}/pages?recursionLevel=oneLevel&includeContent=true&api-version=7.0`

    return this.http.get<WikiResponseValue>(urlQuery, {headers})
  }

  async getWikiSubPagesDetail(pageUrl: string){
    const headers = new HttpHeaders()
      .set('Authorization', 'Basic dXNlcjp0Z3k3YzNkemxwNmttam56NHViZ2p6YmQ3Yng1enBwc2UzeG94dnpvM3RyNWdwYWJwMnFh')
      .set('Content-Type', 'application/json')
      .set('Accept', '*/*');
    const urlQuery = `${pageUrl}?includeContent=true&api-version=7.0`
    return this.http.get<WikiSubpage>(urlQuery, {headers})
  }

  getWikiList(){
    let resultSubPages: WikiSubpage[] = []
    this.getRootWiki()
    .subscribe((data: WikiResponse) => {
      console.log(data.value)
      data.value.forEach((page: WikiResponseValue) => {
        let wikiResult : WikiPage = {
          apiUrl    : page.url,
          href      : page.remoteUrl,
          name      : page.name,
          projectId : page.projectId,
          type      : page.type,
          wikiId    : page.id
        }
        this.getWikiSubPages(wikiResult.apiUrl)
        .subscribe((data: WikiResponseValue) => {
          data.subPages.forEach((subPage: WikiSubpage) => {
            let subPageDetails = this.getWikiSubPagesDetail(subPage.url)
            subPageDetails.then((data) => {
              data.subscribe((fullPage: WikiSubpage) => {
                let pageName = (fullPage.path).replace('/', '')
                let subPageResult: WikiSubpage = {
                content     : fullPage.content,
                gitItemPath : fullPage.gitItemPath,
                id          : fullPage.id,
                name        : pageName,
                remoteUrl   : fullPage.remoteUrl,
                path        : fullPage.path,
                url         : fullPage.url,
              }
              // console.log(subPageResult)
              resultSubPages.push(subPageResult)
            })})
          })
        })
      })
    })
    this.wikiObservable$.next(resultSubPages)
  }
}
