import { MatSnackBarConfig } from "@angular/material/snack-bar"

export interface SnackBarConfig{
    buttonName : string
    message    : string
    style      : SnackBarStyle
    settings   : MatSnackBarConfig
    tempalte?  : SnackBarTemplate
}

export enum SnackBarStyle {
    error       = "snack-notification-error",
    warning     = "snack-notification-warning",
    success     = "snack-notification-success",
    information = "snack-notification-information",
    default     = "snack-notification-default"
}

// This is not currently implemented and just an idea to improve the snackbar customisation
export enum SnackBarTemplate {
    Token          = "TokenTemplate",
    CustomLayout1  = "SnackbarComponent",
}