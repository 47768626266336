<div class="dialog-framework">
  <title mat-dialog-title class="center-title">
      Delete Application
  </title>

  <mat-dialog-content #delete>
    <mat-divider></mat-divider>
    <br>
    <div class="confirmation-group">
      <span class="center-text">You are about to DELETE the following Application:</span>
      <br>
      <span class="center-text"><b>{{data.displayName}}</b></span>
    </div>
    <br>
    <div class="validation-group">
      <span> To Confirm Deletion, please enter the name of the application:</span>
      <br>
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Application Name</mat-label>
        <input matInput type="text"
        [(ngModel)]="typedAppName"
        (keyup)="validateName($event)"
         onpaste="return false;"
         ondrop="return false;"
         autocomplete="off"
        >
      </mat-form-field>
    </div>


    <span class="button-group">
      <jafar-action-button [disabled]="submitButtonDisabled" (btnClick)="onSubmit()">Confirm</jafar-action-button>
      <jafar-dismiss-button (btnClick)="onCancel()">Cancel</jafar-dismiss-button>
    </span>
  </mat-dialog-content>
</div>
