import { Component, EventEmitter, Input, OnChanges, Output, TemplateRef, inject } from '@angular/core';
import { TableColumnHeader } from 'src/app/shared/datasource/interfaces/expandable-table-interface';
import { SpinnerService } from '../../../../datasource/services/spinner/spinner.service';
import { ReusableTable } from '../../../reusable-table/interface/reusable-table.interface';

@Component({
  selector: 'jafar-table-page-layout',
  templateUrl: './table-page-layout.component.html',
  styleUrls: ['./table-page-layout.component.scss']
})
export class TablePageLayoutComponent implements OnChanges{
  @Input() table!: ReusableTable
  @Input("toolbar") toolbarTemplateRef!: TemplateRef<any>;
  @Output() public selectedRecord = new EventEmitter<Event>();

  spinnerService = inject(SpinnerService)

  ngOnChanges(){
    console.log(this.table)
  }

  onSelect(event: Event){
    this.selectedRecord.emit(event)
  }
}
