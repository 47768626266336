import { Injectable }        from "@angular/core";
import { StateStoreService } from "../../../core/datasource/services/state-store.service";
import { PaginatedTable, TableColumnHeader } from "../interfaces/paginated-table.interface";

@Injectable({
    providedIn: 'root'
})
export class TableStoreService { //extends StateStoreService<PaginatedTable>{

    constructor () {
        // super(new PaginatedTable());
    }

    // setTableData(data:any[]){
    //     this.setState ({
    //         ...this.state,
    //         allRecords : data
    //     })
    // }
    
    // setTableDisplayedData(displayedData:any[]){
    //     console.log(displayedData)
    //     this.setState ({
    //         ...this.state,
    //         displayedRecords : displayedData
    //     })
    // }

    // updateDisplayedData(){
    //     const PageSize        = this.state.paginatorOptions.pageSize
    //     const PageIndex       = this.state.paginatorOptions.pageIndex // Page Index starts at 0
    //     const dataInputLength = this.state.paginatorOptions.totalRecords
    //     let startIndex = PageIndex * PageSize;
    //     let endIndex   = startIndex + PageSize;
    //     let slicedData : any ;
    //     // Make sure end index is NOT bigger than the amount of records in List
    //     if (endIndex > dataInputLength){
    //       endIndex = dataInputLength;
    //     }
    //     slicedData = this.state.allRecords.slice(startIndex, endIndex)
    //     this.setTableDisplayedData(slicedData)
    // }
    
    // setSelectedRow(selectedRow:any[]){
    //     this.setState ({
    //         ...this.state,
    //         selectedRecord : selectedRow
    //     })
    // }

    // setDefaultPaginatorOptions(updatedPaginatorOptions: PaginatorOptions): void{
    //     this.setState ({
    //         ...this.state,
    //         paginatorOptions : updatedPaginatorOptions
    //     })    
    // }

    // setPageIndex(updatedPageIndex:number){
    //     this.setState ({
    //         ...this.state,
    //         paginatorOptions : {
    //             ...this.state.paginatorOptions,
    //             pageIndex : updatedPageIndex
    //         }
    //     })
    // }

    // setPageSize(updatedPageSize:number){
    //     this.setState ({
    //         ...this.state,
    //         paginatorOptions : {
    //             ...this.state.paginatorOptions,
    //             pageSize : updatedPageSize
    //         }
    //     })
    // }
    // setColumnHeaders(columnHeaders : TableColumnHeader[]){
    //     let disCol : string[] = []
    //     columnHeaders.forEach( head => {
    //         disCol.push(head.columnHeaderIndex)
    //     })
    //     this.setState ({
    //         ...this.state,
    //         columnHeaders : columnHeaders,
    //         displayedColumns: disCol

    //     })
    // }


}