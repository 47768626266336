import { DynamicFormSchema } from 'src/app/shared/ui/dynamic-form/dynamic-form.interface';
import { Component, EventEmitter, Output, ViewChild, inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ChipAutoComplete } from 'src/app/shared/datasource/interfaces/chip-autocomplete.interface';
import { AADMember, AADMemberType, CreateAadGroupPayload } from 'src/app/shared/datasource/interfaces/aad-group.interface';
import { ChipAutocompleteComponent } from 'src/app/shared/ui/chip-autocomplete/chip-autocomplete.component';
import { SnackBarStyle } from 'src/app/shared/ui/snackbar/snackbar.interface';
import createGroupJson from 'src/assets/config/dynamic-forms/group-form.json';
import { DynamicFormComponent } from 'src/app/shared/ui/dynamic-form/dynamic-form.component';
import { UserService } from 'src/app/core/datasource/services/user/user.service';
import { AadAppregService } from 'src/app/shared/datasource/services/http-requests/azure-functions/application-registrations/aad-appreg.service';
import { AzureApp } from 'src/app/shared/datasource/interfaces/azure-app.interface';
import { AadGroupsService } from 'src/app/shared/datasource/services/http-requests/azure-functions/groups/aad-groups.service';
import { DialogOutput } from 'src/app/shared/datasource/interfaces/dialog.interface';

@Component({
  selector: 'jafar-create-azure-group-popup',
  templateUrl: './create-azure-group-popup.component.html',
  styleUrls: ['./create-azure-group-popup.component.scss']
})
export class CreateAzureGroupPopupComponent {
  @ViewChild("createGroupForm") createGroupForm! : DynamicFormComponent
  @ViewChild("usersDropdown" )  usersDropdown!   : ChipAutocompleteComponent;
  @ViewChild("appsDropdown")    appsDropdown!    : ChipAutocompleteComponent;
  @ViewChild("ownersDropdown")  ownersDropdown!  : ChipAutocompleteComponent;

  //import services
  userProfile = inject(UserService)

  createGroupData : DynamicFormSchema[] = createGroupJson as any //DynamicFormSchema[]
  activeState: boolean = false
  hasMembersButton: boolean = false
  hasAppsButton:boolean = false

  groupNameStepLabel!: string | null
  AppRoleList! : string[]

  // Dropdown List Data set
  allUsers: AADMember[] = []

  // allAppRegistrations: AppRegistration[] = []
  allApplications: AzureApp[] = []
  applicationList: AzureApp[] = []

  selectedUsers: AADMember[] = []
  // selectedAppRegistrations: AppRegistration[] = []
  selectedApplications: AzureApp[] = []
  selectedOwners: AADMember[] = []

  userChipConfig: ChipAutoComplete = {
    listLabel      : 'List of Users',
    displayName    : 'displayName',
    listPlaceHolder: 'Add User...'
  };

  appChipConfig: ChipAutoComplete = {
    listLabel      : 'List of AppRegistrations',
    displayName    : 'displayName',
    listPlaceHolder: 'Add App Registration... '
  }

  ownerChipConfig: ChipAutoComplete = {
    listLabel      : 'List of Owners',
    displayName    : 'displayName',
    listPlaceHolder: 'Add Owner...'
  };

  constructor(
    private azureFunction: AadGroupsService,
    private azureApplications: AadAppregService,
    public dialogRef: MatDialogRef<CreateAzureGroupPopupComponent>,
  ){}


  ngOnInit(): void {
    // this.createGroupForm.dynamicForm.touched
    this.getCIPUsers()
    this.getAzureApplications()
  }

  // Method to get a list of all the CIP users
  getCIPUsers(){
    this.azureFunction.getGroupByGroupName('Role_CIP_User').subscribe(
    (response: any) => {
      console.log(response)
      response.map(
        (group: any) => {
          group.members.map(
            (member: AADMember) =>{
              var updatedMember: AADMember = {
                id         : member.id,
                displayName: member.displayName,
                type       : AADMemberType.user
              }
              this.allUsers.push(updatedMember)
            }
          )
        })
    })
  }

  // Method to get list of all the App Registrations user has access too
  getAzureApplications(){
    this.azureApplications.getApplicationList(this.userProfile.activeUser.id).subscribe(
      apps => {
        console.log(apps)
        this.allApplications = this.createCopyOfArray(apps);
        this.applicationList = this.createCopyOfArray(apps);
      }
    )
  }

  // Method to add the logged in user to the owners list
  getOwner(){
    const currentUser = {
      id: this.userProfile.activeUser.id,
      displayName: this.userProfile.activeUser.displayName,
      type: AADMemberType.user
    }
    const ownerList = JSON.stringify(this.selectedOwners)
    if (ownerList.includes(currentUser.id)){
      console.log("User Already in Owner List , No need to add user as owner")
    }
    else{
      this.selectedOwners.push(currentUser)
    }
  }

  updateStepLabel(){
    this.groupNameStepLabel = `to ${this.createGroupForm.dynamicForm.controls['groupName'].value}`
  }

  updateSelectedApplication(newItem: AzureApp[]) {
    this.selectedApplications = newItem
    this.applicationList = this.allApplications.filter((app: AzureApp) => {
      console.log(app)
      console.log(newItem)
      return (!newItem.includes(app)) ? app : null
    })
    this.hasValidApps()
  }

  updateSelectedUsers(newItem: AADMember[]) {
    this.selectedUsers = newItem
    this.hasValidMembers()
  }

  updateSelectedOwners(newItem: AADMember[]) {
    console.log("new Owner added : ", newItem)
    this.selectedOwners = newItem
    console.log("new Owners list : ", this.selectedOwners)
  }

  hasValidMembers() {
    if (this.selectedUsers.length !== 0){
      this.hasMembersButton = true
    }
    else {
      this.hasMembersButton = false
    }
  }

  hasValidApps(){
    if (this.selectedApplications.length !== 0){
      this.hasAppsButton = true
    }
    else {
      this.hasAppsButton = false
    }
  }

  resetGroupDetails(){
    console.log(this.createGroupForm)
    this.createGroupForm.dynamicForm.reset()
    this.hasAppsButton = false
    this.hasMembersButton =false
    this.groupNameStepLabel = ""
  }

  goToNextStep(stepper : MatStepper){
    console.log("Next Button Pressed")
    stepper.selected!.completed = true;
    stepper.selected!.editable = false;
    stepper.next();
  }

  reset(stepper: MatStepper){

    switch (stepper.selectedIndex){

      case 0 : {
        console.log ("Resetting Step 1 - Group Details ")
        this.resetGroupDetails()
        break;
      }

      case 1 : {
        console.log ("Resetting Step 2 - Users & Apps ")
        this.usersDropdown.reset()
        this.selectedUsers = []
        this.hasValidMembers()
        this.resetGroupDetails()
        break;
      }
      case 2 : {
        console.log ("Resetting Step 3 - Owners ")
        this.usersDropdown.reset()
        this.ownersDropdown.reset()
        this.hasValidMembers()
        this.resetGroupDetails()
        break;
      }

      case 3 : {
        console.log ("Resetting Step 4 - Applications")
        this.appsDropdown.reset()
        this.usersDropdown.reset()
        this.ownersDropdown.reset()
        this.hasValidMembers()
        this.hasValidApps()
        this.resetGroupDetails()
        break;
      }

      case 4 : {
        console.log ("Resetting Step 5 - Confirmation, Step 6 - Completed ")
        this.appsDropdown.reset()
        this.usersDropdown.reset()
        this.ownersDropdown.reset()
        this.hasValidApps()
        this.hasValidMembers()
        this.resetGroupDetails()
        break;
      }
    }

    stepper.reset()
  }

  constructGroupPayload(){
    // Add the user logging the request to the group owners
    this.getOwner()
    let groupPayload: CreateAadGroupPayload = {
      reqFirstName     : this.userProfile.activeUser.givenName,
      reqLastName      : this.userProfile.activeUser.surname,
      reqMailAddress   : this.userProfile.activeUser.mail,
      reqUserId        : this.userProfile.activeUser.id,
      groupName        : this.createGroupForm.dynamicForm.controls['groupName'].value,
      description      : this.createGroupForm.dynamicForm.controls['description'].value,
      contactMail      : this.createGroupForm.dynamicForm.controls['contactMail'].value,
      itsmSupportGroup : this.createGroupForm.dynamicForm.controls['itsmSupportGroup'].value,
      members          : this.selectedUsers.map((user) => {return user.id}),
      appRegistrations : this.selectedApplications.map((app) => {return app.entAppId}),
      owners           : this.selectedOwners.map((owner) => {return owner.id}),
    }

    // groupPayload.owners?.push(this.profile.userProfile.id)
    return groupPayload
  }

  snackbarMessage(){
    const ReturnedData : DialogOutput = {
      data : {
        status:"Success"
      },
      snackbarConfig: {
        buttonName: "OK",
        message: `Group ${this.createGroupForm.dynamicForm.controls['groupName'].value} has been successfully Created.`,
        style : SnackBarStyle.default,
        settings: {
          horizontalPosition: 'center',
          verticalPosition:'top'
        }
      }
    }
    this.dialogRef.close(ReturnedData)
  }

  createGroup(payload: CreateAadGroupPayload){
    this.azureFunction.createGroup(payload)
  }

  onSubmit(){
    const payload = this.constructGroupPayload()
    console.log("Create Payload : ", payload)
    this.createGroup(payload)
    this.snackbarMessage()
  }

  onClose(): void {
    this.dialogRef.close(false)
  }

  // Method to stop Mutable nature of Arrays
  createCopyOfArray(arrayToCopy:any){
    const strArray = JSON.stringify(arrayToCopy)
    const jsonArray = JSON.parse(strArray)
    return jsonArray
  }
}
