import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'jafar-dismiss-button',
  templateUrl: './dismiss-button.component.html',
  styleUrls: ['./dismiss-button.component.scss']
})
export class DismissButtonComponent {
  @Input()disabled: boolean = false
  @Output() btnClick = new EventEmitter()

  onClick(){
    this.btnClick.emit()
  }
  
}
