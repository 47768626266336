import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AadCustomGroupResponse, CreateAadGroupPayload, EditAadGroupPayload, AzureCustomGroup, AADGroup } from '../../../../interfaces/aad-group.interface';
import { azureFunctions } from 'src/environments/environment';
import { GenericApiService } from '../../generic-api/generic-api.service';
import { QueryParams } from '../../../../interfaces/generic-api.interface';

@Injectable({
  providedIn: 'root'
})
export class AadGroupsService extends GenericApiService {

  constructor(private httpClient: HttpClient) {
    super(httpClient)
  }

  // CRUD METHODS FOR CUSTOM GROUPS

  // Create
  createGroup(body: CreateAadGroupPayload){
    const uri: string = `${azureFunctions.portalFunctions.baseUrl}/AADGroup`
    const defaultHttpHeaders = this.defaultHeaders()
    const query: QueryParams = {
      code    : `${azureFunctions.portalFunctions.authCode}`
    }
    const postBody = JSON.stringify(body);
    console.log(postBody);
    (this.post(uri, postBody, defaultHttpHeaders, query)).subscribe(
      (result: any) => {
        console.log(result)
      }
    )
  }

  // Read
  getCustomGroupsByUserId(id:string) : Observable<AzureCustomGroup[]> {
    const uri: string = `${azureFunctions.portalFunctions.baseUrl}/UserCustomGroups`
    const defaultHttpHeaders = this.defaultHeaders()
    const query: QueryParams = {
      code    : `${azureFunctions.portalFunctions.authCode}`,
      userId  : id
    }
    return this.get<AzureCustomGroup[]>(uri, defaultHttpHeaders, query).pipe(
      map(
        (groupPayload : AzureCustomGroup[]) => {
          groupPayload.map(
            (group) => {
              if (group.isOwner){
                group.ownerIconColour = "Healthy"
                group.ownerStatusIcon = "check_circle"
                group.ownerToolTip    = "Owner"
              }
              else {
                group.ownerIconColour = "Critical"
                group.ownerStatusIcon = "cancel"
                group.ownerToolTip    = "Not Owner"
              }
              return group
            }
          )
          return groupPayload
        }
      )
    )
  }

  // Update
  updateGroup(body: EditAadGroupPayload){
    const uri: string = `${azureFunctions.portalFunctions.baseUrl}/AADGroup`
    const defaultHttpHeaders = this.defaultHeaders()
    const query: QueryParams = {
      code    : `${azureFunctions.portalFunctions.authCode}`
    }
    const postBody = JSON.stringify(body);

    console.log(postBody);
    (this.patch(uri, postBody, defaultHttpHeaders, query)).subscribe(
      (result: any) => {
        console.log(result)
      }
    )
  }

  // Delete
  deleteGroup(groupId: string) : Observable<any> {
    const uri: string = `${azureFunctions.portalFunctions.baseUrl}/AADGroup`
    const defaultHttpHeaders = this.defaultHeaders()
    const query : QueryParams = {
      code : `${azureFunctions.portalFunctions.authCode}`,
      id   : groupId
    }

    return this.delete<any>(uri, defaultHttpHeaders, query)
  }

  // GENERAL GROUP METHODS
  // Used to get the Members Of a group ie CIPRoleUsers
  getGroupByGroupName(groupName:string) : Observable<AadCustomGroupResponse[]> {
    const uri: string = `${azureFunctions.portalFunctions.baseUrl}/AADGroup`
    const defaultHttpHeaders = this.defaultHeaders()
    const query: QueryParams = {
      groupFilter : `${groupName}`,
      code        : `${azureFunctions.portalFunctions.authCode}`
    }
    return this.get<AadCustomGroupResponse[]>(uri, defaultHttpHeaders, query)
  }

  getGroupByName(groupName:string) : Observable<AADGroup> {
    const uri: string = `${azureFunctions.portalFunctions.baseUrl}/AADGroup`
    const defaultHttpHeaders = this.defaultHeaders()
    const query: QueryParams = {
      groupFilter : `${groupName}`,
      code        : `${azureFunctions.portalFunctions.authCode}`
    }
    return this.get<AADGroup[]>(uri, defaultHttpHeaders, query).pipe(
      map ((response ) =>(response[0]))
    )
  }

  // Private Helper Methods
  private defaultHeaders(): HttpHeaders{
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json; charset=utf-8');
    return httpHeaders
  }

}
