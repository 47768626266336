<mat-card appearance="outlined" class="reusable-filter-mat-card">
  <mat-card-header>
    <span class="header-span"></span>
    <mat-icon matTooltip="Toggle Filters" class="material-symbols-outlined icon-style" (click)="toggleFilter()">tune</mat-icon>
  </mat-card-header>
  <mat-card-content *ngIf="filterVisible" class="parent-filter-style">
    <!-- Drop Down Filter -->
    <mat-divider [vertical]="true" class="filter-divider"></mat-divider>
    <ng-container *ngIf="dropDownFilter">
      <button mat-button [dropdownTriggerFor]="dropDownFilterMenu" class="button-style">
        {{selectedDropDown || dropDownFilter.config.title}}
        <mat-icon class="material-symbols-outlined icon-style">expand_more</mat-icon>
      </button>
      <jafar-custom-dropdown [dropDownType]="dropDownFilter.config.type" #dropDownFilterMenu>
        <ng-container *ngFor="let option of dropDownFilter.config.data">
          <div (click)="onSelectDropdown(option)" class="dropdown-item">
            {{option}}
          </div>
        </ng-container>
      </jafar-custom-dropdown>
    </ng-container>
    <mat-divider [vertical]="true" class="filter-divider"></mat-divider>
    <!-- Check Box Filter -->
    <ng-container *ngIf="checkBoxFilter">
      <button mat-button [dropdownTriggerFor]="checkboxFilterMenu" class="button-style">
        {{checkBoxFilter.config.title}}
        <mat-icon class="material-symbols-outlined icon-style">expand_more</mat-icon>
      </button>
      <jafar-custom-dropdown [dropDownType]="checkBoxFilter.config.type" #checkboxFilterMenu #checkBoxComponent>
        <ng-container *ngFor="let option of checkBoxFilter.config.data">
          <div class="checkbox-style">
            <mat-checkbox *ngIf="checkBoxFilter.config.displayProperty" (change)="toggleOption(option)" class="checkbox-item" [(ngModel)]="option.isSelected">
              {{option[checkBoxFilter.config.displayProperty]}}
            </mat-checkbox>
          </div>
        </ng-container>
        <button mat-button class="apply-filters" (click)="emitSelectedFilters()">
          Apply
        </button>
      </jafar-custom-dropdown>
    </ng-container>
    <mat-divider [vertical]="true" class="filter-divider"></mat-divider>
    <span class="filter-spacing"></span>
    <mat-icon matTooltip="Clear Filters" (click)="onClear()" class="clear-filter material-symbols-outlined">filter_alt_off</mat-icon>
  </mat-card-content>
</mat-card>
