import { TemplateId } from '@angular/compiler-cli/src/ngtsc/typecheck/api';
import { Component, OnInit, TemplateRef, ViewChild, inject } from '@angular/core';
import { UserStore } from 'src/app/core/datasource/services/user/user-store.service';
import { UserProfileService } from 'src/app/shared/datasource/services/http-requests/ms-graph/user/user-profile.service';
import { PageWidgetComponent } from 'src/app/shared/ui/widget/page-widget/page-widget.component';

@Component({
  selector: 'jafar-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  public  widgetList! : WidgetList[]
  foods: any[] = [
    {value: 'steak-0', viewValue: 'Steak'},
    {value: 'pizza-1', viewValue: 'Pizza'},
    {value: 'tacos-2', viewValue: 'Tacos'},
  ];
  selectedFood = "tacos-2"
  constructor(
    private profile: UserProfileService,
  ) { }

  userStore = inject(UserStore)
  ngOnInit(): void {
    this.profile.getUserProfile()

    this.widgetList = [
      {
        title         : "Company",
        subtitle      : "Manage Companies",
        description   : "This is where you can Manage Companies",
        tooltipView   : "View Companies",
        tooltipCreate : "Create a Company",
        templateDesc  : "descriptionTemplate",
        templateAction: "actionTemplate",
      },
      // {
      //   title         : "Company",
      //   subtitle      : "Manage Companies",
      //   description   : "This is where you can Manage Companies",
      //   tooltipView   : "View Companies",
      //   tooltipCreate : "Create a Company"
      // },
      // {
      //   title         : "Company",
      //   subtitle      : "Manage Companies",
      //   description   : `
      //     This is where you can Manage Companies
      //     This is where you can Manage Companies
      //     This is where you can Manage Companies
      //     This is where you can Manage Companies
      //   `,
      //   tooltipVie}w   : "View Companies",
      //   tooltipCreate : "Create a Company"
      // },
      // {
      //   title         : "Company",
      //   subtitle      : "Manage Companies",
      //   description   : `
      //   This is where you can Manage Companies
      //   This is where you can Manage Companies
      //   This is where you can Manage Companies
      //   This is where you can Manage Companies
      //   This is where you can Manage Companies
      //   This is where you can Manage Companies
      //   This is where you can Manage Companies
      //   This is where you can Manage Companies`,
      //   tooltipView   : "View Companies",
      //   tooltipCreate : "Create a Company"
      // },
      // {
      //   title         : "Company",
      //   subtitle      : "Manage Companies",
      //   description   : "This is where you can Manage Companies",
      //   tooltipView   : "View Companies",
      //   tooltipCreate : "Create a Company"
      // },
      // {
      //   title         : "Company",
      //   subtitle      : "Manage Companies",
      //   description   : "This is where you can Manage Companies",
      //   tooltipView   : "View Companies",
      //   tooltipCreate : "Create a Company"
      // },
      {
        title         : "Customer",
        subtitle      : "Manage Customers",
        description   : "This is where you can Manage Customers",
        tooltipView   : "View Customers",
        tooltipCreate : "Create a Customer",
        templateDesc  : "descriptionTemplate",
        templateAction: "actionTemplate",

      }
    ]
    console.log(this.widgetList)


  }

  onButtonClick(buttonPressed : Event){
    console.log(buttonPressed)
  }

  test(){
    console.log("Button Clicked")
  }

}


export interface WidgetList {
    title         : string
    subtitle      : string,
    description   : string,
    tooltipView   : string,
    tooltipCreate : string,
    templateDesc  : string,
    templateAction: string,
}
