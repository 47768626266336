import { Component, OnInit}   from '@angular/core';
import { LoginService }       from './datasource/services/login.service';
import { ThemeService }       from './datasource/services/theme/theme.service';
import { ConsoleLogService }  from './datasource/services/console-toggle/console-log.service';
import { UserService }        from './datasource/services/user/user.service';

@Component({
  selector: 'jafar-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss']

})
export class CoreComponent  implements OnInit{
  title = 'jafar-portal';

  constructor(
    public authService: LoginService,
    protected themeService: ThemeService,
    private consoleLog: ConsoleLogService,
    private userService : UserService
  ){}

  ngOnInit(): void {
    this.consoleLog.disableConsoleInProduction();
    localStorage.clear()  /// needs to be removed to enable local storage
    this.themeService.startupTheme()
    this.userService.getLoggedInUser()

  }

}