import { Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'tableStyling'
})
export class TableStylingPipe implements PipeTransform {

  transform(value: any, args: any): any {
    const strValue = String(value);
    return IconStyle[strValue as keyof typeof IconStyle]
  }
}

export enum IconStyle{
  true = "positive-boolean",
  false = "negative-boolean",
  unknown = "indeterminate"
}
