<mat-card class="information-card">
    <mat-card-header class="information-card-header">
        <div class="information-text">Information</div>
        <span class="information-title-space"></span>
        <mat-icon matTooltip="Edit Visible Rows"class="information-title-icons" (click)="onChooseRows()">filter_list</mat-icon>
        <mat-icon matTooltip="View Json Payload" class="information-title-icons" (click)="onViewJson()">feed</mat-icon>
    </mat-card-header>

    <mat-card-content class="information-content">
        <jafar-dictionary-table [dataSource]="cipTypePropertyList" [showRows]="visibleCipTypeProperties"></jafar-dictionary-table>
    </mat-card-content>

    
</mat-card>
