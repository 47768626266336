import { Component, EventEmitter, Inject, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AzureCustomGroup } from 'src/app/shared/datasource/interfaces/aad-group.interface';
import { AadGroupsService } from 'src/app/shared/datasource/services/http-requests/azure-functions/groups/aad-groups.service';
import { SnackBarStyle } from 'src/app/shared/ui/snackbar/snackbar.interface';
import { DialogOutput } from 'src/app/shared/datasource/interfaces/dialog.interface';

@Component({
  selector: 'jafar-delete-azure-group-popup',
  templateUrl: './delete-azure-group-popup.component.html',
  styleUrls: ['./delete-azure-group-popup.component.scss']
})
export class DeleteAzureGroupPopupComponent {
  typedGroupName : string = ""
  submitButtonDisabled : boolean = true

  constructor(
    private azureFunction: AadGroupsService,
    public dialogRef: MatDialogRef<DeleteAzureGroupPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AzureCustomGroup
  ) {}

  validateName(event: Event){
    console.log(event)
    const userInput = (event.target as HTMLInputElement).value;
    console.log(userInput)
    console.log(this.data)
    if( userInput === this.data.groupName){
      this.submitButtonDisabled = false
    }
    // need to include the else otherwise nothing sets the button back to disabled if it no longer matches
    else {
      this.submitButtonDisabled = true
    }
  }

  deleteGroup(){
    const groupId = this.data.id
    this.azureFunction.deleteGroup(groupId).subscribe(
      test => console.log(test)
    )
  }

  onCancel(): void {
    this.dialogRef.close(false)
  }

  onSubmit(){
    const ReturnedData : DialogOutput = {
      data : {
        status:"Success"
      },
      snackbarConfig: {
        buttonName: "OK",
        message: "Group Has successfully been deleted",
        style : SnackBarStyle.default,
        settings: {
          horizontalPosition: 'center',
          verticalPosition:'top'
        }
      }
    }
    this.dialogRef.close(ReturnedData)
    this.deleteGroup()
  }
}
