<div class="widget-framework">
    <jafar-page-widget class="widget"
        [contents]="contentTemplate"
        [actions]="actionTemplate"
    >
        <div title>App Registrations</div>
        <div subtitle> Manage Azure Application Registrations </div>
    </jafar-page-widget>
</div>

<ng-template #contentTemplate >
    This is where you can Manage your Azure Application Registrations<br>
</ng-template>

<ng-template #actionTemplate>
    <button mat-icon-button matTooltip="View Applications" (click)="viewClicked()">
        <mat-icon>visibility</mat-icon>
    </button>
    <button mat-icon-button matTooltip="Create Application" (click)="createClicked()" disabled="true">
        <mat-icon>add_circle</mat-icon>
    </button>
</ng-template>
