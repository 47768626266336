import { Injectable, inject }        from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { WorkitemService } from '../services/http-requests/azure-devops/workitem/workitem.service';
import { WorkItem, WorkItemDialogOutput } from "../services/http-requests/azure-devops/workitem/workitem.interface";

@Injectable({
    providedIn: 'root'
})
export class WorkItemsStore {
    // Inject the service the store is linked too
    workItemService = inject(WorkitemService)

    // Decalare the Behaviour Subjects to Store the Data we need
    private readonly _bugRecords$:  BehaviorSubject<WorkItem[]> = new BehaviorSubject<WorkItem[]>([]);
    private readonly _taskRecords$: BehaviorSubject<WorkItem[]> = new BehaviorSubject<WorkItem[]>([]);

    // Methods to populate the Stores
    fetchBugRecords(){
        console.log("fetching Bugs")
        this.workItemService.getWorkItemFromType("portalBug")
            .subscribe(bugItems => {
                console.log(bugItems)
                this._bugRecords$.next(bugItems)
            } )
    }

    fetchTaskRecords(){
        console.log("fetching Tasks")

        this.workItemService.getWorkItemFromType("portalFeeback")
            .subscribe(taskItems => {
                console.log(taskItems)
                this._taskRecords$.next(taskItems) } )
    }

    // Methods to update the store with New Contents
    addBugRecord(newWorkItem : WorkItemDialogOutput) {
        this.workItemService.newWorkItem("portalBug", newWorkItem).subscribe(
            (result) =>  {
                console.log(result)
                this.fetchBugRecords()
            }
        )
    }

    addTaskRecord(userFeedback : WorkItemDialogOutput) {
        this.workItemService.newWorkItem("portalFeeback" ,userFeedback).subscribe(
            (result) =>  {
                console.log(result)
                this.fetchTaskRecords()
            }
        )
    }


      // Observable Streams of the contents of the stroe
    get bugRecords$() : Observable<WorkItem[]> {
        return this._bugRecords$.asObservable();
    }

    get taskRecords$(): Observable<WorkItem[]> {
        return this._taskRecords$.asObservable();
    }

    // Current Contents (Point in time)
    get bugRecords() : WorkItem[] {
        return this._bugRecords$.getValue();
    }

    get taskRecords(): WorkItem[] {
        return this._taskRecords$.getValue();
    }




}
