import { Component, Input, ChangeDetectionStrategy, AfterViewInit, ViewEncapsulation, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALUE_ACCESSOR, ValidatorFn, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { BehaviorSubject } from 'rxjs';
import { DropdownControlOptions } from '../../dynamic-form.interface';
import { ControlValueAccessorDirective } from '../../form-cva/control-value-accessor.directive';

@Component({
  selector: 'jafar-dropdown',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  encapsulation : ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DropdownComponent,
      multi : true
    }
  ]
})
export class DropdownComponent<T> extends ControlValueAccessorDirective<T> implements  AfterViewInit, OnChanges {

  @Input() dropdownOptions!: DropdownControlOptions | undefined
  @Input() selectedOption! : any
  @Output() optionSelected = new  EventEmitter<any>()

  label           : string = ""
  placeholder     : string = ""
  displayProperty : string = ""
  textBoxValue    : string = ""
  dropDownList    : BehaviorSubject<any[]> = new BehaviorSubject<any[]>([])

  ngAfterViewInit(): void {
    this.getControlOptions()
    this.textBoxValue = this.selectedOption
    console.log(this.control)
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes)
  }

  getControlOptions(){
    this.label            = this.dropdownOptions?.label ?? ""
    this.placeholder      = this.dropdownOptions?.placeholder ?? ""
    this.displayProperty  = this.dropdownOptions?.displayProperty ?? ""
  }

  getDisplayProperty(DropdownListData : any) : string{
    return  DropdownListData ? DropdownListData[this.displayProperty] : '';
  }

  onSelection(event : MatAutocompleteSelectedEvent){
    const enteredText   = event.option.value;
    this.selectedOption = enteredText
    this.textBoxValue   = enteredText[this.displayProperty]
    this.optionSelected.emit(event)
  }

  onKeyPress(event : Event){
    // this.control.errors?.['Incorrect'] == true ? null : this.control.setErrors({'Incorrect': true});
    const enteredText = (event.target as HTMLInputElement).value;
    const filteredData = enteredText ? this._filter(enteredText) : this.dropdownOptions?.dropdownData.slice()
    this.dropDownList.next(
      this.sortData(filteredData)
    )
  }

  onFocus(){
    this.control.addValidators(this.inputValidator(this.dropdownOptions?.dropdownData))
    this.dropDownList.next(
      this.sortData(this.dropdownOptions?.dropdownData)
    )
  }

  private _filter(value: string) {
    const filterValue = value.toLowerCase();
    return this.dropdownOptions?.dropdownData.filter(
      (data: any) => {
        if(data[this.displayProperty].toLowerCase().includes(filterValue)){
          return data
        }
      }
    )
  }

  inputValidator(itemList: any[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const index = itemList.findIndex(Item => {
        return new RegExp("^" + Item[this.displayProperty] + "$").test(control.value[this.displayProperty]);
      });
      const result = index < 0 ? { inputValidator: { value: control.value } } : null;
      return result
    };
  }

  sortData<T extends Object[]>(data: T, sortProperty? : keyof T) : T{
    const property = sortProperty ?? this.displayProperty
    const sortedData = data.sort(
      (a:any,b:any) => {
        const valueA = a[property]

        switch (typeof valueA){
          case "number" : {
            const valueB = b[property] as number
            return valueA - valueB
          }

          case "string" : {
            const valueB = b[property] as string
            return  valueA.localeCompare(valueB)
          }

          default :
            return 0
        }

      }
    )
    return sortedData
  }

}
