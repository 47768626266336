// Angular Core
import { NgModule, SecurityContext }                       from '@angular/core';
import { CommonModule, DatePipe }                                    from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {PortalModule}                                       from '@angular/cdk/portal';
import { ClipboardModule }                                 from '@angular/cdk/clipboard';
import { FormsModule, ReactiveFormsModule }                from '@angular/forms';

// Angular Browser
import { BrowserModule }           from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// MSAL Login
import { MsalBroadcastService, MsalInterceptor, MsalInterceptorConfiguration, MsalService, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalRedirectComponent } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, PublicClientApplication }                                                       from '@azure/msal-browser';

// Angular Material Components
import { MatSidenavModule, }        from '@angular/material/sidenav';
import { MatListModule }            from '@angular/material/list';
import { MatIconModule }            from '@angular/material/icon';
import { MatDividerModule }         from '@angular/material/divider';
import { MatToolbarModule }         from '@angular/material/toolbar';
import { MatSlideToggleModule }     from '@angular/material/slide-toggle';
import { MatButtonModule }          from '@angular/material/button';
import { MatStepperIntl, MatStepperModule }         from '@angular/material/stepper';
import { MatTableModule }           from '@angular/material/table';
import { MatGridListModule }        from '@angular/material/grid-list';
import { MatCardModule }            from '@angular/material/card';
import { MatChipsModule }           from '@angular/material/chips';
import { MatAutocompleteModule }    from '@angular/material/autocomplete';
import { MatExpansionModule }       from '@angular/material/expansion';
import { MatDatepickerModule }      from '@angular/material/datepicker';
import { MatPaginatorModule }       from '@angular/material/paginator';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule }          from '@angular/material/dialog';
import { MatFormFieldModule }       from '@angular/material/form-field';
import { MatSelectModule }          from '@angular/material/select';
import { MatInputModule }           from '@angular/material/input';
import { MatTooltipModule }         from '@angular/material/tooltip';
import { MatTabsModule }            from '@angular/material/tabs'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule }            from '@angular/material/menu';
import { MatRadioModule }           from '@angular/material/radio';
import { MatSnackBarModule}         from '@angular/material/snack-bar';
import { MatCheckboxModule }        from '@angular/material/checkbox'
import {MatProgressBarModule}       from '@angular/material/progress-bar';
// 3rd Party Modules
import { MarkdownModule } from 'ngx-markdown';
import { RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';


// App Pages - Core
import { environment }              from 'src/environments/environment';

import { CoreComponent }            from './core.component';
import { CoreRoutingModule }        from './core-routing.module';  // Core Routing Module

import { PortalFrameworkComponent } from './feature/portal-framework/portal-framework.component';           // Core Component
import { LoginPageComponent }       from './feature/login-page/login-page.component';
import { AccessRequestComponent }   from './feature/access-request/access-request.component';

import { SideMenuComponent }        from './ui/side-menu/side-menu.component';
import { ToolbarComponent }         from './ui/toolbar/toolbar.component';
import { HelpIconComponent }        from './ui/help-icon/help-icon.component';

import { CustomHttpInterceptor }    from './datasource/interceptors/http-interceptor';


// App Pages - Shared
import { ToggleIconComponent }            from '../shared/ui/toggle-icon/toggle-icon.component';
import { PaginatedTableComponent }        from '../shared/ui/paginated-table/paginated-table.component';
import { TableStoreService }              from '../shared/datasource/stores/table-store.service';
import { AzureAppStoreService }           from '../shared/datasource/stores/azure-app-store.service';
import { AccordionComponent }             from '../shared/ui/accordion/accordion.component';
import { WikiService }                    from '../shared/datasource/services/http-requests/azure-devops/wiki/wiki.service';
import { MarkdownDialogComponent }        from '../shared/ui/markdown-dialog/markdown-dialog.component';
import { RaiseWorkitemDialogComponent }   from '../shared/ui/raise-workitem-dialog/raise-workitem-dialog.component';
import { ChipAutocompleteComponent }      from '../shared/ui/chip-autocomplete/chip-autocomplete.component';
import { AadGroupsService }               from '../shared/datasource/services/http-requests/azure-functions/groups/aad-groups.service';
import { AutocompleteDropdownComponent }  from '../shared/ui/autocomplete-dropdown/autocomplete-dropdown.component';
import { SnackbarComponent }              from '../shared/ui/snackbar/snackbar.component'
import { SnackbarService }                from '../shared/datasource/services/snackbar/snackbar.service'
import { DropdownComponent }              from '../shared/ui/dynamic-form/form-components/dropdown/dropdown.component';
import { TextboxComponent }               from '../shared/ui/dynamic-form/form-components/textbox/textbox.component';
import { ControlValueAccessorDirective }  from '../shared/ui/dynamic-form/form-cva/control-value-accessor.directive';
import { FormErrorsComponent }            from '../shared/ui/dynamic-form/form-errors/form-errors.component';
import { DynamicFormComponent }           from '../shared/ui/dynamic-form/dynamic-form.component';
import { ChiplistComponent }              from '../shared/ui/dynamic-form/form-components/dropdown/chiplist/chiplist.component';
import { PageWidgetComponent }            from '../shared/ui/widget/page-widget/page-widget.component';
import { CompanyWidgetComponent }         from '../shared/ui/widget/page-widget/company-widget/company-widget.component';
import { CustomerWidgetComponent }        from '../shared/ui/widget/page-widget/customer-widget/customer-widget.component';
import { ManageAzureWidgetComponent }     from '../shared/ui/widget/page-widget/manage-azure-widget/manage-azure-widget.component';
import { ManageCipWidgetComponent }       from '../shared/ui/widget/page-widget/manage-cip-widget/manage-cip-widget.component';
import { AzureApplicationWidgetComponent }  from '../shared/ui/widget/page-widget/azure-application-widget/azure-application-widget.component';
import { AzureCustomGroupWidgetComponent }  from '../shared/ui/widget/page-widget/azure-custom-group-widget/azure-custom-group-widget.component';
import { CheckboxComponent }           from '../shared/ui/dynamic-form/form-components/checkbox/checkbox.component';


// App Pages - Features
import { WikiPageComponent }                  from '../pages/wikipage/main/wikipage.component';
import { RaiseIssueComponent }                from '../pages/raise-issue/main/raise-issue.component';
import { GiveFeedbackComponent }              from '../pages/give-feedback/main/give-feedback.component';
import { ManageAzureGroupsComponent }         from '../pages/manage-azure-groups/main/manage-azure-groups.component';
import { CreateAzureSecretPopup }             from '../pages/manage-azure-app-reg/ui/manage-secrets/create-azure-secret-popup/create-azure-secret-popup.component';
import { DeleteAzureSecretPopup }             from '../pages/manage-azure-app-reg/ui/manage-secrets/delete-azure-secret-popup/delete-azure-secret-popup.component';
import { RefreshAzureSecretPopup }            from '../pages/manage-azure-app-reg/ui/manage-secrets/refresh-azure-secret-popup/refresh-azure-secret-popup.component';
import { ManageAzureAppRegComponent }         from '../pages/manage-azure-app-reg/main/manage-azure-app-reg.component';
import { CreateAzureAppPopupWithRoles }       from '../pages/manage-azure-app-reg/ui/create-application/create-azure-app-popup-with-roles/create-azure-app-popup-with-roles.component';
import { DeleteAzureAppPopup }                from '../pages/manage-azure-app-reg/ui/delete-application/delete-azure-app-popup/delete-azure-app-popup.component';
import { AzureSecretsPageComponent }          from '../pages/manage-azure-app-reg/ui/manage-secrets/azure-secrets-page/azure-secrets-page.component';
import { HomePageComponent }                  from '../pages/home-page/main/home-page.component';
import { CreateAzureGroupPopupComponent } from '../pages/manage-azure-groups/ui/create-group/create-azure-group-popup/create-azure-group-popup.component';
import { DeleteAzureGroupPopupComponent } from '../pages/manage-azure-groups/ui/delete-group/delete-azure-group-popup/delete-azure-group-popup.component';
import { EditAzureGroupPopupComponent } from '../pages/manage-azure-groups/ui/edit-group/edit-azure-group-popup/edit-azure-group-popup.component';
import { ManageCipComponent }                 from '../pages/manage-cip/main/manage-cip.component';
import { CreateCompanyPopupComponent }        from '../pages/manage-cip/ui/company/create-company-popup/create-company-popup.component';
import { ManageCipCustomerComponent }         from '../pages/manage-cip/ui/customer/manage-cip-customer/manage-cip-customer.component';
import { ManageCipCompanyComponent }          from '../pages/manage-cip/ui/company/manage-cip-company/manage-cip-company.component';
import { CreateCustomerPopupComponent }       from '../pages/manage-cip/ui/customer/create-customer-popup/create-customer-popup.component';
import { ManageAzureComponent }               from '../pages/manage-azure/main/manage-azure.component';
import { ActionButtonComponent } from '../shared/ui/buttons/action-button/action-button.component';
import { DismissButtonComponent } from '../shared/ui/buttons/dismiss-button/dismiss-button.component';
import { TextAreaComponent } from '../shared/ui/dynamic-form/form-components/text-area/text-area.component';
import { CaptchaComponent } from '../shared/ui/dynamic-form/form-components/captcha/captcha.component';
import { UserService } from './datasource/services/user/user.service';
import { UserRequestsComponent } from '../pages/user-requests/main/user-requests.component';
import { ExpandableTableComponent } from '../shared/ui/expandable-table/expandable-table.component';
import { MatSortModule } from '@angular/material/sort';
import { IncidentsComponent } from '../pages/ops-dashboard/incidents/incidents.component';
import { OpsDashboardComponent } from '../pages/ops-dashboard/ops-dashboard.component';
import { RequestTasksComponent } from '../pages/ops-dashboard/request-tasks/request-tasks.component';
import { TablePageLayoutComponent } from '../shared/ui/templates/pages/table-page-layout/table-page-layout.component';
import { DynamicTableComponent } from '../shared/ui/dynamic-table/dynamic-table.component';
import { CipSearchComponent } from '../shared/ui/dialogs/cip-search/cip-search.component';
import { ChangesComponent } from '../pages/ops-dashboard/changes/changes.component';
import { RequestsComponent } from '../pages/ops-dashboard/requests/requests.component';
import { DictionaryTableComponent } from '../shared/ui/dictionary-table/dictionary-table.component';
import { CipRowFilterComponent } from '../shared/ui/dialogs/cip-row-filter/cip-row-filter.component';
import { ViewJsonComponent } from '../shared/ui/dialogs/view-json/view-json.component';
import { ThreeGridSpvComponent } from '../shared/ui/templates/pages/three-grid-spv/three-grid-spv.component';
import { SupportGroupsComponent } from '../shared/ui/templates/cip/support-groups/support-groups.component';
import { WorkLogsComponent } from '../shared/ui/templates/cip/work-logs/work-logs.component';
import { QuestionsComponent } from '../shared/ui/templates/cip/questions/questions.component';
import { CipInformationComponent } from '../shared/ui/templates/cip/cip-information/cip-information.component';
import { CipSpvHeaderComponent } from '../shared/ui/templates/cip/cip-spv-header/cip-spv-header.component';
import { CipTaskStatusComponent } from '../shared/ui/templates/cip/cip-task-status/cip-task-status.component';
import { ChangeSpvComponent } from '../pages/ops-dashboard/changes/change-spv/change-spv.component';
import { IncidentSpvComponent } from '../pages/ops-dashboard/incidents/incident-spv/incident-spv.component';
import { RequestTaskSpvComponent } from '../pages/ops-dashboard/request-tasks/request-task-spv/request-task-spv.component';
import { RequestSpvComponent } from '../pages/ops-dashboard/requests/request-spv/request-spv.component';
import { CipChangeWidgetComponent } from '../shared/ui/widget/page-widget/cip-change-widget/cip-change-widget.component';
import { CipIncidentWidgetComponent } from '../shared/ui/widget/page-widget/cip-incident-widget/cip-incident-widget.component';
import { CipRequestTaskWidgetComponent } from '../shared/ui/widget/page-widget/cip-request-task-widget/cip-request-task-widget.component';
import { CipRequestWidgetComponent } from '../shared/ui/widget/page-widget/cip-request-widget/cip-request-widget.component';
import { CipRoutingComponent } from '../pages/manage-cip/ui/routing/cip-routing/cip-routing.component';
import { SplitPanelComponent } from '../shared/ui/templates/pages/split-panel/split-panel.component';
import { EnvRouteDefaultComponent } from '../pages/manage-cip/ui/routing/cip-routing/env-route-default/env-route-default.component';
import { EnvRouteComponent } from '../pages/manage-cip/ui/routing/cip-routing/env-route/env-route.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ReusableTableComponent } from '../shared/ui/reusable-table/reusable-table.component';
import { TableFormattingPipe } from '../shared/ui/reusable-table/pipes/table-formatting.pipe';
import { RoutingTitleComponent } from '../pages/manage-cip/ui/routing/cip-routing/routing-title/routing-title.component';
import { PageWithSidepanelComponent } from '../shared/ui/templates/pages/page-with-sidepanel/page-with-sidepanel.component';
import { SidePanelStoreService } from '../shared/ui/templates/pages/page-with-sidepanel/side-panel-store.service';
import { SidePanelComponent } from '../shared/ui/templates/pages/page-with-sidepanel/side-panel/side-panel.component';
import { CipEnvRouteStore } from '../shared/datasource/stores/cip/cip-envroute-store.service';
import { CipEnvRouteDefaultStore } from '../shared/datasource/stores/cip/cip-envroutedefault-store.service';
import { TableSideMenuStore } from '../shared/ui/templates/pages/split-panel/store/table-sidemenu-store.service';
import { EnvRouteSidepanelComponent } from '../pages/manage-cip/ui/routing/side-panel-pages/env-route-sidepanel/env-route-sidepanel.component';
import { RoutingPageComponent } from '../pages/manage-cip/ui/routing/routing-page.component';
import { CreateEnvRouteDefaultSidepanelComponent } from '../pages/manage-cip/ui/routing/side-panel-pages/create-env-route-default-sidepanel/create-env-route-default-sidepanel.component';
import { CreateEnvRouteSidepanelComponent } from '../pages/manage-cip/ui/routing/side-panel-pages/create-env-route-sidepanel/create-env-route-sidepanel.component';
import { EnvRouteDefaultSidepanelComponent } from '../pages/manage-cip/ui/routing/side-panel-pages/env-route-default-sidepanel/env-route-default-sidepanel.component';
import { TableStylingPipe } from '../shared/ui/reusable-table/pipes/table-styling.pipe';
import { MatNativeDateModule } from '@angular/material/core';
import { DateRangeComponent } from '../shared/ui/date-range-picker/date-range/date-range.component';
import { ReusableFilterComponent } from '../shared/ui/reusable-filter/reusable-filter.component';
import { CustomDropdownComponent } from '../shared/ui/custom-directives/custom-dropdown/custom-dropdown.component';
import { DropdownTriggerForDirective } from '../shared/ui/custom-directives/custom-dropdown/dropdown-trigger-for.directive';


@NgModule({
    declarations: [
        AccordionComponent,
        CoreComponent,
        GiveFeedbackComponent,
        WikiPageComponent,
        PaginatedTableComponent,
        PortalFrameworkComponent,
        RaiseIssueComponent,
        SideMenuComponent,
        ToggleIconComponent,
        ToolbarComponent,
        LoginPageComponent,
        HelpIconComponent,
        MarkdownDialogComponent,
        AccessRequestComponent,
        RaiseWorkitemDialogComponent,
        ManageAzureGroupsComponent,
        CreateAzureGroupPopupComponent,
        DeleteAzureGroupPopupComponent,
        EditAzureGroupPopupComponent,
        ChipAutocompleteComponent,
        HomePageComponent,
        AutocompleteDropdownComponent,
        ManageAzureAppRegComponent,
        ManageCipComponent,
        CreateCompanyPopupComponent,
        CreateCustomerPopupComponent,
        DynamicFormComponent,
        DeleteAzureAppPopup,
        AzureSecretsPageComponent,
        CreateAzureSecretPopup,
        DeleteAzureSecretPopup,
        RefreshAzureSecretPopup,
        SnackbarComponent,
        DropdownComponent,
        TextboxComponent,
        ControlValueAccessorDirective,
        FormErrorsComponent,
        ManageCipCustomerComponent,
        ManageCipCompanyComponent,
        PageWidgetComponent,
        CompanyWidgetComponent,
        CustomerWidgetComponent,
        AzureApplicationWidgetComponent,
        AzureCustomGroupWidgetComponent,
        ManageCipWidgetComponent,
        ManageAzureWidgetComponent,
        ManageAzureComponent,
        CreateAzureAppPopupWithRoles,
        ChiplistComponent,
        TextAreaComponent,
        CaptchaComponent,
        CheckboxComponent,
        ActionButtonComponent,
        DismissButtonComponent,
        UserRequestsComponent,
        ExpandableTableComponent,
        IncidentsComponent,
        RequestTasksComponent,
        OpsDashboardComponent,
        TablePageLayoutComponent,
        DynamicTableComponent,
        CipSearchComponent,
        ChangesComponent,
        RequestsComponent,
        CipRowFilterComponent,
        ViewJsonComponent,
        ThreeGridSpvComponent,
        DictionaryTableComponent,
        IncidentSpvComponent,
        ChangeSpvComponent,
        RequestTaskSpvComponent,
        RequestSpvComponent,
        ReusableFilterComponent,
        // These need to be removed as they should be standalone
        // issue with using DictionaryTable Component which is why they are here for moment
        WorkLogsComponent,
        SupportGroupsComponent,
        QuestionsComponent,
        CipInformationComponent,
        CipSpvHeaderComponent,
        CipTaskStatusComponent,
        CipIncidentWidgetComponent,
        CipChangeWidgetComponent,
        CipRequestWidgetComponent,
        CipRequestTaskWidgetComponent,
        CipRoutingComponent,
        SplitPanelComponent,
        EnvRouteComponent,
        EnvRouteDefaultComponent,
        ReusableTableComponent,
        TableFormattingPipe,
        TableStylingPipe,
        RoutingTitleComponent,
        PageWithSidepanelComponent,
        SidePanelComponent,
        EnvRouteSidepanelComponent,
        RoutingPageComponent,
        EnvRouteDefaultSidepanelComponent,
        CreateEnvRouteDefaultSidepanelComponent,
        CreateEnvRouteSidepanelComponent,
        DateRangeComponent,
        CustomDropdownComponent,
        DropdownTriggerForDirective
    ],
    providers: [
        HttpClientModule,
        AzureAppStoreService,
        TableStoreService,
        SnackbarService,
        WikiService,
        AadGroupsService,
        // Service Used for Azure Login / Logout
        MsalService,
        MsalBroadcastService,
        UserService,
        // Azure Login Provider
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory // This Method calls the function we defined above
        },
        // Used for Http Intercept
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: CustomHttpInterceptor,
          multi: true
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        {
          provide: RECAPTCHA_SETTINGS,
          useValue: {
            theme: "light",
            siteKey: environment.generic.recaptcha.siteKey
          } as RecaptchaSettings,
        },
        {
          provide: MAT_DIALOG_DEFAULT_OPTIONS,
          useValue: {
            disableClose: false,
            hasBackdrop: true,
            backdropClass: 'dialog-background'
          }
        },

        DatePipe,
        SidePanelStoreService,
        CipEnvRouteStore,
        CipEnvRouteDefaultStore,
        TableSideMenuStore


    ],
    bootstrap: [CoreComponent, MsalRedirectComponent],
    exports: [
        CoreComponent,
        SideMenuComponent,
        ToolbarComponent,
        ToggleIconComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ClipboardModule,
        CommonModule,
        CoreRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        MatSidenavModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatFormFieldModule,
        MatExpansionModule,
        MatSelectModule,
        MatListModule,
        MatIconModule,
        MatInputModule,
        MatGridListModule,
        MatChipsModule,
        MatDividerModule,
        MatToolbarModule,
        MatSlideToggleModule,
        MatCardModule,
        MatStepperModule,
        MatTableModule,
        MatTooltipModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatTabsModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        MatMenuModule,
        MatRadioModule,
        MatCheckboxModule,
        MarkdownModule.forRoot({loader:HttpClient, sanitize: SecurityContext.NONE}),
        MatSnackBarModule,
        MatSortModule,
        PortalModule,
        MatProgressBarModule,
        DragDropModule,
        MatDatepickerModule,
        MatNativeDateModule
    ]
})
export class CoreModule {}

export function MSALInstanceFactory():IPublicClientApplication {
  return new PublicClientApplication({
    // TODO: This data probably should be moved out of here
    auth: {
      clientId : environment.azureappid,                                      // This is the AppId from the azure app you created
      authority: `https://login.microsoftonline.com/${environment.azuretenant}`,    // This is the TenantId ... needed in multiTenant Environments
      redirectUri: '/' ,//environment.redirectURl,
      postLogoutRedirectUri: '/'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage
    },
  });
}

// Azure HTTP intercept (injects the users token into the api request)
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  // protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']); // Prod environment. Uncomment to use.
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/application',['Application.ReadWrite.All']);
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/groups',['Directory.Read.All']);

  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap
  };
}

