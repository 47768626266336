import { Component, Inject } from '@angular/core';
import { NumberValueAccessor } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { createAppSecretRequest, deleteAppSecretRequest } from 'src/app/shared/datasource/interfaces/app-secret.interface';
import { AppSecretService } from 'src/app/shared/datasource/services/http-requests/azure-functions/application-registrations/app-secret.service';
import { SnackBarStyle } from 'src/app/shared/ui/snackbar/snackbar.interface';
import { DialogOutput } from 'src/app/shared/datasource/interfaces/dialog.interface';

@Component({
  selector: 'jafar-refresh-azure-secret-popup',
  templateUrl: './refresh-azure-secret-popup.component.html',
  styleUrls: ['./refresh-azure-secret-popup.component.scss']
})
export class RefreshAzureSecretPopup {
  nextButtonDisabled : boolean = true
  expiryMonths!: number
  secretText : string = ""
  constructor(
    private appSecretService : AppSecretService,
    public dialogRef: MatDialogRef<RefreshAzureSecretPopup>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { console.log(data) }


  refreshSecret(){
    // Construct Add and Delete Payloads
    const addSecretPayload : createAppSecretRequest = {
      appObjectId: this.data.appId,
      expiryMonths : this.expiryMonths,
      displayName: this.data.appSecret.displayName
    }

    const deleteRequestPayload: deleteAppSecretRequest = {
      appObjectId : this.data.appId,
      keyId : this.data.appSecret.keyId
    }

    // Create the New Secret with the same name
    this.appSecretService.addApplicationSecret(addSecretPayload).subscribe(
      createResult => {
        // Delete the Old Secret
        this.appSecretService.deleteApplicationSecret(deleteRequestPayload).subscribe(
          deleteResult => { console.log(deleteResult)}
        )
        const resultData : DialogOutput = this.getReturnData(createResult.secretText)
        // Exit with the new secret Text
        this.closeDialog(resultData)
      }
    )
  }

  getReturnData(secretText:string) : DialogOutput{
    const ReturnedData : DialogOutput = {
      data : {
        status:`Success - ${secretText} `
      },
      snackbarConfig: {
        buttonName: "OK",
        message: secretText,
        style : SnackBarStyle.default,
        settings: {
          horizontalPosition: 'center',
          verticalPosition:'top'
        }
      }

    }
    return ReturnedData
  }


  closeDialog(returnedData :DialogOutput){
    this.dialogRef.close(
      returnedData
    )
  }


  onExpirySelection(selection:number){
    console.log(selection)
    this.nextButtonDisabled = false
    this.expiryMonths = selection
  }

  onNext(stepper : MatStepper){
    stepper.selected!.completed = true
    stepper.selected!.editable = false
    stepper.next()
  }

  onSubmit(){
    console.log("Submit")
    this.refreshSecret()
  }

  onCancel(): void {
    console.log("Cancel")
    this.dialogRef.close(false)
  }


}
