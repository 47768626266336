import { Component, ViewChild, inject } from '@angular/core';
import { CIPEnvRoute, CIPEnvRouteCreateRequest, CIPEnvRouteUpdateRequest } from 'src/app/shared/datasource/interfaces/CIP/cip-envRoute.interface';
import { CipCustomerStore } from 'src/app/shared/datasource/stores/cip/cip-customer-store.service';
import { CipEnvRouteStore } from 'src/app/shared/datasource/stores/cip/cip-envroute-store.service';
import { DynamicFormComponent } from 'src/app/shared/ui/dynamic-form/dynamic-form.component';
import { DynamicFormSchema } from 'src/app/shared/ui/dynamic-form/dynamic-form.interface';
import { SidePanelStoreService } from 'src/app/shared/ui/templates/pages/page-with-sidepanel/side-panel-store.service';
import envRouteJson from 'src/assets/config/dynamic-forms/envroute-form-create.json'

@Component({
  selector: 'jafar-create-env-route-sidepanel',
  templateUrl: './create-env-route-sidepanel.component.html',
  styleUrls: ['./create-env-route-sidepanel.component.scss']
})
export class CreateEnvRouteSidepanelComponent {
  @ViewChild("formComponents") formComponents! : DynamicFormComponent

  // Inject Services \ Stores
  sidePanelStore  = inject(SidePanelStoreService)
  envRouteStore   = inject(CipEnvRouteStore)
  customerStore   = inject(CipCustomerStore)

  // Objects that contain the form Configuration
  envRouteForm  : DynamicFormSchema[] = envRouteJson as DynamicFormSchema[]

  // Page Routing \ Layout Options
  routeTitle      : string        = "Create a New Custom Route"

  ngOnInit(){
    console.log(envRouteJson)
    this.setDefaults()
  }

  setDefaults(){
    this.envRouteForm.forEach(
      formComponent => {
        const envProperty = formComponent.controlName as keyof CIPEnvRoute
        formComponent.defaultValue = ""
      }
    )
  }

  ngOnChanges(){
    console.log(this.formComponents)
  }

  onGoBack(){
    this.sidePanelStore.setIsOpen(false)
  }

  onCancel(){
    if (this.formComponents) {
      this.formComponents.dynamicForm.reset()
    }
    this.formComponents.dynamicForm.reset()
  }

  onCreate(){
    this.customerStore.customer
    const createRouteRequest: CIPEnvRouteCreateRequest ={
      customer  : this.customerStore.customer.sapName,
      question  : this.formComponents.dynamicForm.get('question')?.value,
      answer    : this.formComponents.dynamicForm.get('answer')?.value,
      routeName : this.formComponents.dynamicForm.get('routeName')?.value.routeId,
      config    : this.formComponents.dynamicForm.get('config')?.value,
      active    : true
    }
    console.log("Create Route Payload", createRouteRequest)
    this.envRouteStore.newEnvRoute(createRouteRequest)
    this.onGoBack()
  }

  onSelection(selection : any){
      console.log("The Selected Route is ", JSON.stringify(selection))
      if (selection.routeId === "IAT"){
        this.formComponents.dynamicForm.controls['question'].setValue("cmp_ucid")
        this.formComponents.dynamicForm.controls['question'].disable()
      }
      else{
        this.formComponents.dynamicForm.controls['question'].setValue("")
        this.formComponents.dynamicForm.controls['question'].enable()
      }
  }
}
// routeName : this.formComponents.dynamicForm.get('routeName')?.value.routeId,
//       config    : this.formComponents.dynamicForm.get('config')?.value,
//       active    : this.formComponents.dynamicForm.get('active')?.value.length > 0 ? true : false,
//       updateAssociatedItems: false
