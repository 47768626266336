import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform, inject } from '@angular/core';
import { GenericStatus } from 'src/app/shared/datasource/enums/generic-status.enum';

@Pipe({
  name: 'tableFormatting'
})
export class TableFormattingPipe implements PipeTransform {

  datePipe = inject(DatePipe)

  transform(value: any, args: any): any {
    // console.log("Value from pipe", value)
    // console.log("Passed in Pipe", args)
    let FormattedData
    switch (args) {
      case "shortDate" : {
        FormattedData = (this.datePipe.transform(value, "dd/MM/yyyy"))
        break
      }

      case "date" : {
        FormattedData = (this.datePipe.transform(value, "dd MMMM yyyy, HH:mm a z"))
        break;
      }

      case "icon" : {
        FormattedData = this.formatIcon(value)
        break;
      }

      case "none" : {
        FormattedData = value
        break
      }
    }
    // console.log("Transformed Data returned", FormattedData)

    return FormattedData
  }

  formatIcon(value: any){
    const stringValue = String(value)
    return GenericStatus[stringValue as keyof typeof GenericStatus]
  }
}
