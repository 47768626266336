import { ComponentPortal, Portal } from '@angular/cdk/portal';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector    : 'jafar-three-grid-spv',
  templateUrl : './three-grid-spv.component.html',
  styleUrls   : ['./three-grid-spv.component.scss']
})
export class ThreeGridSpvComponent implements OnInit {
  @Input() Header!      : ComponentPortal<any>;
  @Input() MainGrid!    : ComponentPortal<any>;
  @Input() TopGrid!     : ComponentPortal<any>;
  @Input() BottomGrid!  : ComponentPortal<any>;

  threeGridHeader !     : Portal<any>
  threeGridMainGrid!    : Portal<any>
  threeGridTopGrid!     : Portal<any>
  threeGridBottomGrid!  : Portal<any>


  ngOnInit() {
    this.threeGridHeader      = this.Header
    this.threeGridMainGrid    = this.MainGrid
    this.threeGridTopGrid     = this.TopGrid
    this.threeGridBottomGrid  = this.BottomGrid
  }

}
