import { AfterContentInit, Component, OnInit, ViewChild, inject} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SpinnerService } from 'src/app/shared/datasource/services/spinner/spinner.service';
import { TableColumnHeader } from 'src/app/shared/datasource/interfaces/expandable-table-interface';
import { UserService } from 'src/app/core/datasource/services/user/user.service';
import { WorkItemsStore } from 'src/app/shared/datasource/stores/work-item.store';
import feedbackHeadersJson  from '../../../../assets/config/tableHeaders/give-feedback-headers.json'
import { trigger, state, style, transition, animate } from '@angular/animations';
import { RaiseWorkitemDialogComponent } from 'src/app/shared/ui/raise-workitem-dialog/raise-workitem-dialog.component';
import { WorkItemDialog, WorkItemDialogOutput } from 'src/app/shared/datasource/services/http-requests/azure-devops/workitem/workitem.interface';
import { CheckBoxFilterConfig, DropDownFilterConfig } from 'src/app/shared/ui/reusable-filter/reusable-filter.interface';
import { ReusableFilterComponent } from 'src/app/shared/ui/reusable-filter/reusable-filter.component';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'jafar-give-feedback',
  templateUrl: './give-feedback.component.html',
  styleUrls: ['./give-feedback.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})

export class GiveFeedbackComponent implements OnInit {
  // Construct \ Inject Services
  dialog          = inject(MatDialog)
  spinnerService  = inject(SpinnerService)
  workItemStore   = inject(WorkItemsStore)
  userProfile     = inject(UserService)

  // Local variables used to populate the table
  _filteredSuggestions$ = new BehaviorSubject<any>(null)
  filteredSuggestions$ = this._filteredSuggestions$.asObservable()

  // Local Variables to Inject to Filters
  requestedByFilterConfig!: DropDownFilterConfig
  requestStatusFilterConfig!: CheckBoxFilterConfig

  // Class Propeties
  feedbackTableHeaders: TableColumnHeader[] = feedbackHeadersJson as TableColumnHeader[]

  ngOnInit(): void {
    // Populate Store with feedback
    this.workItemStore.fetchTaskRecords()

    // Subscribe to the taskrecords
    this.workItemStore.taskRecords$.subscribe((item: any) => {
      // Populate Local variable that populates the table
      this._filteredSuggestions$.next(item)

      // Populate the Dropdown and Checkbox filters
      const allDropDownItems = item.map((option: any) => {return option.ReportedBy})
      const dropDownFilter = Array.from(new Set(allDropDownItems))
      const statusFilter: string[] = ['Active', 'Closed', 'New']

      // Construct Configs for both Filters
      this.requestStatusFilterConfig = {
        enabled: true,
        config: {
          title: 'Status',
          data: statusFilter,
          displayProperty: 'name',
          type: 'multiple'
        }
      }

      this.requestedByFilterConfig = {
        enabled: true,
        config: {
          title: 'Reported By',
          data: dropDownFilter,
          type: 'single'
        }
      }
    })
  }

  refreshFeedback(){
    this.workItemStore.fetchTaskRecords()
  }

  openDialog(): void {
    const Feedbackdata:WorkItemDialog = {
      input:{
        MainTitle: "Make a Suggestion",
        TitleMessage: "Please give your suggestion a Title:",
        DescriptionMessage: "Please provide more details on your suggestion:"
      },
      output: {
        Title: "",
        Description: "",
        User : "",
        Mail: ""
      }
    }
    const dialogRef = this.dialog.open(
      RaiseWorkitemDialogComponent, {
      data:Feedbackdata,
    });

    dialogRef.afterClosed().subscribe(
      (result :WorkItemDialog)  => {
        console.log("Dialog Returned : " ,result.output)
        if (result !== undefined){
          this.createNewFeedback(result.output)
        }
      }
    );
  }

  createNewFeedback(Feedback : WorkItemDialogOutput){
    this.workItemStore.addTaskRecord(Feedback)
  }

  getFilteredSuggestions(filter: any){
    // Create local vars to reference filters
    const reportedBy = filter.dropDownFilterOutput
    const statusFilter = filter.checkBoxFilterOutput.map((option: any) => {return option.name})
    let filteredItems: any[] = []

    // Iterate through all possible combinations
    if(reportedBy.length !==0 && statusFilter.length !==0){
      // Filter items depending on property values
      const filteredItems = [...this.workItemStore.taskRecords].filter((item: any) =>
        item.ReportedBy === `${reportedBy}` && statusFilter.includes(item.State)
      )
      // Pass values back to local observable
      this._filteredSuggestions$.next(filteredItems)
    }else if(reportedBy && statusFilter.length == 0){
      // Filter items depending on property values
      filteredItems = [...this.workItemStore.taskRecords].filter((item: any) => item.ReportedBy === reportedBy)
      // Pass values back to local observable
      this._filteredSuggestions$.next(filteredItems)
    }else{
      // Filter items depending on property values
      filteredItems = [...this.workItemStore.taskRecords].filter((item: any) => statusFilter.includes(item.State))
      // Pass values back to local observable
      this._filteredSuggestions$.next(filteredItems)
    }
  }

  onClear(){
    // Revert local observable to starting values
    this._filteredSuggestions$.next(this.workItemStore.taskRecords)
  }

}
