import { Injectable, InjectionToken } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ComponentPortal } from '@angular/cdk/portal';
import { SidePanelComponent } from './side-panel/side-panel.component';
import { CIPEnvRoute } from 'src/app/shared/datasource/interfaces/CIP/cip-envRoute.interface';
import { CIPEnvRouteDefault } from 'src/app/shared/datasource/interfaces/CIP/cip-envRouteDefault.interface';
import { CIPHeader } from 'src/app/shared/datasource/interfaces/CIP/cip-page-layout.interface';
import { CIPQuestion } from 'src/app/shared/datasource/interfaces/CIP/cip-questions.interface';
import { CIPSupportGroup } from 'src/app/shared/datasource/interfaces/CIP/cip-supportgroups.interface';
import { CIPWorkLog } from 'src/app/shared/datasource/interfaces/CIP/cip-worklogs.Interface';

@Injectable({
  providedIn: 'root'
})
export class SidePanelStoreService {
  // Decalare the Behaviour Subjects to Store the Data we need
  private readonly _showsidePanel$ : BehaviorSubject<boolean>   = new BehaviorSubject<boolean>(false);
  private readonly _sideMenuComponent$ : BehaviorSubject<ComponentPortal<any>>   = new BehaviorSubject<ComponentPortal<any>>(new ComponentPortal(SidePanelComponent));


  // Exposed Properties of the Store
  // Observable Property
  get isOpen$(): Observable<boolean> {
    return this._showsidePanel$.asObservable();
  }
  get sideMenuComponent() :ComponentPortal<any> {
    return this._sideMenuComponent$.getValue()
  }


  // Exposed Methods to Amend the store
  setIsOpen(newState : boolean) {
    this._showsidePanel$.next(newState)
  }

  setSideMenuComponent(component : ComponentPortal<any>) {
    this._sideMenuComponent$.next(component)
  }
}
