import { BehaviorSubject, Observable } from "rxjs";

export class StateStoreService<T> {
    state$ : Observable<T>;
    private _state$ :  BehaviorSubject<T>;
    
    protected constructor (initialState: T) {
        this._state$ = new BehaviorSubject(initialState);
        this.state$ = this._state$.asObservable();
    }

    // allows us to get the current state via the state property without subscribing to state updates.
    get state (): T {
        return this._state$.getValue();
    }

    // allows us to push a new state.  All subscribed entites subscibed to the state$ property will get notified\updated
    setState (nextState: T): void {
        this._state$.next(nextState);
        // this._state$.complete()
    }
    
}