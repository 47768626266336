import { HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable, catchError, throwError } from "rxjs";
import { GenericApiService } from "../../generic-api/generic-api.service";
import { QueryParams } from "../../../../interfaces/generic-api.interface";
import { CIPSettings } from "src/environments/environment";
import { CIPEnvRoute, CIPEnvRouteCreateRequest, CIPEnvRouteUpdateRequest } from "src/app/shared/datasource/interfaces/CIP/cip-envRoute.interface";
import { CIPEnvRouteDefault, CIPEnvRouteDefaultCreateRequest, CIPEnvRouteDefaultUpdateRequest } from "src/app/shared/datasource/interfaces/CIP/cip-envRouteDefault.interface";

@Injectable({
  providedIn: 'root'
})
export class CIPEnvRouteDefaultService  extends GenericApiService{

  httpClient = inject(HttpClient)

  getDefaultHeaders() : HttpHeaders{
    const headers = new HttpHeaders()
    .set('Accept', '*/*')
    .set('Content-Type', 'application/json')

    return headers
  }

  getDefaultEnvRoutes() : Observable<CIPEnvRouteDefault[]> {
    const headers = this.getDefaultHeaders()
    const url     = `${CIPSettings.baseUrl}/${CIPSettings.endPoints["envRouteDefault"]}`
    let query: QueryParams = {
      code    : `${CIPSettings.code}`
    }
    return this.get<CIPEnvRouteDefault[]>(url, headers, query).pipe(
      catchError(this.errorHandler)
    )
  }

  createDefaultEnvRoute(body: CIPEnvRouteDefaultCreateRequest) : Observable<CIPEnvRouteDefault>{
    const headers = this.getDefaultHeaders()
    const url     = `${CIPSettings.baseUrl}/${CIPSettings.endPoints["envRouteDefault"]}`
    let query: QueryParams = {
      code    : `${CIPSettings.code}`
    }
    let bodyString = JSON.stringify(body);
    console.log(bodyString);
    return this.post<CIPEnvRouteDefault>(url, bodyString, headers, query).pipe(
      catchError(this.errorHandler)
    )
  }

  updateDefaultEnvRoute(routeId: string, body: CIPEnvRouteDefaultUpdateRequest ) : Observable<CIPEnvRouteDefault>{
    const headers = this.getDefaultHeaders()
    const url     = `${CIPSettings.baseUrl}/${CIPSettings.endPoints["envRouteDefault"]}`
    let query: QueryParams = {
      code    : `${CIPSettings.code}`,
      id      : routeId
    }
    let bodyString = JSON.stringify(body)
    return this.put<CIPEnvRouteDefault>(url, bodyString, headers, query).pipe(
      catchError(this.errorHandler)
    )
  }

  deleteDefaultEnvRoute(routeId: string){
    const headers = this.getDefaultHeaders()
    const url     = `${CIPSettings.baseUrl}/${CIPSettings.endPoints["envRouteDefault"]}`
    let query: QueryParams = {
      code    : `${CIPSettings.code}`,
      id      : routeId
    }
    return this.delete(url, headers, query).pipe(
      catchError(this.errorHandler)
    )
  }

  private errorHandler(error: HttpErrorResponse) {
    let errorMessage = 'An unknown error occurred';

    if (error.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    alert(errorMessage);

    return throwError(() => errorMessage);
  }

}
