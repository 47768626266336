import { Injectable }        from "@angular/core";
import { MenuItem }          from "../interfaces/menu-item.interface";
import { SideMenu }          from "../interfaces/side-menu.interface";
import { StateStoreService } from "./state-store.service";

@Injectable()
export class SideMenuService extends StateStoreService<SideMenu>{

    constructor () {
        super(new SideMenu());
    }

    addMenuItems(MenuItems:MenuItem[]): void{
        this.setState({
            ...this.state,
            topSideMenuItems    : MenuItems.filter( menuitem=> menuitem.position == "top"),
            bottomSideMenuItems : MenuItems.filter( menuitem=> menuitem.position == "bottom"),
        })
    }

    expandSideMenu(isExpanded:boolean){
        this.setState ({
            ...this.state,
            showSideMenuText : isExpanded
        })
    }

    toggleSideMenuText(){
        this.setState({
            ...this.state,
            showSideMenuText: !this.state.showSideMenuText
        })
    }

    showSideMenu(isVisible:boolean){
        this.setState ({
            ...this.state,
            visible : isVisible
        })
    }

}
