<mat-card class="card-appearance">
  <mat-card-header class="mat-header">
      <h1>User Requests</h1>
  </mat-card-header>
  <mat-divider class="divider"></mat-divider>
  <mat-card-content class="main-card-content">
    <div class="page-split-container">
      <mat-card class="my-requests-card">
        <mat-card-title class="my-requests-card-title">

          <div class="my-requests-title-row-1">
            <span class="refresh-button-spacing"></span>
            <h1 class="requests-header">My Logged Requests</h1>
            <span class="refresh-button-spacing"></span>
            <mat-icon matTooltip="Refresh Table" (click)="refreshUserRequests()" class="refresh-alignment">sync</mat-icon>
          </div>

          <div class="my-requests-title-row-2">

            <mat-radio-group required="true" [(ngModel)]="selectedFilter">
              <mat-radio-button *ngFor="let filter of requestFilters" [value]="filter"> {{filter}} </mat-radio-button>
            </mat-radio-group>

            <mat-form-field *ngIf="selectedFilter == 'Date'" appearance="outline" class="dropdown" color="accent">
              <mat-label>Filter by Date</mat-label>
              <mat-select (valueChange)="filterDateRange($event)" [(value)]="filterValue">
                <mat-option value=7>Last 7 Days</mat-option>
                <mat-option value=30>Last Month</mat-option>
                <mat-option value=0>All Time</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="selectedFilter == 'Status'" appearance="outline" class="dropdown" color="accent">
              <mat-label>Filter by Status</mat-label>
              <mat-select (valueChange)="filterApprovalStatus($event)">
                <mat-option value="Approved">Approved</mat-option>
                <mat-option value="Denied">Denied</mat-option>
                <mat-option value="Action Required">Action Required</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="selectedFilter == 'Request ID'" appearance="outline" class="dropdown" color="accent">
              <mat-label>Filter by Request ID</mat-label>
              <input matInput placeholder="Enter Request ID" (keyup)="filterRequestID($event)">
            </mat-form-field>

            <jafar-dismiss-button (btnClick)="resetFilters()">Reset Filter</jafar-dismiss-button>

          </div>

        </mat-card-title>
        <mat-card-content class="my-requests-card-content">
          <jafar-paginated-table [tableInput]="filteredRequestData | async" (selectedRecord)="onSelection($event)"></jafar-paginated-table>
        </mat-card-content>
      </mat-card>

      <mat-divider class="table-divider" [vertical]="true"></mat-divider>

      <mat-card class="approvers-card">
        <mat-card-title class="approvers-card-title">
          <h1 class="align-header">Approval Information</h1>
        </mat-card-title>
        <mat-card-content class="approvers-card-content">
          <jafar-paginated-table [tableInput]="approvalData | async"></jafar-paginated-table>
        </mat-card-content>
      </mat-card>
    </div>
  </mat-card-content>
</mat-card>


<!-- < -->
  <!-- </mat-tab>
</mat-tab-group> -->

<!-- <div>
  <div class="my-request">
    <div>
      <div class="filter-alignment">
        <mat-card class="my-requests-card">
          <mat-card-title  class="my-request-card-title">
            <h1>
              My Logged Requests
              <mat-radio-group required="true" [(ngModel)]="selectedFilter">
                <mat-radio-button *ngFor="let filter of requestFilters" [value]="filter"> {{filter}} </mat-radio-button>
              </mat-radio-group>
              <mat-form-field *ngIf="selectedFilter == 'Date'" appearance="outline" class="dropdown" color="accent">
                <mat-label>Filter by Date</mat-label>
                <mat-select (valueChange)="filterDateRange($event)" [(value)]="filterValue">
                  <mat-option value=7>Last 7 Days</mat-option>
                  <mat-option value=30>Last Month</mat-option>
                  <mat-option value=0>All Time</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field *ngIf="selectedFilter == 'Status'" appearance="outline" class="dropdown" color="accent">
                <mat-label>Filter by Status</mat-label>
                <mat-select (valueChange)="filterApprovalStatus($event)">
                  <mat-option value="Approved">Approved</mat-option>
                  <mat-option value="Denied">Denied</mat-option>
                  <mat-option value="Action Required">Action Required</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field *ngIf="selectedFilter == 'Request ID'" appearance="outline" class="dropdown" color="accent">
                <mat-label>Filter by Request ID</mat-label>
                <input matInput placeholder="Enter Request ID" (keyup)="filterRequestID($event)">
              </mat-form-field>
              <jafar-dismiss-button (btnClick)="resetFilters()">Reset Filter</jafar-dismiss-button>
            </h1>

          </mat-card-title>
          <mat-card-content>

          </mat-card-content>
        </mat-card>

      </div>
    </div>
  </div>
</div>
<mat-divider class="table-divider" [vertical]="true"></mat-divider>
<mat-card class="approval-information">
  <mat-card-title class="card-title">

  </mat-card-title>
  <mat-card-content>
    <jafar-paginated-table [tableInput]="approvalData | async"></jafar-paginated-table>

  </mat-card-content>
  <div>

  </div>
</mat-card> -->
