import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MarkdownDisplayService implements OnInit {

  mdcontent!: string
  constructor(
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    
  }

  async getMDContent(path: string){
    this.mdcontent = await lastValueFrom
    (this.http.get(path,
      {
        responseType: 'text'
      })
    )
  }

}
