import { AfterContentInit, ChangeDetectionStrategy, Component,  OnInit, inject } from '@angular/core';

//Import services
import { ThemeService } from '../../datasource/services/theme/theme.service';
import { Toolbar } from '../../datasource/interfaces/toolbar.interface';
import { SideMenuService } from '../../datasource/services/side-menu.service';
import { MenuItem } from '../../datasource/interfaces/menu-item.interface';
import sideMenuJson from 'src/assets/config/framework-config/side-menu-config.json'
import { LoginService } from '../../datasource/services/login.service';
import { environment } from 'src/environments/environment';
import toolbarJson from 'src/assets/config/framework-config/toolbar-config.json'
import { WikiService } from 'src/app/shared/datasource/services/http-requests/azure-devops/wiki/wiki.service';
import { UserStore } from '../../datasource/services/user/user-store.service';

@Component({
  selector: 'jafar-portal-framework',
  templateUrl: './portal-framework.component.html',
  styleUrls: ['./portal-framework.component.scss'],
  providers: [ThemeService, SideMenuService, WikiService],
  changeDetection: ChangeDetectionStrategy.Default

})
export class PortalFrameworkComponent implements OnInit, AfterContentInit {

  currentTheme : string = "lightMode";
  logoUrl$     : string = "";
  logonIcon$   : string = "login";
  userStore =  inject(UserStore)

  sideMenuItems : MenuItem[] = sideMenuJson as MenuItem[]
  toolbarItems: Toolbar = toolbarJson as Toolbar

  constructor(
    private theme : ThemeService,
    public sideMenuService: SideMenuService,
    public loginService: LoginService,
    private wikiService: WikiService
  ){}

  ngOnInit(): void {
    this.getLogoUrl()
    this.toolbarItems.title = environment.toolbartitle
    // this.wikiService.getWikiList() - not needed at the moment
    this.userStore.fetchUserProfile()
    this.userStore.userProfile$.subscribe(
      (result) => {
        this.userStore.fetchOpsDashboardUser(result.id)
        this.userStore.fetchRoutingAdmins(result.id)
      }
    )

    this.userStore.opsDashboardUser$.subscribe(result => {
      this.sideMenuItems.filter(obj => obj.name === 'OPS Dashboard')
      .forEach(filteredObj => filteredObj.visible = result)
      console.log(this.sideMenuItems)
    })

    this.sideMenuService.addMenuItems(this.sideMenuItems)
    this.sideMenuService.expandSideMenu(true)
    this.sideMenuService.showSideMenu(true)
  }

  ngAfterContentInit(): void {

  }

  updateTheme(toggleTheme:boolean){
    // this.theme.setDarkTheme(toggleTheme)
    // this.currentTheme = this.theme.getCurrentheme()
    this.getLogoUrl()
  }

  getLogonIcon(){
    if(this.logonIcon$ == 'login'){
      this.logonIcon$ = 'logout'
    }
    else{
      this.logonIcon$ = 'login'
    }
  }

  getLogoUrl(){
      if (this.currentTheme== "darkMode"){
        this.logoUrl$ = environment.generic.logoUrlDark
      }
      else {
        this.logoUrl$ = environment.generic.logoUrlLight
      }
  }

}
