import { Injectable, InjectionToken } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { CIPEnvRoute } from "src/app/shared/datasource/interfaces/CIP/cip-envRoute.interface";
import { CIPEnvRouteDefault } from "src/app/shared/datasource/interfaces/CIP/cip-envRouteDefault.interface";
import { CIPHeader } from "src/app/shared/datasource/interfaces/CIP/cip-page-layout.interface";
import { CIPQuestion } from "src/app/shared/datasource/interfaces/CIP/cip-questions.interface";
import { CIPSupportGroup } from "src/app/shared/datasource/interfaces/CIP/cip-supportgroups.interface";
import { CIPWorkLog } from "src/app/shared/datasource/interfaces/CIP/cip-worklogs.Interface";

@Injectable({
    providedIn: 'root'
})

export class TableSideMenuStore {

  // Decalare the Behaviour Subjects to Store the Data we need
  private readonly _showTableSideMenu$ : BehaviorSubject<boolean>   = new BehaviorSubject<boolean>(false);


  // Exposed Properties of the Store
  // Observable Property
  get state$(): Observable<boolean> {
    return this._showTableSideMenu$.asObservable();
  }

  // Exposed Methods to Amend the store
  setState(newState : boolean) {
    this._showTableSideMenu$.next(newState)
  }

  toggleState() {
    this._showTableSideMenu$.next(!this._showTableSideMenu$.getValue)
  }


  
}

