<mat-table [dataSource]="filteredData" class="info-table" >

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef>name</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.name}}</mat-cell>
    </ng-container>

    <!-- Value Column -->
    <ng-container matColumnDef="value">
      <mat-header-cell *matHeaderCellDef>value</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.value}}</mat-cell>
    </ng-container>

    <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr> -->
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="basic-table-row"></mat-row>

</mat-table>

 