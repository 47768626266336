import { ChangeDetectionStrategy, Component,  Input } from '@angular/core';
import { SideMenu } from '../../datasource/interfaces/side-menu.interface';
import { Toolbar } from '../../datasource/interfaces/toolbar.interface';
import { LoginService } from '../../datasource/services/login.service';
import { SideMenuService } from '../../datasource/services/side-menu.service';
import { PortalFrameworkComponent } from '../../feature/portal-framework/portal-framework.component';

@Component({
  selector: 'jafar-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ToolbarComponent {
  constructor(
    private sideMenuService: SideMenuService,
    private framework: PortalFrameworkComponent,
    public loginService: LoginService
  ){}

  @Input() toolbarItems! : Toolbar;

  ngOnInit(){

  }

  //TODO: Not sure if the below is the best implementation
  // Maybe use @Output to resolve importing of the framework component???
 
  // toggle has been switched so update theme
  updateTheme(toggleTheme:boolean){
    this.framework.updateTheme(toggleTheme)
  }
  
  // Button has been pressed so toggle/invert the current setting
  showSideMenuText(){
    console.log("I've hit the menu button current value is : " + this.sideMenuService.state.showSideMenuText)
    this.sideMenuService.toggleSideMenuText()
    console.log(" New Value of the show side menu text  is : " + this.sideMenuService.state.showSideMenuText)
  }

}