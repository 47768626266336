<jafar-table-page-layout [table]="changeTable" [toolbar]="toolbar" (selectedRecord)="onSelect($event)">Changes</jafar-table-page-layout>
<ng-template #toolbar>
  <span class="button-group">
    <button mat-button class="action-button" (click)="onSearch()">Search</button>
    <mat-divider class="button-divider" [vertical]="true"></mat-divider>
    <span class="refresh-button-spacing"></span>
    <mat-icon matTooltip="Refresh" (click)="refreshTable()" class="item-alignment">sync</mat-icon>
  </span>
</ng-template>

<ng-template #search>
  <title mat-dialog-title class="center-title">
    Search for a Change
  </title>
  <mat-dialog-content  class="dialog-content" >
    <span class="message-style">Please enter your search type below</span>
    <div class="search-alignment">

      <form [formGroup]="SearchFG">

        <mat-form-field appearance="outline" color="accent" class="search-dropdown">
          <mat-label>Search Type</mat-label>
          <mat-select (valueChange)="selectFilter($event)" value="id">
            <mat-option value="id">CIP ID</mat-option>
            <mat-option value="internalChangeId">Change ID</mat-option>
          </mat-select>
        </mat-form-field>

      <mat-form-field *ngIf="searchData.idType == 'id'" appearance="outline" color="accent" class="form-item">
        <mat-label>Search by CIP ID</mat-label>
        <input matInput
          placeholder="1425d3d6-6b76-4b3f-c929-08db9a7ad17f"
          formControlName="searchId"
          (blur)="getChangeInfo()"
          [class.is-invalid]="SearchFG.controls.searchId.invalid && SearchFG.controls.searchId.errors?.['validateIncidents']"
          [class.is-valid]="SearchFG.controls.searchId.valid"
        >
        <mat-spinner matSuffix *ngIf="SearchFG.controls.searchId.pending" class="is-loading" color="accent"></mat-spinner>

      </mat-form-field>

      <mat-form-field *ngIf="searchData.idType == 'internalChangeId'" appearance="outline" color="accent" class="form-item">
        <mat-label>Search by Incident ID</mat-label>
        <input matInput
          placeholder="CHG000012345"
          formControlName="searchId"
          (blur)="getChangeInfo()"
          [class.is-invalid]="SearchFG.controls.searchId.invalid && SearchFG.controls.searchId.errors?.['validateIncidentInfo']"
          [class.is-valid]="SearchFG.controls.searchId.valid"
        >
        <mat-spinner matSuffix *ngIf="SearchFG.controls.searchId.pending" class="is-loading" color="accent"></mat-spinner>

      </mat-form-field>
      <div class="invalid-response" *ngIf="SearchFG.controls.searchId.errors?.['validateIncidents'] || SearchFG.controls.searchId.errors?.['validateIncidentInfo']">
        No results have been found for ID: {{SearchFG.controls.searchId.value}}
      </div>
    </form>
  </div>
    <span>
      <jafar-action-button (btnClick)="onSubmit()" [disabled]="!SearchFG.valid">Submit</jafar-action-button>
      <jafar-dismiss-button (btnClick)="onCancel()">Cancel</jafar-dismiss-button>
    </span>
  </mat-dialog-content>
</ng-template>
