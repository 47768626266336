import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { CIPIncident, CIPIncidentRecord } from './../../../shared/datasource/interfaces/CIP/cip-incidents.interface';
import { Component, inject, OnInit, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import incidentsHeadersJson  from '../../../../assets/config/tableHeaders/incident-headers.json'
import { Observable, catchError, map, of } from 'rxjs';
import { Router } from '@angular/router';
import { CipSearchComponent } from 'src/app/shared/ui/dialogs/cip-search/cip-search.component';
import { MatDialog, } from '@angular/material/dialog';
import { idType } from 'src/app/shared/datasource/interfaces/CIP/cip-general.interface';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { CipIncidentStore } from 'src/app/shared/datasource/stores/cip/cip-incident-store.service';
import { CipSearchData } from 'src/app/shared/datasource/interfaces/CIP/cip-page-layout.interface';
import { ReusableTable, TableColumns } from 'src/app/shared/ui/reusable-table/interface/reusable-table.interface';
import { PaginationDefaults } from 'src/environments/generic-variables';
import { Customer } from '../../manage-azure-app-reg/datastore/customer.interface';
import { CipCustomerStore } from 'src/app/shared/datasource/stores/cip/cip-customer-store.service';
import { CipRowFilterComponent } from 'src/app/shared/ui/dialogs/cip-row-filter/cip-row-filter.component';
import { DateFilterRange, HelperFunctionsService } from 'src/app/shared/functions/helperfunctions';
import { DateRangeComponent } from 'src/app/shared/ui/date-range-picker/date-range/date-range.component';

@Component({
  selector: 'jafar-incidents',
  templateUrl: './incidents.component.html',
  styleUrls: ['./incidents.component.scss']
})
export class IncidentsComponent implements OnInit, AfterViewInit{
  @ViewChild('datePicker') datePicker!: DateRangeComponent

  // All Injected Services / Stores
  incidentStore   = inject(CipIncidentStore)
  dialog          = inject(MatDialog)
  router          = inject(Router)
  customerStore   = inject(CipCustomerStore)
  helperFunctions = inject(HelperFunctionsService)

  // Local Variables and observables
  allIncidentTableHeaders: TableColumns[] = incidentsHeadersJson as TableColumns[]
  displayedIncidentTableHeaders!: TableColumns[]

  incidentTable$ = new BehaviorSubject<ReusableTable>({
    data     : this.incidentStore.incidents$,
    columns  : this.displayedIncidentTableHeaders,
    settings : {
      pagination: true,
      paginationOptions: PaginationDefaults,
      sorting: {
        sortedColumn: 'createdAt',
        sortedOrder: 'desc'
      },
      search: true,
      reorderColumns: true,
      hidePageSize: false
    }
  })

  customerSelected!: boolean
  selectedDateRange!: DateFilterRange | null
  customerDropdown = {
    listLabel       : "Customer",
    displayName     : "displayName"
  }

  goToSpvPage : boolean = false

  // All Public Variables
  public incidentFilters: idType[] = ['id', 'internalIncidentId', 'customer']
  public searchData!: CipSearchData
  public SearchFG = new FormGroup ({ searchId : new FormControl('', { validators: [Validators.required], updateOn: 'blur'}),})

  @ViewChild("search") searchTemplate!: TemplateRef<any>

  ngOnInit(){
    // Check to see if a customer is in the store, if it is, filter the data on that customer.
    this.customerStore.customer.displayName != "" ?
    this.incidentStore.fetchCustomerIncidents(this.customerStore.customer) :
    this.incidentStore.fetchAllIncidents()

    this.customerStore.fetchCustomers()
    this.displayedIncidentTableHeaders = this.allIncidentTableHeaders
    .filter((response: TableColumns) => {return response.show})
    this.incidentTable$.next({
      ...this.incidentTable$.getValue(),
      columns : this.displayedIncidentTableHeaders
    })
    this.incidentStore.resetIncidentInfo()
  }

  ngAfterViewInit() {
    this.searchData = {
      cipType: "Incident",
      template: this.searchTemplate,
      id: "",
      idType: "id",
    }

    this.SearchFG.controls['searchId'].addAsyncValidators(this.incidentDetailValidator())
    this.SearchFG.controls['searchId'].updateValueAndValidity()
  }

  goToIncident(cipId:string){
    console.log("pageID", cipId)
    this.router.navigate(["opsdashboard/incident",cipId])
  }

  selectFilter(event: idType){
    this.SearchFG.controls['searchId'].clearAsyncValidators()
    this.SearchFG.controls['searchId'].updateValueAndValidity()
    this.SearchFG.reset()
    this.customerStore.clearSelectedCustomer()
    this.searchData.idType = event
  }

  getIncidentInfo(){
    console.log(this.searchData.idType)

    // Clear Async Validators
    switch(this.searchData.idType)
    {
      case "id":
        // Add Async Validators back
        this.SearchFG.controls['searchId'].addAsyncValidators(this.incidentDetailValidator())
        // Initialize Validators
        this.SearchFG.controls['searchId'].updateValueAndValidity()
        if(this.SearchFG.controls['searchId'].value){
          this.incidentStore.fetchIncidentInfo(this.SearchFG.controls['searchId'].value)
        }
        break;

      case "internalIncidentId":
        this.SearchFG.controls['searchId'].addAsyncValidators(this.incidentValidator())
        this.SearchFG.controls['searchId'].updateValueAndValidity()
        if(this.SearchFG.controls['searchId'].value){
         this.incidentStore.fetchAllIncidents(`internalIncidentId eq '${this.SearchFG.controls['searchId'].value}'`)
        }
        break;

      default:
        console.log("ERROR: Invalid Search Type")
        break
    }
  }

  getCustomerIncidents(customer: Customer){
    const dateFilter = this.selectedDateRange ? this.selectedDateRange : null
    if(customer && dateFilter !== null){
      console.log("CUSTOMER + DATE FILTER")
      this.customerStore.updateSelectedCustomer(customer)
      this.incidentStore.fetchCustomerIncidents(customer, this.selectedDateRange)
    }else if(customer && dateFilter == null){
      console.log("CUSTOMER ONLY FILTER")
      this.customerStore.updateSelectedCustomer(customer)
      this.incidentStore.fetchCustomerIncidents(customer)
    }else{
      console.log("NO FILTER")
      this.customerStore.clearSelectedCustomer()
      this.incidentStore.fetchAllIncidents()
    }
  }

  getIncidentsByDateRange(dateRange: DateFilterRange){
    const customer = this.customerStore.customer.displayName != "" ? this.customerStore.customer : null
    if(customer !== null && dateRange){
      console.log("CUSTOMER SELECTED", customer)
      this.incidentStore.fetchCustomerIncidents(customer, this.selectedDateRange)
    }else{
      console.log("NO CUSTOMER SELECTED", customer)
      const dateFilter = `createdAt ge ${dateRange.startDate} and createdAt le ${dateRange.endDate}`
      this.incidentStore.fetchAllIncidents(dateFilter)
    }
  }

  private incidentValidator(): AsyncValidatorFn{
    return (control: AbstractControl): Observable<ValidationErrors | null> =>
    this.incidentStore.validateIncidents(`internalIncidentId eq '${control.value}'`).pipe(
      map((response: CIPIncident[]) =>
        {
          return response.length != 0 ? null : {validateIncidents: true}
        }
      )
    )
  }

  private incidentDetailValidator(): AsyncValidatorFn{
    return (control: AbstractControl): Observable<ValidationErrors | null> =>
    this.incidentStore.validateIncidentInfo(control.value).pipe(
      map((response: CIPIncidentRecord) =>
        {
          return response ? null: {validateIncidentInfo: true}
        }
      ),
      catchError(() => {
        return of({validateIncidentInfo: true});
      })
    )
  }

  onCancel(){
    this.incidentStore.fetchAllIncidents()
    this.dialog.closeAll()
    this.SearchFG.reset()
    this.searchData = {
      cipType: "Incident",
      template: this.searchTemplate,
      id: "",
      idType: "id",
    }
  }

  onChooseColumns(){
    console.log(this.allIncidentTableHeaders)
    console.log(this.displayedIncidentTableHeaders)
    const selectedColumns: TableColumns[] = [...this.allIncidentTableHeaders]
    .filter((column: TableColumns) => {
      console.log(column.show)
      return column.show
    })
    console.log(selectedColumns)
    const dialogRef = this.dialog.open(CipRowFilterComponent, {
      data: {
        allProperties      : this.allIncidentTableHeaders,
        selectedProperties : selectedColumns.map((column: TableColumns) => column.name),
        title: "Available Columns",
        description: "Please select the Columns you would like to display"
      }
    });

    dialogRef.afterClosed().subscribe((result:any) => {
      console.log(result)
      const updatedColumns = [...this.allIncidentTableHeaders].map(
        (column: TableColumns) => {
          result.includes(column.name) ? column = {...column, show: true} : column = {...column, show: false}
          return column
        })

      this.allIncidentTableHeaders = updatedColumns
      this.incidentTable$.next({...this.incidentTable$.getValue(),
      columns: updatedColumns.filter((column: TableColumns) => {return column.show})})
    });
  }

  onClearFilter(){
    // Clear all values selected in date picker
    this.selectedDateRange = null
    this.datePicker.dateRange.controls['startDate'].setValue(null)
    this.datePicker.dateRange.controls['endDate'].setValue(null)
    // Clear any selected customers, and refetch all incidents without filters
    this.customerStore.clearSelectedCustomer()
    this.incidentStore.fetchAllIncidents()
  }

  onDateFilter(event: any){
    console.log(event)
    const dateObject: DateFilterRange = {
      startDate: event.controls['startDate'].value,
      endDate: event.controls['endDate'].value
    }
    const formattedDate = this.helperFunctions.convertToOdataDate(dateObject)
    this.selectedDateRange = formattedDate
    console.log(formattedDate)
    this.getIncidentsByDateRange(formattedDate)
  }

  onRefresh(){
    const customer = this.customerStore.customer.displayName != "" ? this.customerStore.customer : null
    const dateFilter = this.selectedDateRange ? this.selectedDateRange : null
    if(customer !== null && dateFilter !== null){
      console.log("CUSTOMER + DATE FILTER")
      this.incidentStore.fetchCustomerIncidents(customer, this.selectedDateRange)
    }else if(customer && dateFilter == null){
      console.log("CUSTOMER ONLY FILTER")
      this.incidentStore.fetchCustomerIncidents(customer)
    }else if(customer == null && dateFilter){
      console.log("DATE ONLY FILTER")
      const searchString = `CreatedAt ge ${dateFilter.startDate} and CreatedAt le ${dateFilter.endDate}`
      this.incidentStore.fetchAllIncidents(searchString)
    }else{
      console.log("NO FILTER")
      this.incidentStore.fetchAllIncidents()
    }
  }

  onSearch(){
    console.log(this.customerStore.customer)
    this.customerStore.clearSelectedCustomer()
    this.dialog.open(
      CipSearchComponent, {
        data:this.searchData,
        disableClose: true
      });
  }

  onSelect(event: any){
    console.log(event)
    const incident = event as CIPIncident
    this.goToIncident(incident.id)
  }

  onSubmit(){
    const searchType = this.searchData.idType as string
    if(searchType == "id"){
      this.SearchFG.controls['searchId'].value ? this.goToIncident(this.SearchFG.controls['searchId'].value): null
    }

    if(searchType == "internalIncidentId"){
      this.incidentStore.incidents$.subscribe(
        (response: any) => {
        if(response.length == 1 && this.SearchFG.controls['searchId'].value){
          this.goToIncident(response[0].id)
        }
      })
    }
    this.dialog.closeAll()
    this.SearchFG.reset()
  }
}


