<ng-container
	*ngIf="spinnerService.visibility | async; then spinner; else content">
</ng-container>

<ng-template #content>
	<div class="dialog-framework">
		<h1 class="title"> Create Company</h1>
		<mat-divider></mat-divider><br>
		<jafar-dynamic-form class ="form-item" [form]="formData" #companyForm></jafar-dynamic-form>
		<div class = button-group>
      <jafar-action-button [disabled]="!companyForm.dynamicForm.valid" (btnClick)="onSubmit()">Submit</jafar-action-button>
      <jafar-dismiss-button (btnClick)="onCancel()">Cancel</jafar-dismiss-button>
		</div>
	</div>
</ng-template>

<ng-template #spinner>
	<div class="spinner-container">
		<mat-spinner value="Creating Company..." color="accent" class="spinner"></mat-spinner>
		<br>
		<br>
		<h1 class="title">Creating Company ...</h1>
	</div>
</ng-template>
