<button mat-icon-button [matMenuTriggerFor]="beforeMenu">
    <mat-icon>help_outline</mat-icon>
  </button>
  
  <mat-menu #beforeMenu="matMenu" xPosition="before" >
    
    <!-- <button mat-menu-item (click)="openWiki()">
      <mat-icon>book</mat-icon>
      <span>Get Started (Wiki)</span>
    </button>
    
    <mat-divider></mat-divider>

    <button mat-menu-item (click)="openDialog('/assets/mdfiles/solution.md', 'Automation Solution')">
      <mat-icon>precision_manufacturing</mat-icon>
      <span>Automation Solution</span>
    </button>
    
    <mat-divider></mat-divider> -->
   
    <button mat-menu-item (click)="openDialog('/assets/mdfiles/contact.md', 'Contact Support')">
        <mat-icon class="help-menu-icon">contact_mail</mat-icon>
        <span class="help-menu-text">Contact Support</span>
    </button>
   
    <mat-divider></mat-divider>

    <button mat-menu-item routerLink="/feedback">
      <mat-icon class="help-menu-icon">edit_square</mat-icon>
      <span class="help-menu-text">Make a Suggestion</span>
    </button>

    <button mat-menu-item routerLink="/report_bug">
        <mat-icon class="help-menu-icon">assignment_late</mat-icon>
        <span class="help-menu-text">Report an Issue</span>
    </button>

  </mat-menu>
<!-- contact_mail -->