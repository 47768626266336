<mat-card class="questions-card">
    <mat-card-header class="questions-card-header">
        <div class="questions-text">Question & Answers</div>
        <span class="questions-title-space"></span>
        <mat-icon matTooltip="View Json Payload" class="questions-title-icons" (click)="onViewJson()">feed</mat-icon>
    </mat-card-header>

    <mat-card-content class="questions-content">
        <ng-container *ngIf="(noQuestionRecords | async) && (noDisplayQuestionRecords) ; then noItems ; else questions"> </ng-container>
    </mat-card-content>
</mat-card>

<ng-template #questions>
    <ng-container *ngIf="!(noQuestionRecords | async)">
        <h2 class="questiontype-header">Questions</h2>
        <mat-accordion class="questions-accordian">
            <mat-expansion-panel *ngFor="let item of (Questions |async)?.questions">
                <mat-expansion-panel-header class="questions-accordian-header" >
                    <mat-panel-title class="questions-accordion-title">
                        {{item.questionSequence}}. {{item.questionLabel}} :
                    </mat-panel-title>
                    <mat-panel-description class="questions-accordion-description">
                        {{item.answer}}
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="questions-accordian-content">
                    <jafar-dictionary-table [dataSource]="getDictionary(item)"></jafar-dictionary-table>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </ng-container>
    <ng-container *ngIf="!(noDisplayQuestionRecords |async)">
        <h2 class="questiontype-header">Display Questions</h2>
        <mat-accordion class="questions-accordian">
            <mat-expansion-panel *ngFor="let item of (Questions |async)?.displayQuestions">
                <mat-expansion-panel-header class="questions-accordian-header" >
                    <mat-panel-title class="questions-accordion-title">
                        {{item.questionSequence}}. {{item.questionLabel}} :
                    </mat-panel-title>
                    <mat-panel-description class="questions-accordion-description">
                        {{item.answer}}
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="questions-accordian-content">
                    <jafar-dictionary-table [dataSource]="getDictionary(item)"></jafar-dictionary-table>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </ng-container>

</ng-template>


<ng-template #noItems>
    <div class="no-questions"> There are NO Questions & Answers For this Record</div>
</ng-template>
