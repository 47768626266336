import { CommonModule } from '@angular/common';
import { Component, Inject, inject } from '@angular/core';
import { Observable, defaultIfEmpty, map } from 'rxjs';
import { ViewJsonComponent } from '../../../dialogs/view-json/view-json.component';
import { MatDialog } from '@angular/material/dialog';
import { cipInjectionTokens } from 'src/environments/InjectionTokens/cipInjectionTokens';
import { CIPSupportGroup } from 'src/app/shared/datasource/interfaces/CIP/cip-supportgroups.interface';
import { DictionaryTableType } from 'src/app/shared/datasource/interfaces/dictionary-table-type.interface';

@Component({
  selector: 'jafar-support-groups',
  templateUrl: './support-groups.component.html',
  styleUrls: ['./support-groups.component.scss'],
  // imports: [MatExpansionModule, CommonModule, MatIconModule, MatCardModule, CoreModule ],
  // standalone: true
})
export class SupportGroupsComponent  {
  // @Input('SupportGroups') SupportGroups!: Observable<CIPSupportGroup[]>
  constructor( @Inject(cipInjectionTokens.supportGroupsToken) public SupportGroups: Observable<CIPSupportGroup[]>){}
  dialog = inject(MatDialog)

  NoSupportGroupRecords : Observable<boolean> = new Observable<true>

  ngAfterViewInit(){
    this.NoSupportGroupRecords= this.SupportGroups.pipe(
      map(items => items.length == 0 ? true : false),
      defaultIfEmpty(true)
    )
    // this.NoSupportGroupRecords.subscribe(x => console.log(x));

  }

  getDictionary(supportGroup : CIPSupportGroup ){
    let visiblePropeties : string[] =[]
    const Datasource = Object.keys(supportGroup).map(
      (propertyKey : string ) => {
        const indexKey = propertyKey as keyof typeof supportGroup
        const keyValuePair : DictionaryTableType = {
          name: propertyKey,
          value: supportGroup[indexKey]
        }
          visiblePropeties.push(propertyKey)
          return keyValuePair
      }

    )
    return Datasource
  }

  onViewJson (){
    this.dialog.open(ViewJsonComponent, {
      data: this.SupportGroups,
      disableClose: true,
      hasBackdrop: true,
      backdropClass: 'dialog-background'
    } )
    
    // this.SupportGroups.subscribe(
    //   (supportGroups: CIPSupportGroup[]) => {
    //     this.dialog.open(ViewJsonComponent, {data: JSON.stringify(supportGroups)} )
    //   }
    // )
  }

}
