import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { PaginatedTable } from 'src/app/shared/datasource/interfaces/paginated-table.interface';
import { CreateAzureSecretPopup } from '../create-azure-secret-popup/create-azure-secret-popup.component';
import { RefreshAzureSecretPopup } from '../refresh-azure-secret-popup/refresh-azure-secret-popup.component';
import { DeleteAzureSecretPopup } from '../delete-azure-secret-popup/delete-azure-secret-popup.component';
import { SnackbarService } from 'src/app/shared/datasource/services/snackbar/snackbar.service';
import { SnackBarConfig } from 'src/app/shared/ui/snackbar/snackbar.interface';
import { AppSecret } from 'src/app/shared/datasource/interfaces/azure-app-secret.interface';
import { AppSecretService } from 'src/app/shared/datasource/services/http-requests/azure-functions/application-registrations/app-secret.service';
import { DialogInput, DialogOutput } from 'src/app/shared/datasource/interfaces/dialog.interface';
import { DialogService } from 'src/app/shared/datasource/services/dialogs/dialog.service';
import { BehaviorSubject } from 'rxjs';
import { EmptyPaginator } from 'src/app/pages/user-requests/main/user-requests.component';

@Component({
  selector: 'jafar-azure-secrets-page',
  templateUrl: './azure-secrets-page.component.html',
  styleUrls: ['./azure-secrets-page.component.scss']
})

export class AzureSecretsPageComponent implements OnInit {
  disableRefreshButton  : boolean = true
  disableDeleteButton   : boolean = true
  applicationName!      : string
  applicationId!        : string
  userId!               : string;
  appSecrets!           : AppSecret[]
  selectedSecret        : AppSecret | null = null
  tableData             = new BehaviorSubject<PaginatedTable>(EmptyPaginator)

  constructor(
    private router: Router,
    private appSecretService : AppSecretService,
    private snackBar : SnackbarService,
    private dialog : DialogService,

  ){
    console.log(router.getCurrentNavigation())
    const state = this.router.getCurrentNavigation()?.extras.state
		this.applicationName = state?.['applicationName']
    this.applicationId   = state?.['applicationId']
    this.userId          = state?.['userId']
  }

  ngOnInit(): void {
    this.getApplicationSecret()
  }

  getApplicationSecret(){
    const resultSecrets$ = this.appSecretService.getApplicationSecret(this.applicationId)
    resultSecrets$.subscribe(
      secret => {
        this.appSecrets = secret
        let currentDataSource = this.tableData.getValue().tableDataSource
        currentDataSource.data = secret
        const updatedSecrets : PaginatedTable = {
          tableDataSource : currentDataSource,
          columnHeaders: this.getColumnHeaders(),
          showFilter: true
        }
        this.tableData.next(updatedSecrets)
      }
    )
  }

  getColumnHeaders(){
    const columnHeaders =
    [
      {
        columnHeaderIndex: "displayName",
        columnHeaderName: "Display Name"
      },
      {
        columnHeaderIndex: "keyId",
        columnHeaderName: "Secret ID"
      },
      {
        columnHeaderIndex: "hint",
        columnHeaderName: "Secret Hint"
      },
      {
        columnHeaderIndex: "endDateTime",
        columnHeaderName: "Expiry Date"
      },
      {
        columnHeaderIndex: "expiryDays",
        columnHeaderName: "Expiry Days"
      },
      {
        columnHeaderIndex : "expirationStatus",
        columnHeaderName  : "Expiry State",
        icon              : true,
        iconToolTip       : "expirationToolTip",
        iconColor         : "expirationColor"
      },

    ]
    return columnHeaders
  }

  onSelection(selectedSecret : any){
    this.selectedSecret       = selectedSecret
    this.disableRefreshButton = false
    this.disableDeleteButton  = false
  }

  openCreateDialog(): void {
    const createDialog : DialogInput = {
      component :  CreateAzureSecretPopup,
      data: {
        appName : this.applicationName,
        appId   : this.applicationId,
      }
    }
    this.dialog.openDialog(createDialog)

    this.dialog.onClose().subscribe(
      (result : DialogOutput) => {
        this.tokenSnackbar(result.snackbarConfig, "Created")
        this.afterDialogClosed()
      }
    )
  }

  openDeleteDialog(): void {
    const deleteDialog : DialogInput = {
      component :  DeleteAzureSecretPopup,
      data: {
        appName   : this.applicationName,
        appId     : this.applicationId,
        appSecret : this.selectedSecret
      }
    }
    this.dialog.openDialog(deleteDialog)

    this.dialog.onClose().subscribe(
      (result : DialogOutput) => {
        this.defaultSnackbar(result.snackbarConfig)
        this.afterDialogClosed()
      }
    )
  }

  openRefreshDialog() :void {
    const refreshDialog : DialogInput = {
      component :  RefreshAzureSecretPopup,
      data: {
        appName   : this.applicationName,
        appId     : this.applicationId,
        appSecret : this.selectedSecret
      }
    }
    this.dialog.openDialog(refreshDialog)
    this.dialog.onClose().subscribe(
      (result : DialogOutput) => {
        console.log("Closing Refresh")
        this.tokenSnackbar(result.snackbarConfig, "Refreshed")
        this.afterDialogClosed()
      }
    )
  }


  tokenSnackbar(snackbarConfig :SnackBarConfig | undefined, action: string){
    if (snackbarConfig !== undefined){
      this.snackBar.openCustomSnackBar(snackbarConfig, action)
    }
  }

  defaultSnackbar(snackbarConfig :SnackBarConfig | undefined ){
    if (snackbarConfig !== undefined){
      this.snackBar.openSnackBar(snackbarConfig)
    }
  }

  afterDialogClosed(){
    // As delete takes some time we delay a little (0.5seconds) before updating the table
    setTimeout(
      () => { this.getApplicationSecret() }, 500
    );

    this.disableRefreshButton = true
    this.disableDeleteButton = true
    this.selectedSecret = null
  }
}
