import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'jafar-page-widget',
  templateUrl: './page-widget.component.html',
  styleUrls: ['./page-widget.component.scss']
})
export class PageWidgetComponent {
  @Input() cardtitle! : string;
  @Input() subtitle!  : string;

  @Input("contents")
  contentTemplateRef!: TemplateRef<any>;

  @Input("actions")
  actionTemplateRef!: TemplateRef<any>;
  
}
