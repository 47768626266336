import { Injectable, inject } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { CipService } from "../../services/http-requests/azure-functions/cip/cip.service";
import { Customer } from "src/app/pages/manage-azure-app-reg/datastore/customer.interface";

@Injectable({
    providedIn: 'root'
})

export class CipCustomerStore {
  // Inject the service the store is linked too
  private _cipService = inject(CipService)

  // Decalare the Behaviour Subjects to Store the Data we need
  private readonly _customers$ : BehaviorSubject<Customer[]>   = new BehaviorSubject<Customer[]>([EMPTYCUSTOMER]);

  private readonly _selectedCustomer$ : BehaviorSubject<Customer>   = new BehaviorSubject<Customer>(EMPTYCUSTOMER);
  // Methods to populate the Stores

  fetchCustomers(){
    this._cipService.getCIPCustomers()
    .subscribe(response => this._customers$.next(response))
  }

  // Exposed Properties of the Store
  // Observable Property
  get customers$(): Observable<Customer[]> {
    return this._customers$.asObservable();
  }

  get customer(): Customer {
    return this._selectedCustomer$.getValue();
  }

  get customer$(): Observable<Customer> {
    return this._selectedCustomer$.asObservable();
  }
  updateSelectedCustomer(customer : Customer){
    this._selectedCustomer$.next(customer)
  }

  clearSelectedCustomer(){
    this._selectedCustomer$.next(EMPTYCUSTOMER)
  }
}


const EMPTYCUSTOMER : Customer = {
  active: false,
  createdAt: "",
  customerId: "",
  displayName: "",
  name: "",
  sapId: "",
  sapName: ""
}
