import { Component, OnInit } from '@angular/core';
import { CreateCompanyPopupComponent } from '../ui/company/create-company-popup/create-company-popup.component';
import { SnackBarConfig } from 'src/app/shared/ui/snackbar/snackbar.interface';
import { SnackbarService } from 'src/app/shared/datasource/services/snackbar/snackbar.service';
import { CreateCustomerPopupComponent } from '../ui/customer/create-customer-popup/create-customer-popup.component';
import { environment } from 'src/environments/environment';
import { Router, RouterLink } from '@angular/router';
import { CipService } from 'src/app/shared/datasource/services/http-requests/azure-functions/cip/cip.service';
import { DialogInput, DialogOutput } from 'src/app/shared/datasource/interfaces/dialog.interface';
import { DialogService } from 'src/app/shared/datasource/services/dialogs/dialog.service';

@Component({
  selector: 'jafar-manage-cip',
  templateUrl: './manage-cip.component.html',
  styleUrls: ['./manage-cip.component.scss']
})
export class ManageCipComponent  implements OnInit{
  mybreakpoint: number = 8;

  constructor(
    public dialog: DialogService,
    public snackBar: SnackbarService,
    public jeannieService : CipService,
    private router: Router

  ){}

  ngOnInit() {
    this.mybreakpoint = (window.innerWidth <= 1600) ? 1 : 4;
    }

  handleSize(event :any) {
    console.log(event)
    switch (true) {
      case (event.target.innerWidth < 900) : {
        this.mybreakpoint = 1
        break
      }

      case (event.target.innerWidth < 1200) : {
        this.mybreakpoint = 2
        break
      }
      case (event.target.innerWidth < 1600) : {
        this.mybreakpoint = 3
        break
      }

      case (event.target.innerWidth >= 1600) : {
        this.mybreakpoint = 4
        break
      }

      default : {
        this.mybreakpoint = 4
        break
      }

    }
  }


  getCompanies(){
    this.jeannieService.getCIPCompanies().subscribe(
      result => {console.log(result)
      }
    )
  }

  OpenCreateCompanyDialog(){
    const createCompanyDialog : DialogInput = {
      component :  CreateCompanyPopupComponent,
      data: {createData:"No Data"}
    }

    this.dialog.openDialog(createCompanyDialog)

    this.dialog.onClose().subscribe(
      (result : DialogOutput) => {
        result?.snackbarConfig ? this.snackBar.openSnackBar(result.snackbarConfig) : null
      }
    )

  }

  OpenCreateCustomerDialog(){
    const createCustomerDialog : DialogInput = {
      component :  CreateCustomerPopupComponent,
      data: {createData:"No Data"}
    }

    this.dialog.openDialog(createCustomerDialog)

    this.dialog.onClose().subscribe(
      (result : DialogOutput) => {
        result?.snackbarConfig ? this.snackBar.openSnackBar(result.snackbarConfig) : null
      }
    )

  }

  goToCompanies(){
    this.router.navigateByUrl("/managecip/companies")
  }

  goToCustomers(){
    this.router.navigateByUrl("/managecip/customers")
  }

}
