import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'jafar-cip-change-widget',
  templateUrl: './cip-change-widget.component.html',
  styleUrls: ['./cip-change-widget.component.scss']
})
export class CipChangeWidgetComponent {
  router = inject(Router)

  buttonClicked(){
    this.router.navigateByUrl("opsdashboard/changes")
  }
}
