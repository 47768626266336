import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'jafar-azure-custom-group-widget',
  templateUrl: './azure-custom-group-widget.component.html',
  styleUrls: ['./azure-custom-group-widget.component.scss']
})
export class AzureCustomGroupWidgetComponent {
  router = inject(Router)  // instead of using constructor
  viewClicked(){
    this.router.navigateByUrl("manageazure/groups")
  }
  createClicked(){
    console.log("Azure Groups Create Action")
  }

  
  buttonClicked(){
    this.router.navigateByUrl("managecip")
  }
}
