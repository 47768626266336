<mat-card class="env-route-card">
    <mat-card-title class="env-route-card-title">
      <div class="env-route-title-text">Custom Routes</div>
      <ng-container *ngIf="userStore.routingAdmin$ | async">
        <mat-icon class="env-route-title-icons" (click)="onAddEnvRoute()">add</mat-icon>
      </ng-container>
    </mat-card-title>

    <mat-card-content class="env-route-card-content">
      <jafar-reusable-table
        [tableData]="(envRouteStore.customerEnvRoutes$ | async)"
        [tableColumns]="tableHeaders"
        [tableSettings]="tableSettings"
        (selectedRecord)="onRowSelection($event)"
      ></jafar-reusable-table>
    </mat-card-content>

</mat-card>


