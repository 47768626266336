import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator,  } from '@angular/material/paginator';
import { PaginatedTable,  } from '../../datasource/interfaces/paginated-table.interface';
import { environment } from 'src/environments/environment';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'jafar-paginated-table',
  templateUrl: './paginated-table.component.html',
  styleUrls: [ './paginated-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class PaginatedTableComponent implements OnInit,  OnChanges {

  @Input()  public tableInput!: PaginatedTable | null
  @Output() public selectedRecord = new EventEmitter<any[]>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;

  // table data source
  dataSource : MatTableDataSource<any> = new MatTableDataSource()

  public selectedRow       : any
  public columnHeaderIndex : string[] = []
  public pageSize          : number = environment.generic.defaultPageSize
  public pageSizeOptions   : number[] = environment.generic.pageSizeOptions

  ngOnInit(): void {
    console.log(this.tableInput)
    this.columnHeaderIndex = this.getColumnHeaderIndexes()
    this.dataSource.paginator = this.paginator
  }

  ngAfterViewInit(): void {
    if (this.tableInput){
      this.dataSource.paginator = this.paginator
    }
  }

  ngOnChanges(){
    console.log("Paginator Table ngChanges Triggered")
    console.log("Column Headers", this.tableInput?.columnHeaders)
    console.log("Table Data", this.tableInput?.tableDataSource.data)
    this.columnHeaderIndex = this.getColumnHeaderIndexes()

    if (this.tableInput){
      this.dataSource = new MatTableDataSource(this.tableInput.tableDataSource.data)
      this.dataSource.sort = this.sort
      this.dataSource.paginator = this.paginator
      console.log("Table Data : ", this.dataSource)
    }
  }

  getColumnHeaderIndexes() : string[] {
    if (this.tableInput){
      return this.tableInput.columnHeaders.map(
        header => { return header.columnHeaderIndex }
      )
    }
    else {
      return []
    }
  }

  onRowSelection(selectedRow:any){
    console.log(selectedRow)
    this.selectedRow = selectedRow
    this.selectedRecord.emit(selectedRow)
  }

  applyFilter(event: Event) {
    if (this.tableInput){
      console.log(event)
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }

}
