<ng-container *ngIf="tableInput?.showFilter" >
  <mat-form-field class="filter" appearance="outline" color="accent">
    <mat-label>Filter</mat-label>
    <input  matInput   (keyup)="applyFilter($event)" placeholder="filter" #input>
  </mat-form-field>
</ng-container>

<mat-table [dataSource]="dataSource" matSort #table >

  <ng-container
    [matColumnDef]="column.columnHeaderIndex"
    *ngFor="let column of tableInput?.columnHeaders"
  >
  <span>
    <mat-header-cell *matHeaderCellDef mat-sort-header >
      <div class="icon-alignment" *ngIf="column.icon == true else default">
        {{column.columnHeaderName}}
      </div>
      <ng-template #default>
        {{column.columnHeaderName}}
      </ng-template>
    </mat-header-cell>

    <mat-cell *matCellDef="let element; let row"
      [ngClass] = "{'selected-row' : row == selectedRecord}"
    >
    <div class="icon-alignment" *ngIf="column.icon == true && column.iconToolTip && column.iconColor else value">
      <mat-icon matTooltip="{{element[column.iconToolTip]}}" class="{{element[column.iconColor]}}">{{element[column.columnHeaderIndex]}}</mat-icon>
    </div>
    <ng-template #value>
      {{element[column.columnHeaderIndex]}}
    </ng-template>
    </mat-cell>
  </span>
  </ng-container>

  <mat-header-row

    *matHeaderRowDef="columnHeaderIndex"
  ></mat-header-row>

  <mat-row matRipple *matRowDef="let row; columns: columnHeaderIndex;"
    [style.color]= "row === selectedRow ? 'red' : 'pink'"
    [ngClass] = "{'selected-row' : row == selectedRow}"
    (click)="onRowSelection(row)"
  ></mat-row>

</mat-table>

<mat-paginator
  [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions"
></mat-paginator>

