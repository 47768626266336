import { BehaviorSubject } from 'rxjs';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CheckBoxFilterConfig, CheckBoxFilterData, DropDownFilterConfig, FilterOutput } from './reusable-filter.interface';
import { MatSelect } from '@angular/material/select';
import { CustomDropdownComponent } from '../custom-directives/custom-dropdown/custom-dropdown.component';

@Component({
  selector: 'jafar-reusable-filter',
  templateUrl: './reusable-filter.component.html',
  styleUrls: ['./reusable-filter.component.scss']
})
export class ReusableFilterComponent implements OnInit, OnChanges {

  // Input and Output
  @Input() dropDownFilter! : DropDownFilterConfig
  @Input() checkBoxFilter! : CheckBoxFilterConfig
  @ViewChild('checkBoxComponent') checkBoxComponent!: CustomDropdownComponent
  @Output() FilterOutput   = new EventEmitter<any>();
  @Output() ClearFilter    = new EventEmitter<boolean>();

  // Class Variables
  filterVisible: boolean = false
  selectedDropDown!: any
  selectedCheckbox: any[] = []
  appliedFilters: FilterOutput = {
    dropDownFilterOutput: [],
    checkBoxFilterOutput: []
  }

  ngOnInit(): void {
    // this.dropDownFilter = {
    //   enabled : true,
    //   config  :
    //   {
    //     title : 'Personnel Filter',
    //     data  : [
    //       {
    //         name: 'John Kinsington',
    //         title: 'Infrastructure Engineer'
    //       },
    //       {
    //         name: 'Susan Burlsworth',
    //         title: 'Infrastructure Engineer'
    //       },
    //       {
    //         name: 'Macy McCready',
    //         title: 'Infrastructure Engineer'
    //       },
    //       {
    //         name: 'Mike Rinsworth',
    //         title: 'Infrastructure Engineer'
    //       }],
    //     displayProperty: 'name',
    //     type: 'single'
    //   }
    // }

    // this.checkBoxFilter = {
    //   enabled : true,
    //   config  :
    //   {
    //     title : 'Video Games',
    //     data  : ['Call of Duty', 'Satisfactory', 'Dota 2', 'Medal of Honour'],
    //     displayProperty: 'name',
    //     type: "multiple"
    //   }
    // }
    this.checkBoxFilter.config.data = this.constructCheckBoxData(this.checkBoxFilter.config.data)
    console.log(this.checkBoxFilter.config.data)
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes)
  }

  onSelectDropdown(event: any){
    const displayProperty = this.dropDownFilter.config.displayProperty
    this.selectedDropDown = displayProperty ? event[displayProperty] : event
    this.appliedFilters.dropDownFilterOutput = event
    this.FilterOutput.emit(this.appliedFilters)
  }

  toggleOption(option: any) {
    if (this.isSelected(option)) {
      this.appliedFilters.checkBoxFilterOutput = this.appliedFilters.checkBoxFilterOutput.filter((selected) => selected !== option);
      console.log(this.appliedFilters.checkBoxFilterOutput)
    } else {
      this.appliedFilters.checkBoxFilterOutput.push(option);
      console.log(this.appliedFilters.checkBoxFilterOutput)
    }
  }

  isSelected(option: any): boolean {
    return this.appliedFilters.checkBoxFilterOutput.includes(option);
  }

  onClear(){
    this.checkBoxFilter.config.data = this.checkBoxFilter.config.data.map(item => ({ ...item, isSelected: false }));
    this.appliedFilters = {
      dropDownFilterOutput: [],
      checkBoxFilterOutput: []
    }
    this.selectedDropDown = null
    this.ClearFilter.emit(true)
  }

  toggleFilter(){
    this.filterVisible = !this.filterVisible
  }

  emitSelectedFilters(){
    if(this.appliedFilters.checkBoxFilterOutput.length != 0 || this.appliedFilters.dropDownFilterOutput.length != 0){
      this.FilterOutput.emit(this.appliedFilters)
      console.log(this.appliedFilters)
    }
    this.checkBoxComponent.closed.emit()
  }

  constructCheckBoxData(input: any[]): any{
    const dataSet: CheckBoxFilterData[] = input.map(
      (filter: any) => {
        console.log(filter)
        const checkBoxFilterData: CheckBoxFilterData = {
          name: filter,
          isSelected: false
        }
        return checkBoxFilterData
      }
    )
    return dataSet
  }

}
