import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AccordionData } from '../../datasource/interfaces/accordion-interface';

@Component({
  selector: 'jafar-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class AccordionComponent{

  @Input()workItemData!: AccordionData[]

  constructor(
    // public workItemStore: WorkItemStoreService
  ) { }

  ngOnInit(): void {
  }

}
