import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter, Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AccessRequestService } from '../../datasource/services/access-request.service';
import { LoginService } from '../../datasource/services/login.service';
import { MsalBroadcastService } from '@azure/msal-angular';
import { BrowserAuthError, EventMessage, EventType } from '@azure/msal-browser';
import { ThemeService } from '../../datasource/services/theme/theme.service';

@Component({
  selector: 'jafar-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})

export class LoginPageComponent implements OnInit, OnDestroy {
  loginState$ = this.loginService.loginState$
  welcomeMessage = environment.welcomemessage
  logoUrl = environment.generic.logoUrlLight
  feedbackmessage : string = ""
  private readonly _destroying$ = new Subject<string>();

  constructor(
    private loginService: LoginService,
    public accessRequest: AccessRequestService,
    private msalBroadcastService: MsalBroadcastService,
  ) {}

  ngOnInit(): void {
    console.log(this.accessRequest.getFeedbackMessage())
    this.msalBroadcastService.msalSubject$
    .pipe(
      // Optional filtering of events.
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE), 
      takeUntil(this._destroying$)
    )
    .subscribe((result: EventMessage) => {
      if (result.error instanceof BrowserAuthError){
        console.log("This has failed")
        this.accessRequest.setFeedbackMessage("Authorisation Failed: You have not been logged in. ")
      }
    });
  }

  ngOnDestroy() :void {
    this._destroying$.next("");
    this._destroying$.complete();
  }

  homePageLogin(){
     this.loginService.login()
    //  this.appRole.onGetUserAppRoleAssignments()
  }

  homePageLogout(){
    this.loginService.logoff()
  }


}
