import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'jafar-cip-incident-widget',
  templateUrl: './cip-incident-widget.component.html',
  styleUrls: ['./cip-incident-widget.component.scss']
})
export class CipIncidentWidgetComponent {
  router = inject(Router)

  buttonClicked(){
    this.router.navigateByUrl("opsdashboard/incidents")
  }
}
