import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { PaginatedTable } from 'src/app/shared/datasource/interfaces/paginated-table.interface';
import { SnackbarService } from 'src/app/shared/datasource/services/snackbar/snackbar.service';
import { environment } from 'src/environments/environment';
import { CreateCompanyPopupComponent } from '../create-company-popup/create-company-popup.component';
import { SpinnerService } from 'src/app/shared/datasource/services/spinner/spinner.service';
import { CipService } from 'src/app/shared/datasource/services/http-requests/azure-functions/cip/cip.service';
import { DialogInput, DialogOutput } from 'src/app/shared/datasource/interfaces/dialog.interface';
import { DialogService } from 'src/app/shared/datasource/services/dialogs/dialog.service';

@Component({
  selector: 'jafar-manage-cip-company',
  templateUrl: './manage-cip-company.component.html',
  styleUrls: ['./manage-cip-company.component.scss']
})
export class ManageCipCompanyComponent {
  tableData! : PaginatedTable

  constructor(
    private dialog   : DialogService,
    private snackBar : SnackbarService,
    private jeannie  : CipService,
    public spinnerService: SpinnerService
  ){}

  ngOnInit(): void {
    this.tableData = {
      tableDataSource : new MatTableDataSource,
      columnHeaders: [],
      showFilter: true,
    }
    this.getCompanyList()
    this.getColumnHeaders()
  }

  openCreateDialog(){
    const createCompanyDialog : DialogInput = {
      component :  CreateCompanyPopupComponent,
      data: {createData:"No Data"}
    }

    this.dialog.openDialog(createCompanyDialog)

    this.dialog.onClose().subscribe(
      (result : DialogOutput) => {
        result?.snackbarConfig ? this.snackBar.openSnackBar(result.snackbarConfig) : null
      }
    )

  }

  getCompanyList(){
    this.jeannie.getCIPCompanies().subscribe(
      results => {
        console.log(results)
        this.tableData.tableDataSource.data = results
      }
    )
  }

  getColumnHeaders(){

    this.tableData.columnHeaders =
    [
      {
        columnHeaderIndex: "displayName",
        columnHeaderName : "Display Name",
      },
      {
        columnHeaderIndex: "name",
        columnHeaderName : "Name",
      },
      {
        columnHeaderIndex: "companyId",
        columnHeaderName : "Company ID",
      },
      {
        columnHeaderIndex: "companyStatus",
        columnHeaderName : "Status",
        icon             : true,
        iconToolTip      : "companyToolTip",
        iconColor        : "companyIconColour"
      },
    ]

  }
  onSelection(selectedApp : any){}
}
