import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToggleIcons } from '../../datasource/interfaces/toggle-Icon.interface';

@Component({
  selector: 'jafar-toggle',
  templateUrl: './toggle-icon.component.html',
  styleUrls: ['./toggle-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleIconComponent implements OnInit {
  isToggled :boolean = false ;

  @Input() toggleIcons! : ToggleIcons;
  @Output() public toggleStatus = new EventEmitter<boolean>;

  constructor() { }

  ngOnInit(): void {
  }
  
  emitToggleStatus(){
    // toggle has been triggered so invert ToggleStatus then emit the new status
    this.isToggled = !this.isToggled
    this.toggleStatus.emit(this.isToggled)
  }

}
