import { ComponentPortal, Portal } from '@angular/cdk/portal';
import { Component, Input, inject } from '@angular/core';
import { TableSideMenuStore } from './store/table-sidemenu-store.service';

@Component({
  selector: 'jafar-split-panel',
  templateUrl: './split-panel.component.html',
  styleUrls: ['./split-panel.component.scss']
})
export class SplitPanelComponent {
  @Input() Header!      : ComponentPortal<any>;
  @Input() RightPanel!  : ComponentPortal<any>;
  @Input() LeftPanel!   : ComponentPortal<any>;
  @Input() SideMenu!    : ComponentPortal<any>

  tableSubMenuStore = inject(TableSideMenuStore)


  splitPanelHeader!     : Portal<any>
  splitPanelRightPanel! : Portal<any>
  splitPanelLeftPanel!  : Portal<any>
  splitPanelSideMenu!   : Portal<any>


  ngOnInit() {
    this.splitPanelHeader     = this.Header
    this.splitPanelRightPanel = this.RightPanel
    this.splitPanelLeftPanel  = this.LeftPanel
    this.splitPanelSideMenu   = this.SideMenu
  }

  onShowSideMenu(){
    this.tableSubMenuStore.setState(true)
  }
  onBackdropClick(){
    console.log("Backdrop clicked")
    this.tableSubMenuStore.setState(false)
  }
}
