<mat-form-field
  appearance="outline"
  color="accent"
  class="sk-form-element-a"
>
<!-- [ngClass]="{'enabled-border': control.enabled}" -->

  <mat-label>{{label}}</mat-label>
  <input matInput
    [formControl]="control"
    [placeholder]="placeholder"
    [value]=""
    [id]="label"
    (blur)="updateTouched()"
  >
</mat-form-field>
<jafar-form-errors
  [errors]="control.errors"
  [touched]="control.touched"
></jafar-form-errors>
