import { ComponentPortal } from '@angular/cdk/portal';
import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CipRequestTaskStore } from 'src/app/shared/datasource/stores/cip/cip-requestTask-store.service';
import { CipInformationComponent } from 'src/app/shared/ui/templates/cip/cip-information/cip-information.component';
import { CipSpvHeaderComponent } from 'src/app/shared/ui/templates/cip/cip-spv-header/cip-spv-header.component';
import { QuestionsComponent } from 'src/app/shared/ui/templates/cip/questions/questions.component';
import { WorkLogsComponent } from 'src/app/shared/ui/templates/cip/work-logs/work-logs.component';

@Component({
  selector: 'jafar-request-task-spv',
  templateUrl: './request-task-spv.component.html',
  styleUrls: ['./request-task-spv.component.scss']
})
export class RequestTaskSpvComponent {
  HeaderComponent!     : ComponentPortal<CipSpvHeaderComponent>;
  MainGridComponent!   : ComponentPortal<CipInformationComponent>;
  TopGridComponent!    : ComponentPortal<QuestionsComponent>;
  BottomGridComponent! : ComponentPortal<WorkLogsComponent>;

  requestTaskStore = inject(CipRequestTaskStore)
  route = inject(ActivatedRoute)

  ngOnInit() {
    this.route.params.subscribe(
      (result : any) => { this.requestTaskStore.fetchRequestTaskInfo(result.id) }
    )

    const reqTaskHeaderInjector = this.requestTaskStore.requestTaskHeaderInjector
    const reqTaskInjector       = this.requestTaskStore.requestTaskInjector
    const questionsInjector     = this.requestTaskStore.requestTaskQuestionsInjector
    const workLogsInjector      = this.requestTaskStore.requestTaskWorkLogsInjector

    // const supportGroupsInjector = this.cipStore.incidentSupportGroupInjector
    this.HeaderComponent       = new ComponentPortal(CipSpvHeaderComponent    , null, reqTaskHeaderInjector);
    this.MainGridComponent     = new ComponentPortal(CipInformationComponent  , null, reqTaskInjector);
    this.TopGridComponent      = new ComponentPortal(QuestionsComponent       , null, questionsInjector);
    this.BottomGridComponent   = new ComponentPortal(WorkLogsComponent        , null, workLogsInjector);
  }
}
