import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { DictionaryTableType } from '../../datasource/interfaces/dictionary-table-type.interface';

@Component({
  selector: 'jafar-dictionary-table',
  templateUrl: './dictionary-table.component.html',
  styleUrls: ['./dictionary-table.component.scss']
})
export class DictionaryTableComponent implements OnInit, OnChanges{
  displayedColumns: string[] = ['name', 'value'];
  filteredData : DictionaryTableType[] = []
  @Input() dataSource! : DictionaryTableType[]
  @Input() showRows: string[] =[]

  @ViewChild(MatTable) table!: MatTable<DictionaryTableType>;

  ngOnInit(){
    this.filterData()
  }

  ngOnChanges(){
    this.filterData()
  }

  ngAfterViewInit(){
    // this.filterData()
  }

  filterData() {
    if(this.showRows.length > 0) {
      this.filteredData = [...this.dataSource.filter(input => this.showRows.includes(input.name))]
    }
    else {
      this.filteredData = [...this.dataSource]
    }
  }
}
