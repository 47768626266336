import { Injectable }        from "@angular/core";
import { StateStoreService } from "src/app/core/datasource/services/state-store.service";
import { AzureAppStore } from "../interfaces/azure-app-store.interface";
import { AzureApp } from "../interfaces/azure-app.interface";

@Injectable({
    providedIn: 'root'
})
export class AzureAppStoreService extends StateStoreService<AzureAppStore>{

    constructor () {
        super(new AzureAppStore());
    }

    updateUserRoles(updatedUserRoles:string[]): void{
        this.setState({
            ...this.state, 
            userRoles : updatedUserRoles
        })
    }

    updateAllAzureApps(updatedAllAzureApps:AzureApp[]){
        this.setState ({
            ...this.state,
            allAzureApps : updatedAllAzureApps
        })
    }

    updateUserAzureApps(updatedUserAzureApps:AzureApp[]){
        this.setState ({
            ...this.state,
            userAzureApps : updatedUserAzureApps
        })
    }

    updateSelectedApp(updatedSelectedApp:AzureApp){
        this.setState ({
            ...this.state,
            selectedApp : updatedSelectedApp
        })
    }

    reset(){
        this.setState({
            ...this.state,
            userRoles: [],
            allAzureApps: [],
            userAzureApps: [],
        })
    }

}