<div class="dialog-framework">
  <div class="title-framework">
    <span class="title-spacing"></span>
    <title mat-dialog-title class="center-title">
      Refresh Application Secret
    </title>
    <span class="title-spacing"></span>
    <mat-icon class="close-icon-position" matTooltip="Close" (click)="onCancel()">close</mat-icon>
  </div>
    <mat-divider></mat-divider>
    <br>
    <div class="center-text">
      In the process of refreshing the secret, it will create a new secret key<br>
      Please make sure you update the key wherever you reference it. <br>
      <br>
    </div>

    <mat-stepper #stepper
      orientation="vertical"
      [linear]="true"
      animationDuration="800"
      class="stepper"
    >

      <mat-step completed="false" editable="true" label="Expiration" >
        <div class="expiration-group">
          <div class="center-text" >
            Please select secret expiry time.<br>
          </div>

          <div>
            <mat-form-field appearance="outline" class="dropdown" color="accent" >
              <mat-label>Expiry Months</mat-label>
              <mat-select (valueChange)="onExpirySelection($event) " >
                <mat-option value=3>3 Months</mat-option>
                <mat-option value=6 >6 Months</mat-option>
                <mat-option value=12>12 Months</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

        </div>

        <div class="button-group">
          <jafar-action-button [disabled]="nextButtonDisabled" (btnClick)="onNext(stepper)">Next</jafar-action-button>
          <jafar-dismiss-button (btnClick)="onCancel()">Cancel</jafar-dismiss-button>
        </div>


      </mat-step>

      <mat-step completed="false" editable="true" label="Confirmation">
        <br>
        <div>
          <h3>You are about to refresh the following Secret:</h3>
        </div>
        <div>
          Application Name:<br>
          <b>{{ data.appName }}</b><br>
          <br>
          Secret Name:<br>
          <b>{{ data.appSecret.displayName }}</b><br>
          <br>
          Expiry:<br>
          <b> {{ expiryMonths}} Months</b><br>
          <br>
        </div>
        <div>
          <div> Are you sure you want to refresh the above Secret?</div>
          <br>
          <div class="button-group">
            <jafar-action-button (btnClick)="onSubmit()">Submit</jafar-action-button>
            <jafar-dismiss-button (btnClick)="onCancel()">Cancel</jafar-dismiss-button>
          </div>
          <br>
          <div> *Note this will Delete the current Secret and Create a new one with the same name.</div>
        </div>
      </mat-step>

    </mat-stepper>

  </div>



