<mat-card class="card-appearance">
    <mat-card-header class="mat-header">
      <h1>Manage Azure Applications</h1>
      <span class="button-group">
          <button mat-button class="action-button" color="accent" (click)="openCreateDialog()">Create Application</button>
          <mat-divider class="button-divider" [vertical]="true"></mat-divider>
          <button mat-button [disabled]="disableAppSecretButton" color="accent" class="action-button" (click)="openManageSecrets()"> Manage Secret</button>
          <mat-divider class="button-divider" [vertical]="true"></mat-divider>
          <button mat-button [disabled]="disableDeleteButton" color="accent" class="action-button" (click)="openDeleteDialog()"> Delete Application</button>
          <span class="refresh-button-spacing"></span>
          <mat-icon matTooltip="Refresh" (click)="refreshTable()" class="item-alignment">sync</mat-icon>
      </span>
    </mat-card-header>
    <mat-divider class="divider"></mat-divider>
  <ng-container *ngIf="(spinnerService.visibility | async); then spinner; else content"></ng-container>
</mat-card>
<ng-template #spinner>
  <div class="spinner-container">
      <mat-spinner value="Loading Azure Custom Groups..." color="accent" class="spinner"></mat-spinner>
      <br>
      <br>
      <h1 class="spinner-title">Loading Azure Applications...</h1>
  </div>
</ng-template>

<ng-template #content>
  <mat-card class="card-appearance">
    <mat-card-content>
      <jafar-paginated-table [tableInput]="tableData" (selectedRecord)="onSelection($event)"></jafar-paginated-table>
    </mat-card-content>
  </mat-card>
</ng-template>
