<mat-toolbar class="toolbar" color="primary">
  <mat-toolbar-row>
    
    <!--  Menu (burger) Button  -->
    <mat-icon (click)="showSideMenuText()">
      {{toolbarItems.sideMenuIcon.buttonIcon}}
    </mat-icon>
    
    <!--  Toolbar Menu Title  -->
    <span class = "toolbar-text">{{ toolbarItems.title }}</span>
    <span class="toolbar-spacing"></span>
    

    <!-- ToolbarMenu Icons -->
    <!-- 
      The below needs to be created dynamically like the side panel
      this will then cater for any future additions without changing this code
    -->
    <!-- <jafar-toggle 
      class="toolbar-icon-spacing" 
      [toggleIcons]="toolbarItems.themeToggle"      
      (toggleStatus)="updateTheme($event)"
      matTooltip="Change Theme"
    >
    </jafar-toggle> -->

    <mat-divider [vertical]="true" class="toolbar-divider"></mat-divider>
 
    <jafar-help-icon 
      class="toolbar-icon-spacing"
      matTooltip="Help"
    >
    </jafar-help-icon>

    <mat-icon 
      class="toolbar-icon-spacing" 
      matTooltip="Log Off"
      (click)="this.loginService.logoff()"
    >
      logout
    </mat-icon>
  </mat-toolbar-row>
</mat-toolbar>
