import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'jafar-manage-cip-widget',
  templateUrl: './manage-cip-widget.component.html',
  styleUrls: ['./manage-cip-widget.component.scss']
})
export class ManageCipWidgetComponent {
  router = inject(Router)  // instead of using constructor
  
  buttonClicked(){
    this.router.navigateByUrl("managecip")
  }
}
