import { Component } from '@angular/core';

@Component({
  selector: 'jafar-manage-azure',
  templateUrl: './manage-azure.component.html',
  styleUrls: ['./manage-azure.component.scss']
})
export class ManageAzureComponent {

}
