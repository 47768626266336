import { Injectable } from '@angular/core';
import { BehaviorSubject, filter } from 'rxjs';
import { theme } from './theme';

@Injectable({
  providedIn: 'root'
})

export class ThemeService {
  //BehaviorSubject is used to keep the active theme as a state.
  private activeThemeSubject = new BehaviorSubject<any|undefined>(undefined);

  private prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)')
  
  // added in case we want to subscribe to it
  activeTheme$ = this.activeThemeSubject.asObservable();

  //to get the active theme..
  get activeTheme(): string | undefined {
    return this.activeThemeSubject.getValue();
  }

  startupTheme()  {
    const storedTheme = this.loadTheme();
    if (storedTheme && this.themeNames.includes(storedTheme)) {
      console.log("loading" + storedTheme + "theme")
      this.set(storedTheme)
    } 
    else if (this.prefersDarkMode.matches) {
      console.log("setting Dark Theme")
      this.set("dark")
    }
    else {
      console.log("setting Light Theme")
      this.set("light")
    }

    this.updateTheme()

  }

  // takes “theme name” as an argument. Using the themes we defined earlier in theme.ts,
  // it removes then adds CSS classes to the documentElement.
  set(themeName: string): void {
    if (
      this.activeTheme === themeName ||
      !this.themeNames.includes(themeName)
    ) {
      return;
    }

    this.activeThemeSubject.next(themeName);
    console.log(this.activeTheme)
    document.documentElement.classList.remove(...Object.values(theme));
    document.documentElement.classList.add(theme[themeName]);
    this.saveTheme(themeName)
  }

  updateTheme(){
    this.prefersDarkMode.addEventListener('change', (DarkMode) => {
      if (DarkMode.matches){
        console.log("setting theme to Dark Mode")
        this.set('dark')
      }
      else{
        console.log("setting theme to light mode")
        this.set('light')
      }
    })
  }

  saveTheme(themeName : string){
    localStorage.setItem('theme', themeName);
  }

  loadTheme() : string | null{
    const theme = localStorage.getItem('theme')
    return theme
  }

  // List all possible themes
  get themeNames(): string[] {
    return Object.keys(theme);
  }

}
