<div class="widget-framework">
  <jafar-page-widget [contents]="contentTemplate" [actions]="actionTemplate" >
      <div title>Company</div>
      <div subtitle> Manage CIP Companies  </div>
  </jafar-page-widget>
</div>

<ng-template #contentTemplate >
  Create new companies in ITSM<br>
</ng-template>

<ng-template #actionTemplate>
  <button mat-flat-button matTooltip="Manage Companies" (click)="buttonClicked()" class="manage-azure-button">
      Go To Companies
      <mat-icon>shortcut</mat-icon>
  </button>
</ng-template>
