  <a mat-list-item
      (click)="onItemSelected(menuItem)"
      [ngStyle]="{'padding-left': (depth * 13) + 'px'}"
      [ngClass]="{
      'active': menuItem.path ? router.isActive(menuItem.path, true): false, 'expanded': expanded
    }"
  >
    <mat-icon  matListItemIcon class="menu-icon material-symbols-outlined">
      {{menuItem.icon}}
    </mat-icon>
    <span *ngIf="sideMenuService.state.showSideMenuText" class="menu-text">
      {{ menuItem.name }}
    </span>
    <span
      fxFlex
      *ngIf="menuItem.children && menuItem.children.length"
    >
      <span fxFlex></span>
      <mat-icon matListItemIcon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'" class="expand-icon">
        expand_more
      </mat-icon>
    </span>
  </a>
  <div *ngIf="expanded">
    <jafar-side-menu
      *ngFor="let childItem of menuItem.children" [menuItem]="childItem" [depth]="depth+1"
    ></jafar-side-menu>
  </div>
