import { Component, Input, inject } from '@angular/core';
import { SidePanelStoreService } from './side-panel-store.service';
import { ComponentPortal } from '@angular/cdk/portal';

@Component({
  selector: 'jafar-page-with-sidepanel',
  templateUrl: './page-with-sidepanel.component.html',
  styleUrls: ['./page-with-sidepanel.component.scss']
})
export class PageWithSidepanelComponent {
  showContent : boolean  = true
  sidePanelStore = inject(SidePanelStoreService)

  @Input() content!   : ComponentPortal<any>;

}
