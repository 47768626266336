import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { BehaviorSubject, map, Observable, startWith } from 'rxjs';
import { ChipDataLookup } from 'src/app/pages/manage-azure-groups/datastore/create-group.interface';
import { ChipAutoComplete } from '../../datasource/interfaces/chip-autocomplete.interface';
import { ChipAutocompleteComponent } from '../chip-autocomplete/chip-autocomplete.component';

@Component({
  selector: 'jafar-autocomplete-dropdown',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './autocomplete-dropdown.component.html',
  styleUrls: ['./autocomplete-dropdown.component.scss']
})
export class AutocompleteDropdownComponent implements OnInit{
  public inputBox = new FormControl<string | any>('');
  dropDownList: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([])
  globalDisplayData: string[] = [];
  selectedItem!: any | undefined
  displayProperty: string = ''

  @Input()inputData: any = []
  @Input()inputConfig!: ChipAutoComplete
  @Output()newItemEvent = new EventEmitter<any>();

  constructor() {}

  ngOnInit(){
    this.displayProperty = this.inputConfig.displayName
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedItem = event.option.value
    this.inputBox.setValue(event.option.value[this.displayProperty])
    console.log("sending value from selected method")
    console.log(event)
    this.newItemEvent.emit(event.option.value)
  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.inputData.filter(
      (data :any) => data[this.displayProperty].toLowerCase().includes(filterValue)
    );
  }

  displayFn(displayData: any): string {
    return displayData ? displayData : '';
  }

  public reset(){
    this.selectedItem = undefined
    this.inputBox.setValue(null)
    console.log("sending value from reset method")
    this.newItemEvent.emit(this.selectedItem)
  }

  LostFocus() {
    if (this.selectedItem){
      this.inputBox.setValue(this.selectedItem[this.displayProperty])
    }
    else {
      this.inputBox.setValue(null)
    }
  }

  onKeyPress(event : Event){
    const enteredText = (event.target as HTMLInputElement).value;
    const filteredData = enteredText ? this._filter(enteredText) : this.inputData.slice()
    this.dropDownList.next(
      this.sortData(filteredData)
    )
  }

  onFocus(){
    this.dropDownList.next(
      this.sortData(this.inputData)
    )
  }

  sortData<T extends Object[]>(data: T, sortProperty? : keyof T) : T{
    const property = sortProperty ?? this.displayProperty
    const sortedData = data.sort(
      (a:any,b:any) => {
        const valueA = a[property]

        switch (typeof valueA){
          case "number" : {
            const valueB = b[property] as number
            return valueA - valueB
          }

          case "string" : {
            const valueB = b[property] as string
            return  valueA.localeCompare(valueB)
          }

          default :
            return 0
        }

      }
    )
    return sortedData
  }
}
