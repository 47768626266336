import { STEPPER_GLOBAL_OPTIONS, STEP_STATE } from '@angular/cdk/stepper';
import { AfterViewInit, Component, Input, ViewChild, inject } from '@angular/core';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { CIPItemState, CIPProcessState, CIPStageInfo} from 'src/app/shared/datasource/interfaces/CIP/cip-states.interface';
import { HelperFunctionsService } from 'src/app/shared/functions/helperfunctions';
import { CIPStages, ErrorStep } from 'src/assets/config/cip/states';
@Component({
  selector: 'jafar-cip-task-status',
  templateUrl: './cip-task-status.component.html',
  styleUrls: ['./cip-task-status.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {
        displayDefaultIndicatorType: false }
    },
  ],
})
export class CipTaskStatusComponent implements AfterViewInit{
  @Input()itemState! : CIPItemState
  @Input()processState! : CIPProcessState

  @ViewChild("stepper") statusStepper! : MatStepper
  @ViewChild("step1")   statusStep1!   : MatStep
  @ViewChild("step2")   statusStep2!   : MatStep
  @ViewChild("step3")   statusStep3!   : MatStep
  @ViewChild("step4")   statusStep4!   : MatStep
  @ViewChild("step5")   statusStep5!   : MatStep
  helperFunctions = inject(HelperFunctionsService)
  cipStage! : CIPStageInfo

  ngAfterViewInit(){
    this.getCipStage()
    this.setStepperStatus()
  }

  getCipStage(){
    this.itemState    = this.itemState    === undefined || this.itemState    === null ? this.itemState    = "none" : this.itemState
    this.processState = this.processState === undefined || this.processState === null ? this.processState = "none" : this.processState
    this.cipStage  = CIPStages[this.itemState][this.processState] ?? ErrorStep
  }

  setStepperStatus(){
    this.statusStepper.selectedIndex = this.cipStage.stepIndex
    // Linear doesnt seem to set the current step to the correct state

    switch (this.cipStage.stepNumber) {
      // Edge Case - Not Triggered  in CIP Yet
      case 0 : {
        this.statusStepper.selectedIndex = 2

        this.statusStep1.label = ""
        this.statusStep2.label = ""
        this.statusStep3.label = this.cipStage.label
        this.statusStep4.label = ""
        this.statusStep5.label = ""

        this.statusStep1.state = ""
        this.statusStep2.state = ""
        this.statusStep3.state = ""
        this.statusStep4.state = ""
        this.statusStep5.state = ""

        // this is needed as the first step is marked as completed
        this.statusStep1.completed=false

        break;
      }

      // Edge Case - Not Configured in Config File
      case 999 : {
        this.statusStepper.selectedIndex = 4

        this.statusStep1.label = ""
        this.statusStep2.label = ""
        this.statusStep3.label = this.cipStage.label
        this.statusStep4.label = ""
        this.statusStep5.label = ""

        this.statusStep1.state = ""
        this.statusStep2.state = ""
        this.statusStep3.state = STEP_STATE.ERROR
        this.statusStep4.state = ""
        this.statusStep5.state = ""

        this.statusStep1.completed = false
        this.statusStep2.completed = false
        this.statusStep3.completed = false
        this.statusStep4.completed = false

        this.statusStep1.completed = false
        this.statusStep3.errorMessage = `Status with Item State: ${this.helperFunctions.capitalizeFirstLetter(this.itemState)} and Process State: ${this.helperFunctions.capitalizeFirstLetter(this.processState)} has not been configured`
        break;
      }

      // Step 1
      case 1 : {
        this.statusStep1.label = this.cipStage.label
        break;
      }

      // Step 2
      case 2 : {
        this.statusStep1.completed = true
        this.statusStep2.label = this.cipStage.label
        break;
      }

      // Step 3
      case 3 : {
        this.statusStep1.completed = true
        this.statusStep2.completed = true
        this.statusStep3.label = this.cipStage.label
        break;
      }

      // Step 4
      case 4 : {
        this.statusStep1.completed = true
        this.statusStep2.completed = true
        this.statusStep3.completed = true
        this.statusStep4.label = this.cipStage.label
        break;
      }

      // Step 5
      case 5 : {
        this.statusStep1.completed = true
        this.statusStep2.completed = true
        this.statusStep3.completed = true
        this.statusStep4.completed = true
        this.statusStep4.label = `Step 4 - ${this.helperFunctions.capitalizeFirstLetter(this.processState)}`
        this.statusStep5.label = this.cipStage.label
        this.statusStep5.state = STEP_STATE.DONE
        break;
      }

    }
  }

}


