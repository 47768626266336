<mat-card class="env-route-default-card">
  <mat-card-title class="env-route-default-card-title">
    <div class="env-route-default-title-text">Default Routes</div>
    <ng-container *ngIf="userStore.routingAdmin$ | async">
      <mat-icon class="env-route-default-title-icons"
        *ngIf="hasNoDefaultRoute"
        (click)="onAddEnvRouteDefault()"
      > add </mat-icon>
    </ng-container>

  </mat-card-title>

  <mat-card-content class="env-route-default-card-content">
    <jafar-reusable-table
    [tableData]="(envRouteDefaultStore.customerDefaultEnvRoutes$ | async)"
    [tableColumns]="tableHeaders"
    [tableSettings]="tableSettings"
    (selectedRecord)="onRowSelection($event)"
  ></jafar-reusable-table>
  </mat-card-content>

</mat-card>

