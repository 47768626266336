import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'jafar-manage-azure-widget',
  templateUrl: './manage-azure-widget.component.html',
  styleUrls: ['./manage-azure-widget.component.scss'],
})
export class ManageAzureWidgetComponent {
  router = inject(Router)  // instead of using constructor
  
  buttonClicked(){
    this.router.navigateByUrl("manageazure")
  }
}
