<mat-form-field
  appearance="outline"
  color="accent"
  class="sk-form-element-a"
>
  <mat-label>{{label}}</mat-label>
  <textarea matInput
    class="fixed"
    [rows]="rows"
    [cols]="cols"
    [formControl]="control"
    [placeholder]="placeholder"
    [value]=""
    [id]="label"
    (blur)="updateTouched()"
  ></textarea>
</mat-form-field>
<jafar-form-errors
  [errors]="control.errors"
  [touched]="control.touched"
></jafar-form-errors>
