import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { idType, CIPRecord } from 'src/app/shared/datasource/interfaces/CIP/cip-general.interface';
import { TableColumnHeader } from 'src/app/shared/datasource/interfaces/expandable-table-interface';
import { DynamicTable } from 'src/app/shared/datasource/interfaces/templates/dynamic-table.interface';
import { CipSearchComponent } from 'src/app/shared/ui/dialogs/cip-search/cip-search.component';
import changeHeadersJson  from '../../../../assets/config/tableHeaders/change-headers.json'
import { CipChangeStore } from 'src/app/shared/datasource/stores/cip/cip-change-store.service';
import { FormGroup, FormControl, Validators, AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable, catchError, map, of } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { CIPChange } from 'src/app/shared/datasource/interfaces/CIP/cip-changes.interface';
import { CipSearchData } from 'src/app/shared/datasource/interfaces/CIP/cip-page-layout.interface';
import { ReusableTable, TableColumns } from 'src/app/shared/ui/reusable-table/interface/reusable-table.interface';
import { PaginationDefaults } from 'src/environments/generic-variables';

@Component({
  selector: 'jafar-changes',
  templateUrl: './changes.component.html',
  styleUrls: ['./changes.component.scss']
})
export class ChangesComponent implements OnInit, AfterViewInit{
  changeStore  = inject(CipChangeStore)
  changeTableHeaders: TableColumns[] = changeHeadersJson as TableColumns[]
  changeTable!: ReusableTable
  dialog = inject(MatDialog)
  router = inject(Router)

  public changeFilters: idType[] = ['id', 'internalChangeId']
  public searchData!: CipSearchData
  public SearchFG = new FormGroup (
    {
      searchId         : new FormControl('', Validators.required),
    }
  )

  @ViewChild("search") searchTemplate!: TemplateRef<any>

  ngOnInit(){
    this.changeStore.fetchChanges().subscribe((response: CIPChange[]) =>{
      this.changeStore.updateAllChanges(response)
    })

    this.changeTable = {
      data     : this.changeStore.changes$,
      columns  : this.changeTableHeaders,
      settings : {
        pagination: true,
        paginationOptions: PaginationDefaults,
        sorting: {
          sortedColumn: 'createdAt',
          sortedOrder: 'desc'
        },
        search: false,
        reorderColumns: true,
        hidePageSize: false
      }
    }
  }

  ngAfterViewInit() {
    this.searchData = {
      cipType: "Change",
      template: this.searchTemplate,
      id: "",
      idType: 'id',
    }
    this.SearchFG.controls['searchId'].addAsyncValidators(this.changeDetailValidator())
    this.SearchFG.controls['searchId'].updateValueAndValidity()
  }

  onSearch(){
    this.dialog.open(
      CipSearchComponent, {
        data:this.searchData,
        disableClose: true
      });
  }

  goToChange(cipId:string){
    this.router.navigate(["opsdashboard/change",cipId])
  }

  onSelect(event: any){
    console.log(event)
    const change = event as CIPChange
    this.goToChange(change.id)
  }

  refreshTable(){
    this.changeStore.fetchChanges()
  }

  selectFilter(event: idType){
    this.SearchFG.controls['searchId'].clearAsyncValidators()
    this.SearchFG.controls['searchId'].updateValueAndValidity()
    this.searchData.idType = event
  }

  getChangeInfo(){

    // Clear Async Validators
    switch(this.searchData.idType)
    {
      case "id":
        // Add Async Validators back
        this.SearchFG.controls['searchId'].addAsyncValidators(this.changeDetailValidator())
        // Initialize Validators
        this.SearchFG.controls['searchId'].updateValueAndValidity()
        if(this.SearchFG.controls.searchId.value){
          (this.changeStore.fetchChangeInfo(this.SearchFG.controls.searchId.value as string)).subscribe(
            (response: any) => {
              this.changeStore.updateChangeInfo(response)
            }
          )
        }
        break;

      case "internalChangeId":
        this.SearchFG.controls['searchId'].addAsyncValidators(this.changeValidator())
        this.SearchFG.controls['searchId'].updateValueAndValidity()
        const searchString = `internalChangeId eq '${this.SearchFG.controls['searchId'].value}'`
        this.changeStore.fetchChanges(searchString).subscribe(
          (response: CIPChange[]) => {
            console.log(response)
            this.changeStore.updateAllChanges(response)
          }
        )
        break;

      default:
        console.log("ERROR: Invalid Search Type")
        break
    }
  }

  private changeValidator(): AsyncValidatorFn{
    return (control: AbstractControl): Observable<ValidationErrors | null> =>
    this.changeStore.fetchChanges(`internalIncidentId eq '${control.value}'`).pipe(
      map((response: CIPChange[]) =>
        {
          console.log(response.length)
          return response.length != 0 ? null : {validateChanges: true}
        }
      )
    )
  }

  private changeDetailValidator(): AsyncValidatorFn{
    return (control: AbstractControl): Observable<ValidationErrors | null> =>
    this.changeStore.fetchChangeInfo(control.value).pipe(
      map((response: HttpResponse<CIPRecord>) =>
        {
          console.log(response)
          return response ? null: {validateIncidentInfo: true}
        }
      ),
      catchError(() => {
        return of({validateIncidentInfo: true});
      })
    )
  }

  onSubmit(){
    const searchType = this.searchData.idType as string
    if(searchType == "id"){
      this.SearchFG.controls['searchId'].value ? this.goToChange(this.SearchFG.controls['searchId'].value): null
    }
    this.dialog.closeAll()
    this.SearchFG.reset()
  }

  onCancel(){
    this.changeStore.fetchChanges().pipe(map((response: CIPChange[]) =>{
      this.changeStore.updateAllChanges(response)
    }))
    this.dialog.closeAll()
    this.SearchFG.reset()
  }
}
