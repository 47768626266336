import { Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult} from '@azure/msal-browser';
import { Router} from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { UserService } from './user/user.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  loginState$ = new BehaviorSubject(false);

  constructor(
    private loginService: MsalService,
    private router: Router,
    private msalBroadcastService : MsalBroadcastService,
    private userService : UserService
  ) {}

  ngOnInit(){
  }

  // Method to log user on - popup
  login(){
    // We need to subscribe to the result of the login
    // When the user logs in we set the active account to the response of the subscribe
    this.loginState$.next(true)

    this.loginService.loginPopup().subscribe(
      {
        next:(response: AuthenticationResult) => {
          this.loginService.instance.setActiveAccount(response.account)
          this.router.navigate(['/home'])
        },

        error: (error) => {
          console.log(error)
        },

        complete: () => {
          this.userService.getLoggedInUser()
        }

      }
    );




  }

  // Method to log user off - popup
  logoff(){
    this.loginState$.next(false)
    this.loginService.logoutPopup();
    this.router.navigate(['/login'])
  }

  // Method to check if user is logged in
  isLoggedIn() : boolean {
      // We simple check the service if the account is active
      return this.loginService.instance.getActiveAccount() != null
  }
}
