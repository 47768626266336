import { Component, OnInit} from '@angular/core';
import { PortalFrameworkComponent } from 'src/app/core/feature/portal-framework/portal-framework.component';
import { WikiService } from 'src/app/shared/datasource/services/http-requests/azure-devops/wiki/wiki.service';

@Component({
  selector: 'jafar-wikipage',
  templateUrl: './wikipage.component.html',
  styleUrls: ['./wikipage.component.scss']
})

export class WikiPageComponent implements OnInit {

  constructor(
    public framework : PortalFrameworkComponent,
    public wikiService: WikiService) { }

  ngOnInit(){
  }
}