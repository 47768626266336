<div class="widget-framework">
    <jafar-page-widget [contents]="contentTemplate" [actions]="actionTemplate" >
        <div title>Azure</div>
        <div subtitle> Manage Azure Elements  </div>
    </jafar-page-widget>
</div>

<ng-template #contentTemplate >
    This is where you can manage Azure<br>

</ng-template>

<ng-template #actionTemplate>
    <button mat-flat-button matTooltip="Manage Azure" (click)="buttonClicked()" class="manage-azure-button">
        Go To Manage Azure
        <mat-icon>shortcut</mat-icon>
    </button>
</ng-template>
