<div class="widget-framework">
  <jafar-page-widget [contents]="contentTemplate" [actions]="actionTemplate" >
      <div title>Incidents</div>
      <div subtitle> View List of CIP Incidents  </div>
  </jafar-page-widget>
</div>

<ng-template #contentTemplate >
  View a List of CIP Incidents.<br> You also have the ability to Search for a Incidents by: <br> <b>CIP Id</b> or <b>Incident Id</b><br>
</ng-template>

<ng-template #actionTemplate>
  <button mat-flat-button matTooltip="Manage Customers" (click)="buttonClicked()" class="manage-azure-button">
      Go To Incidents
      <mat-icon>shortcut</mat-icon>
  </button>
</ng-template>
