import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { UserProfile } from 'src/app/shared/datasource/interfaces/user-profile';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

// @Injectable({
//   providedIn: 'root'
// })
export class UserService {
  private httpClient = inject(HttpClient)
  //BehaviorSubject is used to keep the active user Profile as a state.
  private activeUserSubject = new BehaviorSubject<UserProfile>({
    displayName: "",
    givenName  : "",
    surname    : "",
    mail       : "",
    id         : ""
  });

  // to subscribe to the active user
  activeUser$ = this.activeUserSubject.asObservable()

  // to get the current active user..
  get activeUser() {
    return this.activeUserSubject.getValue()
  }

  // Calls the Graph API to get the User Profile Detaisl (called from login Service),
  getLoggedInUser() : void {
    this.httpClient
      .get<UserProfile>(`${environment.generic.graphApiUrl}/me`)
      .subscribe((user) => {
        this.activeUserSubject.next(user)
      })
  }

  


  // saveUser(userProfile : UserProfile){
  //   let user : string = JSON.stringify(userProfile)
  //   localStorage.setItem('user', user);
  // }

  // loadUser() : UserProfile | null{
  //   const user = localStorage.getItem('user')
  //   let userProfile : UserProfile = user ? JSON.parse(user) : null
  //   return userProfile
  // }

}
