<div class ="bug-framework">
  <mat-card appearance="outlined" id="card" class="card-apperance">
    <mat-card-title class="headline-h1"> Report an Issue </mat-card-title>
      <mat-card-content>
        <mat-divider></mat-divider>
        <div class="body-spacing">
          <h4>If you encountered any issues, please let us know by clicking on the button below:</h4>
        </div>
        <jafar-action-button (btnClick)="openDialog()">Raise an Issue</jafar-action-button>
      </mat-card-content>
    </mat-card>

    <mat-card appearance="outlined" id="card" class="view-bug">
      <ng-container *ngIf="(spinnerService.visibility | async); then spinner; else content"></ng-container>
      <ng-template #spinner>
        <div class="spinner-container">
            <mat-spinner value="Loading Bugs..." color="accent" class="spinner"></mat-spinner>
            <br>
            <br>
            <h1 class="spinner-title">Loading Bugs...</h1>
        </div>
      </ng-template>
      <ng-template #content>
        <mat-card-title class="headline-h1">
          Reported Issues
          <span class="headline-span"></span>
          <jafar-reusable-filter
            [dropDownFilter]="requestedByFilterConfig"
            [checkBoxFilter]="bugStatusFilterConfig"
            (FilterOutput)="getFilteredBugs($event)"
            (ClearFilter)="onClear()">
          </jafar-reusable-filter>
          <mat-icon matTooltip="Refresh"  (click)="refreshBug()" class="item-alignment">sync</mat-icon>
        </mat-card-title>
        <mat-card-content>
          <mat-divider></mat-divider> <br>
          <jafar-expandable-table
            [tableData]="filteredBugs$ | async"
            [tableHeaders]="issuesTableHeaders"
          ></jafar-expandable-table>
        </mat-card-content>
      </ng-template>
    </mat-card>
</div>
