<mat-table [dataSource]="dataSource" multiTemplateDataRows matSort class="mat-elevation-z8" #table>

  <ng-container [matColumnDef]="column.columnHeaderIndex" *ngFor="let column of tableHeaders">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      <div class="icon-alignment" *ngIf="column.icon == true else default">
        {{column.columnHeaderName}}
      </div>
      <ng-template #default>
        {{column.columnHeaderName}}
      </ng-template>
    </mat-header-cell>

    <mat-cell *matCellDef="let data">
      <div *ngIf="column.icon == true && column.iconToolTip && column.iconColor else value">
        <mat-icon matTooltip="{{data[column.iconToolTip]}}" class="material-symbols-outlined">
          {{data[column.columnHeaderIndex]}}
        </mat-icon>
      </div>
      <ng-template #value>
        {{data[column.columnHeaderIndex]}}
      </ng-template>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="expand">
    <mat-header-cell *matHeaderCellDef aria-label="row actions" mat-sort-header>&nbsp;</mat-header-cell>
    <mat-cell *matCellDef="let data">
      <button mat-icon-button aria-label="expand row" (click)="(expandedData = expandedData === data ? null : data); $event.stopPropagation()">
        <mat-icon *ngIf="expandedData !== data">keyboard_arrow_down</mat-icon>
        <mat-icon *ngIf="expandedData === data">keyboard_arrow_up</mat-icon>
      </button>
    </mat-cell>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <mat-cell *matCellDef="let data" [attr.colspan]="columnsToDisplayWithExpand.length">
      <div class="data-detail"
           [@detailExpand]="data == expandedData ? 'expanded' : 'collapsed'">
        <div class="expanded-diagram">
          <mat-icon matTooltip="{{data[expandedIconDetail.iconToolTip]}}" class="icon-fill material-symbols-outlined">{{data[expandedIconDetail.columnHeaderIndex]}}</mat-icon>
        </div>
        <div class="expanded-data-description">
          <span class="expanded-data-description-attribution"> {{expandedColumn.columnHeaderName}}: </span>
          <br>
          {{data[expandedColumn.columnHeaderIndex]}}

        </div>
      </div>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></mat-header-row>
  <mat-row matRipple *matRowDef="let row; columns: columnsToDisplayWithExpand;"
      class="data-row"
      [class.example-expanded-row]="expandedData === row"
      (click)="expandedData = expandedData === row ? null : row">
  </mat-row>
  <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="data-detail-row"></mat-row>
</mat-table>

<mat-paginator
  [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions"
></mat-paginator>
