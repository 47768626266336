<div class="popup-framework">
  <div class="popup-title">
    <span class="title-spacing"></span>
    <h2 class="heading">Editing: {{data.groupName}}</h2>
    <span class="title-spacing"></span>
    <mat-icon class="close-icon-position" matTooltip="Close" (click)="onClose()">close</mat-icon>
  </div>
  <mat-vertical-stepper #stepper [linear]="true" labelPosition="bottom" animationDuration="800" class="stepper">

    <!-- Step 1 - Group Description Information -->
    <mat-step  *ngIf="data.isOwner">
      <ng-template matStepLabel >
        Make Changes to Group
      </ng-template>
      <ng-template matStepContent  >
        <h4 class="form-description">
          Please fill in the form below to request access for an Account
        </h4>
        <jafar-dynamic-form class ="form-item" [form]="editGroupData" #editGroupForm></jafar-dynamic-form>
        <jafar-action-button [disabled]="!editGroupForm.dynamicForm.valid" (btnClick)="goToNextStep(stepper, 1)">Next</jafar-action-button>
        <jafar-dismiss-button (btnClick)="reset(stepper)">Reset</jafar-dismiss-button>
      </ng-template>
    </mat-step>

    <!-- Step 2 - Group Members (users & apps) -->
    <mat-step completed="false">
      <ng-template matStepLabel>
        Change Group Memberships
      </ng-template>
      <ng-template matStepContent>
        <div class="form-style">
          <span>Please Select all Users you want to Add</span>
          <jafar-chip-autocomplete #usersDropdown	class="user-width"
            [inputData]="allUsers"
            [defaultData]="defaultUsers"
            [inputConfig]="userChipConfig"
            (newItemEvent)="updatedGroupsUserList($event)"
          ></jafar-chip-autocomplete>
        </div>

        <jafar-action-button [disabled]="!membersSelectedButton" (btnClick)="goToNextStep(stepper, 2)">Next</jafar-action-button>
        <jafar-dismiss-button (btnClick)="reset(stepper)">Reset</jafar-dismiss-button>
      </ng-template>
    </mat-step>

    <!-- Step 3 -->
    <mat-step completed="false" *ngIf="data.isOwner">
      <ng-template matStepLabel>
        Manage Owners
      </ng-template>
      <ng-template matStepContent>
        <div class="form-style">
          <span>Please Select all Users you want to Add</span>
          <jafar-chip-autocomplete #ownersDropdown class="app-width"
            [inputData]="allUsers"
            [defaultData]="defaultOwners"
            [inputConfig]="ownerChipConfig"
            (newItemEvent)="updateGroupOwners($event)"
          ></jafar-chip-autocomplete>
        </div>
        <jafar-action-button [disabled]="!ownerSelectedButton" (btnClick)="goToNextStep(stepper, 3)">Next</jafar-action-button>
        <jafar-dismiss-button (btnClick)="reset(stepper)">Reset</jafar-dismiss-button>
      </ng-template>
    </mat-step>

    <!-- Step 4-->
    <mat-step completed="false">
      <ng-template matStepLabel>
        Grant Access to Group
      </ng-template>
      <ng-template matStepContent>
        <div class="form-style">
          <span>Please Select all access this group requires</span>
          <jafar-chip-autocomplete #appsDropdown class="app-width"
            [inputData]="azureDefaultPayload"
            [defaultData]="azureCurrentPayload"
            [inputConfig]="appChipConfig"
            (newItemEvent)="updateGroupsAppRegistrations($event)"
          ></jafar-chip-autocomplete>
        </div>
        <jafar-action-button [disabled]="!appSelectedButton" (btnClick)="goToNextStep(stepper, 4)">Next</jafar-action-button>
        <jafar-dismiss-button (btnClick)="reset(stepper)">Reset</jafar-dismiss-button>
      </ng-template>
    </mat-step>

    <!-- Step 5 -->
    <mat-step completed="false">
      <ng-template matStepLabel>
        Confirmation
      </ng-template>
    <ng-template matStepContent>
        <br>
        <mat-card appearance="raised" class="card-style">
            <mat-card-title class="card-headline" >
              <div> Please Confirm Your Changes </div>
            </mat-card-title>
            <mat-card-content>
                <mat-divider></mat-divider>
                <br>
                <h4> <b> You are about to make the following changes: </b> </h4>
                <div>
                  Group Name: <b>{{newGroupInformation.groupName}}</b><br>
                  Description: <b>{{newGroupInformation.description}}</b><br>
                  Contact Mail: <b>{{newGroupInformation.contactMail}}</b><br>
                  ITSM Support Group: <b>{{newGroupInformation.itsmSupportGroup}}</b><br>
                  Users:
                  <div *ngFor="let user of newGroupInformation.members">
                    <b>{{user.displayName}}</b><br>
                  </div>
                  AppRegistrations:
                  <div *ngFor="let app of newGroupInformation.apps">
                    <b>{{app.displayName}}</b><br>
                  </div>
                  Owners:
                  <div *ngFor="let owner of newGroupInformation.owners">
                    <b>{{owner.displayName}}</b><br>
                  </div>
                </div>
            </mat-card-content>
            <mat-card-actions class="body-spacing">
              <jafar-action-button [disabled]="!appSelectedButton" (btnClick)="goToNextStep(stepper, 5)">Submit</jafar-action-button>
              <jafar-dismiss-button (btnClick)="reset(stepper)">Reset</jafar-dismiss-button>
              <jafar-dismiss-button (btnClick)="onCancel()">Cancel</jafar-dismiss-button>
              <div class="button-spacing"></div>
            </mat-card-actions>
        </mat-card>
    </ng-template>
  </mat-step>

  </mat-vertical-stepper>
</div>
