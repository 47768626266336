import { HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable, catchError, throwError } from "rxjs";
import { GenericApiService } from "../../generic-api/generic-api.service";
import { QueryParams } from "../../../../interfaces/generic-api.interface";
import { CIPSettings } from "src/environments/environment";
import { CIPEnvRoute, CIPEnvRouteCreateRequest, CIPEnvRouteUpdateRequest } from "src/app/shared/datasource/interfaces/CIP/cip-envRoute.interface";

@Injectable({
  providedIn: 'root'
})
export class CIPEnvRouteService  extends GenericApiService{

  httpClient = inject(HttpClient)

  private getDefaultHeaders() : HttpHeaders{
    const headers = new HttpHeaders()
    .set('Accept', '*/*')
    .set('Content-Type', 'application/json')

    return headers
  }

  getEnvRoutes() : Observable<CIPEnvRoute[]> {
    const headers = this.getDefaultHeaders()
    const url     = `${CIPSettings.baseUrl}/${CIPSettings.endPoints["envRoute"]}`
    let query: QueryParams = {
      code    : `${CIPSettings.code}`
    }
    return this.get<CIPEnvRoute[]>(url, headers, query).pipe(
      catchError(this.errorHandler)
    )
  }

  createEnvRoute(body: CIPEnvRouteCreateRequest) : Observable<CIPEnvRoute>{
    const headers = this.getDefaultHeaders()
    const url     = `${CIPSettings.baseUrl}/${CIPSettings.endPoints["envRoute"]}`
    let query: QueryParams = {
      code    : `${CIPSettings.code}`
    }
    let bodyString = JSON.stringify(body)
    return this.post<CIPEnvRoute>(url, bodyString, headers, query)
    .pipe(
      catchError(
        (error:HttpErrorResponse) => (this.errorHandler(error))
      )  
    )
  }

  updateEnvRoute(routeId: string, body: CIPEnvRouteUpdateRequest, ) : Observable<CIPEnvRoute>{
    const headers = this.getDefaultHeaders()
    const url     = `${CIPSettings.baseUrl}/${CIPSettings.endPoints["envRoute"]}`
    let query: QueryParams = {
      code    : `${CIPSettings.code}`,
      id      : routeId
    }
    let bodyString = JSON.stringify(body)
    return this.put<CIPEnvRoute>(url, bodyString, headers, query).pipe(
      catchError(this.errorHandler)
    )
  }

  deleteEnvRoute(routeId: string){
    const headers = this.getDefaultHeaders()
    const url     = `${CIPSettings.baseUrl}/${CIPSettings.endPoints["envRoute"]}`
    let query: QueryParams = {
      code    : `${CIPSettings.code}`,
      id      : routeId
    }
    return this.delete(url, headers, query).pipe(
      catchError(this.errorHandler)
    )
  }

  private errorHandler(errorResponse: HttpErrorResponse) {
    console.log(errorResponse)
    let errorMessage = ""

    if (errorResponse.error.error.message) {
      errorMessage = errorResponse.error.error.message
    }
    else if (errorResponse.message){
      errorMessage = errorResponse.message
    }
    else{
      errorMessage= "Unknown Error Occured"
    }
    // if we want the url maybe? Url:\n${errorResponse.url?.split("?")[0]}\n
    let FullerrorMessage = `Error Code:\n${errorResponse.status} - ${errorResponse.statusText}\n\nMessage:\n${errorMessage}`;

    alert(FullerrorMessage);

    return throwError(() => errorResponse);
  }
}
