<div class="dialog-framework">
  <div class="title-framework">
    <span class="title-spacing"></span>
    <title mat-dialog-title class="center-title">
      Delete Application Secret
    </title>
    <span class="title-spacing"></span>
    <mat-icon class="close-icon-position" matTooltip="Close" (click)="onCancel()">close</mat-icon>
  </div>

  <mat-dialog-content #delete>
    <mat-divider></mat-divider>
    <br>
    <div class="confirmation-group">
      <span class="center-text">You are about to DELETE the following Application Secret:</span>
      <br>
      <span class="center-text"><b>{{data.appSecret.displayName}}</b></span>
    </div>
    <br>
    <div class="validation-group">
      <span> To Confirm Deletion, please enter the name of the Secret:</span>
      <br>
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Application Secret Name</mat-label>
        <input matInput type="text"
          [(ngModel)]="typedAppName"
          (keyup)="validateName($event)"
          onpaste="return false;"
          ondrop="return false;"
          autocomplete="off"
        >
      </mat-form-field>
    </div>


    <span class="button-group">
      <jafar-action-button [disabled]="submitButtonDisabled" (btnClick)="onSubmit()">Confirm</jafar-action-button>
      <jafar-dismiss-button (btnClick)="onCancel()">Cancel</jafar-dismiss-button>
      <!-- <button mat-raised-button class="next-button" color="primary"
        [disabled]="submitButtonDisabled"
        (click)="onSubmit()"
      > Confirm </button>

      <button mat-stroked-button class="reset-button"
        (click)="onCancel()"
      > Cancel </button> -->

    </span>
  </mat-dialog-content>
</div>
