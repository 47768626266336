import { Component, Inject, OnChanges, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, defaultIfEmpty, map, of } from 'rxjs';
import { cipInjectionTokens } from 'src/environments/InjectionTokens/cipInjectionTokens';

@Component({
  selector: 'jafar-cip-spv-header',
  templateUrl: './cip-spv-header.component.html',
  styleUrls: ['./cip-spv-header.component.scss']
})
export class CipSpvHeaderComponent implements OnInit{
  constructor(
    @Inject(cipInjectionTokens.cipInformationToken) public cipHeader: Observable<any>
  ){}

  showHeader : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  ngOnInit(){
    console.log("Show Header", this.showHeader)
    this.cipHeader.subscribe(
      (items) => {
        console.log("Show Header", this.showHeader)

        console.log("Header Items", items )
        if (items) {
          items.length == 0 ? this.showHeader.next(false) : this.showHeader.next(true)
        }
      }
    )
  }

  ngOnChanges(){
    console.log("Show Header", this.showHeader)
  }
  //   this.NoHeader = this.cipHeader.pipe(
  //     map(items => items.length == 0 ? true : false),
  //     defaultIfEmpty(true)
  //   )
  //   this.NoHeader.subscribe(x => console.log(x));
  // }
}

