<div class="suggestion-framework">
  <mat-card  id="card" class="card-apperance" class="card-apperance">
    <mat-card-title class="headline-h1"> Make a Suggestion </mat-card-title>
    <mat-card-content>
      <mat-divider></mat-divider>
      <div class="body-spacing">
        <h4>If you have any ideas on how we can improve, please provide your suggestion by clicking the button below</h4>
      </div>
      <jafar-action-button (btnClick)="openDialog()">Make a Suggestion</jafar-action-button>
    </mat-card-content>
  </mat-card>
  <mat-card appearance="outlined" id="card" class="list-suggestion">
    <ng-container *ngIf="(spinnerService.visibility | async); then spinner; else content"></ng-container>

    <ng-template #spinner>
      <div class="spinner-container">
          <mat-spinner value="Loading Suggestions..." color="accent" class="spinner"></mat-spinner>
          <br>
          <br>
          <h1 class="spinner-title">Loading Suggestions...</h1>
      </div>
    </ng-template>
    <ng-template #content>
      <mat-card-title class="headline-h1">
        List of Suggestions
        <span class="headline-span"></span>
        <jafar-reusable-filter
          [dropDownFilter]="requestedByFilterConfig"
          [checkBoxFilter]="requestStatusFilterConfig"
          (FilterOutput)="getFilteredSuggestions($event)"
          (ClearFilter)="onClear()">
        </jafar-reusable-filter>
        <mat-icon matTooltip="Refresh" (click)="refreshFeedback()" class="item-alignment">sync</mat-icon>
      </mat-card-title>
      <mat-card-content>
        <mat-divider></mat-divider> <br>
        <jafar-expandable-table
          [tableData]="filteredSuggestions$ | async"
          [tableHeaders]="feedbackTableHeaders"
        ></jafar-expandable-table>
      </mat-card-content>
      </ng-template>
  </mat-card>
</div>
