<form >
    <mat-form-field class="form-style" appearance="outline" color="accent">
      <mat-label>{{inputConfig.listLabel}}</mat-label>

      <input
        type="list"
        matInput
        placeholder="{{inputConfig.listPlaceHolder}}"
        (keyup)="onKeyPress($event)"
        [formControl]="inputBox"
        [matAutocomplete]="auto"
        (focus)="onFocus()"
        (blur)="LostFocus()"
        color="accent"
        class="text-box"
      />

      <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="displayFn"
      (optionSelected)="selected($event)"
      >
        <mat-option *ngFor="let item of dropDownList | async" [value]="item">
          {{item[displayProperty]}}

        </mat-option>

      </mat-autocomplete>

      <button *ngIf="selectedItem" matSuffix mat-icon-button (click)="reset()">
        <mat-icon>close</mat-icon>
      </button>

    </mat-form-field>
</form>
