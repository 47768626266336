import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'jafar-customer-widget',
  templateUrl: './customer-widget.component.html',
  styleUrls: ['./customer-widget.component.scss']
})
export class CustomerWidgetComponent {
  router = inject(Router)

  buttonClicked(){
    this.router.navigateByUrl("managecip/customers")
  }
}
