import { Component, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { cipInjectionTokens } from 'src/environments/InjectionTokens/cipInjectionTokens';

@Component({
  selector: 'jafar-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss']
})
export class SidePanelComponent {
  constructor(
    @Inject(cipInjectionTokens.selectedRouteToken)
    public SelectedRoute: Observable<any>
  ){}
}
