import { Component, OnInit, inject } from '@angular/core';
import { CipCustomerStore } from 'src/app/shared/datasource/stores/cip/cip-customer-store.service';
import { CipEnvRouteStore } from 'src/app/shared/datasource/stores/cip/cip-envroute-store.service';
import { CipEnvRouteDefaultStore } from 'src/app/shared/datasource/stores/cip/cip-envroutedefault-store.service';

@Component({
  selector: 'jafar-cip-routing',
  templateUrl: './cip-routing.component.html',
  styleUrls: ['./cip-routing.component.scss']
})
export class CipRoutingComponent implements OnInit{
  envRouteStore         = inject(CipEnvRouteStore)
  envRouteDefaultStore  = inject(CipEnvRouteDefaultStore)
  customersStore        = inject(CipCustomerStore)

  ngOnInit(){
    this.envRouteStore.fetchEnvRoutes()
    this.envRouteDefaultStore.fetchEnvRouteDefaults()
    this.customersStore.fetchCustomers()
    this.customersStore.clearSelectedCustomer()
  }
}
