import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TextControlOptions } from '../../dynamic-form.interface';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { ControlValueAccessorDirective } from '../../form-cva/control-value-accessor.directive';

@Component({
  selector: 'jafar-textbox',
  templateUrl: './textbox.component.html',
  styleUrls: ['./textbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TextboxComponent,
      multi : true
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic'
      }
    }
  ]
})

export class TextboxComponent<T> extends ControlValueAccessorDirective<T> implements AfterViewInit{
  @Input() textOptions!: TextControlOptions | undefined
  @Input()customErrorMessages! : any
  placeholder! : string
  label! : string

  ngAfterViewInit(): void {
    this.label = this.textOptions?.label ?? ""
    this.placeholder = this.textOptions?.placeholder ?? ""
    console.log("Custome Error Message Object", this.customErrorMessages)
  }

}
