import { Component, Inject } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'jafar-cip-row-filter',
  templateUrl: './cip-row-filter.component.html',
  styleUrls: ['./cip-row-filter.component.scss']
})
export class CipRowFilterComponent {
  constructor(
    public dialogRef: MatDialogRef<CipRowFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  propertiesToShow : string[] = []
  allProperties    : string[] = []

  ngOnInit(){
   this.propertiesToShow = [...this.data.selectedProperties]
   this.allProperties    = [...this.data.allProperties]
   console.log(this.data)
  }

  isChecked(property : string) : boolean{
    if (this.propertiesToShow.includes(property)){
        return true
    }
    else{
        return false
    }

  }

  onOptionChange(option : MatCheckboxChange){
    console.log(option)
    if (option.checked) {
      if(!this.propertiesToShow.includes(option.source.id))
        this.propertiesToShow.push(option.source.id)
    }
    else{
      this.propertiesToShow = this.propertiesToShow.filter(
        props => { return props !== option.source.id }
      )
    }
  }

  onNoClick(){}
}
