import { UserProfile } from './../../../shared/datasource/interfaces/user-profile';
import { Component, ViewEncapsulation, OnInit, inject, AfterViewInit } from '@angular/core';
import { SpinnerService } from 'src/app/shared/datasource/services/spinner/spinner.service';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from 'src/app/core/datasource/services/user/user.service';
import { Approval, ApprovalResponse } from 'src/app/shared/datasource/interfaces/approval-interface';
import { PaginatedTable } from 'src/app/shared/datasource/interfaces/paginated-table.interface';
import { UserRequestService } from 'src/app/shared/datasource/services/http-requests/ms-graph/user/user-request.service';
import { compareDates, convertStringToDate, getFilterStartDate } from '../datastore/user-request-helpers';
import { BehaviorSubject, Observable, filter, map, of } from 'rxjs';
import { MatTab } from '@angular/material/tabs';
import { UserStore } from 'src/app/core/datasource/services/user/user-store.service';
import { HelperFunctionsService } from 'src/app/shared/functions/helperfunctions';

@Component({
  selector: 'jafar-user-requests',
  templateUrl: './user-requests.component.html',
  styleUrls: ['./user-requests.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserRequestsComponent implements OnInit{
  userProfile =  inject(UserStore)
  userRequestService = inject (UserRequestService)
  helperFunctions = inject(HelperFunctionsService)

  filteredRequestData$!: Observable<PaginatedTable>
  // Radio Button Details
  selectedFilter : string   = "Date";
  requestFilters  : string[] = ['Date', 'Request ID', 'Status'];

  // Dropdown Variable
  filterValue: string = ""
  private requestData!: ApprovalResponse[]
  public filteredRequestData = new BehaviorSubject<PaginatedTable>(EmptyPaginator)
  public approvalData        = new BehaviorSubject<PaginatedTable>(EmptyPaginator)
  public selectedRequest : ApprovalResponse | null = null

  constructor(public spinnerService: SpinnerService){}

  ngOnInit(): void {
    this.selectedFilter ="Date"
    this.refreshUserRequests()
  }

  refreshUserRequests(){
    this.userProfile.userProfile$.subscribe(
      (user: UserProfile) => {
        this.userRequestService.getUserRequests(user.mail).subscribe(
          (requests: ApprovalResponse[]) => {
            let  currentDS = this.filteredRequestData.getValue()
            currentDS.tableDataSource.data = [...requests]
            const headers = this.getAllRequestHeaders()
            const newPageTable : PaginatedTable = {
              tableDataSource: currentDS.tableDataSource,
              columnHeaders: headers,
              showFilter: false
            }
            this.filteredRequestData.next(newPageTable)
            this.requestData = requests
          }
        )
      })
  }

  resetFilters(){
    this.refreshUserRequests()
    this.selectedFilter = "Date"
    this.filterValue = ""
  }

  getAllRequestHeaders(){
    const headers =
    [
      {
        columnHeaderIndex: "createdAt",
        columnHeaderName : "Created At",
      },
      {
        columnHeaderIndex: "id",
        columnHeaderName : "Request ID",
      },
      {
        columnHeaderIndex: "description",
        columnHeaderName : "Description",
      },
      {
        columnHeaderIndex: "approvalStatus",
        columnHeaderName : "Approval Status",
        icon             : true,
        iconColor        : "approvalColor",
        iconToolTip      : "approvalTooltip"
      }
    ]
    return headers
  }

  getApprovalHeaders(){
    const headers =
    [
      {
        columnHeaderIndex: "fullName",
        columnHeaderName : "Approver Name",
      },
      {
        columnHeaderIndex: "lineManagerStatus",
        columnHeaderName : "Line Manager",
        icon             : true,
        iconColor        : "lineManagerColor",
        iconToolTip      : "lineManagerTooltip"
      },
      {
        columnHeaderIndex: "decisionStatus",
        columnHeaderName : "Approval Status",
        icon             : true,
        iconColor        : "decisionColor",
        iconToolTip      : "decisionTooltip"
      }
    ]

    return headers
  }

  onSelection(selectedRequest : any){
    let updatedApprovals: Approval[] = selectedRequest.approvals.map(
      (approval: Approval) => {
        approval.fullName = `${approval.firstName} ${approval.lastName}`
        const decisionIconDetails = this.helperFunctions.getIcon(approval, "decision")
        approval.decisionColor = decisionIconDetails.color
        approval.decisionTooltip = decisionIconDetails.tooltip
        approval.decisionStatus = decisionIconDetails.icon
        const lineManagerIconDetails = this.helperFunctions.getIcon(approval, "isLineManager")
        approval.lineManagerColor = lineManagerIconDetails.color
        approval.lineManagerTooltip = lineManagerIconDetails.tooltip
        approval.lineManagerStatus = lineManagerIconDetails.icon
        return approval
      }
    )
    let currentApprovalData = this.approvalData.getValue()
    currentApprovalData.tableDataSource.data = [...updatedApprovals]
        const headers = this.getApprovalHeaders()
        console.log(headers)
        const newPageTable : PaginatedTable = {
          tableDataSource: currentApprovalData.tableDataSource,
          columnHeaders: headers,
          showFilter: false
        }
        console.log(newPageTable)

        this.approvalData.next(newPageTable)
        console.log("BS DATA :", this.approvalData)
  }

  filterDateRange(selectedValue: string){
    const switchValue: number = Number(selectedValue)
    if(switchValue > 0){
      const filterDate = getFilterStartDate(switchValue);
      const updatedData = this.requestData.filter(
        (request: ApprovalResponse) => {
          const createdAtDate = convertStringToDate(request.createdAt)
          const validDate = compareDates(createdAtDate, filterDate)
          return validDate == true ? request: null
        }
      )
      let filteredDataSource = this.filteredRequestData.getValue().tableDataSource
      filteredDataSource.data = updatedData
      const newPageTable : PaginatedTable = {
        tableDataSource: filteredDataSource,
        columnHeaders: this.getAllRequestHeaders(),
        showFilter: false
      }
      this.filteredRequestData.next(newPageTable)
    }else{
      let filteredDataSource = this.filteredRequestData.getValue().tableDataSource
      filteredDataSource.data = [...this.requestData]
      const filteredPageTable : PaginatedTable = {
        tableDataSource: filteredDataSource,
        columnHeaders: this.getAllRequestHeaders(),
        showFilter: false
      }
      this.filteredRequestData.next(filteredPageTable)
    }
  }

  filterApprovalStatus(selectedValue: any){
    console.log(selectedValue)
    const updatedData = this.requestData.filter(
      (request: ApprovalResponse) => {
        return request.approvalTooltip == selectedValue ? request: null
      }
    )
    let filteredDataSource = this.filteredRequestData.getValue().tableDataSource
    filteredDataSource.data = updatedData
    const filteredPageTable : PaginatedTable = {
      tableDataSource: filteredDataSource,
      columnHeaders: this.getAllRequestHeaders(),
      showFilter: false
    }
    this.filteredRequestData.next(filteredPageTable)
  }

  filterRequestID(selectedValue: any){
    const enteredText = (selectedValue.target as HTMLInputElement).value;
    console.log(enteredText)
    const updatedData = this.requestData.filter(
      (request: ApprovalResponse) => {
        return (request.id.toString()).includes(enteredText) ? request: null
      }
    )
    let filteredDataSource = this.filteredRequestData.getValue().tableDataSource
    filteredDataSource.data = updatedData
    const filteredPageTable : PaginatedTable = {
      tableDataSource: filteredDataSource,
      columnHeaders: this.getAllRequestHeaders(),
      showFilter: false
    }
    this.filteredRequestData.next(filteredPageTable)
  }

}


export const EmptyPaginator : PaginatedTable = {
  tableDataSource: new MatTableDataSource,
  columnHeaders: [],
  showFilter: false,
}
