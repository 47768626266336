import { ChangeDetectionStrategy, Component, Input, OnChanges, AfterViewInit, SimpleChanges } from '@angular/core';
import { MatChipGridChange, MatChipInputEvent, MatChipListboxChange } from '@angular/material/chips';
import { ChipListOptions } from '../../../dynamic-form.interface';
import { ControlValueAccessorDirective } from '../../../form-cva/control-value-accessor.directive';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'jafar-chiplist',
  templateUrl: './chiplist.component.html',
  styleUrls: ['./chiplist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ChiplistComponent,
      multi : true
    }
  ]
})
export class ChiplistComponent <T> extends ControlValueAccessorDirective<T> implements OnChanges{

  @Input() chipOptions! : ChipListOptions[] | undefined
  selectedItems: string[] = []

  ngOnChanges(changes : SimpleChanges){
    console.log("chiplist component : ngOnChanges : passed in changes - " , changes)
    if (changes['chipOptions'].isFirstChange() ){
      this.updatSelectedItems(true)
    }
    else{
      this.updatSelectedItems(false)
    }

  }

  updatSelectedItems(skipUpdate: boolean){
    this.chipOptions?.forEach(
      (option:ChipListOptions) =>{ this.addRemoveRole(option.selected, option.id)}
    )
    console.log("checkbox Component : updateSelectedItems : this.selectedItems -" , this.selectedItems)
    if (!skipUpdate){
      this.onChange(this.selectedItems)
    }
}

addRemoveRole(checkState:boolean, value : string){
  // Add Item To Array
  if (checkState){
    // Only Add Items not already in list
    if (!this.selectedItems.includes(value)){this.selectedItems.push(value)}
  }
  // Delete Item from array
  else {
    this.selectedItems = this.selectedItems.filter(r => r != value)
  }
}

  toggleSelection(chip : MatChipListboxChange){
    // console.log(chip)
    console.log(chip)
    this.selectedItems = chip.value
    this.onChange(this.selectedItems)

  }

}
