import { ChangeDetectionStrategy, Component, OnInit, ViewChild, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RaiseWorkitemDialogComponent } from 'src/app/shared/ui/raise-workitem-dialog/raise-workitem-dialog.component';
import { SpinnerService } from 'src/app/shared/datasource/services/spinner/spinner.service';
import { UserService } from 'src/app/core/datasource/services/user/user.service';
import { TableColumnHeader } from 'src/app/shared/datasource/interfaces/expandable-table-interface';
import { WorkItemsStore } from 'src/app/shared/datasource/stores/work-item.store';
import issuesHeadersJson  from '../../../../assets/config/tableHeaders/raise-issue-headers.json'
import { WorkItemDialogOutput, WorkItemDialog } from 'src/app/shared/datasource/services/http-requests/azure-devops/workitem/workitem.interface';
import { BehaviorSubject } from 'rxjs';
import { DropDownFilterConfig, CheckBoxFilterConfig } from 'src/app/shared/ui/reusable-filter/reusable-filter.interface';
import { ReusableFilterComponent } from 'src/app/shared/ui/reusable-filter/reusable-filter.component';

@Component({
  selector: 'jafar-raise-issue',
  templateUrl: './raise-issue.component.html',
  styleUrls: ['./raise-issue.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})

export class RaiseIssueComponent implements OnInit {
  // Construct \ Inject Services
  dialog          = inject(MatDialog)
  spinnerService  = inject(SpinnerService)
  workItemStore   = inject(WorkItemsStore)
  userProfile     = inject(UserService)

  // Local variables used to populate the table
  _filteredBugs$ = new BehaviorSubject<any>(null)
  filteredBugs$ = this._filteredBugs$.asObservable()

  // Local Variables to Inject to Filters
  requestedByFilterConfig!: DropDownFilterConfig
  bugStatusFilterConfig!: CheckBoxFilterConfig

  // Class Propeties
  issuesTableHeaders: TableColumnHeader[] = issuesHeadersJson as TableColumnHeader[]

  ngOnInit(): void {
    this.workItemStore.fetchBugRecords()

    this.workItemStore.bugRecords$.subscribe((item: any) => {
      // Populate Local variable that populates the table
      this._filteredBugs$.next(item)

      // Populate the Dropdown and Checkbox filters
      const allDropDownItems = item.map((option: any) => {return option.ReportedBy})
      const dropDownFilter = Array.from(new Set(allDropDownItems))
      const statusFilter: string[] = ['Active', 'Closed', 'New', 'Resolved']

      // Construct Configs for both Filters
      this.bugStatusFilterConfig = {
        enabled: true,
        config: {
          title: 'Status',
          data: statusFilter,
          displayProperty: 'name',
          type: 'multiple'
        }
      }

      this.requestedByFilterConfig = {
        enabled: true,
        config: {
          title: 'Reported By',
          data: dropDownFilter,
          type: 'single'
        }
      }
    })
  }

  createNewIssue(Issue : WorkItemDialogOutput){
    this.workItemStore.addBugRecord(Issue)
  }

  refreshBug(){
    this.workItemStore.fetchBugRecords()
  }

  openDialog(): void {
    const Bugdata:WorkItemDialog = {
      input:{
        MainTitle: "Raise Issue",
        TitleMessage: "Please give the issue a title:",
        DescriptionMessage: "Please describe the issue you are experiencing:"
      },
      output: {
        Title: "",
        Description: "",
        User : "",
        Mail: ""
      }
    }
    const dialogRef = this.dialog.open(
      RaiseWorkitemDialogComponent, {
        data:Bugdata
      }
    );

    dialogRef.afterClosed().subscribe(
      (result :WorkItemDialog)  => {
        console.log("Dialog Returned : " ,result.output)
        if (result !== undefined){
          this.createNewIssue(result.output)
        }
      }
    );

  }

  getFilteredBugs(filter: any){
    // Create local vars to reference filters
    const reportedBy = filter.dropDownFilterOutput
    const statusFilter = filter.checkBoxFilterOutput.map((option: any) => {return option.name})
    let filteredItems: any[] = []

    // Iterate through all possible combinations
    if(reportedBy.length !==0 && statusFilter.length !==0){
      // Filter items depending on property values
      const filteredItems = [...this.workItemStore.bugRecords].filter((item: any) =>
        item.ReportedBy === `${reportedBy}` && statusFilter.includes(item.State)
      )
      // Pass values back to local observable
      this._filteredBugs$.next(filteredItems)
    }else if(reportedBy && statusFilter.length == 0){
      // Filter items depending on property values
      filteredItems = [...this.workItemStore.bugRecords].filter((item: any) => item.ReportedBy === reportedBy)
      // Pass values back to local observable
      this._filteredBugs$.next(filteredItems)
    }else{
      // Filter items depending on property values
      filteredItems = [...this.workItemStore.bugRecords].filter((item: any) => statusFilter.includes(item.State))
      // Pass values back to local observable
      this._filteredBugs$.next(filteredItems)
    }
  }

  onClear(){
    // Revert local observable to starting values
    this._filteredBugs$.next(this.workItemStore.bugRecords)
  }

}
