import { Injectable } from '@angular/core';
import  PatternValidationForm from '../../../../../assets/config/validation/pattern-validation.json'
import { ValidationErrorRecord, ValidationList, ValidationPatternName, ValidationRecord } from './validation-pattern.interface';
import { Validators } from '@angular/forms';
@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  patternValidatonList  : ValidationList = PatternValidationForm as ValidationList

  validate(inputValue: string | null | undefined, validationSet: string[]): boolean{
    let lowerCaseInput: string = ""
    if(inputValue != null){lowerCaseInput = inputValue.toLowerCase()}
    return validationSet.includes(lowerCaseInput) ? true : false
  }

  getErrorMessage(error : ValidationErrorRecord ){
    console.log("Validation Error : ", error)
    switch (error.validationType) {
      case "required"  : return "this is required"
      case "minlength" : return `this needs a min length of ${error.validationResult['requiredLength']} characters`
      case "pattern"   : return this.getPatternErrorMessage(error.validationResult['requiredPattern'])
      case "email"     : return "Please Enter a valid email Address"
      case "inputValidator" : return "Please select a valid option from the list"
      default          : return `There is an unknown error ${error}`
    }
  }

 private getPatternErrorMessage(pattern: string) : string {
    let message : string = ""
    Object.keys(this.patternValidatonList).forEach(
      (key) => {
        const patternName : ValidationPatternName = key as ValidationPatternName
        if (pattern.includes(this.patternValidatonList[patternName].pattern)) {
          message = this.patternValidatonList[patternName].message
        }
      }
    )
    return message
  }

  getFormValidators(validators: ValidationRecord[]) : Validators | null{
    const validatorsList : Validators[] = []
    validators.forEach(
      (validator : ValidationRecord ) => {
        switch (validator.validatorName) {
          case 'required' :
            validatorsList.push(Validators.required)
            break

          case 'minlength' :
            validatorsList.push(Validators.minLength(validator.validatorOptions))
            break

          case 'email' :
            validatorsList.push(Validators.email)
            break

          case 'pattern' :
            validator.validatorOptions.forEach(
              (patternName : ValidationPatternName) => {
                validatorsList.push(Validators.pattern(this.getPattern(patternName)))
              }
            )
            break

          default :
            console.error("Validator is not configured: ", validator.validatorName )
            break
        }
      }
    )
    console.log("List of Validators" , validatorsList)
    return validatorsList

  }

  private getPattern(patternName: ValidationPatternName ) : string {
    return this.patternValidatonList[patternName].pattern
  }

}
