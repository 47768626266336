import { ComponentPortal } from '@angular/cdk/portal';
import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CipChangeStore } from 'src/app/shared/datasource/stores/cip/cip-change-store.service';
import { CipInformationComponent } from 'src/app/shared/ui/templates/cip/cip-information/cip-information.component';
import { CipSpvHeaderComponent } from 'src/app/shared/ui/templates/cip/cip-spv-header/cip-spv-header.component';
import { SupportGroupsComponent } from 'src/app/shared/ui/templates/cip/support-groups/support-groups.component';
import { WorkLogsComponent } from 'src/app/shared/ui/templates/cip/work-logs/work-logs.component';

@Component({
  selector: 'jafar-change-spv',
  templateUrl: './change-spv.component.html',
  styleUrls: ['./change-spv.component.scss']
})
export class ChangeSpvComponent {
  HeaderComponent!     : ComponentPortal<CipSpvHeaderComponent>;
  MainGridComponent!   : ComponentPortal<CipInformationComponent>;
  TopGridComponent!    : ComponentPortal<WorkLogsComponent>;
  BottomGridComponent! : ComponentPortal<SupportGroupsComponent>;

  changeStore = inject(CipChangeStore)
  route = inject(ActivatedRoute)

  ngOnInit() {
    this.route.params.subscribe(
      (result : any) => { this.changeStore.fetchChangeInfo(result.id).subscribe((response: any) => this.changeStore.updateChangeInfo(response)) }
    )

    const changeHeaderInjector     = this.changeStore.changeHeaderInjector
    const changeInjector           = this.changeStore.changeInjector
    const supportGroupsInjector    = this.changeStore.changeSupportGroupInjector
    const workLogsInjector         = this.changeStore.changeWorkLogsInjector

    // const supportGroupsInjector = this.cipStore.incidentSupportGroupInjector
    this.HeaderComponent       = new ComponentPortal(CipSpvHeaderComponent    , null, changeHeaderInjector);
    this.MainGridComponent     = new ComponentPortal(CipInformationComponent  , null, changeInjector);
    this.TopGridComponent      = new ComponentPortal(WorkLogsComponent        , null, workLogsInjector);
    this.BottomGridComponent   = new ComponentPortal(SupportGroupsComponent   , null, supportGroupsInjector);

  }
}
