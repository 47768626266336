import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild, inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PaginationDefaults } from 'src/environments/generic-variables';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TableColumns, TableSettings } from './interface/reusable-table.interface';
import { MatDialog } from '@angular/material/dialog';
import { CipRowFilterComponent } from '../dialogs/cip-row-filter/cip-row-filter.component';

@Component({
  selector: 'jafar-reusable-table',
  templateUrl: './reusable-table.component.html',
  styleUrls: ['./reusable-table.component.scss']
})
export class ReusableTableComponent implements OnInit, AfterViewInit, OnChanges{
  @Input() tableData!     : any  // Need to make this a Generic Type (Array)
  @Input() tableColumns   : TableColumns[] =[]
  @Input() tableSettings! : TableSettings
  // Need to expand for Action Buttons & Expandable Cells

  // This should emit Genric Type (singular)
  @Output() public selectedRecord = new EventEmitter<any>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;
  dialog = inject(MatDialog)
  dataSource : MatTableDataSource<any> = new MatTableDataSource()
  displayedColumnsIds : string[] = []
  ngOnInit(): void {

    console.log(this.tableData)
    //Set Table Defaults if not passed in
    if (!this.tableSettings) {
      this.tableSettings = {
        pagination        : true,
        paginationOptions : PaginationDefaults,
        sorting           : {
          sortedColumn: 'id',
          sortedOrder: 'desc'
        },
        search            : true,
        reorderColumns    : true,
        hidePageSize      : true,
      }
    }

    this.displayedColumnsIds = [...this.tableColumns].map((column: TableColumns) => column.id)
    console.log(this.tableColumns)
    console.log(this.displayedColumnsIds)
  }

  ngAfterViewInit(){
      // Handle Pagination
      if (this.paginator && this.tableSettings) {
        if (this.tableSettings.pagination){
          this.paginator.hidePageSize = this.tableSettings.hidePageSize
          this.dataSource.paginator = this.paginator
        }
      }
      // Handle Sorting
      if (this.sort && this.tableSettings){
        if (this.tableSettings.sorting){
          this.dataSource.sort = this.sort
        }
      }
  }

  ngOnChanges(){
    console.log(this.tableData)
    console.log(this.tableColumns)
    this.dataSource = new MatTableDataSource(this.tableData)
    this.displayedColumnsIds = [...this.tableColumns].map((column: TableColumns) => column.id)
    // Handle Pagination
    if (this.paginator && this.tableSettings) {
      if (this.tableSettings.pagination){
        this.paginator.hidePageSize = this.tableSettings.hidePageSize
        this.dataSource.paginator = this.paginator
      }
    }

    // Handle Sorting
    if (this.sort && this.tableSettings){
      if (this.tableSettings.sorting){
        this.dataSource.sort = this.sort
      }
    }
  }

  // This should expect a Generic Type (singular)
  onRowSelection(selectedRow : any){
    this.selectedRecord.emit(selectedRow)
  }

  onDrop(event?: CdkDragDrop<string[]>) {
    if(event){
      moveItemInArray(this.displayedColumnsIds, event.previousIndex, event.currentIndex);
    }
  }

  filterTable(event: Event) {
    if (this.tableData){
      console.log(event)
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }
}

