<mat-card class="work-log-card">
    <mat-card-header class="work-log-card-header">
        <div class="work-log-text">Work Logs</div>
        <span class="work-log-title-space"></span>
        <mat-icon matTooltip="View Json Payload" class="work-log-title-icons" (click)="onViewJson()">feed</mat-icon>
    </mat-card-header>

    <mat-card-content class="work-log-content">
        <div  *ngIf="!(NoWorklogRecords | async); else displayNoItems">
            <mat-accordion class="work-log-accordian">
                <mat-expansion-panel *ngFor="let item of (WorkLogs |async)" class="work-log-accordian">
                    <mat-expansion-panel-header class="work-log-accordian-header" >
                        <mat-panel-title class="work-log-accordion-title">
                            {{item.submitter}} ({{item.createdAt | date:"dd MMM yyyy"}} &#64; {{item.createdAt | date:"hh:mm a"}}) :
                        </mat-panel-title>
                        <mat-panel-description>
                            {{item.type}} - {{item.shortDescription}}
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="work-log-accordian-content">
                        <jafar-dictionary-table [dataSource]="getDictionary(item)" ></jafar-dictionary-table>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>

    </mat-card-content>
</mat-card>

<ng-template #displayNoItems>
    <div class="no-work-logs"> There are NO Work Logs For this Record</div>
</ng-template>
