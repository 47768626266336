<h1>
    Manage CIP Routing
</h1>
<jafar-autocomplete-dropdown class="customer-dropdown"
    [inputData]="customerStore.customers$ | async"
    [inputConfig]="customerDropdownOptions"
    (click)="onDropdownClick()"
    (newItemEvent)="onCustomerSelection($event)"
>

</jafar-autocomplete-dropdown>
<!-- <span class="button-group">
    <button mat-button
        class="action-button"
        color="accent"
        [matMenuTriggerFor]="menu"
    >
        Select Customer
    </button>
    <mat-menu  #menu="matMenu" xPosition="after" yPosition="below" class="customer-mat-menu" (closed)="onCustomerSelection($event)" >
        <div *ngFor="let customer of (customerStore.customers$ | async)">
            <button (click)="onCustomerSelection($event)" mat-menu-item>{{customer.displayName}}</button>
        </div>
        <!-- <button mat-menu-item>Item 2</button> -->
    <!-- </mat-menu> -->
<!-- </span> -->



