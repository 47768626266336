<mat-card class="welcome-page-card">
    <h1 class="headline-h1">Manage Azure </h1>
    <mat-divider></mat-divider>

    <mat-card-content class="mat-content">

        <jafar-azure-application-widget></jafar-azure-application-widget>
        <jafar-azure-custom-group-widget></jafar-azure-custom-group-widget>

    </mat-card-content>

</mat-card>
