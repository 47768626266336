import { Component, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { PaginatedTable } from 'src/app/shared/datasource/interfaces/paginated-table.interface';
import { CreateAzureAppPopupWithRoles } from '../ui/create-application/create-azure-app-popup-with-roles/create-azure-app-popup-with-roles.component';
import { DeleteAzureAppPopup } from '../ui/delete-application/delete-azure-app-popup/delete-azure-app-popup.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarService } from 'src/app/shared/datasource/services/snackbar/snackbar.service';
import { Router } from '@angular/router';
import appRegJson from 'src/assets/config/tableHeaders/manage-appreg-headers.json'
import { SpinnerService } from 'src/app/shared/datasource/services/spinner/spinner.service';
import { AadAppregService } from 'src/app/shared/datasource/services/http-requests/azure-functions/application-registrations/aad-appreg.service';
import { AzureApp } from 'src/app/shared/datasource/interfaces/azure-app.interface';
import { DialogInput, DialogOutput } from 'src/app/shared/datasource/interfaces/dialog.interface';
import { DialogService } from 'src/app/shared/datasource/services/dialogs/dialog.service';
import { UserService } from 'src/app/core/datasource/services/user/user.service';
import { TableColumnHeader } from 'src/app/shared/datasource/interfaces/expandable-table-interface';

@Component({
  selector: 'jafar-manage-azure-app-reg',
  templateUrl: './manage-azure-app-reg.component.html',
  styleUrls: ['./manage-azure-app-reg.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ManageAzureAppRegComponent implements OnInit{
  public userProfile = inject(UserService)
  public tableData!             : PaginatedTable
  public disableAppSecretButton : boolean = true
  public disableDeleteButton    : boolean = true
  private selectedApp           : AzureApp | null = null
  appRegHeaders: TableColumnHeader[] = appRegJson as TableColumnHeader[]
  constructor(
    public aadAppRegService : AadAppregService,
    public dialog: DialogService,
    public matSnackBar : MatSnackBar,
    public snackBar: SnackbarService,
    private router: Router,
    public spinnerService: SpinnerService
  ){}

  ngOnInit(): void {
    this.tableData = {
      tableDataSource : new MatTableDataSource,
      columnHeaders: [],
      showFilter: true,
    }
    this.userProfile.activeUser$.subscribe(
      (user)=> {this.getApplicationList(user.id)}
    )
    // this.getApplicationList()
    this.tableData.columnHeaders = this.appRegHeaders
  }

  onSelection(selectedApp : any){
    this.selectedApp            = selectedApp
    this.disableAppSecretButton = false
    this.disableDeleteButton    = !selectedApp.isOwner
  }

  getApplicationList(id : string){
    this.aadAppRegService.getApplicationList(id).subscribe(
      apps => { this.tableData.tableDataSource.data = apps }
    )
  }

  openCreateDialog(): void {
    var createComponent
    createComponent  = CreateAzureAppPopupWithRoles

    const createDialog : DialogInput = {
      component : createComponent,
      data: {ownerId : this.userProfile.activeUser.id}
    }
    this.dialog.openDialog(createDialog)

    // Will update the application list on close
    this.dialogClosed()
  }

  openDeleteDialog(): void {
    const deleteDialog : DialogInput = {
      component :  DeleteAzureAppPopup,
      data: this.selectedApp
    }
    this.dialog.openDialog(deleteDialog)

    // Will update the application list on close
    this.dialogClosed()

  }

  openManageSecrets(){
    this.router.navigate(
      ['manageazure/appreg/appsecrets'],{
        state: {
          applicationName : `${this.selectedApp?.displayName }`,
          applicationId   : `${this.selectedApp?.id }`,
        }
      }
    );
  }

  dialogClosed(){
    this.dialog.onClose().subscribe(
      (result : DialogOutput) => {
        console.log(result)
        if (result){
          if (result.snackbarConfig !== undefined){
            //this.snackBar.openCustomSnackBar(result.snackbarConfig)
            this.snackBar.openSnackBar(result.snackbarConfig)
          }
          setTimeout(
            () => { this.getApplicationList(this.userProfile.activeUser.id) }, 1000
          );

          this.disableAppSecretButton = true
          this.disableDeleteButton = true
          this.selectedApp = null
        }
      }
    )
  }

  refreshTable(){
    this.getApplicationList(this.userProfile.activeUser.id)
    this.disableAppSecretButton = true
  }

}
