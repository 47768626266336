import { Component, OnInit, } from '@angular/core';

@Component({
  selector: 'jafar-ops-dashboard',
  templateUrl: './ops-dashboard.component.html',
  styleUrls: ['./ops-dashboard.component.scss'],
})
export class OpsDashboardComponent implements OnInit {

  ngOnInit() {
  }
}

