<div class="dynamic-form-framework">
  <ng-container *ngFor="let formItem of form" [formGroup]="dynamicForm">
    <ng-container [ngSwitch]="formItem.controlType" >

      <!-- Text Box Input -->
      <jafar-textbox *ngSwitchCase="'text'"
        [formControlName]="formItem.controlName"
        [textOptions]="formItem.textOptions"
      ></jafar-textbox>
      

      <!-- Text Area Input -->
      <jafar-text-area *ngSwitchCase="'textArea'"
      [formControlName]="formItem.controlName"
      [textAreaOptions]="formItem.textAreaOptions"
      ></jafar-text-area>

      <!-- Captcha Input -->
      <jafar-captcha *ngSwitchCase="'captcha'"
      [formControlName]="formItem.controlName"
      [captchaOptions]="formItem.captchaOptions"
      ></jafar-captcha>

      <!-- Dropdown Input -->
      <jafar-dropdown *ngSwitchCase="'dropdown'"
        [formControlName]="formItem.controlName"
        [dropdownOptions]="formItem.dropdownOptions"
        [selectedOption]="formItem.defaultValue"
        (optionSelected)="dropdownSelection($event)"
      ></jafar-dropdown>

      <!-- ChipList Input -->
      <jafar-chiplist *ngSwitchCase="'chiplist'"
        [formControlName]="formItem.controlName"
        [chipOptions]="formItem.chiplistOptions"
      ></jafar-chiplist>

      <!-- CheckBox Input -->
      <jafar-checkbox *ngSwitchCase="'checkbox'"
        [formControlName]="formItem.controlName"
        [checkboxOptions]="formItem.checkboxOptions"
        class="dynamic-form-checkbox"
      >
      </jafar-checkbox>

    </ng-container>
  </ng-container>
</div>



<!-- Enbale the below For Debugging  form -->
<!-- <div *ngIf="dynamicForm.valueChanges | async  as values">
	<pre> {{values | json  }} </pre>
</div> -->
