<jafar-table-page-layout [table]="(requestTaskTable$ | async)!" [toolbar]="toolbar" (selectedRecord)="onSelect($event)">Request Tasks</jafar-table-page-layout>
<ng-template #toolbar>
  <span class="button-group">
    <button mat-button class="action-button" (click)="onSearch()">
      <mat-icon class="information-title-icons material-symbols-outlined">search</mat-icon>
      Search
    </button>
    <mat-divider class="button-divider" [vertical]="true"></mat-divider>
    <button mat-button class="action-button" (click)="onChooseColumns()">
      <mat-icon class="information-title-icons material-symbols-outlined">view_column_2</mat-icon>
      Choose Columns
    </button>
    <mat-divider class="button-divider" [vertical]="true"></mat-divider>
    <jafar-date-range (newDateEvent)="onDateFilter($event)" #datePicker></jafar-date-range>
    <span class="refresh-button-spacing"></span>
    <div *ngIf="selectedDateRange || customerStore.customer.displayName !== ''" class="filter-text">
      Filters:
      <div *ngIf="selectedDateRange" class="filter-text-spacing">Date Range: {{selectedDateRange.startDate | tableFormatting: 'shortDate'}} - {{selectedDateRange.endDate | tableFormatting: 'shortDate'}} </div>
      <div *ngIf="customerStore.customer.displayName !== ''" class="filter-text-spacing"> Customer: {{customerStore.customer.displayName}}</div>
    </div>
    <mat-icon matTooltip="Clear Filters" (click)="onClearFilter()" class="clear-filter material-symbols-outlined">filter_alt_off</mat-icon>
    <mat-icon matTooltip="Refresh" (click)="onRefresh()" class="item-alignment">sync</mat-icon>
  </span>
</ng-template>

<ng-template #search>
  <title mat-dialog-title class="center-title">
    Search for a Request Task
  </title>
  <mat-dialog-content  class="dialog-content" >
    <span class="message-style">Please enter your search type below</span>
    <div class="search-alignment">

      <form [formGroup]="SearchFG">

        <mat-form-field appearance="outline" color="accent" class="search-dropdown">
          <mat-label>Search Type</mat-label>
          <mat-select (valueChange)="selectFilter($event)" value="id">
            <mat-option value="id">CIP ID</mat-option>
            <mat-option value="internalRequestTaskId">Request Task ID</mat-option>
            <mat-option value="grandParentId">Request ID</mat-option>
            <mat-option value="customer">Customer</mat-option>
          </mat-select>
        </mat-form-field>

      <mat-form-field *ngIf="searchData.idType == 'id'" appearance="outline" color="accent" class="form-item">
        <mat-label>Search by CIP ID</mat-label>
        <input matInput
          placeholder="1425d3d6-6b76-4b3f-c929-08db9a7ad17f"
          formControlName="searchId"
          (blur)="getRequestTaskInfo()"
          [class.is-invalid]="SearchFG.controls.searchId.invalid && SearchFG.controls.searchId.errors?.['validateRequestTasks']"
          [class.is-valid]="SearchFG.controls.searchId.valid"
        >
        <mat-spinner matSuffix *ngIf="SearchFG.controls.searchId.pending" class="is-loading" color="accent"></mat-spinner>

      </mat-form-field>

      <mat-form-field *ngIf="searchData.idType == 'internalRequestTaskId'" appearance="outline" color="accent" class="form-item">
        <mat-label>Search by Request Task ID</mat-label>
        <input matInput
          placeholder="TAS000012345678"
          formControlName="searchId"
          (blur)="getRequestTaskInfo()"
          [class.is-invalid]="SearchFG.controls.searchId.invalid && SearchFG.controls.searchId.errors?.['validateRequestTaskInfo']"
          [class.is-valid]="SearchFG.controls.searchId.valid"
        >
        <mat-spinner matSuffix *ngIf="SearchFG.controls.searchId.pending" class="is-loading" color="accent"></mat-spinner>

      </mat-form-field>

      <mat-form-field *ngIf="searchData.idType == 'grandParentId'" appearance="outline" color="accent" class="form-item">
        <mat-label>Search by Request ID</mat-label>
        <input matInput
          placeholder="REQ000001234567"
          formControlName="searchId"
          (blur)="getRequestTaskInfo()"
          [class.is-invalid]="SearchFG.controls.searchId.invalid && SearchFG.controls.searchId.errors?.['validateRequestTaskInfo']"
          [class.is-valid]="SearchFG.controls.searchId.valid"
        >
        <mat-spinner matSuffix *ngIf="SearchFG.controls.searchId.pending" class="is-loading" color="accent"></mat-spinner>

      </mat-form-field>

      <div class="invalid-response" *ngIf="SearchFG.controls.searchId.errors?.['validateRequestTasks'] || SearchFG.controls.searchId.errors?.['validateRequestTaskInfo']">
        No results have been found for ID: {{SearchFG.controls.searchId.value}}
      </div>
    </form>
    <jafar-autocomplete-dropdown
    *ngIf="searchData.idType == 'customer'"
      class="customer-dropdown"
      [inputData]="customerStore.customers$ | async"
      [inputConfig]="customerDropdown"
      (newItemEvent)="getCustomerRequestTasks($event)"
    >
    </jafar-autocomplete-dropdown>
  </div>
    <span>
      <jafar-action-button (btnClick)="onSubmit()" [disabled]="((this.customerStore.customer$ | async)?.name === '' ? true: false) && (!SearchFG.valid)">Submit</jafar-action-button>
      <jafar-dismiss-button (btnClick)="onCancel()">Cancel</jafar-dismiss-button>
    </span>
  </mat-dialog-content>
</ng-template>
