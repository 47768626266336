import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'jafar-cip-request-task-widget',
  templateUrl: './cip-request-task-widget.component.html',
  styleUrls: ['./cip-request-task-widget.component.scss']
})
export class CipRequestTaskWidgetComponent {
  router = inject(Router)

  buttonClicked(){
    this.router.navigateByUrl("opsdashboard/requesttasks")
  }
}
