<mat-card class="card-framework">
    <mat-card-header class="card-header">
        <ng-template [cdkPortalOutlet]="Header"></ng-template>
    </mat-card-header>

    <mat-divider class="divider" ></mat-divider>

    <mat-card-content class="card-content">
        <mat-drawer-container hasBackdrop="true" class="sub-sidepanel-container" >
            <mat-drawer mode="over" [opened]="tableSubMenuStore.state$ | async" position="end" disableClose="true" >
                <ng-template [cdkPortalOutlet]="SideMenu"></ng-template>
            </mat-drawer>
            <mat-drawer-content>
                <div class="grid-content-top" >
                    <ng-template [cdkPortalOutlet]="RightPanel"></ng-template>
                </div>

                <mat-divider class="grid-divider"></mat-divider>

                <div class="grid-content-bottom">
                    <ng-template [cdkPortalOutlet]="LeftPanel"></ng-template>
                </div>
            </mat-drawer-content>
        </mat-drawer-container>






    </mat-card-content>

</mat-card>


<ng-template #displayNoItems>
    <div class="no-Item"> There are NO Items to Display for this incident</div>
</ng-template>

