<div class="widget-framework">
    <jafar-page-widget [contents]="contentTemplate" [actions]="actionTemplate" >
        <div title>Changes</div>
        <div subtitle> View List of CIP Changes  </div> 
    </jafar-page-widget>
  </div>
  
  <ng-template #contentTemplate >
    View a List of CIP Changes.<br> You also have the ability to Search for a Change by: <br> <b>CIP Id</b> or <b>Change Id</b><br>
  </ng-template>
  
  <ng-template #actionTemplate>
    <button mat-flat-button matTooltip="Manage Customers" (click)="buttonClicked()" class="manage-azure-button">
        Go To Changes
        <mat-icon>shortcut</mat-icon>
    </button>
  </ng-template>
