import { Component, ViewChild, inject } from '@angular/core';
import { CIPEnvRoute, CIPEnvRouteUpdateRequest } from 'src/app/shared/datasource/interfaces/CIP/cip-envRoute.interface';
import { CIPEnvRouteDefaultCreateRequest } from 'src/app/shared/datasource/interfaces/CIP/cip-envRouteDefault.interface';
import { CipCustomerStore } from 'src/app/shared/datasource/stores/cip/cip-customer-store.service';
import { CipEnvRouteDefaultStore } from 'src/app/shared/datasource/stores/cip/cip-envroutedefault-store.service';
import { DynamicFormComponent } from 'src/app/shared/ui/dynamic-form/dynamic-form.component';
import { DynamicFormSchema } from 'src/app/shared/ui/dynamic-form/dynamic-form.interface';
import { SidePanelStoreService } from 'src/app/shared/ui/templates/pages/page-with-sidepanel/side-panel-store.service';
import envRouteDefaultJson from 'src/assets/config/dynamic-forms/envroutedefault-form-create.json'

@Component({
  selector: 'jafar-create-env-route-default-sidepanel',
  templateUrl: './create-env-route-default-sidepanel.component.html',
  styleUrls: ['./create-env-route-default-sidepanel.component.scss']
})
export class CreateEnvRouteDefaultSidepanelComponent {
  @ViewChild("formComponents") formComponents! : DynamicFormComponent

  // Inject Services \ Stores
  sidePanelStore  = inject(SidePanelStoreService)
  envRouteDefaultStore   = inject(CipEnvRouteDefaultStore)
  customerStore   = inject(CipCustomerStore)

  // Objects that contain the form Configuration
  envRouteForm  : DynamicFormSchema[] = envRouteDefaultJson as DynamicFormSchema[]

  // Page Routing \ Layout Options
  routeTitle : string = "Create a New Default Route"

  ngOnInit(){
    this.setDefaults()
  }

  setDefaults(){
    this.envRouteForm.forEach(
      formComponent => {
        const envProperty = formComponent.controlName as keyof CIPEnvRoute
        formComponent.defaultValue = ""
      }
    )
  }

  onGoBack(){
    this.sidePanelStore.setIsOpen(false)
  }

  onCancel(){
    if (this.formComponents) {
      this.formComponents.dynamicForm.reset()
    }
  }

  onCreate(){
    const createDefaultRouteRequest: CIPEnvRouteDefaultCreateRequest ={
      customer: this.customerStore.customer.sapName,
      requestType: this.formComponents.dynamicForm.get('requestType')?.value.requestTypeId,
      routeName: this.formComponents.dynamicForm.get('routeName')?.value.routeId,
      config: this.formComponents.dynamicForm.get('config')?.value,
      active: true
    }
    console.log("Create Default Route Payload", createDefaultRouteRequest)
    this.envRouteDefaultStore.newEnvRouteDefault(createDefaultRouteRequest)
    this.onGoBack()
  }
}
