import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccessRequestComponent } from './feature/access-request/access-request.component';
import { LoginPageComponent } from './feature/login-page/login-page.component';

const routes: Routes = [

  { path: 'login', component: LoginPageComponent},
  { path: 'access', component: AccessRequestComponent},
  { path: '', redirectTo: '/login', pathMatch: 'full'},
  { path: '', 
    loadChildren: () => import('../pages/pages-routing.module')
    .then(m => m.PagesRoutingModule)
  },
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class CoreRoutingModule { }
