import { AfterViewInit, Component, Input, inject } from '@angular/core';
import { ControlValueAccessorDirective } from '../../form-cva/control-value-accessor.directive';
import { CaptchaOptions } from '../../dynamic-form.interface';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { ThemeService } from 'src/app/core/datasource/services/theme/theme.service';

@Component({
  selector: 'jafar-captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CaptchaComponent,
      multi : true
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic'
      }
    }
  ]
})
export class CaptchaComponent<T> extends ControlValueAccessorDirective<T> implements AfterViewInit {
  @Input()captchaOptions!: CaptchaOptions | undefined
  @Input()customErrorMessages! : any
  placeholder! : string
  label! : string
  public theme = inject(ThemeService)

  ngAfterViewInit(): void {
    this.label = this.captchaOptions?.label ?? ""
  }
}
