import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TableColumnHeader } from '../../datasource/interfaces/expandable-table-interface';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { environment } from 'src/environments/environment';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { COMMA } from '@angular/cdk/keycodes';

@Component({
  selector: 'jafar-dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.scss']
})
export class DynamicTableComponent {
  // Inputs for table
  @Input() tableData! : any
  @Input() tableHeaders : TableColumnHeader[] =[]
  @Output() public selectedRecord = new EventEmitter<Event>();

  // Viewchild objects for sort and table rendering
  @ViewChild(MatSort, {static: true}) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  // table data source
  dataSource : MatTableDataSource<any> = new MatTableDataSource(this.tableData)

  // Variables used to display data
  columnHeaderIndex : string[] = []
  columnsToDisplay: string[] = [];

  // Paginator Details
  pageSize: number = environment.generic.defaultPageSize
  pageSizeOptions: number[] = environment.generic.pageSizeOptions

  ngOnInit(): void{
    this.columnHeaderIndex          = this.getColumnHeaderIndexes()

    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort

    console.log("Table Headers Index : ",this.columnHeaderIndex )
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator
  }

  ngOnChanges(){
    this.dataSource = new MatTableDataSource(this.tableData)
    this.dataSource.sort = this.sort
    this.dataSource.paginator = this.paginator;
  }

  getColumnHeaderIndexes() : string[] {
    return this.tableHeaders.map(
      header => { return header.columnHeaderIndex }
    )
  }

  onRowSelection(selectedRow:Event){
    console.log(selectedRow)
    this.selectedRecord.emit(selectedRow)
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.columnHeaderIndex, event.previousIndex, event.currentIndex);
  }
}
