<div *ngIf="workItemData as accordionData">
    <mat-accordion class="align-headers">
        <mat-expansion-panel *ngFor="let data of accordionData">
            <mat-expansion-panel-header>
            <mat-panel-title>
                <mat-icon color="cc" matTooltip="{{data.ToolTip}}" class="material-symbols-outlined">{{data.StateIcon}}</mat-icon>
                &nbsp;&nbsp;&nbsp;<b>{{data.Title}}</b>
            </mat-panel-title>
            <mat-panel-description>
                {{ data.ReportedBy }}
                <pre>{{ data.Id }}</pre>
            </mat-panel-description>
            </mat-expansion-panel-header>
            <p>
            {{data.Description}}
            </p>
        </mat-expansion-panel>
    </mat-accordion>
</div>
