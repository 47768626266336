import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, QueryList } from '@angular/core';
import { Observable } from 'rxjs';
import { QueryParams } from '../../../interfaces/generic-api.interface';
@Injectable({
  providedIn: 'root'})

  export abstract class GenericApiService {
  constructor(
    protected http: HttpClient
  ) { }

    // Make this input as an interface????
  protected get<returnType>(
    uri: string,
    headers?: HttpHeaders,
    queryParams?: QueryParams,
  ):Observable<returnType>
  {
    // call function to convert query array to string
    const QueryString = this.convertQueryToString(queryParams)
    const fullUri: string = `${uri}${QueryString}`
    return this.http.get(fullUri, {headers} ) as Observable<returnType>
  }

  protected post<returnType>(
    uri: string,
    body: string,
    headers?: HttpHeaders,
    queryParams?: QueryParams
  ):Observable<returnType>  {


  // call function to convert query array to string
    const QueryString = this.convertQueryToString(queryParams)
    const fullUri: string = `${uri}${QueryString}`
    return this.http.post(fullUri, body, {headers}) as Observable<returnType>
  }

  protected patch<returnType>(
    uri: string,
    body: string,
    headers?: HttpHeaders,
    queryParams?: QueryParams
  ):Observable<returnType>  {


  // call function to convert query array to string
    const QueryString = this.convertQueryToString(queryParams)
    const fullUri: string = `${uri}${QueryString}`
    return this.http.patch(fullUri, body, {headers}) as Observable<returnType>
  }

  protected put<returnType>(
    uri: string,
    body: string,
    headers?: HttpHeaders,
    queryParams?: QueryParams
  ):Observable<returnType>  {


  // call function to convert query array to string
    const QueryString = this.convertQueryToString(queryParams)
    const fullUri: string = `${uri}${QueryString}`
    return this.http.put(fullUri, body, {headers}) as Observable<returnType>
  }

  protected delete<returnType>(
    uri: string,
    headers?: HttpHeaders,
    queryParams?: QueryParams
  ):Observable<returnType>  {

    const QueryString = this.convertQueryToString(queryParams)
    const fullUri: string = `${uri}${QueryString}`
    return this.http.delete(fullUri, {headers}) as Observable<returnType>
  }

  protected convertQueryToString(queryList?: QueryParams){
    let queryString: string = ""

    for (var query in queryList){
      // console.log(query)
      // console.log(queryList)

      if (queryString === ""){
        queryString = "?"
      }
      else{
        queryString += "&"
      }

      queryString += `${query}=${queryList[query]}`
      // console.log(queryString)
    }

    return queryString

  }

}
