<mat-card class="card-framework">
    <mat-card-header class="card-header">
        <ng-template [cdkPortalOutlet]="threeGridHeader"></ng-template>
    </mat-card-header>

    <mat-divider class="divider" ></mat-divider>

    <mat-card-content class="card-content">
        <mat-grid-list cols="7"  gutterSize="15"  rowHeight="fit" class="grid-framework" >

            <!-- Main Panel -->
            <mat-grid-tile [colspan]="3" [rowspan]="2" >
                <div class="grid-content-main">
                    <ng-template [cdkPortalOutlet]="threeGridMainGrid"></ng-template>
                </div>
            </mat-grid-tile>

            <!-- Top Sub Panel -->
            <mat-grid-tile [colspan]="4" [rowspan]="1">
                <div class="grid-content-top" > 
                    <ng-template [cdkPortalOutlet]="threeGridTopGrid"></ng-template>
                </div>
            </mat-grid-tile>

            <!-- Bottom Sub Panel -->
            <mat-grid-tile [colspan]="4" [rowspan]="1">
                <div class="grid-content-bottom">
                    <ng-template [cdkPortalOutlet]="threeGridBottomGrid"></ng-template>
                </div>
            </mat-grid-tile>

        </mat-grid-list>

    </mat-card-content>

</mat-card>




















<ng-template #displayNoItems>
    <div class="no-Item"> There are NO Items to Display for this incident</div>
</ng-template>

