import { Injectable, Injector, inject } from "@angular/core";
import { BehaviorSubject, Observable, map } from "rxjs";
import { cipInjectionTokens } from "src/environments/InjectionTokens/cipInjectionTokens";
import { CIPProcessState, CIPItemState } from "../../interfaces/CIP/cip-states.interface";
import { CIPRequestsService } from "../../services/http-requests/azure-functions/cip/cip-requests.service";
import { CIPHeader } from "../../interfaces/CIP/cip-page-layout.interface";
import { CIPRequest, CIPRequestRecord } from "../../interfaces/CIP/cip-requests.interface";
import { CIPSupportGroup } from "../../interfaces/CIP/cip-supportgroups.interface";
import { CIPQuestionRecord } from "../../interfaces/CIP/cip-questions.interface";
import { Customer } from "src/app/pages/manage-azure-app-reg/datastore/customer.interface";

@Injectable({
    providedIn: 'root'
})
export class CipRequestStore {
  // Inject the service the store is linked too
  private requestService = inject(CIPRequestsService)

  // Decalare the Behaviour Subjects to Store the Data we need
  private readonly _allRequests$ : BehaviorSubject<CIPRequest[]>       = new BehaviorSubject<CIPRequest[]>([]);

  private readonly _emptyCIPRecord : CIPRequestRecord= {
    requestInfo     : null,
    workLogs        : [],
    questions       : []
  }

  private readonly _requestInfo$ : BehaviorSubject<CIPRequestRecord>   = new BehaviorSubject<CIPRequestRecord>(this._emptyCIPRecord)

  // Methods to populate the Stores

  fetchRequests(search? : string){
    this.requestService.getRequests(search)
    .subscribe(response => this._allRequests$.next(response))
  }

  fetchRequestInfo(cipId : string){
    this.requestService.getRequestDetails(cipId)
    .subscribe(response => this._requestInfo$.next(response))
  }

  fetchCustomerRequests(customer: Customer, dateObject?: any){
    // Initialize customer search string
    const customerSearchString = `customerId eq '${customer.customerId}'`

    // Construct final search string depending on inputs
    const searchString = dateObject ?
    customerSearchString + `and CreatedAt ge ${dateObject.startDate} and CreatedAt le ${dateObject.endDate}`:
    customerSearchString

    this.requestService.getRequests(searchString).subscribe(response =>
      {
        console.log(response)
        this._allRequests$.next(response)
      }
    )
  }

  validateRequests(search? : string){
    return this.requestService.getRequests(search)
  }

  validateRequestInfo(cipId : string){
    return this.requestService.getRequestDetails(cipId)
  }

  resetRequestInfo(){
    this._requestInfo$.next(this._emptyCIPRecord)
  }


  // Observable Streams of the contents of the stroe
  // REQUESTS
  get requests$() : Observable<CIPRequest[]> {
      return this._allRequests$.asObservable();
  }

  get requestInfo$() : Observable<CIPRequestRecord> {
      return this._requestInfo$.asObservable();
  }

  get requestHeaderInjector() : Injector {
      const requestHeader : Observable<CIPHeader | null> = this._requestInfo$.pipe(
          map((request :CIPRequestRecord)=> {
            if (request.requestInfo) {
              const requestInfo : CIPRequest = request.requestInfo
              const  header : CIPHeader = {
                  CIPType         : "Request",
                  CIPInternalId   : requestInfo.internalRequestId,
                  Customer        : requestInfo.msgHeaderCompany,
                  CreatedOn       : requestInfo.createdAt,
                  ProcessState    : (requestInfo.processStateName).toLowerCase() as CIPProcessState,
                  ItemState       : (requestInfo.requestStateName).toLowerCase() as CIPItemState
              }
              return header
            }
            else{
							return null
						}
          })
      )
      return Injector.create({providers: [{ provide: cipInjectionTokens.cipInformationToken, useValue: requestHeader}]})

  }

  get requestInjector() : Injector {
      const requestInfo = this._requestInfo$.pipe(map((result :any)=>( result.requestInfo as CIPSupportGroup )) )
      return Injector.create({providers: [{ provide: cipInjectionTokens.cipInformationToken, useValue: requestInfo}]})
  }

  get requestQuestionsInjector() : Injector {
      const questions = this._requestInfo$.pipe(
        map(
          (result)=>{
            let allQuestions : CIPQuestionRecord = {
              questions: result.questions ?? [],
            }
            return allQuestions
          }
        )
      )
      return Injector.create({providers: [{ provide: cipInjectionTokens.questionsToken, useValue: questions}]})
  }

  get requestWorkLogsInjector() : Injector {
      const worklogs = this._requestInfo$.pipe(map((result)=>( result.workLogs ?? [])) )
      return Injector.create({providers: [{ provide: cipInjectionTokens.workLogsToken, useValue: worklogs}]})
  }
}

