import { ComponentPortal } from '@angular/cdk/portal';
import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CipRequestStore } from 'src/app/shared/datasource/stores/cip/cip-request-store.service';
import { CipInformationComponent } from 'src/app/shared/ui/templates/cip/cip-information/cip-information.component';
import { CipSpvHeaderComponent } from 'src/app/shared/ui/templates/cip/cip-spv-header/cip-spv-header.component';
import { QuestionsComponent } from 'src/app/shared/ui/templates/cip/questions/questions.component';
import { WorkLogsComponent } from 'src/app/shared/ui/templates/cip/work-logs/work-logs.component';

@Component({
  selector: 'jafar-request-spv',
  templateUrl: './request-spv.component.html',
  styleUrls: ['./request-spv.component.scss']
})
export class RequestSpvComponent {
  HeaderComponent!     : ComponentPortal<CipSpvHeaderComponent>;
  MainGridComponent!   : ComponentPortal<CipInformationComponent>;
  TopGridComponent!    : ComponentPortal<WorkLogsComponent>;
  BottomGridComponent! : ComponentPortal<QuestionsComponent>;

  requestStore = inject(CipRequestStore)
  route = inject(ActivatedRoute)

  ngOnInit() {
    this.route.params.subscribe(
      (result : any) => { this.requestStore.fetchRequestInfo(result.id)}
    )
    const reqHeaderInjector     = this.requestStore.requestHeaderInjector
    const reqInjector           = this.requestStore.requestInjector
    const questionsInjector     = this.requestStore.requestQuestionsInjector
    const workLogsInjector      = this.requestStore.requestWorkLogsInjector

    // const supportGroupsInjector = this.cipStore.incidentSupportGroupInjector
    this.HeaderComponent       = new ComponentPortal(CipSpvHeaderComponent    , null, reqHeaderInjector);
    this.MainGridComponent     = new ComponentPortal(CipInformationComponent  , null, reqInjector);
    this.TopGridComponent      = new ComponentPortal(WorkLogsComponent        , null, workLogsInjector);
    this.BottomGridComponent   = new ComponentPortal(QuestionsComponent       , null, questionsInjector);

  }
}
