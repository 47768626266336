<form>
    <mat-form-field class="form-style" appearance="fill" color="accent">
      <mat-label>{{  inputConfig.listLabel  }}</mat-label>
      <mat-chip-grid #chipGrid>

        <mat-chip-row *ngFor="let item of selectedItems"
          (removed)="remove(item)"
        > {{  item[displayProperty]  }}
          <button matChipRemove> <mat-icon>cancel</mat-icon> </button>
        </mat-chip-row>

      </mat-chip-grid>

      <input #listInput
        placeholder="{{inputConfig.listPlaceHolder}}"
        [formControl]="inputBox"
        [matChipInputFor]="chipGrid"
        [matAutocomplete]="auto"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)"
        (keypress)="inputFilter()"
        (blur)="clearInput(listInput)"
      />

      <mat-autocomplete  #auto="matAutocomplete"
        [displayWith]="displayFn"
        (optionSelected)="selected($event)">

        <mat-option *ngFor="let item of dropdownList$ | async" [value]="item">
          {{  item[displayProperty]  }}
        </mat-option>

      </mat-autocomplete>

    </mat-form-field>

</form>
