import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConsoleLogService {

  constructor() { }

  disableConsoleInProduction(): void {
    // Disable logs if consoleLogs Flag is set to false
    if (!environment.consoleLogs) {
      console.warn(`🚨 Console output is currently disabled for ${environment.toolbartitle}!`);
      console.log   = function (): void { };
      console.debug = function (): void { };
      console.warn  = function (): void { };
      console.info  = function (): void { };
    }
  }

}
