<div class="popup-framework">
  <div class="popup-title">
    <span class="title-spacing"></span>
	  <h2 class="heading">Create a Custom Azure Group</h2>
    <span class="title-spacing"></span>
    <mat-icon class="close-icon-position" matTooltip="Close" (click)="onClose()">close</mat-icon>
  </div>
	<mat-vertical-stepper #stepper [linear]="true" labelPosition="bottom"	animationDuration="800"	class="stepper">

		<!-- Step 1 -->
		<mat-step completed="false">
			<ng-template matStepLabel>
				Provide Group Details
			</ng-template>
			<ng-template matStepContent>
        <div>Enter below details for group creation
        </div>
        <jafar-dynamic-form class ="form-item" [form]="createGroupData" #createGroupForm></jafar-dynamic-form>
        <div class="button-group">
          <jafar-action-button [disabled]="!createGroupForm.dynamicForm.valid" (btnClick)="goToNextStep(stepper)">Next</jafar-action-button>
          <jafar-dismiss-button (btnClick)="reset(stepper)">Reset</jafar-dismiss-button>
        </div>
      </ng-template>
		</mat-step>

		<!-- Step 2 -->
		<mat-step completed="false">
			<ng-template matStepLabel>
				Add Members {{groupNameStepLabel}}
			</ng-template>
			<ng-template matStepContent>
				<div class="form-style">
					<span>Please Select all Users you want to Add</span>
					<jafar-chip-autocomplete #usersDropdown class="user-width"
          [inputData]="allUsers"
          [inputConfig]="userChipConfig"
          (newItemEvent)="updateSelectedUsers($event)">
          </jafar-chip-autocomplete>
				</div>
				<div class="button-group">
          <jafar-action-button [disabled]="!hasMembersButton" (btnClick)="goToNextStep(stepper)">Next</jafar-action-button>
          <jafar-dismiss-button (btnClick)="reset(stepper)">Reset</jafar-dismiss-button>
				</div>
			</ng-template>
		</mat-step>

		<!-- Step 3 -->
		<mat-step completed="false">
			<ng-template matStepLabel>
				Add Group Owners {{groupNameStepLabel}}
			</ng-template>
			<ng-template matStepContent>
				<p>
					<b> Please note that you will already be added as an owner,<br>
						this step allows you to define additional owners of
						the group</b>
				</p>
				<div class="form-style">
					<span>Please Select all Users you want to Add</span>
					<jafar-chip-autocomplete #ownersDropdown class="user-width"	[inputData]="allUsers" [inputConfig]="ownerChipConfig" (newItemEvent)="updateSelectedOwners($event)"></jafar-chip-autocomplete>
				</div>
				<span>
          <jafar-action-button (btnClick)="goToNextStep(stepper)">Next</jafar-action-button>
          <jafar-dismiss-button (btnClick)="reset(stepper)">Reset</jafar-dismiss-button>
				</span>
			</ng-template>
		</mat-step>

		<!-- Step 4 -->
		<mat-step completed="false">
			<ng-template matStepLabel>
				Add Applications {{groupNameStepLabel}}
			</ng-template>
			<ng-template matStepContent>
				<div class="form-style">
					<span>Please Select the App Registrations you want the group to have access too.</span>
					<jafar-chip-autocomplete #appsDropdown class="app-width" [inputData]="allApplications"	[inputConfig]="appChipConfig"	(newItemEvent)="updateSelectedApplication($event)"></jafar-chip-autocomplete>
				</div>
				<span>
          <jafar-action-button [disabled]="!hasAppsButton" (btnClick)="goToNextStep(stepper)">Next</jafar-action-button>
          <jafar-dismiss-button (btnClick)="reset(stepper)">Reset</jafar-dismiss-button>
				</span>
			</ng-template>
		</mat-step>

		<!-- Step 5 -->
		<mat-step completed="false">
			<ng-template matStepLabel>
				Confirmation
			</ng-template>
			<ng-template matStepContent>
				<br>
				<mat-card appearance="raised">
					<mat-card-title class="card-headline">
						<div> Confirmation Of Group Creation </div>
					</mat-card-title>
					<mat-card-content>
						<mat-divider></mat-divider>
						<br>
						<div> You are about to create a group with the following Details:</div><br>

						<b>Group Name : </b> {{createGroupForm.dynamicForm.controls['groupName'].value}}<br>
						<b>Description : </b> {{createGroupForm.dynamicForm.controls['description'].value}}<br>
						<b>Contact Mail : </b> {{createGroupForm.dynamicForm.controls['contactMail'].value}}<br>
						<b>ITSM Support Group : </b> {{createGroupForm.dynamicForm.controls['itsmSupportGroup'].value}}<br>
						<div *ngIf="selectedUsers">
							<b>Users : </b>
							<div *ngFor="let user of selectedUsers">
								{{ user.displayName }}
							</div>
						</div>
						<div *ngIf="selectedApplications">
							<b>App Registrations : </b>
							<div *ngFor="let app of selectedApplications;">
								{{ app.displayName }}
							</div>
						</div>
						<div *ngIf="selectedOwners.length != 0">
							<b>Additional Owners: </b>
							<div *ngFor="let owner of selectedOwners">
								{{ owner.displayName }}
							</div><br>
						</div>
						<div>Are you sure you want to Continue?</div>
					</mat-card-content>
					<mat-card-actions class="body-spacing">
            <jafar-action-button (btnClick)="onSubmit()">Yes</jafar-action-button>
            <jafar-dismiss-button (btnClick)="reset(stepper)">Reset</jafar-dismiss-button>
						<div class="button-spacing"></div>
					</mat-card-actions>
				</mat-card>
			</ng-template>
		</mat-step>
	</mat-vertical-stepper>
</div>
