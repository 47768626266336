import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { PaginatedTable } from 'src/app/shared/datasource/interfaces/paginated-table.interface';
import { SnackbarService } from 'src/app/shared/datasource/services/snackbar/snackbar.service';
import { environment } from 'src/environments/environment';
import { CreateCustomerPopupComponent } from '../create-customer-popup/create-customer-popup.component';
import { SpinnerService } from 'src/app/shared/datasource/services/spinner/spinner.service';
import { CipService } from 'src/app/shared/datasource/services/http-requests/azure-functions/cip/cip.service';
import { DialogInput, DialogOutput } from 'src/app/shared/datasource/interfaces/dialog.interface';
import { DialogService } from 'src/app/shared/datasource/services/dialogs/dialog.service';

@Component({
  selector: 'jafar-manage-cip-customer',
  templateUrl: './manage-cip-customer.component.html',
  styleUrls: ['./manage-cip-customer.component.scss']
})
export class ManageCipCustomerComponent {
  tableData! : PaginatedTable

  constructor(
    private dialog   : DialogService,
    private snackBar : SnackbarService,
    private jeannie  : CipService,
    public spinnerService: SpinnerService
  ){}

  ngOnInit(): void {
    this.tableData = {
      tableDataSource : new MatTableDataSource,
      columnHeaders: [],
      showFilter: true,
    }
    this.getCustomerList()
    this.getColumnHeaders()
  }



  openCreateDialog(){
    const createCustomerDialog : DialogInput = {
      component :  CreateCustomerPopupComponent,
      data: {createData:"No Data"}
    }

    this.dialog.openDialog(createCustomerDialog)

    this.dialog.onClose().subscribe(
      (result : DialogOutput) => {
        result?.snackbarConfig ? this.snackBar.openSnackBar(result.snackbarConfig) : null
      }
    )

  }

  getCustomerList(){
    this.jeannie.getCIPCustomers().subscribe(
      results => {
        console.log(results)
        this.tableData.tableDataSource.data = results
      }
    )
  }

  getColumnHeaders(){

    this.tableData.columnHeaders =
    [
      {
        columnHeaderIndex: "displayName",
        columnHeaderName : "Display Name",
      },

      {
        columnHeaderIndex: "name",
        columnHeaderName : "Name",
      },

      {
        columnHeaderIndex: "customerId",
        columnHeaderName : "Customer ID",
      },
      {
        columnHeaderIndex: "sapName",
        columnHeaderName : "Sap Name",
      },
      {
        columnHeaderIndex: "sapId",
        columnHeaderName : "Sap ID",
      },
      {
        columnHeaderIndex: "customerStatus",
        columnHeaderName : "Status",
        icon             : true,
        iconToolTip      : "customerToolTip",
        iconColor        : "customerIconColour"
      },
    ]

  }
  onSelection(selectedApp : any){}
}
