<div class="framework">
    <jafar-toolbar
        [toolbarItems]="toolbarItems"
    ></jafar-toolbar>

    <mat-drawer-container class="side-panel-container"  autosize>

        <mat-drawer #drawer opened mode="side" class="side-panel-menu">

            <div class="side-panel">

            <mat-nav-list class="side-menu-items">
                <ng-container *ngFor="let menuItem of sideMenuService.state.topSideMenuItems">
                    <jafar-side-menu  *ngIf="menuItem.visible" [menuItem]="menuItem"></jafar-side-menu>

                </ng-container>
            </mat-nav-list>

            <span class="side-menu-vertical-spacing"></span>

            <mat-divider></mat-divider>

            <mat-nav-list class="side-menu-items">
                <jafar-side-menu *ngFor="let menuItem of sideMenuService.state.bottomSideMenuItems" [menuItem]="menuItem"></jafar-side-menu>
            </mat-nav-list>
        </div>
        </mat-drawer>

        <mat-drawer-content class = "portal-content">
            <router-outlet></router-outlet>
        </mat-drawer-content>

    </mat-drawer-container>
</div>
