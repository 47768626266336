import { convertStringToDate } from 'src/app/pages/user-requests/datastore/user-request-helpers';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, inject, OnInit } from '@angular/core';
import { CIPEnvRoute } from 'src/app/shared/datasource/interfaces/CIP/cip-envRoute.interface';
import { CipEnvRouteStore } from 'src/app/shared/datasource/stores/cip/cip-envroute-store.service';
import { TableColumns, TableSettings } from 'src/app/shared/ui/reusable-table/interface/reusable-table.interface';
import { SidePanelStoreService } from 'src/app/shared/ui/templates/pages/page-with-sidepanel/side-panel-store.service';
import { EnvRouteSidepanelComponent } from '../../side-panel-pages/env-route-sidepanel/env-route-sidepanel.component';
import { CreateEnvRouteSidepanelComponent } from '../../side-panel-pages/create-env-route-sidepanel/create-env-route-sidepanel.component';
import envRouteHeaders from 'src/assets/config/tableHeaders/envroute-headers.json'
import { UserStore } from 'src/app/core/datasource/services/user/user-store.service';
@Component({
  selector: 'jafar-env-route',
  templateUrl: './env-route.component.html',
  styleUrls: ['./env-route.component.scss']
})
export class EnvRouteComponent{
  envRouteStore       = inject(CipEnvRouteStore)
  tableSideMenuStore  = inject(SidePanelStoreService)
  userStore           = inject(UserStore)

  tableSettings: TableSettings = {
    pagination: false,
    paginationOptions: {
      pageSize: 0,
      pageSizeOptions: []
    },
    sorting: {
      sortedColumn: 'active',
      sortedOrder: 'desc'
    },
    search: false,
    reorderColumns: true,
    hidePageSize: true
  }

  tableHeaders : TableColumns[] = envRouteHeaders as TableColumns[]

  onAddEnvRoute(){
    const sideMenuComp = new ComponentPortal(CreateEnvRouteSidepanelComponent)
    this.tableSideMenuStore.setSideMenuComponent(sideMenuComp)
    this.tableSideMenuStore.setIsOpen(true)
  }

  onRowSelection(rowSelection: CIPEnvRoute){


    this.userStore.opsDashboardUser$.subscribe(result => console.log(result))
    this.envRouteStore.updateSelectedEnvRoute(rowSelection)

    const sideMenuComp = new ComponentPortal(EnvRouteSidepanelComponent)
    this.tableSideMenuStore.setSideMenuComponent(sideMenuComp)
    this.tableSideMenuStore.setIsOpen(true)
  }
}
