<div class="dialogbox">
<title mat-dialog-title class="center-title">{{ data.input.MainTitle }}</title>
<mat-dialog-content  class="dialog-content" >
  <span class="message-style">{{ data.input.TitleMessage }}</span>
  <jafar-dynamic-form class ="form-item" [form]="workItemData" #workItemForm></jafar-dynamic-form>
  <mat-dialog-actions>
    <jafar-action-button [mat-dialog-close]="data" [disabled]="!workItemForm.dynamicForm.valid" (btnClick)="onSubmit()">Submit</jafar-action-button>
    <jafar-dismiss-button (btnClick)="onCancel()">Cancel</jafar-dismiss-button>
  </mat-dialog-actions>
</mat-dialog-content>
</div>
