<div class="captcha">
  <ng-container class="container-fluid py-3">
    <div class="row mt-3">
        <div class="col-sm-12 mb-2">
        <re-captcha
            [id]="label"
            [formControl]="control"
            [theme]="this.theme.activeTheme$ | async"
        >
        </re-captcha>
        </div>
    </div>
    <div class="row">
    </div>
  </ng-container>

  <jafar-form-errors
    [errors]="control.errors"
    [touched]="control.touched"
  ></jafar-form-errors>
</div>
