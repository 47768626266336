<mat-card class="welcome-page-card">
    <h1 class="headline-h1">Ops Dashboard </h1>
    <mat-divider></mat-divider>

    <mat-card-content class="mat-content">

        <jafar-cip-incident-widget></jafar-cip-incident-widget>
        <!-- <jafar-cip-change-widget></jafar-cip-change-widget> -->
        <jafar-cip-request-widget></jafar-cip-request-widget>
        <jafar-cip-request-task-widget></jafar-cip-request-task-widget>

    </mat-card-content>

</mat-card>
