import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, inject, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicFormSchema, CheckboxOptions } from './dynamic-form.interface';
import { ValidationService } from '../../datasource/services/validation/validation.service';
import { ValidationRecord } from '../../datasource/services/validation/validation-pattern.interface';

@Component({
  selector: 'jafar-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class DynamicFormComponent implements  OnInit {
  @Input() form!   : DynamicFormSchema[]
  @Output() selectedOption  = new EventEmitter<any>()

  formBuilder       = inject(FormBuilder)
  validationService = inject(ValidationService)
  dynamicForm : FormGroup  = this.formBuilder.group({})

  // ngOnChanges(){
  //   console.log("dynamic form ngChanges:", this.form)
  //   for (const control of this.form) {
  //     const formValidators = this.getValidators(control.validators)
  //     const formControl    = this.formBuilder.control(control.defaultValue, formValidators)
  //     this.dynamicForm.addControl(control.controlName, formControl)
  //   }
  // }
  ngOnChanges(changes : SimpleChanges){
    console.log(changes)
  }

  ngOnInit(){
    console.log("dynamic form ngChanges:", this.form)
    for (const control of this.form) {
      // controlDisabled = control.textOptions?.disabled == true ? true : false
      // controlDisabled     = control.checkboxOptions?.disabled == true ? true : false
      const formValidators = this.getValidators(control.validators)
      const formControl    = this.formBuilder.control({value: control.defaultValue, disabled: control.disabled}, formValidators)
      this.dynamicForm.addControl(control.controlName, formControl)
    }
  }

  getValidators(validators : any) : Validators | null{
    console.log("Validators :" , validators)
    let allValidators : any[]  = []
    Object.keys(validators).forEach(
      (validator : string ) => {
        const validatoinRecord : ValidationRecord = {
          validatorName : validator,
          validatorOptions : validators[validator]
        }
        allValidators.push(validatoinRecord)
      }
    )
    console.log("Validator List ", allValidators)
    const formValidators = this.validationService.getFormValidators(allValidators)
    return formValidators

  }

  dropdownSelection(selection : any){
    console.log(selection.option.value)
    this.selectedOption.emit(selection.option.value)
  }

}
