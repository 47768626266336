import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, concatMap, map, switchMap, toArray } from 'rxjs';
import { StateStoreService } from 'src/app/core/datasource/services/state-store.service';
import { WorkItem, WorkItemDefault, WorkItemDefaultResponse, WorkItemDetailsDefaultResponse, WorkItemDialogOutput, WorkItemResponse, WorkItemStore, workItemType } from './workitem.interface';
import { UserService } from 'src/app/core/datasource/services/user/user.service';
import { GenericTooltips } from 'src/app/shared/datasource/enums/generic-tooltips.enum';
import { GenericStatus } from 'src/app/shared/datasource/enums/generic-status.enum';
import { DevOps } from 'src/environments/generic-variables';

@Injectable({
  providedIn: 'root'
})
export class WorkitemService extends StateStoreService<WorkItemStore>{
  constructor(
    private http: HttpClient,
    private userService : UserService
  ){
    super(new WorkItemStore());
  }
  // Headers
  getDefaultHeaders(token : string) : HttpHeaders{
    const headers = new HttpHeaders()
    .set('Authorization', `Basic ${token}`)
    .set('Accept', '*/*')
    .set('Content-Type', 'application/json;charset=utf-8')

    return headers
  }

  getPostHeaders(token : string) : HttpHeaders{
    const headers = new HttpHeaders()
    .set('Authorization', `Basic ${token}`)
    .set('Accept', '*/*')
    .set('Content-Type', 'application/json-patch+json;charset=utf-8')

    return headers
  }

  // Get List of Work Items from specific Type
  getWorkItemFromType (workItemType: workItemType) : Observable<WorkItem[]> {
    const headers = this.getDefaultHeaders(DevOps.token)
    const projectName = DevOps[workItemType].projectName
    const queryId     = DevOps[workItemType].queryId
    const url         = `${DevOps.baseUrl}/${projectName}/${DevOps.queryEndpoint}/${queryId}?${DevOps.apiVersion}`

    return this.http.get<WorkItemDefaultResponse>(url, {headers}).pipe(
      // Create a stream of workItems from the array of workItems
      switchMap((response : WorkItemDefaultResponse ) => { return response.workItems }),

      // Get Work Item Details for Each WorkItem Stream (concatMap will wait for response)
      concatMap((workItem :WorkItemDefault) => { return this.getWorkItemInfo(workItem.url)}),

      // Map the properties we want to return
      map((workItemDetails : WorkItemDetailsDefaultResponse ) => (
        {
        Title       : workItemDetails.fields["System.Title"],
        State       : workItemDetails.fields["System.State"],
        Id          : workItemDetails.id,
        ReportedBy  : workItemDetails.fields["Custom.ReportedBy"],
        WorkItemType: workItemDetails.fields["System.WorkItemType"],
        Description : workItemDetails.fields["System.Description"],
        StateIcon   : GenericStatus[workItemDetails.fields["System.State"] as keyof typeof GenericStatus],
        ToolTip     : GenericTooltips[workItemDetails.fields["System.State"] as keyof typeof GenericTooltips]
      } as WorkItem )),

      // Convert All streams back to an array (this might need amending to use reduce ??)
      toArray(),
    )
  }

  // Get Specific Work Item info
  getWorkItemInfo(url : string){
    const headers = this.getDefaultHeaders(DevOps.token)

    const newUrl = url
    return this.http.get<any>(newUrl,{headers})
  }

  // Function to create a new work-item based off of the workitem category and details
  newWorkItem(category : workItemType , details : WorkItemDialogOutput ) : Observable<any>{
    const headers     = this.getPostHeaders(DevOps.token)
    const projectName = DevOps[category].projectName
    const type        = DevOps[category].type
    const url         = `${DevOps.baseUrl}/${projectName}/${DevOps.workItemEndpoint}/${type}?${DevOps.apiVersion}`

    const body = [
        {op: "add", path: "/fields/System.Title"                  , value: details.Title },
        {op: "add", path: "/fields/System.Description"            , value: details.Description },
        {op: "add", path: "/fields/System.AreaPath"               , value: DevOps[category].area },
        {op: "add", path: "/fields/System.IterationPath"          , value: DevOps[category].iteration },
        {op: "add", path: "/fields/System.Tags"                   , value: DevOps[category].tag },
        {op: "add", path: "/fields/Custom.ReportedBy"             , value: details.User},
        {op: "add", path: "/fields/Custom.ReportedBy_mailcontact" , value: details.Mail }
    ];
    console.log("POST BODY : ", body)
    return this.http.post<WorkItemResponse>(url, body, {headers})
  }
}
