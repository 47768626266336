import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogInput, DialogOutput } from '../../interfaces/dialog.interface';
import { Observable } from 'rxjs';
import { SnackbarService } from 'src/app/shared/datasource/services/snackbar/snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  private openedDialog! : any
  constructor(
    public dialog: MatDialog,
    public snackBar: SnackbarService,
  ) { }

  openDialog(dialogInput : DialogInput){
    if (dialogInput.data) {
      this.openedDialog = this.dialog.open(dialogInput.component,{data: dialogInput.data, disableClose: true})
    }
    else {
      this.openedDialog = this.dialog.open(dialogInput.component)
    }
  }

  onClose() : any{
    // Display snackBar if the popup has returned a snack bar config
    // this.openedDialog.afterClosed().subscribe(
    //   (returnData : DialogOutput) => {
    //     this.showSnackBar(returnData)
    //   }
    // )
    // We return the observable so the parent component can subscribe to it
    return this.openedDialog.afterClosed()
  }

  // private showSnackBar(data : any)  {
  //   if (data.snackbarConfig) {
  //     this.snackBar.openSnackBar(data.snackbarConfig)
  //   }
  // }



}
