import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { DeleteAadApplicationPayload } from './delete-app.interface';
import {SnackBarStyle} from 'src/app/shared/ui/snackbar/snackbar.interface'
import { AzureApp } from 'src/app/shared/datasource/interfaces/azure-app.interface';
import { CipService } from 'src/app/shared/datasource/services/http-requests/azure-functions/cip/cip.service';
import { AadAppregService } from '../../../../../shared/datasource/services/http-requests/azure-functions/application-registrations/aad-appreg.service';
import { DialogOutput } from 'src/app/shared/datasource/interfaces/dialog.interface';

@Component({
  selector: 'jafar-delete-azureapp-popup',
  templateUrl: './delete-azure-app-popup.component.html',
  styleUrls: ['./delete-azure-app-popup.component.scss']
})
export class DeleteAzureAppPopup {
  typedAppName : string = ""
  submitButtonDisabled : boolean = true

  constructor(
    private jeannieService: CipService,
    private aadAppregService : AadAppregService,
    public dialogRef: MatDialogRef<DeleteAzureAppPopup>,
    @Inject(MAT_DIALOG_DATA) public data: AzureApp
  ) {}

  validateName(event: Event){
    console.log(event)
    const userInput = (event.target as HTMLInputElement).value;
    console.log(userInput)
    console.log(this.data)
    if( userInput === this.data.displayName){
      this.submitButtonDisabled = false
    }
    // need to include the else otherwise nothing sets the button back to disabled if it no longer matches
    else {
      this.submitButtonDisabled = true
    }
  }

  deleteApplication(){
    const appObjId = this.data.id
    this.aadAppregService.deleteApplication(appObjId)
  }

  onCancel(): void {
    this.dialogRef.close(false)
  }

  onSubmit(){
    const ReturnedData : DialogOutput = {
      data : {
        status:"Success"
      },
      snackbarConfig: {
        buttonName: "OK",
        message: "Application Has successfully been deleted",
        style : SnackBarStyle.default,
        settings: {
          horizontalPosition: 'center',
          verticalPosition:'top'
        }
      }
    }
    this.dialogRef.close(ReturnedData)
    // console.log("Delete Application is currently turned Off")
    this.deleteApplication()
  }

}
