<mat-checkbox class="form-checkbox"
  [formControl]="control"
  color      = "primary"
  [value]    = "checkboxValue"
  [checked]  = "checkboxChecked"
  (change)   = "onCheckClick($event)"
>
  {{checkboxOptions?.displayName}}
</mat-checkbox>

