import { ChangeDetectorRef, Component, OnInit, ViewChild, inject } from '@angular/core';
import { CIPEnvRouteUpdateRequest } from 'src/app/shared/datasource/interfaces/CIP/cip-envRoute.interface';
import { DynamicFormComponent } from 'src/app/shared/ui/dynamic-form/dynamic-form.component';
import { DynamicFormSchema } from 'src/app/shared/ui/dynamic-form/dynamic-form.interface';
import { SidePanelStoreService } from 'src/app/shared/ui/templates/pages/page-with-sidepanel/side-panel-store.service';
import { TablePageType } from '../env-route-sidepanel/env-route-sidepanel.component';
import { CipEnvRouteDefaultStore } from 'src/app/shared/datasource/stores/cip/cip-envroutedefault-store.service';
import envRouteDefaultJson from 'src/assets/config/dynamic-forms/envroutedefault-form.json'
import { CIPEnvRouteDefault } from 'src/app/shared/datasource/interfaces/CIP/cip-envRouteDefault.interface';
import { UserStore } from 'src/app/core/datasource/services/user/user-store.service';

@Component({
  selector: 'jafar-env-route-default-sidepanel',
  templateUrl: './env-route-default-sidepanel.component.html',
  styleUrls: ['./env-route-default-sidepanel.component.scss']
})
export class EnvRouteDefaultSidepanelComponent implements OnInit {
  @ViewChild("formComponents") formComponents! : DynamicFormComponent

  // Inject Services \ Stores
  sidePanelStore  = inject(SidePanelStoreService)
  userStore       = inject(UserStore)
  defaultEnvRouteStore   = inject(CipEnvRouteDefaultStore)
  changeDetectorRef = inject(ChangeDetectorRef)
  // Objects that contain the form Configuration
  defaultEnvRouteForm  : DynamicFormSchema[] = envRouteDefaultJson as DynamicFormSchema[]

  // Page Routing \ Layout Options
  routeTitle      : string        = "Route Details"
  showPage        : TablePageType = "viewRouteForm"
  showActionIcons : boolean       = true
  editPageFirstLoad : boolean = false

  ngOnInit(){
    this.setDefaults()
  }

  editFormLoad(){
    if (!this.editPageFirstLoad){
      console.log("Triggering Change Detection")
      this.changeDetectorRef.detectChanges()
      this.changeDetectorRef.markForCheck()
      this.editPageFirstLoad = true
    }
  }
  setDefaults(){
    this.defaultEnvRouteForm.forEach(
      formComponent => {
        const envProperty = formComponent.controlName as keyof CIPEnvRouteDefault
        formComponent.defaultValue = this.defaultEnvRouteStore.selectedRouteDefault[envProperty]
      }
    )
  }

  onGoBack(){
    this.sidePanelStore.setIsOpen(false)
  }

  onCancel(){
    if (this.formComponents) {
      this.setDefaults()
    }
    this.routeTitle = "Route Details"
    this.showActionIcons = true
    this.showPage = "viewRouteForm"
  }

  onViewJsonIcon(){
    this.showPage = "showJson"
    this.routeTitle = "View Route as Json"
    this.showActionIcons = false
  }

  onEditRouteIcon(){
    this.showPage        = "editRouteForm"
    this.routeTitle      = "Editting Route"
    this.showActionIcons = false
  }

  onDeleteRouteIcon(){
    this.showPage        = "deleteRouteForm"
    this.showActionIcons = false
    this.routeTitle      = "Delete Route"
  }

  onUpdate(){
    const routeId = this.defaultEnvRouteStore.selectedRouteDefault.id
    const updateRoute: CIPEnvRouteUpdateRequest ={
      routeName : this.formComponents.dynamicForm.get('routeName')?.value.routeId,
      config    : this.formComponents.dynamicForm.get('config')?.value,
      active    : this.formComponents.dynamicForm.get('active')?.value.length > 0 ? true : false,
      updateAssociatedItems: false
    }
    this.defaultEnvRouteStore.editEnvRouteDefault(routeId, updateRoute)
    this.onGoBack()
  }

  onDelete(){
    const routeId = this.defaultEnvRouteStore.selectedRouteDefault.id
    this.defaultEnvRouteStore.deleteEnvRouteDefault(routeId)
    this.onGoBack()
  }
}
