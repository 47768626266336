<mat-card class="card-appearance">
    <mat-card-header class="mat-header">
        <h1>Manage CIP Customers</h1>
        <span class="button-group">
        <button mat-button class="action-button" color="accent" (click)="openCreateDialog()"> Create Customer</button>
        <mat-divider class="button-divider" [vertical]="true"></mat-divider>
        <button mat-button [disabled]="true" color="accent" class="action-button"> Manage Customer</button>
        <mat-divider class="button-divider" [vertical]="true"></mat-divider>
        <button mat-button [disabled]="true" color="accent" class="action-button"> Delete Customer</button>
        <span class="refresh-button-spacing"></span>
        <mat-icon matTooltip="Refresh" (click)="getCustomerList()" class="item-alignment">sync</mat-icon>
        </span>
    </mat-card-header>
    <mat-divider class="divider"></mat-divider>
<ng-container    *ngIf="(spinnerService.visibility | async); then spinner; else content"></ng-container>

<ng-template #spinner>
  <div class="spinner-container">
      <mat-spinner color="accent" class="spinner"></mat-spinner>
      <br>
      <br>
      <h1 class="spinner-title">Loading Customers...</h1>
  </div>
</ng-template>

<ng-template #content>
  <mat-card class="card-appearance">
    <mat-card-content>
        <jafar-paginated-table [tableInput]="tableData" (selectedRecord)="onSelection($event)"></jafar-paginated-table>
    </mat-card-content>
  </mat-card>
</ng-template>
